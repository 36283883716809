/* eslint-disable no-console */
/* eslint-disable react/jsx-no-undef */
import { LeftOutlined, SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Collapse, Input, Tabs, notification } from 'antd';
import { Style } from 'ol/style';
import React, { useContext, useEffect, useState } from 'react';
import bStationIcon from '../../assets/images/bStationGreen.svg';
import fleetListIcon from '../../assets/images/fleet_list-01.svg';
import polygon_icon from '../../assets/images/polygon_icon.svg';
import tractorIcon from '../../assets/images/tractorGreen.svg';
import mpl_waypoints from '../../assets/images/waypoint_single.svg';

import { getBasestationList } from '../../constants/Api';
import {
  alignItems,
  checkIsTractorConnected,
  fleetListTabs,
  getConnectivityImg,
  getOperationNewStatus,
  indicatorsOrder,
  tractorConstantsNew,
  wrapIndictorsData,
} from '../../constants/Common';
import constant from '../../constants/constant';
import {
  MapFeature,
  TractorHeartBeat,
  TractorsList,
} from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import { SET_TRACTORS_LIST } from '../../context/actions';
import TermsCond from '../auth/TermsCond';
import AutoDriveFeedBackPop from '../livecommand/AutoDriveFeedBackPop';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';
import RemoteDriveDetailsNew from '../remote_drive_new/RemoteDriveDetailsNew';
import {
  SELECTED_TRACTOR,
  SELECTED_TRACTORS,
  SET_PINNED_TRACTORS,
  SET_SELECTED_OPENED_FLEET_TAB,
  SET_SHOW_CONNECT_LOADER,
  SET_SHOW_CONNECT_TRY_AGAIN_POP,
  SET_SHOW_REMOTE_DRIVE_DETAILS,
  SET_SHOW_TERM_AND_COND,
} from '../remote_drive_new/actions';
import './../livecommand/autodrive.css';
import './../livecommand/style.css';
import './../remote_drive_new/autodrivelist.css';
import TractorList from './TractorList';
import './style.css';
const { TRACTOR_COMMUNICATION } = constant;

export const baseData: MapFeature[] = [
  {
    id: 1,
    name: 'Ground Zero',
    mapId: '1',
    type: 'BASESTATION',
    visible: true,
    style: new Style({}),
  },
];
const { Panel } = Collapse;
const { TabPane } = Tabs;
interface Props {
  recenterItem: (item: MapFeature) => void;
  setRecenterTabId: (tractorId: number) => void;
  heartBeat: TractorHeartBeat;
  selectedTractorFromFleetId: number;
  polygons: MapFeature[];
  initTravelledPath: (tractorId: number, tractors?: any[]) => void;
  clearPreviewPath: (id: number) => void;
  setSelectedTractorsForMaps: (selectedTractors: any) => void;
  addRowFollowRVCommand: () => void;
  preCommandState: string;
  handleResetCommand: (state: string) => void;
  setRedirectToggle: (toggle: boolean) => void;
  redirectToggle: boolean;
}
const LiveMapFleetList: React.FC<Props> = ({
  recenterItem,
  setRecenterTabId,
  heartBeat,
  selectedTractorFromFleetId,
  polygons,
  initTravelledPath,
  clearPreviewPath,
  setSelectedTractorsForMaps,
  addRowFollowRVCommand,
  preCommandState,
  handleResetCommand,
  setRedirectToggle,
  redirectToggle,
}: Props) => {
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const {
    RDReducer,
    mapPinnedTractors,
    connectToTractor,
    selectedTractorsIds,
    unPinnedTractors,
    clearAllLocalDBCommands,
  } = useContext(RemoteDriveAppCtx);
  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const [autoDriveState] = AutoDriveReducer;
  const [appState, appDispatch] = APPReducer;
  const { tractors, tractorsList, showAutoDriveConnect } = appState;
  const { isPinned, drivingTractors } = autoDriveState;
  const [state, dispatch] = RDReducer;
  const {
    showRemoteDriveDetails,
    selectedTractor,
    selectedTractors,
    showTermAndCond,
    showAutoDriveFeedBackPop,
    pinnedTractors,
    showConnectLoader,
    selectedOpenedFleetTab,
    waypointsForRemoteAv,
  } = state;
  const pageSize = 100;
  const pageNumber = 1;
  const [baseStations, setBaseStations] = useState<any[]>([]);
  const [fleetId, setFleetId] = useState<any>('Tractor');
  const [autoValue, setAutoValue] = useState('');
  const [options, setOptions] = useState<any>();
  const [tabid, setTabid] = useState<any>('');
  useEffect(() => {
    showConnectLoader &&
      tractorsList &&
      tractorsList.length &&
      tractorsList.length > 0 &&
      tractorsList.map((tractor: TractorsList) => {
        const { heartbeat } = tractor;
        if (
          heartbeat &&
          heartbeat.tractor_id &&
          selectedTractor &&
          selectedTractor.id &&
          heartbeat.tractor_id == selectedTractor.id &&
          checkIsTractorConnected(tractor, userDetails.id) &&
          getOperationNewStatus(heartbeat) !== tractorConstantsNew.available.key
        ) {
          dispatch({
            type: SET_SHOW_TERM_AND_COND,
            payload: false,
          });
          dispatch({
            type: SET_SHOW_CONNECT_LOADER,
            payload: false,
          });
          dispatch({
            type: SET_SHOW_CONNECT_TRY_AGAIN_POP,
            payload: false,
          });
        }
      });
  }, [tractorsList, selectedTractor, showConnectLoader]);

  useEffect(() => {
    setSelectedTractorsForMaps(selectedTractors);
  }, [selectedTractors]);

  const genExtra = () => (
    <img
      src={fleetListIcon}
      className="flIconArrow"
      onClick={(event) => {
        event.stopPropagation();
      }}
    />
  );
  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      tractors &&
      tractors.length > 0
    ) {
      getTractorsList();
    }
  }, [userDetails, tractors]);

  const getTractorsList = async () => {
    try {
      let records = [];
      records = tractors && tractors.length > 0 ? tractors : [];
      const list = records && records.length > 0 ? records : [];
      list.forEach((element: TractorsList) => {
        (element.housemeter = element.heartbeat
          ? element.heartbeat.hour_meter &&
            `${(Number(element.heartbeat?.hour_meter) / 3600).toFixed()} hrs`
          : ''),
          (element.indicatorsData = []);
        element.isAvailable =
          element?.is_tractor_in_service === 1
            ? tractorConstantsNew.service.key
            : element.connection === 'offline'
            ? tractorConstantsNew.off.key
            : getOperationNewStatus(element?.heartbeat as TractorHeartBeat);
        if (element.name === 'TS2')
          element.isAvailable = tractorConstantsNew.available.key;
        for (const arrangeIndicator of indicatorsOrder) {
          for (const indicator of element.indicators) {
            if (arrangeIndicator === indicator.indicator_name) {
              element.indicatorsData.push(indicator);
            }
          }
        }
      });
      let newList: TractorsList[] = [];

      newList = alignItems(list, userDetails.id);
      newList = newList.map((item) => {
        item['w_indicators'] = wrapIndictorsData(item.indicators);
        item.connectivity = getConnectivityImg(
          item['w_indicators'][TRACTOR_COMMUNICATION],
        );
        return item;
      });

      appDispatch({
        type: SET_TRACTORS_LIST,
        payload: newList,
      });
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const onSelect = (data: string) => {
    setAutoValue(data);
    if (data) {
      if (tractorsList && tractorsList.length > 0) {
        const tractorId = selectedTractor?.id;
        for (const tractor of tractorsList) {
          if (
            tractor &&
            tractor.name &&
            tractor.name === data &&
            tractorId !== tractor.id
          ) {
            setTractorSelection(tractor.id);
          }
        }
        for (const polygon of polygons) {
          if (polygon && polygon.name && polygon.name === data) {
            recenterItem(polygon);
          }
        }
        for (const baseStation of baseStations) {
          if (baseStation && baseStation.name && baseStation.name === data) {
            // to redirect base station statically
            recenterItem(baseData[0]);
          }
        }
        for (const waypoint of waypointsForRemoteAv) {
          if (waypoint && waypoint.name && waypoint.name === data) {
            recenterItem(waypoint);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (
      redirectToggle &&
      selectedTractorFromFleetId &&
      tractorsList &&
      tractorsList.length > 0
    ) {
      setTractorSelection(selectedTractorFromFleetId);
      setRedirectToggle(false);
    }
  }, [selectedTractorFromFleetId, tractorsList, redirectToggle]);
  const setTractorSelection = (tractorId: number) => {
    if (tractorsList && tractorsList.length > 0) {
      for (const tractor of tractorsList) {
        if (tractor && tractor.id && tractor.id === Number(tractorId)) {
          setFleetId('Tractor');
          setTractorTabes(tractor);
        }
      }
    }
  };
  const isPinnedFun = (id: number): boolean => pinnedTractors.includes(id);
  const setTractorTabes = (tractor: TractorsList) => {
    setAutoValue('');
    const tractorsConst = selectedTractors;
    let exist = false;
    // checking tractor tab already opened or not
    if (tractorsConst && tractorsConst.length > 0) {
      for (const iterator of tractorsConst) {
        if (iterator.id && tractor.id && iterator.id === tractor.id) {
          exist = true;
        }
      }
    }
    // change to details view
    // recenter the tractor position to map center
    // to select particular tab
    setTabid(`${tractor.id}`);
    // to maintain tabs
    if (isPinned) {
      if (pinnedTractors.length == 1) {
        if (exist || isPinnedFun(tractor.id)) {
          dispatch({
            type: SELECTED_TRACTORS,
            payload: [],
          });
          unPinnedTractors(tractor.id);
        } else {
          mapPinnedTractors(tractor.id);
          dispatch({
            type: SELECTED_TRACTORS,
            payload: [tractor],
          });
        }
      } else if (pinnedTractors.length > 1) {
        if (exist) {
          const list = pinnedTractors.filter(
            (item: any) => item !== tractor.id,
          );
          dispatch({
            type: SET_PINNED_TRACTORS,
            payload: list,
          });
          const [ftractor] = tractorsList.filter(
            (tractor: any) => tractor.id === list[0],
          );
          dispatch({
            type: SELECTED_TRACTORS,
            payload: [ftractor],
          });
        } else {
          if (isPinnedFun(tractor.id)) {
            const list = pinnedTractors.filter(
              (item: any) => item !== tractor.id,
            );
            dispatch({
              type: SET_PINNED_TRACTORS,
              payload: list,
            });
            const [ftractor] = tractorsList.filter(
              (tractor: any) => tractor.id === list[0],
            );
            dispatch({
              type: SELECTED_TRACTORS,
              payload: [ftractor],
            });
          } else {
            mapPinnedTractors(tractor.id);
            dispatch({
              type: SELECTED_TRACTORS,
              payload: [tractor],
            });
          }
        }
      } else {
        if (exist) {
          dispatch({
            type: SELECTED_TRACTORS,
            payload: [],
          });
          unPinnedTractors(tractor.id);
        } else {
          dispatch({
            type: SELECTED_TRACTORS,
            payload: [tractor],
          });
          mapPinnedTractors(tractor.id);
        }
      }
    } else {
      if (exist) {
        dispatch({
          type: SELECTED_TRACTORS,
          payload: [],
        });
      } else {
        dispatch({
          type: SELECTED_TRACTORS,
          payload: [tractor],
        });
      }
    }
  };
  const onSearch = (searchText: string) => {
    if (searchText) {
      const objects: any = [];
      tractorsList &&
        tractorsList.forEach((value: TractorsList) => {
          if (
            value.name &&
            value.name.toLowerCase().includes(searchText.toLowerCase())
          ) {
            objects.push({
              label: (
                <div className="flSearchSec">
                  <img src={tractorIcon} alt="tractor" width="18" height="18" />

                  <div
                    className="flSearchName"
                    data-testid={`${value.name}-LiveMapFleetList`}
                  >
                    {value.name}
                  </div>
                </div>
              ),
              value: value.name,
            });
          }
        });
      polygons &&
        polygons.forEach((value: MapFeature) => {
          if (
            value.name &&
            value.name.toLowerCase().includes(searchText.toLowerCase())
          ) {
            objects.push({
              label: (
                <div className="flSearchSec">
                  <img
                    src={polygon_icon}
                    alt="tractor"
                    width="13"
                    height="18"
                  />
                  <div className="flSearchName">{value.name}</div>
                </div>
              ),
              value: value.name,
            });
          }
        });
      baseStations &&
        baseStations.forEach((value: MapFeature) => {
          if (
            value.name &&
            value.name.toLowerCase().includes(searchText.toLowerCase())
          ) {
            objects.push({
              label: (
                <div className="flSearchSec">
                  <img
                    src={bStationIcon}
                    alt="tractor"
                    width="13"
                    height="18"
                  />
                  <div className="flSearchName">{value.name}</div>
                </div>
              ),
              value: value.name,
            });
          }
        });
      waypointsForRemoteAv &&
        waypointsForRemoteAv.length > 0 &&
        waypointsForRemoteAv.forEach((value: MapFeature) => {
          if (
            value.name &&
            value.name.toLowerCase().includes(searchText.toLowerCase())
          ) {
            objects.push({
              label: (
                <div className="flSearchSec">
                  <img
                    src={mpl_waypoints}
                    alt="tractor"
                    width="13"
                    height="18"
                  />
                  <div className="flSearchName">{value.name}</div>
                </div>
              ),
              value: value.name,
            });
          }
        });
      objects && objects.length === 1 && onSelect(objects[0].value);
      setOptions(objects);
    } else {
      setOptions([]);
    }
  };

  useEffect(() => {
    if (heartBeat && heartBeat.tractor_id) {
      const sTractors = selectedTractors;
      if (sTractors && sTractors.length > 0) {
        for (const tractor of sTractors) {
          if (
            tractor &&
            tractor.id &&
            heartBeat.tractor_id &&
            tractor.id == heartBeat.tractor_id
          ) {
            tractor.isAvailable = getOperationNewStatus(
              heartBeat as TractorHeartBeat,
            );
            tractor.heartbeat = heartBeat;
            // to update tractor details
            selectedTractor &&
              selectedTractor.id &&
              selectedTractor.id === tractor.id &&
              dispatch({
                type: SELECTED_TRACTOR,
                payload: tractor,
              });
            dispatch({
              type: SELECTED_TRACTORS,
              payload: sTractors,
            });
          }
        }
      }
      let tractorsConst: TractorsList[] = tractorsList;
      if (tractorsConst && tractorsConst.length > 0) {
        for (const tractor of tractorsConst) {
          if (
            tractor &&
            tractor.id &&
            heartBeat.tractor_id &&
            tractor.id == heartBeat.tractor_id
          ) {
            tractor.isAvailable = getOperationNewStatus(
              heartBeat as TractorHeartBeat,
            );
            tractor.heartbeat = heartBeat;
            tractorsConst = alignItems(tractorsConst, userDetails.id);
            appDispatch({
              type: SET_TRACTORS_LIST,
              payload: tractorsConst,
            });
          }
        }
      }
    }
  }, [heartBeat]);

  useEffect(() => {
    initTravelledPath(
      tabid === '' ? 0 : tabid,
      tabid === '' ? [] : tractorsList,
    );
  }, [tabid]);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getBasestations();
    }
    return () => {
      localStorage.removeItem('cam_views');
    };
  }, [userDetails, pageSize]);

  const getBasestations = async () => {
    try {
      const baserecords = await getBasestationList(
        userDetails.organization.api_url,
        userDetails.organization.id,
        pageNumber,
        pageSize,
        '',
        '',
      );
      setBaseStations(baserecords.records);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  // added this code to show tractor is selected
  useEffect(() => {
    if (selectedTractors && selectedTractors.length > 0) {
      if (tractorsList && tractorsList.length > 0) {
        tractorsList.map((tractor: TractorsList) => {
          tractor.selected = false;
        });
        selectedTractors.map((item: any) => {
          tractorsList.map((tractor: TractorsList) => {
            if (item.id === tractor.id) {
              tractor.selected = true;
            }
          });
        });
      }
    } else {
      if (tractorsList && tractorsList.length > 0) {
        tractorsList.map((tractor: TractorsList) => (tractor.selected = false));
      }
    }
    tractorsList &&
      tractorsList.length > 0 &&
      appDispatch({
        type: SET_TRACTORS_LIST,
        payload: tractorsList,
      });
  }, [selectedTractors, tabid]);

  useEffect(() => {
    // recenter the tractor functionality
    setRecenterTabId(Number(tabid));
  }, [userDetails, tabid]);

  useEffect(() => {
    dispatch({
      type: SET_SHOW_REMOTE_DRIVE_DETAILS,
      payload: false,
    });
    let id = '';
    handleResetCommand('');
    if (selectedTractors && selectedTractors.length) {
      const [item] = selectedTractors;
      id = item.id;
      dispatch({
        type: SET_SHOW_REMOTE_DRIVE_DETAILS,
        payload: true,
      });
      dispatch({
        type: SELECTED_TRACTOR,
        payload: item,
      });
      const remoteAvTractor = item.heartbeat;
      if (
        remoteAvTractor &&
        remoteAvTractor.drive_action_details &&
        remoteAvTractor.drive_action_details.current_drive_action &&
        remoteAvTractor.drive_action_details.current_drive_action.length > 0 &&
        getOperationNewStatus(remoteAvTractor) !==
          tractorConstantsNew.charging.key &&
        remoteAvTractor.drive_action_details.current_operator_id &&
        remoteAvTractor?.drive_action_details?.current_operator_id ===
          userDetails?.id &&
        getOperationNewStatus(remoteAvTractor) !==
          tractorConstantsNew.remote_av.key &&
        getOperationNewStatus(remoteAvTractor) !==
          tractorConstantsNew.copycat.key
      ) {
        setTimeout(() => {
          handleResetCommand('GOTO');
        }, 100);
      }
      setTabid(`${id}`);
    } else {
      dispatch({
        type: SELECTED_TRACTOR,
        payload: null,
      });
      setTabid('');
    }
  }, [selectedTractors]);

  return (
    <>
      <div className="fleetlistBlk" id="fleet-list-block">
        <div className="searchMapBlk fListSearchMain">
          <SearchOutlined />
          <AutoComplete
            options={options}
            onSelect={onSelect}
            onSearch={onSearch}
            value={autoValue}
            dropdownClassName="popuplist"
          >
            <Input
              data-testid="searchMapInputField-LiveMapFleetList"
              placeholder="Search Map"
              autoComplete="off"
              onChange={(e) => setAutoValue(e.target.value)}
              className="fListSearch"
            />
          </AutoComplete>
        </div>
        <div
          className={`operationsBlk ${
            drivingTractors && drivingTractors.length > 0 ? 'dmenHandle' : ''
          }`}
          data-testid="fleetListSelector-LiveMapFleetList"
        >
          <Collapse
            expandIconPosition="right"
            className="parentAccordion parentIcon"
            expandIcon={({ isActive }) => (
              <LeftOutlined
                onClick={(event) => {
                  setFleetId(fleetId === 'Tractor' ? '0' : 'Tractor');
                  event.stopPropagation();
                }}
                rotate={isActive ? 360 : 270}
              />
            )}
            activeKey={fleetId}
          >
            <Panel
              header="FLEET LIST"
              className="operationsTitle"
              key="Tractor"
              extra={genExtra()}
            >
              <TractorList
                key="tractorAutoDriveList"
                tractors={tractorsList}
                setTractorTabes={setTractorTabes}
                keyFilter={fleetListTabs.fleetListAutoDriveList}
              />
              <div className="childAccordion2 fleetOverallBlk">
                <TractorList
                  key="tractorList"
                  tractors={tractorsList}
                  setTractorTabes={setTractorTabes}
                  keyFilter={fleetListTabs.fleetListTractorsList}
                />
              </div>
            </Panel>
          </Collapse>
        </div>

        {selectedTractor && selectedTractor.id && showRemoteDriveDetails && (
          <RemoteDriveDetailsNew
            clearPreviewPath={clearPreviewPath}
            handleResetCommand={handleResetCommand}
            preCommandState={preCommandState}
            addRowFollowRVCommand={addRowFollowRVCommand}
          />
        )}

        {showTermAndCond && (
          <TermsCond
            handleCallback={(flag: boolean) => {
              if (
                flag &&
                selectedTractorsIds &&
                selectedTractorsIds.length > 0
              ) {
                dispatch({
                  type: SET_SELECTED_OPENED_FLEET_TAB,
                  payload: fleetListTabs.fleetListAutoDriveList,
                });
                selectedTractorsIds &&
                  selectedTractorsIds.length > 0 &&
                  connectToTractor(selectedTractorsIds[0]);
                clearPreviewPath(selectedTractorsIds[0]);
                clearAllLocalDBCommands(selectedTractorsIds[0]);
              }
              dispatch({
                type: SET_SHOW_TERM_AND_COND,
                payload: false,
              });
            }}
          />
        )}
        {showAutoDriveFeedBackPop && (
          <AutoDriveFeedBackPop showModal={showAutoDriveFeedBackPop} type={1} />
        )}
      </div>
    </>
  );
};

export default LiveMapFleetList;
