import React, { useState } from 'react';
import ImplementsList from './ImplementsList';
import Implements from './Implements';
import MSHeader from '../layouts/MSHeader';
import { useHistory } from 'react-router-dom';
import RoutesConstants from '../../routes/RoutesConstant';

const ImplementsContainer: React.FC = () => {
  const { push } = useHistory();
  const [addNewImplement, setAddNewImplement] = useState<boolean>(false);
  return (
    <>
      <div className="dashboardContainer impCont">
        <MSHeader
          title="Implements Directory"
          handleBack={() => push(RoutesConstants.FarmProfile)}
        />
        {addNewImplement ? (
          <ImplementsList setAddNewImplement={setAddNewImplement} />
        ) : (
          <Implements setAddNewImplement={setAddNewImplement} />
        )}
      </div>
    </>
  );
};

export default ImplementsContainer;
