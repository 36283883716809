import { useEffect, useState } from 'react';
import moment from 'moment';
import { useApplicationContext } from '../../../../context/AppContext';
import {
  dealerEmployeesFleetSummaryDetails,
  dealerFleetHealthDetails,
  dealerFleetUsageDetails,
  dealerOperationalHours,
  dealerSummaryDetails,
  getDealerEnergyCostSavings,
  getDealerTractorList,
} from '../../../../constants/Api';
import { notification } from 'antd';
import useDealerDashboardStore from '../useDealerDashboardStore';
import { getTotalHours, getValue } from '../../../operationalAnalytics/common';

const useDealerDashboard = (init = false) => {
  const { userDetails } = useApplicationContext();
  const { setDealerDashboardSummary, dealerDashboardSummary } =
    useDealerDashboardStore();
  const initDealerSummary = async () => {
    try {
      const { organization } = userDetails;
      const response = await dealerSummaryDetails(organization.api_url);
      const empFleetresponse = await dealerEmployeesFleetSummaryDetails(
        organization.api_url,
      );
      setDealerDashboardSummary({
        fleet: empFleetresponse.fleet_tractor_count,
        employees: empFleetresponse?.employee_count,
        tickets: response?.ticket_count,
        customers: response?.customer_count,
      });
    } catch (error: any) {
      notification.error({
        message: `useDealerDashboard: ${error?.message}`,
      });
    }
  };
  useEffect(() => {
    if (userDetails?.organization && init) initDealerSummary();
  }, [userDetails]);
  return { dealerDashboardSummary };
};

interface SavingData {
  totalCo2Savings: string;
  totalSavings: string;
  totalOperationalHours: string;
}

export const userDealerOperationalHours = () => {
  const { userDetails } = useApplicationContext();
  const [loading, setLoading] = useState(false);
  const [operationalHours, setOperationalHours] = useState<any>([]);
  const [savingsData, setSavingsData] = useState<SavingData>({
    totalCo2Savings: '',
    totalSavings: '',
    totalOperationalHours: '',
  });

  const initDealerOperationalHours = async (cropType: string) => {
    try {
      setLoading(true);
      const { organization } = userDetails;
      const { hours_of_operation, total_hours } = await dealerOperationalHours(
        organization.api_url,
        cropType,
      );
      const response = await getDealerEnergyCostSavings(
        organization.api_url,
        cropType,
      );
      setSavingsData({
        totalCo2Savings: response.total_co2_saved,
        totalSavings: response.total_saving,
        totalOperationalHours: getTotalHours(total_hours),
      });
      const data = hours_of_operation.map((item: any) =>
        Object.assign({
          category: moment(item.action_date).format('ddd MM/DD'),
          value1: getValue(item?.manual_hours),
          value2: getValue(item?.autonomy_hours),
          value3: getValue(item?.op_assist_hours),
          value4: getValue(item?.idle_hours),
          time1: getTotalHours(item?.manual_hours),
          time2: getTotalHours(item?.autonomy_hours),
          time3: getTotalHours(item?.op_assist_hours),
          time4: getTotalHours(item?.idle_hours),
          total: getTotalHours(item?.total_operation_hours),
        }),
      );
      setOperationalHours(data);
    } catch (error: any) {
      notification.error({
        message: `useDealerFleetUsage: ${error?.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  return { initDealerOperationalHours, loading, operationalHours, savingsData };
};

export const useDealerTractors = () => {
  const { userDetails } = useApplicationContext();
  const [tractors, setTractors] = useState<any>([]);
  const initDealerTractors = async () => {
    try {
      const { organization } = userDetails;
      let response = await getDealerTractorList(organization.api_url);
      response = response.map(({ tractor_id, tractor_name }: any) => ({
        value: tractor_id,
        name: tractor_name,
      }));
      setTractors(response);
    } catch (error: any) {
      notification.error({
        message: `useDealerTickets: ${error?.message}`,
      });
    }
  };
  useEffect(() => {
    if (userDetails?.organization) initDealerTractors();
  }, [userDetails]);

  return [tractors];
};

export const useDealerFleetHelath = () => {
  const { userDetails } = useApplicationContext();
  const { setFleetHealthData, fleetHealthData } = useDealerDashboardStore();
  const [loading, setLoading] = useState(false);
  const [tractorId, setTractorId] = useState(0);

  const initDealerFleetHealthDetails = async () => {
    try {
      setLoading(true);
      const { organization } = userDetails;
      const response = await dealerFleetHealthDetails(
        organization.api_url,
        tractorId,
      );
      setFleetHealthData({
        level1: response.indicator_level1_count,
        level2: response?.indicator_level2_count,
        level3: response?.indicator_level3_count,
        total: response?.total_active_errors,
      });
    } catch (error: any) {
      notification.error({
        message: `useDealerFleetHelath: ${error?.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userDetails?.organization && tractorId) initDealerFleetHealthDetails();
  }, [userDetails, tractorId]);

  return {
    initDealerFleetHealthDetails,
    fleetHealthData,
    loading,
    tractorId,
    setTractorId,
  };
};

export const useDealerFleetUsage = () => {
  const { userDetails } = useApplicationContext();
  const { setFleetUsageData, fleetUsageData } = useDealerDashboardStore();
  const [loading, setLoading] = useState(false);

  const initDealerFleetUsageDetails = async () => {
    try {
      setLoading(true);
      const { organization } = userDetails;
      const response = await dealerFleetUsageDetails(organization.api_url);
      if (response) {
        const {
          manual_hours,
          auto_drive,
          operator_assist,
          idle_hours,
          total_hours,
        } = response;
        setFleetUsageData({
          manualHours: getTotalHours(manual_hours),
          autonomyHours: getTotalHours(auto_drive),
          opAssistHours: getTotalHours(operator_assist),
          idleTimeHours: getTotalHours(idle_hours),
          totalHours: getTotalHours(total_hours),
          manual: getValue(manual_hours),
          autonomy: getValue(auto_drive),
          idleTime: getValue(idle_hours),
          opAssist: getValue(operator_assist),
          total: getValue(manual_hours),
        });
      }
    } catch (error: any) {
      notification.error({
        message: `useDealerFleetUsage: ${error?.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userDetails?.organization) initDealerFleetUsageDetails();
  }, [userDetails]);

  return { initDealerFleetUsageDetails, fleetUsageData, loading };
};

export default useDealerDashboard;
