/* eslint-disable no-console */
import * as am4core from '@amcharts/amcharts4/core';
import { notification } from 'antd';
import { Feature, Overlay, View } from 'ol';
import OLMap from 'ol/Map';
import { ScaleLine } from 'ol/control';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import * as proj from 'ol/proj';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import {
  getAllTractorSnapShot,
  getMapFleetIndicators,
  getOpsDriveActionPath,
  getPolygonsData,
} from '../../../constants/Api';
import {
  calPtsDistanceArray,
  centerTheLocation,
  displayRecenterAllViewPortSetUp,
  drawPoly,
  getDateTime,
  getDateTimes,
  getIndInfo,
  getIndInfos,
  getPathBorderColor,
  getPathColor,
  getPathTimeInfo,
  getSnapInfo,
  getTicketInfo,
  mapAllItems,
  polygonHoverLabel,
  priority,
} from '../../../constants/Common';
import {
  ATOM,
  GILES,
  GUARD_RAILS,
  JUMP_AND_GO,
  rangeForAnalytics,
  SATILITE_LAYER_URL,
} from '../../../constants/constant';
import { MapFeature } from '../../../constants/types';
import { ApplicationContext } from '../../../context/AppContext';
import { SET_POLYGONS_LIST } from '../../../context/actions';
import {
  drawForClusterLayer,
  drawLayerWithOutCluster,
} from '../../../hooks/useDrawLayer';
import tractorAnalyticDetailsStore from './../store/tractorAnalyticDetails';

import autoDrive from './../images/path-8134-1.svg';
import operatorAssistant from './../images/path-8134-2.svg';
import idle from './../images/path-8134-3.svg';
import manualDrive from './../images/path-8134.svg';

// import SnapIcon from '../../../assets/images/m_sshot.svg';
import snapShot from '../../../assets/images/op_sshot.svg';
import operationPath from '../../../assets/images/operationPath.svg';
import mapSat from './../../../assets/images/map_default_view.svg';
import mapNor from './../../../assets/images/map_satelite_view.svg';
import cameraClarity from './../images/camera_poor_oa.svg';
import human from './../images/human.svg';
import implementCollision from './../images/implement-collision-oa.svg';
import ptoProtected from './../images/pto-oa.svg';
import vehicle from './../images/vehicle.svg';
// import tractor__normal from './../../assets/images/tractor-icons/tractor__normal.svg';
// import selected_tractor_user_manual from './../../../assets/images/tractor-icons/selected_tractor_user_manual.svg';
import { TICKET_TYPES } from '../../../constants/constant';
import DiagnosticWidget from '../../fleethealth/DiagnosticWidget';
import { getSeverity, getTags } from '../../fleethealth/Diagnostics';
import SnapView from '../../snapshot/SnapView';
import OperationalAnalyticsTicketDetails from '../OperationalAnalyticsTicketDetails';
import useAnalyticsStore from './../store/operationAnalytics';

import moment from 'moment';
import { SelectedChunk } from '../../../lib/types';
import TicketsCreateEdit from '../../tickets/TicketsCreateEdit';
import {
  changeTimeFormat,
  consolidateDriveActions,
  fetchName,
  fillGaps,
  findNearestTimestamp,
  handleSameLocationChangeFeatures,
  handleSameLocationFeatures,
  selectedPath,
} from '../common';
import {
  roundStyle,
  selectedCameraMap,
  splitArrayIntoChunks,
} from '../constants';
import './styles.scss';
import Cluster from 'ol/source/Cluster';

let gSelectedTractor: any = null;
let toggleForGuardRails = false;
let toggleForTicket = false;
let toggleForIndicator = false;
let globalGuardrailData: any = null;
let gMouseEntered = false;
// let gTriggeredToReArrange = false;
let gHasZoomed = false;
let gDriveActionAndTime: selectedPath | null = null;
let gPlayVideo = true;
let gChunks: SelectedChunk[] = [];
let gOnPathClick = false;
let allResponses: any = [];
let gOnclickForMapAndChart = false;
interface Props {
  operations?: any;
  selectedCamera: string;
  setPathLoader: (state: boolean) => void;
  setDriveActionAndTime: (state: selectedPath) => void;
  driveActionAndTime: selectedPath;
  setProgressData: (data: any) => void;
  setJngData: (data: any) => void;
  setHasZoomed: (state: boolean) => void;
  playVideo: boolean;
  hasZoomed: boolean;
  onPathClick: boolean;
  setOnPathClick: (state: boolean) => void;

  selectedChunk: SelectedChunk;
  setSelectedChunk: (state: SelectedChunk) => void;

  chunks: SelectedChunk[];
  setSeekTime: (seekTime: number) => void;
  setPlayVideo: (play: boolean) => void;
  setOnclickForMapAndChart: (onClickForMapAndChart: boolean) => void;
  onClickForMapAndChart: boolean;
  setShowGuardrailModel: (state: boolean) => void;
  setShowIndicatorModel: (state: boolean) => void;
  setShowTicketPopup: (state: boolean) => void;
  setToggleTicketWidget: (state: boolean) => void;
  setTicketId: (ticketId: any) => void;
  setGuardrailData: (guardrailData: any) => void;
  setErrorDetails: (errorDetails: any) => void;
  showGuardrailModel: boolean;
  showIndicatorModel: boolean;
  showTicketPopup: boolean;
  toggleTicketWidget: boolean;
  guardrailData: any;
  errorDetails: any;
  ticketId: any;
}

const OperationTractorDetailsMap: React.FC<Props> = ({
  operations,
  selectedCamera,
  setDriveActionAndTime,
  setPathLoader,
  driveActionAndTime,
  setProgressData,
  hasZoomed,
  setHasZoomed,
  onPathClick,
  playVideo,
  selectedChunk,
  setSelectedChunk,
  chunks,
  setOnPathClick,
  setSeekTime,
  setPlayVideo,
  setJngData,
  setOnclickForMapAndChart,
  onClickForMapAndChart,
  setShowGuardrailModel,
  setShowIndicatorModel,
  setShowTicketPopup,
  setToggleTicketWidget,
  setTicketId,
  setGuardrailData,
  setErrorDetails,
  showGuardrailModel,
  showIndicatorModel,
  showTicketPopup,
  toggleTicketWidget,
  guardrailData,
  errorDetails,
  ticketId,
}: Props) => {
  const { selectedDate, driveActionsList, setDriveActionsList } =
    useAnalyticsStore();
  const {
    selectedTractor,
    ticketsData,
    snapshots,
    guradrails: guardRails,
    errorCodes,
    addErrorCodes,
    addSnapshots,
    addGuardrails,
    setIndicatorLoader,
    setSnapLoader,
  } = tractorAnalyticDetailsStore();

  const { userDetails, APPReducer, user } = useContext(ApplicationContext);
  const [appState, appDispatch] = APPReducer;
  const { polygonsList, groundZero, tractorsMap } = appState;
  const [mapInitialized, setMapInitialized] = useState(false);
  const [base, setBase] = useState<[number, number]>([0, 0]);
  const [mouseEntered, setMouseEntered] = useState(false);
  // const [triggeredToReArrange, setTriggeredToReArrange] = useState(false);
  const [ticketDetails, setTicketDetails] = useState(null);
  const [toggleSatellite, setToggleSatellite] = useState(false);

  const mapElement: any = useRef();
  const container: any = useRef();
  const content: any = useRef();
  const mapRef = useRef<OLMap | null>(null);
  const vectorTileLayerRef = useRef<TileLayer<any>>();
  const satelliteTileLayerRef = useRef<TileLayer<any>>();
  const polygonsLayerRef = useRef<VectorLayer<any>>();
  const tractorPathLayerRef = useRef<VectorLayer<any>>();
  const pointLayerRef = useRef<VectorLayer<any>>();
  const tractorPointRef: any = useRef();
  const currentPathRef: any = useRef();
  const mapAllIconsClusterSource: any = useRef();

  const hoverPointLayerRef = useRef<VectorLayer<any>>();
  const mapAllIcons = useRef<VectorLayer<any>>();
  useEffect(() => {
    gOnclickForMapAndChart = onClickForMapAndChart;
  }, [onClickForMapAndChart]);
  useEffect(() => {
    gDriveActionAndTime = driveActionAndTime;
  }, [driveActionAndTime]);
  useEffect(() => {
    gHasZoomed = hasZoomed;
  }, [hasZoomed]);
  useEffect(() => {
    gMouseEntered = mouseEntered;
  }, [mouseEntered]);
  useEffect(() => {
    gSelectedTractor = selectedTractor;
  }, [selectedTractor]);
  useEffect(() => {
    mapInitialized && selectedCamera && mapRef.current?.updateSize();
  }, [selectedCamera, mapInitialized]);
  useEffect(() => {
    gOnPathClick = onPathClick;
  }, [onPathClick]);

  useEffect(() => {
    gChunks = chunks;
  }, [chunks]);
  useEffect(() => {
    if (user && groundZero) {
      initializeMap();
    }
  }, [user, groundZero]);

  useEffect(() => {
    gPlayVideo = playVideo;
  }, [playVideo]);

  useEffect(() => {
    if (groundZero) {
      const { latitude, longitude } = groundZero;
      setBase([latitude, longitude]);
    }
  }, [groundZero]);
  useEffect(() => {
    selectedDate &&
      mapInitialized &&
      base &&
      centerTheLocation([base[1], base[0]], mapRef.current, 15);
  }, [selectedDate, base, mapInitialized]);

  //  mapInitialization start here
  const initializeMap = async () => {
    try {
      const { latitude, longitude } = groundZero;

      const vectorLayer = new TileLayer({
        source: new OSM(),
        visible: true,
      });

      const satelliteLayer = new TileLayer({
        source: new XYZ({
          url: SATILITE_LAYER_URL,
          cacheSize: 1000,
        }),
        visible: false,
      });
      const initialPathLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        zIndex: 0,
      });
      tractorPointRef.current = new Feature({
        type: 'icon',
        geometry: new Point([0, 0]),
      });
      tractorPointRef.current.setStyle(new Style({}));
      tractorPointRef.current.setId(99999);

      currentPathRef.current = new Feature();
      currentPathRef.current.setGeometry(new LineString([]));

      const pointFeatureLayer = new VectorLayer({
        source: new VectorSource({
          features: [tractorPointRef.current, currentPathRef.current],
        }),
        visible: true,
        zIndex: priority.PATH + 0.1,
      });

      // polygons list layar
      const polygonsFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
      });
      const tractorPathFeaturesLayer = new VectorLayer({
        source: new VectorSource(),
        zIndex: priority.PATH,
      });
      const source = new VectorSource({
        features: [],
      });
      const clusterSource = new Cluster({
        distance: 10,
        source: source,
      });
      mapAllIconsClusterSource.current = clusterSource;
      const mapAllIconsLayer = new VectorLayer({
        source: clusterSource,
        zIndex: priority['MAP_ALL_ITEMS'],
        style: function (feature: any) {
          const size = feature.get('features').length;
          let style = new Style({});
          if (size === 1) {
            const feat = feature.get('features');
            if (
              !gHasZoomed ||
              (gHasZoomed &&
                gDriveActionAndTime &&
                gDriveActionAndTime?.drive_action_uuid &&
                feat[0] &&
                feat[0].get('driveActionUuid') ===
                  gDriveActionAndTime?.drive_action_uuid)
            ) {
              style = feat[0].get('style');
            }

            feature.set('name', feat[0].get('name'));
            feature.set('pointType', feat[0].get('pointType'));
            feature.set('content', feat[0].get('content'));
            feature.set('arrContent', [feat[0].get('content')]);
            feature.set('style', style);
          }
          if (size > 1) {
            const feat = feature.get('features');
            const feats = feat.filter(
              (fe: any) =>
                !gHasZoomed ||
                (gHasZoomed &&
                  gDriveActionAndTime &&
                  gDriveActionAndTime?.drive_action_uuid &&
                  fe &&
                  fe.get('driveActionUuid') ===
                    gDriveActionAndTime?.drive_action_uuid),
            );
            if (feats && feats.length > 0) {
              if (feats.length === 1) {
                style = feats[0].get('style');
                feature.set('name', feats[0].get('name'));
                feature.set('pointType', feats[0].get('pointType'));
                feature.set('content', feats[0].get('content'));
                feature.set('arrContent', [feats[0].get('content')]);
                feature.set('style', style);
              } else {
                style = mapAllItems(feats.length);
                const arrContent: any[] = [];
                feats.map((fe: any) => {
                  arrContent.push({
                    ...fe.get('content'),
                    pointType: fe.get('pointType'),
                  });
                });
                feature.set('pointType', feats[0].get('pointType'));
                feature.set('arrContent', arrContent);
                feature.set('style', style);
              }
            }
          }
          return style;
        },
      });
      // create map
      const initialMap = new OLMap({
        target: mapElement.current,
        layers: [
          satelliteLayer,
          vectorLayer,
          initialPathLayer,
          polygonsFeaturesLayer,
          tractorPathFeaturesLayer,
          pointFeatureLayer,
          mapAllIconsLayer,
        ],
        controls: [scaleControl()],
        view: new View({
          projection: 'EPSG:3857',
          // center: proj.transform([0, 0], 'EPSG:4326', 'EPSG:3857'),
          center: proj.transform(
            [longitude, latitude],
            'EPSG:4326',
            'EPSG:3857',
          ),
          // zoom: 2,
          zoom: 17,
          maxZoom: 25,
        }),
      });

      // set flag for map initialization
      setMapInitialized(true);
      // centerTheLocation([longitude, latitude], initialMap, 15);
      mapRef.current = initialMap;
      // initialize safelight layer
      satelliteTileLayerRef.current = satelliteLayer;
      // initialize vector layer
      vectorTileLayerRef.current = vectorLayer;
      polygonsLayerRef.current = polygonsFeaturesLayer;

      tractorPathLayerRef.current = tractorPathFeaturesLayer;
      pointLayerRef.current = pointFeatureLayer;
      mapAllIcons.current = mapAllIconsLayer;
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };
  //  mapInitialization ends here

  const scaleControl = () => {
    const control = new ScaleLine({
      units: 'metric',
      className: 'positionMap',
    });
    return control;
  };

  useEffect(() => {
    const init = async () => {
      polygonsLayerRef.current?.getSource().clear();
      try {
        const { organization } = userDetails;
        let recordsList = [];
        if (polygonsList && polygonsList.length > 0) {
          recordsList = polygonsList;
        } else {
          const response = await getPolygonsData(
            organization.api_url,
            organization.farm.id,
          );
          const { records } = response;
          recordsList =
            records && records.length && records.length > 0 ? records : [];
        }
        drawPolygon(
          recordsList,
          polygonsList && polygonsList.length > 0 ? false : true,
        );
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };
    if (userDetails && userDetails.organization && mapInitialized && base) {
      init();
    }
  }, [userDetails, mapInitialized, base]);

  const drawPolygon = async (polyList: any, save: boolean) => {
    try {
      const records = polyList && polyList.length > 0 ? polyList : [];
      const listItems: MapFeature[] = [];
      records.map(async (record: any, index: number) => {
        const { polygonFeature, polygonItem } = await drawPoly(
          userDetails,
          base,
          polygonsLayerRef.current,
          1,
          1,
          record,
          false, // suggested as false
          !save, // call api to get vertices -> false call api , -> true dont call api
        );
        if (polygonItem && polygonItem.vertices) {
          record.vertices = polygonItem.vertices;
        }
        // if (record.color === 'brown') {
        //   polygonFeature &&
        //     displayRecenterAllViewPortSetUp(
        //       [polygonFeature],
        //       mapRef.current,
        //       80,
        //     );
        // }
        // polygons added to maintain app context start
        listItems.push(polygonItem as MapFeature);
        if (records && records.length - 1 === index) {
          save &&
            appDispatch({
              type: SET_POLYGONS_LIST,
              payload: records,
            });
        }
        // polygons added to maintain app context end
      });
      polygonHoverLabel(mapRef.current);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };
  const handleDraw = (record: any, innerItem: any) => {
    const { drive_action_uuid: selectedDriveAction, tractor_details: tractor } =
      record;
    record['jng'] = innerItem;
    record['tractor_name'] = tractor?.name;
    !driveActionsList.get(selectedDriveAction) &&
      driveActionsList.set(selectedDriveAction, record);

    const driveUuidData = record.path
      ?.filter(
        (item: any) =>
          item.planner !== 'standby' &&
          item?.path?.length > 1 &&
          item.planner !== 'copycat',
      )
      ?.map((item: any, index: number) => {
        const lastRecord = item?.path[item?.path.length - 1];
        const firstRecord = item?.path[0];
        return {
          category: '',
          drive_action_uuid: selectedDriveAction,
          drive_action_uuid_id: selectedDriveAction + '_' + index,
          op_type: item.planner,
          time: firstRecord[3],
          from: changeTimeFormat(firstRecord[3]),
          to: changeTimeFormat(lastRecord[3]),
          color: am4core.color(getPathColor(item.planner)),
          // defualtColor: am4core.color('#BBCBCF'),
          duration: moment(lastRecord[3]).diff(firstRecord[3], 'minute'),
          operator: fetchName(
            innerItem.operator.first_name,
            innerItem.operator.last_name,
          ),
          operatorData: innerItem.operator,
          opName: `${innerItem.operator.first_name}
                ${innerItem.operator.last_name}`,
        };
      });
    driveUuidData &&
      driveUuidData.length &&
      driveUuidData.length > 0 &&
      allResponses.push(...driveUuidData);

    drawJAG(selectedDriveAction, record, true, selectedTractor.implementName);
  };

  useEffect(() => {
    const init = async () => {
      try {
        tractorPathLayerRef?.current?.getSource()?.clear();
        if (operations && operations.length > 0) {
          const listItemData = splitArrayIntoChunks(operations);

          setPathLoader(true);
          allResponses = [];
          const { organization } = userDetails;
          const promises: any[] = [];
          const returnData = await Promise.all(
            listItemData.map(async (innerItem) => {
              const ids1 = innerItem.map(
                (item: { drive_action_uuid: string }) => {
                  driveActionsList.get(item.drive_action_uuid) &&
                    handleDraw(
                      driveActionsList.get(item.drive_action_uuid),
                      item,
                    );
                  return item.drive_action_uuid;
                },
              );
              const ids = ids1.filter(
                (item: string) => !driveActionsList.get(item),
              );
              if (ids.toString().length > 0) {
                const response = await getOpsDriveActionPath(
                  organization.api_url,
                  ids.toString(),
                );
                promises.push(response);
                const records =
                  response && response.records && response.records.length > 0
                    ? response.records
                    : [];
                records.map((record: any, index: number) =>
                  handleDraw(record, innerItem[index]),
                );
              }
            }),
          );
          // await Promise.allSettled(returnData);
          await Promise.allSettled(promises);
          const consolidatedData = consolidateDriveActions(
            allResponses.sort((a: any, b: any) => a?.time - b?.time),
          );
          setJngData(consolidatedData);
          const updateData = fillGaps(allResponses);
          setProgressData(operations?.length > 0 ? updateData : []);
          recenterAllPaths();
        }
      } catch (error) {
        console.error(error);
      } finally {
        setDriveActionsList(driveActionsList);
        setPathLoader(false);
      }
    };
    mapInitialized && selectedTractor && selectedTractor.tractorId && init();
  }, [operations]);

  const recenterAllPaths = () => {
    const feats = tractorPathLayerRef.current?.getSource().getFeatures();
    feats &&
      feats.length &&
      feats.length > 0 &&
      displayRecenterAllViewPortSetUp(feats, mapRef.current, 80);
  };

  useEffect(() => {
    if (!showTicketPopup) {
      toggleForTicket = false;
    } else {
      setShowIndicatorModel(false);
      setShowGuardrailModel(false);
    }
  }, [showTicketPopup]);

  useEffect(() => {
    if (!showIndicatorModel) {
      toggleForIndicator = false;
    } else {
      setShowTicketPopup(false);
      setShowGuardrailModel(false);
    }
  }, [showIndicatorModel]);

  useEffect(() => {
    if (!showGuardrailModel) {
      toggleForGuardRails = false;
    } else {
      setShowTicketPopup(false);
      setShowIndicatorModel(false);
    }
  }, [showGuardrailModel]);

  useEffect(() => {
    const initIndicatorData = async () => {
      try {
        let list: any = [];
        setIndicatorLoader(true);
        const opsList = operations;
        let index = 0;
        for (const operation of operations) {
          const data = await getMapFleetIndicators(
            userDetails.organization.api_url,
            userDetails.organization.id,
            operation.drive_action_uuid,
          );
          if (data && data.length > 0) {
            const [{ indicators_data }] = data;
            indicators_data.map(
              (item: any) =>
                (item.drive_action_uuid = operation.drive_action_uuid),
            );
            if (indicators_data && indicators_data.length > 0) {
              list = [...list, ...indicators_data];
            }
          }
          index + 1 == opsList.length && setIndicatorLoader(false);
          if (index + 1 == opsList.length) {
            // initIndicatorLayer(list);
            addErrorCodes(list);
          }
          index = index + 1;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIndicatorLoader(false);
      }
    };
    mapInitialized &&
      userDetails &&
      userDetails.organization &&
      operations &&
      operations.length > 0 &&
      initIndicatorData();
  }, [mapInitialized, operations, userDetails]);

  useEffect(() => {
    const initSnapData = async () => {
      try {
        let list: any = [];
        setSnapLoader(true);
        let index = 0;
        for (const operation of operations) {
          const { organization } = userDetails;
          const data = await getAllTractorSnapShot(
            organization.api_url,
            organization.farm.id,
            selectedTractor.tractorId,
            1,
            300,
            'completed',
            '',
            operation.drive_action_start_date_time,
            operation.drive_action_finish_date_time,
          );
          if (data && data.records && data.records.length > 0) {
            list = [...list, ...data.records];
          }
          index + 1 == operations.length && setSnapLoader(false);
          if (index + 1 == operations.length) {
            // initSnapLayer(list);
            list.map(
              (element: any) =>
                (element.created_type_text = nameAndIcon(element)?.name),
            );
            const snapshots = list.filter(
              ({ created_at }: any) =>
                created_at !== 'GUARD_RAILS' && created_at !== 'JUMP_AND_GO',
            );
            const guardRails = list.filter(
              ({ created_at }: any) => created_at === 'GUARD_RAILS',
            );
            addSnapshots(snapshots);
            addGuardrails(guardRails);
          }
          index = index + 1;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setSnapLoader(false);
      }
    };
    if (
      mapInitialized &&
      userDetails?.organization &&
      operations?.length > 0 &&
      selectedTractor?.tractorId
    )
      initSnapData();
  }, [mapInitialized, operations, userDetails, selectedTractor]);

  useEffect(() => {
    if (mapInitialized && userDetails && userDetails.organization) {
      mapAllIcons?.current?.getSource()?.getSource()?.clear();
      // indicators start here
      const initIndicators = () => {
        drawLayerWithOutCluster(
          errorCodes,
          'OPERATION_INDICATOR',
          5,
          mapAllIcons.current,
        );
      };
      errorCodes && errorCodes.length > 0 && initIndicators();
      // indicators ends here
      // tickets part starts here
      const initTicketLayer = (data: any[]) => {
        drawLayerWithOutCluster(
          data,
          'Operation_Ticket',
          3,
          mapAllIcons.current,
        );
      };
      ticketsData && ticketsData.length > 0 && initTicketLayer(ticketsData);
      // tickets part ends here
      // Snaps part starts here
      const initSnaps = async (items: any) => {
        initSnapLayer(items, mapAllIcons.current);
      };
      if (snapshots && snapshots.length > 0) {
        initSnaps(snapshots);
      }
      if (guardRails && guardRails.length > 0) {
        initSnaps(guardRails);
      }
      // Snaps part ends here
    }
  }, [
    mapInitialized,
    userDetails,
    ticketsData,
    snapshots,
    guardRails,
    errorCodes,
  ]);

  const nameAndIcon = (snapshot: any) => {
    const { created_at, guard_rail_type } = snapshot;
    let name = 'SNAPSHOT';
    let icon = snapShot;
    if (created_at === 'GUARD_RAILS') {
      name = 'Guard Rails';
      if (guard_rail_type === 'human_protection') {
        icon = human;
        name = 'Human Detected';
      } else if (guard_rail_type === 'path_protection') {
        icon = operationPath;
        name = 'Obstacle Detected';
      } else if (guard_rail_type === 'pto_protection') {
        icon = ptoProtected;
        name = 'Pto Protection';
      } else if (guard_rail_type === 'vehicle_presence') {
        icon = vehicle;
        name = 'Vehicle Detected';
      } else if (guard_rail_type === 'camera_clarity') {
        icon = cameraClarity;
        name = 'Poor camera clarity';
      } else if (guard_rail_type === 'implement_guardrail') {
        icon = implementCollision;
        name = 'Implement Protection';
      }
    }
    return { name, icon };
  };
  const initSnapLayer = async (data: any, layer: any) => {
    const features: any[] = [];
    if (data) {
      data?.map((snapshot: any, index: number) => {
        const { latitude, longitude, created_at, drive_action_uuid } = snapshot;
        if (latitude && longitude) {
          const coordinates = [latitude, longitude];
          const item = nameAndIcon(snapshot);
          const icon = item.icon;
          const name = item.name;
          let scale = 1.5;
          scale = 1.5;
          const style = new Style({
            image: new Icon({
              src: icon,
              scale,
            }),
            zIndex: 100,
          });
          const hoverStyle = new Style({
            image: new Icon({
              src: icon,
              scale: scale * 1.2,
            }),
            zIndex: 100,
          });

          const feature = new Feature({
            geometry: new Point(
              proj.fromLonLat([coordinates[1], coordinates[0]]),
            ),
            name: name,
            driveActionUuid: drive_action_uuid,
            pointType: 'SNAP',
            content: { ...snapshot, created_type_text: name },
            style,
            point: [coordinates[1], coordinates[0]],
          });
          feature.setStyle(style);
          feature.set('style', style);
          feature.set('hoverStyle', hoverStyle);
          if (created_at === 'GUARD_RAILS') {
            // guardRailsfeatures.push(feature);
            // defaultLayer?.getSource().addFeature(feature);
            features.push(feature);
            layer && layer.getSource().getSource().addFeature(feature);
          } else if (created_at === 'JUMP_AND_GO') {
            // jumpAndGofeatures.push(feature);
          } else {
            // defaultLayer?.getSource().addFeature(feature);
            features.push(feature);
            layer && layer.getSource().getSource().addFeature(feature);
          }
        }
      });
    }
    return features;
  };

  const drawJAG = (
    selectedDriveAction: string,
    jumpAndGo: any,
    all = false,
    keyItem: string,
  ) => {
    const { tractor_name = '', operator_name = '', path, jng } = jumpAndGo;
    const defaultLayer = tractorPathLayerRef.current;
    let oarr: any = [];
    let lastIndexPoint: any = null;
    path
      .filter(
        (point: any) =>
          point.planner !== 'standby' &&
          point.planner !== 'copycat' &&
          point?.path?.length > 1,
      ) // filter standby
      .map((point: any, index: number) => {
        // slice points
        if (lastIndexPoint) point.path = [lastIndexPoint, ...point.path];

        const result = point.path.reduce(
          (
            a: { data: any[][]; i: number | undefined; last_item: any },
            itemPoint: any[],
            i: number,
          ) => {
            const ar = point.path;

            let distance = 0;

            if (i < ar.length) {
              distance = ar[i + 1]
                ? calPtsDistanceArray([itemPoint, ar[i + 1]])
                : 1;

              if (distance > rangeForAnalytics) {
                a.data.push(ar.slice(a.i, i + 1));
                a.i = i + 1;
                if (ar[i + 1]) a.data.push([ar[i], ar[i + 1]]);
              }
            }

            if (ar.length - 1 == i && a.data.length == 0) {
              a.data.push(ar);
              lastIndexPoint = ar[ar.length - 1];
              return a.data;
            }
            if (ar.length - 1 == i && a.data.length > 0) {
              lastIndexPoint = ar[ar.length - 1];
              return a.data;
            }
            return a;
          },
          { i: 0, data: [], last_item: {} },
        );
        result
          .filter((item: any, key: number) => !(key % 2))
          .map((item: any, key: number) => {
            const arr: any[] = [];
            let from = 0;
            let to = 0;
            if (item && item.length > 0) {
              to = item[item.length - 1][3];
              from = item[0][3];
            }
            item.map((itemPoint: any) => {
              const [, latitude, longitude] = itemPoint;
              arr?.push(proj.fromLonLat([longitude, latitude]));
            });

            let tractorLocation: Feature<any> = new Feature();
            if (defaultLayer) {
              tractorLocation = defaultLayer
                ?.getSource()
                .getFeatureById(selectedDriveAction);
            }
            if (!tractorLocation) {
              tractorLocation = new Feature();
            }
            oarr = [...oarr, ...arr];
            tractorLocation.setGeometry(new LineString(arr));
            let color = getPathColor(point.planner);
            const borderColor = getPathBorderColor(point.planner);
            if (key % 2) color = 'grey';
            const style = [
              new Style({
                stroke: new Stroke({
                  color: borderColor,
                  width: 8,
                }),
                zIndex: 0,
              }),
              new Style({
                stroke: new Stroke({
                  color: color,
                  width: 6,
                }),
                zIndex: 1,
              }),
            ];
            const opacityStyle = [
              new Style({
                stroke: new Stroke({
                  color: borderColor + '80',
                  width: 8,
                }),
                zIndex: 0,
              }),
              new Style({
                stroke: new Stroke({
                  color: color + '80',
                  width: 6,
                }),
                zIndex: 1,
              }),
            ];
            const pathHover = [
              new Style({
                stroke: new Stroke({
                  color: borderColor,
                  width: 10,
                }),
                zIndex: 1,
              }),
              new Style({
                stroke: new Stroke({
                  color,
                  width: 6,
                }),
                zIndex: 1,
              }),
            ];
            tractorLocation.setStyle(style);
            tractorLocation.set('style', style);
            tractorLocation.set('opacityStyle', opacityStyle);
            tractorLocation.set('hoverStyle', pathHover);
            // tractorLocation.set('name', selectedDriveActionUser);
            tractorLocation.set('name', '');
            tractorLocation.set('pointType', 'PATH');
            tractorLocation.set('from', from);
            tractorLocation.set('to', to);
            tractorLocation.set('operationType', keyItem);

            tractorLocation.set('drawPointsText', point);
            // tractorLocation.set('tractorId', tractorId);
            tractorLocation.set('tractor_name', tractor_name);
            tractorLocation.set('operator_name', operator_name);
            tractorLocation.set('jng', {
              ...jng,
              drive_action_uuid_id: selectedDriveAction + '_' + index,
            });
            tractorLocation.set('operationInfo', jumpAndGo);
            tractorLocation.set('drive_action_uuid', selectedDriveAction);
            tractorLocation.set(
              'drive_action_uuid_id',
              selectedDriveAction + '_' + index,
            );
            // console.log('drive_action_uuid_id', id + '_' + index);
            defaultLayer?.getSource().addFeature(tractorLocation);
          });
      });

    if (!all && path.length && path?.path?.length) {
      const path_pt = path[0].path[0];
      path_pt[2] && oarr?.push(proj.fromLonLat([path_pt[2], path_pt[0]]));
    }

    if (!all && oarr && oarr.length > 0 && mapRef.current) {
      const view = mapRef.current?.getView();
      const zoom = view.getZoom();
      if (zoom) {
        mapRef.current?.getView().setCenter(oarr[0]);
        mapRef.current?.getView().setZoom(15);
      }
    }
  };

  useEffect(() => {
    if (mapInitialized) {
      handleClick();
      handlePointerMove();
      const pointLocation: Feature<any> = new Feature();
      pointLocation.setId(999);
      const selectPointFeatureLayer = new VectorLayer({
        source: new VectorSource({
          features: [pointLocation],
        }),
        visible: true,
        style: new Style({}),
        zIndex: 111,
      });
      mapRef.current?.addLayer(selectPointFeatureLayer);
      hoverPointLayerRef.current = selectPointFeatureLayer;
    }
  }, [mapInitialized]);
  const handleOperationIndClick = (content: any) => {
    if (content) {
      const { subsystem = '', functionality_affected = '' } =
        content.error_code_data || {};
      content.functionality_affected = functionality_affected;
      content.subsystem = subsystem;
      content.severity = content?.indicator_level;
      content.start_time = content?.created_date_time
        ? getDateTimes(content?.created_date_time)
        : '';
      content.counter_measure = content?.error_code_data?.counter_measure
        ? content?.error_code_data?.counter_measure?.split(';')
        : '';
      setErrorDetails(content);
      setShowIndicatorModel(true);
      setShowGuardrailModel(false);
      setShowTicketPopup(false);
      toggleForIndicator = true;
    }
  };
  const handleOperationSnapClick = (content: any) => {
    if ([GUARD_RAILS, GILES, ATOM, JUMP_AND_GO].includes(content?.created_at)) {
      setGuardrailData({
        guard_rail_triggered_uuid: content.transaction_id,
        id: content.tractor_id.id,
        tractor_id: content.tractor_id.id,
        title: getDateTime(content.created_date_time),
        created_date_time: content.created_date_time,
        guard_rail_type: content.guard_rail_type,
        created_at: content.created_at,
        created_by: content.created_by,
        tractorName: content?.tractor_id?.name,
        created_type_text: content?.created_type_text,
      });
      setShowGuardrailModel(true);

      setShowIndicatorModel(false);
      setShowTicketPopup(false);
      globalGuardrailData = content;
    } else {
      // setSelectedMapSnapshot(content);
    }
    toggleForGuardRails = true;
  };
  const handleOperationTicketClick = (content: any) => {
    if (!toggleForTicket) {
      ticketDetailsById(content.ticketId);

      setShowTicketPopup(true);

      setShowIndicatorModel(false);
      setShowGuardrailModel(false);
    } else {
      setShowTicketPopup(false);
      toggleForTicket = false;
    }
  };
  const handleClick = () => {
    let click = false;
    mapRef.current?.on('click', (evt) => {
      mapRef.current?.forEachFeatureAtPixel(evt.pixel, function (feature: any) {
        const pointType = feature.get('pointType');
        if (pointType == 'SNAP') {
          if (!toggleForGuardRails) {
            const point = feature.get('point');
            const content = feature.get('content');
            handleOperationSnapClick(content);

            const view = mapRef.current?.getView();
            if (view) {
              point &&
                mapRef.current?.getView().setCenter(proj.fromLonLat(point));
            }
          } else {
            // setGuardrailData(null);
            if (content?.id === globalGuardrailData?.id)
              setShowGuardrailModel(false);
            toggleForGuardRails = false;
          }
        } else if (pointType && pointType === 'OPERATION_INDICATOR') {
          if (!toggleForIndicator) {
            const point = feature.get('point');
            const content = feature.get('content');
            const features = feature.get('features');
            const size =
              features && features?.length && features?.length > 0
                ? features?.length
                : 0;
            size === 1 && handleOperationIndClick(content);
            point &&
              mapRef.current?.getView().setCenter(proj.fromLonLat(point));
          } else {
            setShowIndicatorModel(false);
            toggleForIndicator = false;
          }
        } else if (pointType && pointType === 'Operation_Ticket') {
          const content = feature.get('content');
          const point = feature.get('point');
          const features = feature.get('features');
          const size =
            features && features?.length && features?.length > 0
              ? features?.length
              : 0;
          size === 1 && handleOperationTicketClick(content);
          point && mapRef.current?.getView().setCenter(proj.fromLonLat(point));
        } else if (pointType === 'PATH') {
          const geometry = feature.getGeometry();
          const closePoint = geometry.getClosestPoint(evt.coordinate);
          const coordinates = geometry.getCoordinates();

          for (let element = 0; element < coordinates.length - 1; element++) {
            const lineString = new LineString([
              coordinates[element],
              coordinates[element + 1],
            ]);

            if (lineString.intersectsCoordinate(closePoint)) {
              const drawPointsText = feature.get('drawPointsText');
              const jng = feature.get('jng');
              const drive_action_uuid = feature.get('drive_action_uuid');
              const drive_action_uuid_id = feature.get('drive_action_uuid_id');
              if (drawPointsText.path && drawPointsText.path[element]) {
                if (gChunks && gChunks.length > 0) {
                  const time = drawPointsText.path[element][3];
                  if (!click) {
                    setOnclickForMapAndChart(!gOnclickForMapAndChart);
                    click = true;
                    setPlayVideo(false);
                    setHasZoomed(true);
                    gChunks.forEach((chunk: SelectedChunk) => {
                      if (
                        time >= chunk.startDateTime &&
                        chunk.endDateTime >= time
                      ) {
                        setSelectedChunk(chunk);
                        setSeekTime && setSeekTime(chunk.startTime);
                        gDriveActionAndTime &&
                          gDriveActionAndTime.created_date_time &&
                          chunk?.startDateTime &&
                          setDriveActionAndTime &&
                          setDriveActionAndTime({
                            ...gDriveActionAndTime,
                            created_date_time: chunk?.startDateTime,
                          });
                      }
                    });
                    setTimeout(() => {
                      click = false;
                    }, 100);
                    setTimeout(() => {
                      setPlayVideo(true);
                    }, 2000);
                  }
                } else {
                  if (!click) {
                    setOnclickForMapAndChart(!gOnclickForMapAndChart);
                    click = true;
                    setOnPathClick(!gOnPathClick);
                    const lastRecord =
                      drawPointsText.path[drawPointsText.path.length - 1][3];
                    const firstRecord = drawPointsText.path[0][3];
                    // setSeekTime && setSeekTime(chunk.startTime);

                    setDriveActionAndTime &&
                      setDriveActionAndTime({
                        fromClick: 'chart',
                        created_date_time: firstRecord,
                        drive_action_uuid: drive_action_uuid,
                        drive_action_uuid_id: drive_action_uuid_id,
                        from: firstRecord,
                        to: lastRecord,
                        operator: jng?.operator,
                      });
                    setHasZoomed(true);
                    setTimeout(() => {
                      click = false;
                    }, 100);
                  }
                }
              }
            }
          }
        }
      });
    });
  };

  const getViewAllMapItems = (listItems: any[]) => {
    if (listItems && listItems.length > 1) {
      const ids = listItems.map((item: any) => item.id);
      if (ids && ids.length > 1) {
        const features: any = [];
        mapAllIcons.current
          ?.getSource()
          ?.getSource()
          .getFeatures()
          .forEach((feature: any) => {
            const content = feature.get('content');
            if (content && content.id && ids.includes(content.id)) {
              features.push(feature);
            }
          });
        features &&
          features.length > 1 &&
          displayRecenterAllViewPortSetUp(features, mapRef.current);
        mapRef.current?.updateSize();
      }
    }
  };

  const handlePointerMove = () => {
    const overlay = new Overlay({
      element: container.current,
      autoPan: false,
      autoPanAnimation: {
        duration: 10,
      },
    });
    let selected: any = null;
    const handleClick = (pointType: string, contenta: any) => {
      if (pointType === 'OPERATION_INDICATOR') {
        handleOperationIndClick(contenta);
      } else if (pointType === 'Operation_Ticket') {
        handleOperationTicketClick(contenta);
      } else if (pointType === 'SNAP') {
        handleOperationSnapClick(contenta);
      }
      content.current.removeEventListener('click', handleClick);
    };
    let gPointerMoveContentAndPointType = {
      pointType: '',
      content: {},
      contents: [],
    };
    content &&
      content.current &&
      content.current.addEventListener('click', (event: any) => {
        const target = event.target;
        // console.log(
        //   target.classList,
        //   target.getAttribute('dataContent'),
        //   target.getAttribute('clickClass'),
        //   target.getAttribute('allDataContent'),
        //   target.classList.contains('operations-row'),
        //   target.classList.contains('ticket-number'),
        //   target.classList.contains('incidentContentRows'),
        //   target.classList.contains('allDataContent'),
        //   target,
        // );
        if (
          (target.classList.contains('operations-row') ||
            target.classList.contains('ticket-number') ||
            target.classList.contains('clickClass')) &&
          target.getAttribute('dataContent')
        ) {
          const dataItem = target.getAttribute('dataContent');

          let dataContent = dataItem.replaceAll('@', '"');
          dataContent = JSON.parse(dataContent);
          dataContent &&
            dataContent.pointType === 'OPERATION_INDICATOR' &&
            handleOperationIndClick(dataContent);
          dataContent &&
            dataContent.pointType === 'Operation_Ticket' &&
            handleOperationTicketClick(dataContent);
          dataContent &&
            dataContent.pointType === 'SNAP' &&
            handleOperationSnapClick(dataContent);
        } else if (
          target.classList.contains('inciViewAllBtn') &&
          target.getAttribute('allDataContent')
        ) {
          const dataItem = target.getAttribute('allDataContent');
          let allDataContent = dataItem.replaceAll('@', '"');
          allDataContent = JSON.parse(allDataContent);
          getViewAllMapItems(allDataContent);
        } else {
          gPointerMoveContentAndPointType &&
            gPointerMoveContentAndPointType.pointType &&
            gPointerMoveContentAndPointType.content &&
            !gPointerMoveContentAndPointType.contents &&
            handleClick(
              gPointerMoveContentAndPointType.pointType,
              gPointerMoveContentAndPointType.content,
            );
        }
      });
    mapRef.current?.on('pointermove', function (evt) {
      const pointLayer = hoverPointLayerRef.current;
      if (selected !== null && !gHasZoomed) {
        const feat = pointLayer?.getSource().getFeatureById(999);
        feat?.setStyle(new Style({}));
        selected?.setStyle(selected?.get('style'));
        selected = null;
      }
      const hit = mapRef.current?.forEachFeatureAtPixel(
        evt.pixel,
        function (feature: any) {
          const pointType = feature.get('pointType');
          if (gMouseEntered) return false;
          const geometry = feature.getGeometry();
          const coordinates = geometry.getCoordinates();
          const features = feature.get('features');
          const size =
            features && features?.length && features?.length > 0
              ? features.length
              : 0;
          if (size === 1) {
            if (pointType === 'OPERATION_INDICATOR') {
              const contenta = feature.get('content');
              const arrContent = feature.get('arrContent');
              let innerHTML = '';
              if (size === 1) {
                innerHTML = getIndInfo(contenta);
              }
              if (size > 1) {
                innerHTML = getIndInfos(arrContent);
              }
              content.current.innerHTML = innerHTML;
              overlay.setPosition(coordinates);
              gPointerMoveContentAndPointType = {
                pointType,
                content: contenta,
                contents: [],
              };
              if (
                !overlay.getElement()?.classList.contains('tooltip-container')
              ) {
                overlay.getElement()?.classList.add('tooltip-container');
              }
              mapRef.current?.addOverlay(overlay);
              return true;
            } else if (pointType === 'Operation_Ticket') {
              const contenta = feature.get('content');
              const innerHTML = getTicketInfo(contenta);
              content.current.innerHTML = innerHTML;
              overlay.setPosition(coordinates);
              gPointerMoveContentAndPointType = {
                pointType,
                content: contenta,
                contents: [],
              };
              if (
                !overlay.getElement()?.classList.contains('tooltip-container')
              ) {
                overlay.getElement()?.classList.add('tooltip-container');
              }
              mapRef.current?.addOverlay(overlay);
              return true;
            } else if (pointType === 'SNAP') {
              const contenta = feature.get('content');
              const nameText = feature.get('name');
              const innerHTML = getSnapInfo({ ...contenta, nameText });
              content.current.innerHTML = innerHTML;
              overlay.setPosition(coordinates);
              gPointerMoveContentAndPointType = {
                pointType,
                content: contenta,
                contents: [],
              };
              if (
                !overlay.getElement()?.classList.contains('tooltip-container')
              ) {
                overlay.getElement()?.classList.add('tooltip-container');
              }
              mapRef.current?.addOverlay(overlay);
              return true;
            }
          } else if (size > 1) {
            const arrContent = feature.get('arrContent');
            let innerHTML = '';
            innerHTML = getIndInfos(arrContent);
            content.current.innerHTML = innerHTML;
            overlay.setPosition(coordinates);
            gPointerMoveContentAndPointType = {
              pointType,
              content: {},
              contents: arrContent,
            };
            if (
              !overlay
                .getElement()
                ?.classList.contains('tooltip-container-map-item')
            ) {
              overlay.getElement()?.classList.add('tooltip-container-map-item');
            }
            mapRef.current?.addOverlay(overlay);
            return true;
          }
          if (pointType === 'PATH') {
            const feat = pointLayer?.getSource().getFeatureById(999);
            if (feat) {
              feat.setGeometry(
                new Point(
                  proj.fromLonLat(
                    proj.transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326'),
                  ),
                ),
              );
              feat.setStyle(
                new Style({
                  image: new CircleStyle({
                    radius: 5,
                    stroke: new Stroke({
                      color: 'blue',
                    }),
                    fill: new Fill({
                      color: '#3399CC',
                    }),
                  }),
                  zIndex: 10,
                }),
              );
              if (!gHasZoomed) {
                selected = feature;
                selected?.setStyle(feature.get('hoverStyle'));
              }

              const geometry = feature.getGeometry();
              const point = geometry.getClosestPoint(evt.coordinate);
              const coordinates = geometry.getCoordinates();

              for (
                let element = 0;
                element < coordinates.length - 1;
                element++
              ) {
                if (
                  new LineString([
                    coordinates[element],
                    coordinates[element + 1],
                  ]).intersectsCoordinate(point)
                ) {
                  if (
                    feature &&
                    feature.get('drawPointsText') &&
                    feature.get('drawPointsText').path &&
                    feature.get('drawPointsText').path[element]
                  ) {
                    const drawPointsText = feature.get('drawPointsText');
                    const innerHTML = getPathTimeInfo(
                      drawPointsText.path[element],
                      gSelectedTractor?.implementName,
                    );
                    feature.set('name', innerHTML);
                    content.current.innerHTML = innerHTML;
                    if (
                      !overlay
                        .getElement()
                        ?.classList.contains('tooltip-container')
                    ) {
                      overlay.getElement()?.classList.add('tooltip-container');
                    }
                    overlay.setPosition(evt.coordinate);
                    mapRef.current?.addOverlay(overlay);
                    return true;
                  }
                }
              }
            }
          }

          const coordinate = evt.coordinate;
          const name = feature.get('name');
          const dataType = feature.get('pointType');
          if (name) {
            content.current.innerHTML = '<p>' + name + '</p>';
            if (dataType !== 'PATH') {
              overlay.getElement()?.classList.remove('ol-details-popup');
            }
            overlay.setPosition(coordinate);
            mapRef.current?.addOverlay(overlay);
            return true;
          }

          return false;
        },
      );
      if (hit) {
        evt.map.getTargetElement().style.cursor = 'pointer';
      } else {
        if (!gMouseEntered) {
          overlay.setPosition(undefined);
          mapRef.current?.addOverlay(overlay);
          evt.map.getTargetElement().style.cursor = '';
        }
      }
    });
  };
  const ticketDetailsById = (ticketId: any) => {
    setToggleTicketWidget(true);
    setTicketId(ticketId);
  };
  const onCloseTicketWidget = () => {
    setToggleTicketWidget(false);
  };

  useEffect(() => {
    if (hasZoomed) {
      tractorPointRef.current.get('style') &&
        tractorPointRef.current.setStyle(tractorPointRef.current.get('style'));
    } else {
      tractorPointRef.current.setStyle(new Style({}));
    }
  }, [hasZoomed]);
  useEffect(() => {
    if (
      driveActionAndTime &&
      driveActionAndTime.drive_action_uuid
      // driveActionAndTime.fromClick === 'chart'
    ) {
      const allFeatures = tractorPathLayerRef?.current
        ?.getSource()
        .getFeatures();
      const filteredItem = allFeatures.filter(
        (feature: any) =>
          feature.get('drive_action_uuid_id') ===
          driveActionAndTime.drive_action_uuid_id,
      )[0];
      if (filteredItem) {
        const jng = filteredItem.get('jng');
        const drawPointsText = filteredItem.get('drawPointsText');
        if (drawPointsText?.path?.length > 0) {
          const startIndex = findNearestTimestamp(
            drawPointsText.path,
            driveActionAndTime.created_date_time,
          );
          if (Number(startIndex) >= 0) {
            const coordinates = drawPointsText.path[Number(startIndex)];
            const loc = proj.fromLonLat([coordinates[2], coordinates[1]]);
            tractorPointRef?.current?.setGeometry(new Point(loc));
            const style = roundStyle({
              ...jng.operator,
              knownBearing: coordinates[4] || 0,
            });
            tractorPointRef.current.setStyle(style);
            tractorPointRef.current.set('style', style);
          }
        }
      }
    }
  }, [driveActionAndTime]);
  useEffect(() => {
    if (
      hasZoomed &&
      driveActionAndTime &&
      driveActionAndTime.created_date_time
    ) {
      tractorPathLayerRef.current?.setOpacity(0.6);
      tractorPathLayerRef.current
        ?.getSource()
        .getFeatures()
        .forEach((feature: any) => {
          if (
            feature.get('drive_action_uuid_id') ==
            driveActionAndTime.drive_action_uuid_id
          ) {
            // feature.setStyle(feature.get('style'));
            // feature.setStyle(feature.get('lowOpacityStyle'));
          } else {
            feature.setStyle(new Style({}));
          }
        });
    } else {
      tractorPathLayerRef.current?.setOpacity(1);

      tractorPathLayerRef.current
        ?.getSource()
        .getFeatures()
        .forEach((feature: any) => {
          if (feature.get('drive_action_uuid_id')) {
            feature.setStyle(feature.get('style'));
          }
        });
    }
  }, [driveActionAndTime, hasZoomed]);
  useEffect(() => {
    if (
      hasZoomed &&
      gDriveActionAndTime &&
      gDriveActionAndTime.created_date_time &&
      gDriveActionAndTime?.drive_action_uuid_id
    ) {
      tractorPathLayerRef.current
        ?.getSource()
        .getFeatures()
        .forEach((feature: any) => {
          if (
            feature.get('drive_action_uuid_id') ==
            gDriveActionAndTime?.drive_action_uuid_id
          ) {
            const padding = selectedCamera === selectedCameraMap ? 80 : 20;
            displayRecenterAllViewPortSetUp([feature], mapRef.current, padding);
          }
        });
    }
    mapRef.current?.updateSize();
  }, [hasZoomed, onPathClick, selectedCamera]);

  const onCreateTicket = () => {
    setTicketDetails(errorDetails);
    setShowIndicatorModel(false);
    setToggleTicketWidget(true);
  };
  useEffect(() => {
    if (
      hasZoomed &&
      driveActionAndTime &&
      driveActionAndTime.created_date_time &&
      driveActionAndTime?.drive_action_uuid_id
    ) {
      const allFeatures = tractorPathLayerRef?.current
        ?.getSource()
        .getFeatures();
      const filteredItem = allFeatures.filter(
        (feature: any) =>
          feature.get('drive_action_uuid_id') ===
          driveActionAndTime?.drive_action_uuid_id,
      )[0];
      if (filteredItem) {
        const drawPointsText = filteredItem.get('drawPointsText');
        if (drawPointsText?.path?.length > 0) {
          const startIndex = 0;

          const endIndex = findNearestTimestamp(
            drawPointsText.path,
            driveActionAndTime.created_date_time,
          );
          const arr: any[] = [];
          const item = drawPointsText.path.slice(startIndex, endIndex + 1);
          // item.map((path_pt: { longitude: number; latitude: number }) => {
          item.map((path_pt: any[]) => {
            arr?.push(proj.fromLonLat([path_pt[2], path_pt[1]]));
          });
          currentPathRef &&
            currentPathRef.current &&
            currentPathRef.current.setGeometry(new LineString(arr));

          filteredItem.get('style') &&
            currentPathRef.current.setStyle(filteredItem.get('style'));
        }
      }
    } else {
      currentPathRef &&
        currentPathRef?.current &&
        currentPathRef?.current?.setStyle(new Style({}));
    }
  }, [driveActionAndTime, hasZoomed]);

  const handleShowHideEvents = (hZoomed: boolean) => {
    let list = mapAllIcons.current?.getSource()?.getSource()?.getFeatures();
    list = list?.length > 0 ? list : [];
    hZoomed &&
      list?.map((feature: any) => {
        feature?.setStyle(new Style({}));
        if (
          gDriveActionAndTime &&
          gDriveActionAndTime?.drive_action_uuid &&
          feature &&
          feature.get('driveActionUuid') ===
            gDriveActionAndTime?.drive_action_uuid
        ) {
          feature.setStyle(feature.get('style'));
        } else {
          feature?.setStyle(new Style({}));
        }
      });
    !hZoomed &&
      list?.map((feature: any) => {
        feature.setStyle(feature.get('style'));
      });
  };
  useEffect(() => {
    // mapInitialized &&
    //   mapAllIconsClusterSource &&
    //   mapAllIconsClusterSource?.current &&
    //   mapAllIconsClusterSource?.current?.setDistance(hasZoomed ? 0 : 10);
    setTimeout(() => {
      handleShowHideEvents(hasZoomed);
    }, 1000);
  }, [mapInitialized, hasZoomed]);

  // // // to recenter the path to map center
  // useEffect(() => {
  //   mapRef.current?.updateSize();
  // }, [selectedCamera]);

  // to change road map and satelight map
  useEffect(() => {
    vectorTileLayerRef.current?.setVisible(!toggleSatellite);
    satelliteTileLayerRef.current?.setVisible(toggleSatellite);
  }, [toggleSatellite]);

  useEffect(() => {
    guardrailData &&
      guardrailData.id &&
      mapAllIcons?.current
        ?.getSource()
        ?.getSource()
        ?.getFeatures()
        .forEach((feature: any) => {
          if (feature.get('content')?.id == guardrailData?.itemId) {
            const point = feature.get('point');
            point &&
              mapRef.current?.getView().setCenter(proj.fromLonLat(point));
          }
        });
  }, [guardrailData]);

  useEffect(() => {
    ticketId &&
      mapAllIcons?.current
        ?.getSource()
        ?.getSource()
        ?.getFeatures()
        .forEach((feature: any) => {
          if (feature.get('content')?.tractor_id == ticketId) {
            const point = feature.get('point');
            point &&
              mapRef.current?.getView().setCenter(proj.fromLonLat(point));
          }
        });
  }, [ticketId]);

  useEffect(() => {
    errorDetails &&
      errorDetails.id &&
      mapAllIcons?.current
        ?.getSource()
        ?.getSource()
        ?.getFeatures()
        .forEach((feature: any) => {
          if (feature.get('content')?.id == errorDetails?.id) {
            const point = feature.get('point');
            point &&
              mapRef.current?.getView().setCenter(proj.fromLonLat(point));
          }
        });
  }, [errorDetails]);

  return (
    <>
      <div className={`coverage-map-video`}>
        {/* {selectedCameraMap !== selectedCamera && (
          <div className="map-overlay"></div>
        )} */}
        <div
          ref={mapElement}
          style={{
            position: 'relative',
            // height: '100vh',
            width: '100%',
            height: '100%',
            // marginTop: 46,
            // visibility:
            //   selectedCamera === selectedCameraMap ? 'visible' : 'hidden',
          }}
          //to align right bottom of map distance
          className="posHd"
        >
          {!hasZoomed && selectedCamera === selectedCameraMap && (
            <div className="legend">
              <div className="autonomous">Key</div>
              <div className="version-vitality">
                <div className="history-hero">
                  <img
                    className="path-8134-icon"
                    loading="lazy"
                    alt=""
                    src={manualDrive}
                  />

                  <div className="autonomous1">Manual Drive</div>
                </div>
                <div className="history-hero1">
                  <img className="path-8134-icon1" alt="" src={autoDrive} />

                  <div className="autonomous">Autodrive</div>
                </div>
                <div className="history-hero2">
                  <img
                    className="path-8134-icon2"
                    alt=""
                    src={operatorAssistant}
                  />

                  <div className="autonomous">Operator Assist</div>
                </div>
                <div className="history-hero3">
                  <img className="path-8134-icon3" alt="" src={idle} />

                  <div className="autonomous">Idle</div>
                </div>
              </div>
            </div>
          )}
          <div className="mapViewOperation">
            <img
              src={toggleSatellite ? mapNor : mapSat}
              onClick={() => {
                setToggleSatellite(!toggleSatellite);
              }}
              alt=""
            />
          </div>
        </div>
        {mapInitialized && (
          <div
            ref={container}
            className="ol-popup incidentPopup"
            style={{ display: 'block' }}
            onMouseEnter={() => setMouseEntered(true)}
            onMouseLeave={() => setMouseEntered(false)}
          >
            <div ref={content}></div>
          </div>
        )}
        {showGuardrailModel && (
          <SnapView
            snapshotFlag={showGuardrailModel}
            guardrailsList={guardrailData}
            Close={() => setShowGuardrailModel(false)}
            selectedJumpnGo={{ tractor: { name: guardrailData?.tractorName } }}
          />
        )}
        {toggleTicketWidget && (
          <OperationalAnalyticsTicketDetails
            ticketId={ticketId}
            onCloseTicketWidget={onCloseTicketWidget}
            toggleTicketWidget={toggleTicketWidget}
          />
        )}
        {showIndicatorModel && (
          <DiagnosticWidget
            isEdit={false}
            details={errorDetails}
            setIsEdit={() => console.log('1')}
            setToggleWidget={setShowIndicatorModel}
            getSeverity={getSeverity}
            getTags={getTags}
            onCreateTicket={onCreateTicket}
            toggleWidget={showIndicatorModel}
            tractorId={errorDetails?.tractor_id}
          />
        )}
        {ticketDetails && toggleTicketWidget && (
          <TicketsCreateEdit
            tractorId={selectedTractor.tractorId}
            Close={onCloseTicketWidget}
            ticketObj={ticketDetails}
            toggleWidget={toggleTicketWidget}
            ticketCreationType={TICKET_TYPES.INDICATOR_ERROR_CODE}
          />
        )}
      </div>
    </>
  );
};

export default memo(OperationTractorDetailsMap);
