import React, { useEffect, useRef, useState } from 'react';
// import { operationalHoursData } from './data.json';
import { pathColorCodes } from '../../operationalAnalytics/constants';
import { userDealerOperationalHours } from './hooks/useDealerDashboard';
import { useApplicationContext } from '../../../context/AppContext';
import tusageIcon from '../../../assets/images/totalUsageIcon1.svg';
import dSavingsIcon from '../../../assets/images/dieselSavings.svg';
import cSavingsIcon from '../../../assets/images/co2Savedicon.svg';
import chartNoData from './images/chart_nodata.svg';
import MultiStackedBarChartDD from '../../charts/MultiStackedBarChartDD';
import { EmptyView } from './DealerCustomerUsage';

interface Props {
  cropType: string;
}

const DealerOperationalHoursCard: React.FC<Props> = ({ cropType }) => {
  const { userDetails } = useApplicationContext();
  const { initDealerOperationalHours, loading, operationalHours, savingsData } =
    userDealerOperationalHours();
  const [cardSize, setCardSize] = useState({ width: 0, height: 0 });
  const cardRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (userDetails.organization) initDealerOperationalHours(cropType);
  }, [userDetails, cropType]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setCardSize({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        });
      }
    });

    if (cardRef.current) {
      resizeObserver.observe(cardRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div className="ms_blockHead" ref={cardRef}>
      {!loading && !operationalHours?.length ? (
        <EmptyView image={chartNoData} />
      ) : (
        <MultiStackedBarChartDD
          className="dp_msBarChart"
          loader={loading}
          chartId="dealersOperationalHours"
          data={operationalHours}
          barColors={[
            pathColorCodes.manual,
            pathColorCodes.autonomy,
            pathColorCodes.op_assists,
            pathColorCodes.idle,
          ]}
          yTitle="Hours Of Operation"
          cardWidth={cardSize.width}
        />
      )}
      <div className="operation_cards_sec">
        <div className="operation_card">
          <div>
            <img src={tusageIcon} alt=" " />
          </div>
          <div className="operation_card_dtls">
            <span className="operation_card_title">Total Usage</span>
            <span className="operation_card_value">
              {savingsData.totalOperationalHours}
            </span>
          </div>
        </div>
        <div className="operation_card">
          <div>
            <img src={dSavingsIcon} alt=" " />
          </div>
          <div className="operation_card_dtls">
            <span className="operation_card_title">Diesel Savings</span>
            <span className="operation_card_value">
              ${savingsData.totalSavings}
            </span>
          </div>
        </div>
        <div className="operation_card">
          <div>
            <img src={cSavingsIcon} alt=" " />
          </div>
          <div className="operation_card_dtls">
            <span className="operation_card_title">
              CO<sub>2</sub> Savings
            </span>
            <span className="operation_card_value">
              {savingsData.totalCo2Savings} lbs
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealerOperationalHoursCard;
