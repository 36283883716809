import { Button, Modal, notification, Spin } from 'antd';
import 'antd/dist/antd.css';
import Layout from 'antd/lib/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import defaulttractorImg from '../../assets/images/MK-V-default.svg';
// subscribption images start here
// import sbAssist from '../../assets/images/sb_assist.svg';
// import sbAutomate from '../../assets/images/sb_automate.svg';
// import sbBasestation from '../../assets/images/sb_basestation.svg';
// import sbEssentials from '../../assets/images/sb_essentials.svg';
// import sbStarlink from '../../assets/images/sb_starlink.svg';
// import sbWifi from '../../assets/images/sb_wifi.svg';
// subscribption images ends here
import closeGreen from '../../assets/images/X_icon-bs.svg';

import waiIcon from '../../assets/images/wai_logo.svg';
import { getTireInformation, Updatetractor } from '../../constants/Api';
import {
  getStatusForTractor,
  getTractorStatusClass,
  tractorConstantsNew,
  tractorModelImageMap,
} from '../../constants/Common';
import { privilagesConstants } from '../../constants/Privilages';
import { tireList, TractorsList } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import applicationIds from '../../locale/applicationIds.json';
import translate from '../../locale/en_translate.json';
import RoutesConstants from '../../routes/RoutesConstant';
import { track } from '../../util/logger';
import AssignBasestationTractor from '../basestation/AssignBasestationTractor';
import CustomInput from '../common/CustomInput';
import DataCollectionModal from './DataCollectionModal';
import './style.css';

interface Props {
  fleetobj: TractorsList;
  Close: () => void;
  setRefresh: () => void;
  updateFleet: (data: any) => void;
  fleetflage: boolean;
}
const Editfleet: React.FC<Props> = ({
  fleetobj,
  updateFleet,
  Close,
  setRefresh,
  fleetflage,
}: Props) => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const [editFlag, setEditFlag] = useState<boolean>(false);
  const [Name, setName] = useState(fleetobj?.name);
  const { userDetails, privilegeChecker } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);

  const [showDataCollection, setShowDataCollection] = useState<boolean>(false);
  const [assignflag, setassignflag] = useState<boolean>(false);
  const [assignObj, setAssignObj] = useState<any>();
  const [tireInfo, setTireInfo] = useState<tireList>();
  const [showConfirmPop, setShowConfirmPop] = useState(false);

  const getTireDetails = async (serial_number: string) => {
    try {
      setLoader(true);
      const response = await getTireInformation(
        userDetails.organization.api_url,
        serial_number,
      );
      setTireInfo(response);
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (fleetobj && userDetails && userDetails.organization) {
      setName(fleetobj?.name);
      setEditFlag(true);
      getTireDetails(fleetobj?.serial_number);
    }
  }, [fleetobj, userDetails]);

  const Editdetails = () => {
    setEditFlag(false);
    track('Edit Fleet', {
      event: 'Edit Fleet Details Clicked',
    });
  };
  const SaveTractor = async () => {
    const data = {
      name: Name,
    };
    try {
      setLoader(true);
      const response: any = await Updatetractor(
        userDetails.organization.api_url,
        fleetobj.id,
        data,
      );
      fleetobj.name = Name;
      if (response && response?.id === fleetobj?.id)
        updateFleet({ ...fleetobj, ...response });
      notification.success({ message: t(translate.label.tractorUpdated) });
      setEditFlag(true);
      setRefresh();
      track('Edit Fleet', {
        event: 'Edit fleet name Successfully',
      });
    } catch (err: any) {
      track('Edit Fleet', {
        event: 'Edit fleet name Failed',
      });
      notification.error({
        message: err?.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const showUpdaterIcon = (record: any) => {
    if (
      record?.softwareversion &&
      record?.heartbeat?.software_version !==
        record?.latest_software?.software_version
    ) {
      return true;
    }

    return false;
  };

  const assignMethod = (obj: any) => {
    setAssignObj(obj);
    setassignflag(true);
  };
  const handleCancel = (state = false) => {
    if (state) {
      Close();
    }
    setassignflag(false);
  };

  return (
    <Layout>
      <div className={'rightSideWidegt ' + (fleetflage ? 'expand' : 'hide')}>
        {editFlag && (
          <>
            <div className="closeIconGray">
              <img
                id={applicationIds.fleetScreen.fleetCloseBtn}
                data-testid="closeButton-Editfleet"
                src={closeGreen}
                alt="close icon"
                onClick={Close}
              />
            </div>
            <div className="dpPulloutScroll">
              <div className="tractorDtlHead mb32">
                <div className="bsCard">
                  <div className="bsIconSec">
                    <img
                      // src={fleetTractorms}
                      src={
                        tractorModelImageMap(fleetobj?.tractor_model) ??
                        defaulttractorImg
                      }
                      width={150}
                      alt=""
                    />
                  </div>
                  <div className="bsNameStatus">
                    <span
                      className="bsNameHead"
                      data-testid="tractorHeader-Editfleet"
                    >
                      {fleetobj?.name}
                    </span>
                    <span
                      className={`bsStatus ${getTractorStatusClass(
                        fleetobj.isAvailable,
                      )}`}
                    >
                      {getStatusForTractor(fleetobj?.isAvailable)}
                    </span>
                  </div>
                </div>
              </div>

              <div className="bsContent mb32">
                <div className="bsRow">
                  <span className="bslabel">Hour Meter</span>
                  <span className="bsValue">{fleetobj?.hour_meter ?? '-'}</span>
                </div>

                <div className="bsRow">
                  <span className="bslabel">PIN</span>
                  <span className="bsValue">
                    {fleetobj?.tractor_pin ?? '-'}
                  </span>
                </div>

                <div className="bsRow">
                  <span className="bslabel">Tire Brand & Type</span>
                  <span className="bsValue">
                    {tireInfo ? tireInfo?.brand : '-'}{' '}
                    {tireInfo ? tireInfo?.tire_type : '-'}
                  </span>
                </div>
                {fleetobj?.isAvailable === tractorConstantsNew.off.key && (
                  <div className="bsRow">
                    <span className="bslabel">Last Online</span>
                    <span className="bsValue">-</span>
                  </div>
                )}
              </div>
              <div className="bsContent mb32">
                <div className="bsRow2 mb10">
                  <span className="bslabelHead">Versions</span>
                  <span className="bsValue wInh">
                    {showUpdaterIcon(fleetobj) && (
                      <Button
                        className="updateBtnAvl"
                        onClick={() => {
                          push({
                            pathname: RoutesConstants.FleetHealth,
                            state: {
                              action: 'SOFTWARE',
                              tractorId: fleetobj?.id,
                            },
                          });
                        }}
                      >
                        Update Available
                      </Button>
                    )}
                  </span>
                </div>
                <div className="bsRow">
                  <span className="bslabel">Software </span>
                  <span className="bsValue">
                    {fleetobj?.softwareversion ?? '-'}
                  </span>
                </div>
                <div className="bsRow">
                  <span className="bslabel">Firmware </span>
                  <span className="bsValue">{fleetobj?.firmware ?? '-'}</span>
                </div>
              </div>
              <div className="bsContent2 mb32">
                <div className="bsRow2">
                  <span className="bslabelHead">Assignments</span>
                  <span className="bsValue wInh">
                    {privilegeChecker(
                      privilagesConstants.Edit_base_station_information,
                    ) &&
                      fleetobj?.connection === 'online' && (
                        <Button
                          className="updateBtnAvl"
                          onClick={() => {
                            assignMethod(fleetobj);
                          }}
                        >
                          {t(translate.buttons.edit)}
                        </Button>
                      )}
                  </span>
                </div>
                <div className="bsRow">
                  <span className="bslabel">Site</span>
                  <span className="bsValue">
                    {fleetobj?.site_details?.farmsite_name ?? 'Unassigned'}
                  </span>
                </div>
                <div className="bsRow">
                  <span className="bslabel">Base Station</span>
                  <span className="bsValue">
                    {fleetobj?.basestation?.name ?? 'Unassigned'}
                  </span>
                </div>
              </div>
              {/* <div className="bsContent2 mb32">
                <div className="bsRow2">
                  <span className="bslabelHead dpgap8">
                    Subscriptions
                    <span>
                      <img src={infoDp} alt="" />
                    </span>
                  </span>
                  <span className="bsValue">
                    <div className="assignstationbtn">
                      <button className="learnMore">Learn More</button>
                    </div>
                  </span>
                </div>
                <div className="bsRow">
                  <span className="bslabel">Site</span>
                  <span className="bsValue">test299</span>
                </div>
                <div className="bsRow">
                  <span className="bslabel">Base Station</span>
                  <span className="bsValue">Unassigned</span>
                </div>
              </div> */}
              {/* <div className="bsContent2 mb32">
                <div className="dpbsCard">
                  <div className="subTypeIcon">
                    <img src={sbEssentials} alt="" />
                  </div>
                  <div className="subDtlSec">
                    <div className="subPackDtl">
                      <span className="subPackName">Digital Plan</span>
                      <span className="subPackType">Essentials</span>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div className="dpbsCard">
                  <div className="subTypeIcon">
                    <img src={sbBasestation} alt="" />
                  </div>
                  <div className="subDtlSec">
                    <div className="subPackDtl">
                      <span className="subPackName">Connectivity Plan</span>
                      <span className="subPackType">4G Cellular</span>
                    </div>
                    <div>
                      <span className="subExpiryDtl sbexpired">
                        Exp. 5/20/25
                      </span>
                    </div>
                  </div>
                </div>
                <div className="dpbsCard">
                  <div className="subTypeIcon">
                    <img src={sbStarlink} alt="" />
                  </div>
                  <div className="subDtlSec">
                    <div className="subPackDtl">
                      <span className="subPackName">Connectivity Plan</span>
                      <span className="subPackType">Starlink</span>
                    </div>
                    <div>
                      <span className="subExpiryDtl sbexpired">
                        Exp. 5/20/25
                      </span>
                    </div>
                  </div>
                </div>
                <div className="dpbsCard">
                  <div className="subTypeIcon">
                    <img src={sbWifi} alt="" />
                  </div>
                  <div className="subDtlSec">
                    <div className="subPackDtl">
                      <span className="subPackName">Connectivity Plan</span>
                      <span className="subPackType">Wi-Fi Only</span>
                    </div>
                    <div>
                      <span className="subExpiryDtl sbexpired"></span>
                    </div>
                  </div>
                </div>
                <div className="dpbsCard">
                  <div className="subTypeIcon">
                    <img src={sbAssist} alt="" />
                  </div>
                  <div className="subDtlSec">
                    <div className="subPackDtl">
                      <span className="subPackName">Digital Plan</span>
                      <span className="subPackType">Assist</span>
                    </div>
                    <div>
                      <span className="subExpiryDtl sbexpired">
                        Exp. 12/20/27
                      </span>
                    </div>
                  </div>
                </div>
                <div className="dpbsCard">
                  <div className="subTypeIcon">
                    <img src={sbAutomate} alt="" />
                  </div>
                  <div className="subDtlSec">
                    <div className="subPackDtl">
                      <span className="subPackName">Digital Plan</span>
                      <span className="subPackType">Automate</span>
                    </div>
                    <div>
                      <span className="subExpiryDtl sbexpired">
                        Exp. 12/20/27
                      </span>
                    </div>
                  </div>
                </div>
                <div className="dpbsCard">
                  <div className="subTypeIcon">
                    <img src={sbBasestation} alt="" />
                  </div>
                  <div className="subDtlSec">
                    <div className="subPackDtl">
                      <span className="subPackName">Connectivity Plan</span>
                      <span className="subPackType">Base Station LTE</span>
                    </div>
                    <div>
                      <span className="subExpiryDtl sbexpired">
                        Exp. 5/20/25
                      </span>
                    </div>
                  </div>
                </div>
                <div className="dpbsCard">
                  <div className="subTypeIcon">
                    <img src={sbAutomate} alt="" />
                  </div>
                  <div className="subDtlSec">
                    <div className="subPackDtl">
                      <span className="subPackName">Digital Plan</span>
                      <span className="subPackType">Automate</span>
                    </div>
                    <div>
                      <span className="subExpiryDtl sbexpired">
                        Pending Activation
                      </span>
                    </div>
                  </div>
                </div>
                <div className="dpbsCard expiredBdr">
                  <div className="subTypeIcon">
                    <img src={sbAssist} alt="" />
                  </div>
                  <div className="subDtlSec">
                    <div className="subPackDtl">
                      <span className="subPackName">Digital Plan</span>
                      <span className="subPackType">Assist</span>
                    </div>
                    <div>
                      <span className="subExpiryDtl sbexpired">EXPIRED</span>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="bsButtonSec">
              <button
                className="bsButton bsBtnGreen"
                id={applicationIds.fleetScreen.editFleetDetails}
                onClick={Editdetails}
                data-testid="fleetEditDetailsLink-Editfleet"
              >
                {t(translate.buttons.editNAme)}
              </button>
            </div>
          </>
        )}
        {!editFlag && (
          <>
            <div className="closeIconGray">
              <img
                id={applicationIds.fleetScreen.fleetCloseBtn}
                data-testid="closeButton-Editfleet"
                src={closeGreen}
                alt="close icon"
                onClick={Close}
              />
            </div>
            <div className="tractorDtlHead mb32">
              <div className="bsCard">
                <div className="bsIconSec">
                  <img
                    src={
                      tractorModelImageMap(fleetobj?.tractor_model) ??
                      defaulttractorImg
                    }
                    width={150}
                    alt=""
                  />
                </div>
                <div className="bsNameStatus">
                  <span
                    className="bsNameHead"
                    data-testid="tractorHeader-Editfleet"
                  >
                    {fleetobj?.name}
                  </span>
                  <span
                    className={`bsStatus ${getTractorStatusClass(
                      fleetobj.isAvailable,
                    )}`}
                  >
                    {getStatusForTractor(fleetobj?.isAvailable)}
                  </span>
                </div>
              </div>
            </div>
            <div className="bsContent2 mb32">
              <div className="bsRow2">
                <span className="bslabelHead">Edit Tractor Name</span>
              </div>
              <div className="bsRow2">
                <span className="bslabelFull">
                  This change will be reflected on all accounts across the
                  organization and on the Smart Screen.
                </span>
              </div>
            </div>
            <div className="bsContent mb32 widget_card">
              <CustomInput
                label={t(translate.editFleet.tractorName)}
                value={Name}
                required={true}
                setValue={(value) => setName(value)}
                testId="tractorNameEditInputField-Editfleet"
              />
            </div>
            <div className="bsButtonSec">
              <Button
                className="bsButton bsBtnGreen"
                id={applicationIds.fleetScreen.saveTractor}
                onClick={() => setShowConfirmPop(true)}
                data-testid="fleetSaveButton-Editfleet"
                disabled={fleetobj.name === Name || Name.trim().length == 0}
              >
                {t(translate.buttons.saveChanges)}
              </Button>
            </div>
            <div className="edit-loader">
              {loader && (
                <div className="loader">
                  <Spin size="large" />
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {showDataCollection && (
        <DataCollectionModal
          showModal={showDataCollection}
          closeModal={() => setShowDataCollection(false)}
          tractorIdentity={fleetobj}
        />
      )}

      {assignObj && assignflag && (
        <AssignBasestationTractor
          showModal={assignflag}
          handleCancel={(value) => handleCancel(value)}
          assignObj={assignObj}
        ></AssignBasestationTractor>
      )}
      {showConfirmPop && (
        <Modal
          visible={showConfirmPop}
          closable={false}
          footer={false}
          className="edit_operator edit_fleet"
        >
          <div className="popup_head">
            <img src={waiIcon} />
            <span className="head_text">Save Changes</span>
          </div>
          <p className="popup_desc_text">
            Are you sure you want to exit? Your changes will not be saved.
          </p>
          <div className="button_sec">
            <Button
              key="edit"
              className="normal_btn"
              onClick={() => setShowConfirmPop(false)}
            >
              Exit Without Saving
            </Button>
            <Button
              key="cancel"
              className="green_btn edit_fleet_save"
              onClick={() => {
                setShowConfirmPop(false);
                SaveTractor();
              }}
            >
              Save
            </Button>
          </div>
        </Modal>
      )}
    </Layout>
  );
};

export default Editfleet;
