/* eslint-disable no-console */
import { Col, Row } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { ApplicationContext } from '../../context/AppContext';
import EditImplement from './EditImplement';
import { isEmpty, ProgressData } from './common';
import useAnalytics from './hooks/useAnalytics';
import useTractorAnalyticsDetails from './hooks/useTractorAnalyticsDetails';
import useAnalyticsStore from './store/operationAnalytics';
import tractorAnalyticDetailsStore from './store/tractorAnalyticDetails';

import PieChart from '../charts/PieChart';
import AlertsContainer from './AlertsContainer';
import MapAndCamera from './OperationalCoverageMap/MapAndCamera';
import { pathColorCodes } from './constants';
import CustomDatePicker from './ui/CustomDatePicker';
import { HrsMins, WeekendsFilter } from './ui/helper';

import distance from './images/distance.svg';
import imp_icon from './images/implement-1.svg';
import speed from './images/speed.svg';
import time from './images/time.svg';

import { getDateTime, getDateTimes } from '../../constants/Common';
import {
  ATOM,
  GILES,
  GUARD_RAILS,
  JUMP_AND_GO,
} from '../../constants/constant';
import './css/global.scss';
import './css/styles.scss';
import OperationalAnalyticsTicketDetails from './OperationalAnalyticsTicketDetails';

export interface Task {
  id: number;
  title: string;
  startTime: string; // Format: HH:mm (e.g., "09:00")
  endTime: string; // Format: HH:mm (e.g., "17:00")
}

interface Props {
  onBack: () => void;
  setOperationType: (operationsType: string) => void;
  setSelectedTab: (selectedTab: string) => void;
}

const OperationAnalytics: React.FC<Props> = ({
  onBack,
  setOperationType,
  setSelectedTab,
}) => {
  const { userDetails } = useContext(ApplicationContext);
  const { ticketsLoader, getOperationsByImplement, initAnalyticsData } =
    useTractorAnalyticsDetails();
  const { dateText } = useAnalytics();
  const { selectedDate, setSelectedDate, toggleWeekends, includeWeekends } =
    useAnalyticsStore();
  const { analyticDetails, operationalHours, selectedTractor, reset } =
    tractorAnalyticDetailsStore();
  const [toggleTicketWidget, setToggleTicketWidget] = useState<boolean>(false);
  const [ticketId, setTicketId] = useState<any>();
  const editImplementRef = useRef<any>(null);
  const [jngData, setJngData] = useState<ProgressData[]>([]);
  const [jngLoader, setJngLoader] = useState<boolean>(false);

  const [guardrailData, setGuardrailData] = useState<any>({});
  const [showGuardrailModel, setShowGuardrailModel] = useState(false);
  const [errorDetails, setErrorDetails] = useState<any>();
  const [showIndicatorModel, setShowIndicatorModel] = useState(false);

  const { operationHours, totalOperationalHours, operators } = analyticDetails;
  const [showTicketPopup, setShowTicketPopup] = useState<boolean>(false);
  const [snapLoader, setSnapLoader] = useState(false);
  const [indLoader, setIndLoader] = useState(false);

  useEffect(() => {
    if (userDetails?.organization && selectedDate && selectedTractor) {
      const { implement, tractorId } = selectedTractor;

      initAnalyticsData(
        selectedDate?.startOf('day')?.toDate()?.getTime(),
        selectedDate?.endOf('day')?.toDate()?.getTime(),
        implement,
        tractorId,
      );
      getOperationsByImplement();
    }
  }, [userDetails, selectedDate, selectedTractor]);

  const renderTime = ({ hours, minutes }: any) => {
    return <HrsMins hours={hours} minutes={minutes} />;
  };

  const ticketDetailsById = (ticket: any) => {
    setToggleTicketWidget(true);
    setTicketId(ticket?.ticketId);
  };

  const onCloseTicketWidget = () => {
    setToggleTicketWidget(false);
  };

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const handleOperationIndClick = (content: any) => {
    if (content) {
      const { subsystem = '', functionality_affected = '' } =
        content.error_code_data || {};
      content.functionality_affected = functionality_affected;
      content.subsystem = subsystem;
      content.severity = content?.indicator_level;
      content.start_time = content?.created_date_time
        ? getDateTimes(content?.created_date_time)
        : '';
      content.counter_measure = content?.error_code_data?.counter_measure
        ? content?.error_code_data?.counter_measure?.split(';')
        : '';
      setErrorDetails(content);
      setShowIndicatorModel(true);
      setShowGuardrailModel(false);
      setToggleTicketWidget(false);
    }
  };
  const handleOperationSnapClick = (content: any) => {
    if ([GUARD_RAILS, GILES, ATOM, JUMP_AND_GO].includes(content?.created_at)) {
      setGuardrailData({
        guard_rail_triggered_uuid: content.transaction_id,
        id: content.tractor_id.id,
        itemId: content.id,
        tractor_id: content.tractor_id.id,
        title: getDateTime(content.created_date_time),
        created_date_time: content.created_date_time,
        guard_rail_type: content.guard_rail_type,
        created_at: content.created_at,
        created_by: content.created_by,
        tractorName: content?.tractor_id?.name,
        created_type_text: content?.created_type_text,
      });
      setShowGuardrailModel(true);
      setShowIndicatorModel(false);
      setToggleTicketWidget(false);
    }
  };

  return (
    <>
      <section className="background-parent">
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={10}>
            <div className="frame-parent2 noimplement_modes">
              <div className="frame-wrapper">
                <div className="frame-group">
                  <div className="back-header">
                    <div className="backButtonBig" onClick={onBack}></div>
                    {/* <img src={Back_Button} height="55" onClick={onBack} /> */}
                    <div className="header">
                      <div className="mowing">
                        {analyticDetails?.operationType}
                      </div>
                      <div className="date-picker-section">
                        <div className="date-month-selection">{dateText}</div>
                        <div className="date-picker">
                          <CustomDatePicker
                            handleOnChange={(dates) => setSelectedDate(dates)}
                            selectedDate={selectedDate}
                            showToday={false}
                          />
                          {/* <img src={cal_img} alt="" /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="operations-graph1">
                <div className="header-info">
                  <div className="name-implement-operators">
                    <span className="text-10343">
                      {selectedTractor?.tractorShortPin}
                    </span>
                    <div className="implement-operators">
                      <div className="edit-implement">
                        <div className="implement-name">
                          <div className="">
                            <img src={imp_icon} />
                          </div>
                          <span className="bush-hog-rotary-mower">
                            {!isEmpty(selectedTractor?.implement)
                              ? selectedTractor?.implement
                              : 'No Implement'}
                          </span>
                        </div>
                        <div className="frame-69">
                          <span
                            className="edit-implement-6a"
                            onClick={() =>
                              editImplementRef.current.showModal(true)
                            }
                          >
                            Edit Implement
                          </span>
                        </div>
                      </div>
                      <div className="frame-6b">
                        {operators.map((operator: any, i: number) => {
                          return (
                            <div className="frame-6c" key={i}>
                              <div className="operator-icons">
                                <div className="avatar-circles">
                                  <div className="mm">{operator?.name}</div>
                                </div>
                              </div>
                              <span className="matt-monroe">
                                {operator?.operator}:
                              </span>
                              <span className="h-m-ac">
                                <HrsMins
                                  hours={operator?.operationalHours.hours}
                                  minutes={operator?.operationalHours.minutes}
                                />
                                , {operator.distance} mi
                              </span>
                            </div>
                          );
                        })}
                        {/* <div className="frame-6c">
                          <div className="operator-icons">
                            <div className="avatar-circles">
                              <div className="mm">MS</div>
                            </div>
                          </div>
                          <span className="matt-monroe">Mike Smith:</span>
                          <span className="h-m-ac">3h 34m, 13.5ac</span>
                        </div> */}
                        {/* <div className="frame-70">
                          <div className="operator-icons">
                            <div className="avatar-circles">
                              <div className="mm">MS</div>
                            </div>
                          </div>
                          <span className="mike-smith">Mike Smith:</span>
                          <span className="h-m-ac-74">3h 34m, 13.5ac</span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="tractor-render">
                    <div className="image"></div>
                  </div>
                </div>
                <div className="duration">
                  <div className="duration-block">
                    <div className="graph-call-outs sixEight">
                      <div className="title-icon">
                        <img src={time} />
                        <span className="operations-duration">Duration</span>
                      </div>
                      <div className="caption">
                        <span className="operation-lasted">
                          This operation lasted{' '}
                        </span>
                        <span className="h-m">
                          <HrsMins
                            hours={totalOperationalHours?.hours}
                            minutes={totalOperationalHours?.minutes}
                          />
                        </span>
                        <span className="operation-lasted-76"> </span>
                        <span className="pie-chart">
                          across different drive modes.
                        </span>
                      </div>
                      <div className="frame-77">
                        <div className="rectangle-78">
                          <div className="manual-bar"></div>
                          <div className="title-4h-03m">
                            <span className="title-manual">
                              {renderTime(operationHours?.manual)}
                            </span>
                            <span className="frame-7a">Manual</span>
                          </div>
                        </div>
                        <div className="title-autonomy">
                          <div className="autonomy-bar"></div>
                          <div className="rectangle-81">
                            <span className="frame-82">
                              {renderTime(operationHours?.autoDrive)}
                            </span>
                            <span className="title-35-m">Autonomy</span>
                          </div>
                        </div>
                        <div className="rectangle-7b">
                          <div className="opassist-bar"></div>
                          <div className="title-2h-05m">
                            <div className="op-assist">
                              {renderTime(operationHours?.opAssist)}
                            </div>
                            <span className="title-1h-12m">Op Assist</span>
                          </div>
                        </div>

                        <div className="group-1a">
                          <div className="idle-bar"></div>
                          <div className="group-1b">
                            <div className="wrapper-22">
                              {renderTime(operationHours?.idle)}
                              {/* {operationHours?.idle?.hours && (
                                <>
                                  <span className="text-52">
                                    {operationHours?.idle?.hours}
                                  </span>
                                  <span className="text-53">h </span>
                                </>
                              )}
                              <span className="text-52">
                                {operationHours?.idle?.minutes}
                              </span>
                              <span className="text-53">m</span> */}
                            </div>
                            <span className="idle-time">Idle time</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="group">
                      {/* <img className="" src={PieChart} /> */}
                      <PieChart
                        loader={ticketsLoader}
                        chartId="OperationalHours"
                        data={operationalHours}
                        barColors={[
                          pathColorCodes?.manual,
                          pathColorCodes?.autonomy,
                          pathColorCodes?.op_assists,
                          pathColorCodes?.idle,
                        ]}
                        // barColors={['#67afb7', '#ec9322', '#f1ce90', '#cfcfcf']}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="statistics">
                {/* <div className="statistics-item">
                  <img src={coverage} />
                  <div className="statistics-group">
                    <span className="statistics-title ">Coverage (ac)</span>
                    <span className="statistics-value">
                      {analyticDetails?.coverage}
                    </span>
                  </div>
                </div>
                <div className="statistics-item">
                  <img src={efficiency} />
                  <div className="statistics-group">
                    <span className="statistics-title">Efficiency (ac/hr)</span>
                    <span className="statistics-value">
                      {analyticDetails?.efficiency}
                    </span>
                  </div>
                </div> */}
                <div className="statistics-item">
                  <img src={distance} />
                  <div className="statistics-group">
                    <span className="statistics-title ">Distance (mi)</span>
                    <span className="statistics-value">
                      {analyticDetails?.distance}
                    </span>
                  </div>
                </div>
                <div className="statistics-item">
                  <img src={speed} />
                  <div className="statistics-group">
                    <span className="statistics-title ">Speed (mph)</span>
                    <span className="statistics-value">
                      {analyticDetails?.averageSpeed}
                    </span>
                  </div>
                </div>
              </div>
              <div className="bottom-left-tickets">
                {/* <div className="header2">
                  <div className="header2">
                    <div className="title-info">
                      <div className="operations9">Tickets</div>
                    </div>
                    <div className="shape-compiler">
                      <img
                        className="implement-1-icon1"
                        alt=""
                        src={tickets_outline}
                      />

                      <div className="image-parser">{ticketsData?.length}</div>
                    </div>
                  </div>
                </div> */}
                <div className="link-aggregator">
                  <AlertsContainer
                    ticketDetailsById={ticketDetailsById}
                    handleOperationSnapClick={handleOperationSnapClick}
                    handleOperationIndClick={handleOperationIndClick}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={14}>
            <div className="incidents-section">
              <WeekendsFilter
                includeWeekends={includeWeekends}
                toggleWeekends={toggleWeekends}
              />
              <MapAndCamera
                setJngData={setJngData}
                setJngLoader={setJngLoader}
                setShowGuardrailModel={setShowGuardrailModel}
                setShowIndicatorModel={setShowIndicatorModel}
                setShowTicketPopup={setShowTicketPopup}
                setToggleTicketWidget={setToggleTicketWidget}
                setTicketId={setTicketId}
                setGuardrailData={setGuardrailData}
                setErrorDetails={setErrorDetails}
                showGuardrailModel={showGuardrailModel}
                showIndicatorModel={showIndicatorModel}
                showTicketPopup={showTicketPopup}
                toggleTicketWidget={toggleTicketWidget}
                guardrailData={guardrailData}
                errorDetails={errorDetails}
                ticketId={ticketId}
                snapLoader={snapLoader}
                setSnapLoader={setSnapLoader}
                indLoader={indLoader}
                setIndLoader={setIndLoader}
              />

              {/* <div className="container-17">
                <div className="bottom-left-graphs1">
                  <div className="header2 align-top">
                    <div className="align-left graph-call-outs6">
                      <div className="operations4">Efficiency</div>
                      <div className="proximity-provider">
                        <div className="layer-llama">
                          <span className="span2">3.2 </span>
                          <span className="achr">ac/hr</span>
                        </div>

                        <div className="comparative-statistic">
                          <img
                            className="increase-icon"
                            loading="lazy"
                            alt=""
                            src={increase}
                          />

                          <div className="blend-brewer">19%</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <img src={hr2} alt="" />
                    </div>
                  </div>
                  <div className="link-aggregator ">
                    <img src={efficiency_graph} className="graphImg" />
                  </div>
                </div>

                <div className="bottom-left-graphs1">
                  <div className="header2 align-top">
                    <div className="align-left graph-call-outs6">
                      <div className="title-info">
                        <div className="operations">Average Battery Range</div>
                        <div className="info-bubbles">
                          <img className="info-3-icon" src={info_img} />
                        </div>
                      </div>
                      <div className="proximity-provider">
                        <div className="layer-llama">
                          <span className="span2">8</span>
                          <span className="achr">h</span>
                          <span className="span2"> 42</span>
                          <span className="achr">m</span>
                        </div>
                        <div className="comparative-statistic2">
                          <img
                            className="decrease-icon1"
                            alt=""
                            src={decrease}
                          />
                          <div className="blend-brewer">8%</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <img src={bnatterycharger} alt="" />
                    </div>
                  </div>
                  <div className="link-aggregator ">
                    <img src={battery_graph} className="graphImg" />
                  </div>
                </div>
              </div> */}
              {/* <div className="savings">
                <div className="left-side">
                  <div className="graph-call-outs2">
                    <div className="title-info">
                      <div className="operations9">Total Savings</div>
                      <div className="info-bubbles">
                        <img className="info-3-icon" src={info_img} />
                      </div>
                    </div>
                    <div className="filter-merger">$287.32</div>
                  </div>
                  <div className="image-cropper">
                    <div className="graph-call-outs3">
                      <div className="operations10">CO2 Savings</div>
                      <div className="div49">62.8 lbs</div>
                    </div>
                    <div className="numerical-summarizer">
                      <img className="co2-icon" src={co2_img} />
                    </div>
                  </div>
                </div>
                <div className="savings-graph">
                  <img src={savings_graph} style={{ height: "123px" }} />
                </div>
              </div> */}
            </div>
          </Col>
        </Row>
      </section>
      <EditImplement
        ref={editImplementRef}
        onImplementSelect={(operationType: string) => {
          setOperationType(operationType);
          setSelectedTab('operationalHours');
        }}
        jngData={jngData}
        loader={jngLoader}
      />
      {/* {toggleTicketWidget && (
        <OperationalAnalyticsTicketDetails
          ticketId={ticketId}
          onCloseTicketWidget={onCloseTicketWidget}
          toggleTicketWidget={toggleTicketWidget}
        />
      )} */}
    </>
  );
};

export default OperationAnalytics;
