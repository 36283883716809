import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import DealerTractors from './Tractors';
import Customers from './Customers';
import BaseStation from './BaseStation';
import { useLocation } from 'react-router-dom';

const { TabPane } = Tabs;

const Inventory: React.FC = () => {
  const [tabid, setTabid] = useState<string>('tractors');
  const location = useLocation();
  const state: any = location && location.state ? location.state : null;

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('state', state);
    if (state && state.tab === 'customers') {
      setTabid(state.tab);
    }
  }, [state]);

  return (
    <div className="rightSideContainer">
      <Tabs
        activeKey={tabid}
        centered
        id="org-tab-Organization"
        className="common_tabs implementTab"
        onChange={(key) => {
          setTabid(key);
        }}
      >
        <TabPane
          tab="Tractors"
          key="tractors"
          className="attTableHeight mBottom0"
        >
          {tabid === 'tractors' && <DealerTractors />}
        </TabPane>
        <TabPane
          tab="Customers"
          key="customers"
          className="attTableHeight mBottom0"
        >
          {tabid === 'customers' && <Customers />}
        </TabPane>
        <TabPane
          tab="Basestations"
          key="basestation"
          className="attTableHeight mBottom0"
        >
          {tabid === 'basestation' && <BaseStation />}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Inventory;
