import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Avatar, Button, Input, notification, Select } from 'antd';
import Layout from 'antd/lib/layout/layout';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProfileIcon from '../../assets/images/defaultProfileIcon.svg';
import closeGreen from '../../assets/images/right_widget_close_icon.svg';
import { AddNewEmployee, imageUploadMethod } from '../../constants/Api';
import { isValidEmail, onlyNumbersAllow } from '../../constants/Common';
import { ApplicationContext } from '../../context/AppContext';
import { useTeamsContext } from '../../context/TeamContext';
import applicationIds from '../../locale/applicationIds.json';
import translate from '../../locale/en_translate.json';
import ImageCrop from '../widget/ImageCrop';
import AppLoader from './AppLoader';
interface Props {
  Close: (state?: boolean) => void;
  toggleWidget: boolean;
}

const AddEmployee: React.FC<Props> = ({ Close, toggleWidget }: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const { teams } = useTeamsContext();
  const [selectTeamsIds, setselectTeamsIds] = useState<number[]>([]);
  const [access, setAccess] = useState({
    app_access: false,
    tractor_access: false,
  });
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [contactNum, setContactNum] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>();
  const [loader, setLoader] = useState<boolean>(false);
  const [firstNameError, setFirstNameError] = useState<boolean>();
  const [firstNameLongError, setFirstNameLongError] = useState<boolean>();
  const [firstNameSpecialCharaterError, setFirstNameSpecialCharaterError] =
    useState<boolean>();
  const [lastNameSpecialCharacterError, setLastNameSpecialCharacterError] =
    useState<boolean>();
  const [lastNameError, setLastNameError] = useState<boolean>();
  const [lastNameLongError, setLastNameLongError] = useState<boolean>();
  const [teamValidation, setteamValidation] = useState<boolean>(false);
  const [validations, setValidations] = useState<any>({
    firstName: true,
    lastName: true,
    email: true,
    enabled: false,
  });
  const { Option } = Select;
  const [fileUrl, setFileUrl] = useState<any>('');
  const [fileUrlData, setfileUrlData] = useState<any>('');

  useEffect(() => {
    const taccess = teams
      .filter((team: any) => selectTeamsIds.includes(team.id))
      .reduce(
        (a: any, team: any) => {
          if (team.app_access) a.app_access = true;
          if (team.tractor_access) a.tractor_access = true;
          return a;
        },
        { app_access: false, tractor_access: false },
      );
    setAccess(taccess);
  }, [selectTeamsIds]);

  useEffect(() => {
    if (
      firstName &&
      firstName?.trim().length > 2 &&
      firstName?.trim().length <= 25 &&
      !getregexErrors(firstName?.trim()) &&
      lastName &&
      lastName?.trim().length > 2 &&
      lastName?.trim().length <= 25 &&
      !getregexErrors(lastName?.trim()) &&
      selectTeamsIds &&
      selectTeamsIds.length > 0
    ) {
      if (access?.app_access) {
        const emildata =
          email && email.trim().length > 0 && isValidEmail(email);
        setIsDisabled(!emildata);
      } else {
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(true);
    }
  }, [firstName, lastName, email, access, selectTeamsIds]);

  useEffect(() => {
    if (access?.app_access && email?.trim()?.length > 0) {
      const validationError = isValidEmail(email);
      setEmailError(!validationError);
    } else if (email?.trim().length > 2) {
      const validationError = isValidEmail(email);
      setEmailError(!validationError);
    } else {
      setEmailError(false);
    }
  }, [email, access]);

  const getregexErrors = (value: any) => {
    const exp = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    const regexp = new RegExp(exp);
    return regexp.test(value);
  };

  useEffect(() => {
    const exp = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    const regexp = new RegExp(exp);
    if (regexp.test(firstName)) {
      setFirstNameSpecialCharaterError(true);
    } else {
      setFirstNameSpecialCharaterError(false);
    }
    if (firstName?.trim() !== '' && firstName?.trim().length < 3) {
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
    if (firstName?.trim() !== '' && firstName?.trim().length > 25) {
      setFirstNameLongError(true);
    } else {
      setFirstNameLongError(false);
    }
  }, [firstName]);

  useEffect(() => {
    const exp = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    const regexp = new RegExp(exp);
    if (regexp.test(lastName)) {
      setLastNameSpecialCharacterError(true);
    } else {
      setLastNameSpecialCharacterError(false);
    }

    if (lastName?.trim() !== '' && lastName?.trim().length < 3) {
      setLastNameError(true);
    } else {
      setLastNameError(false);
    }

    if (lastName?.trim() !== '' && lastName?.trim().length > 25) {
      setLastNameLongError(true);
    } else {
      setLastNameLongError(false);
    }
  }, [lastName]);

  const selectTeams = async (e: any) => {
    setselectTeamsIds(e);
    setteamValidation(false);
  };

  const handleFormSubmit = () => {
    const exp = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    const regexp = new RegExp(exp);

    setValidations({
      ...validations,
      firstName:
        firstName.trim() !== '' &&
        firstName?.trim().length > 2 &&
        firstName?.trim().length < 25 &&
        !regexp.test(firstName),
      lastName:
        lastName?.trim() !== '' &&
        lastName?.trim().length > 2 &&
        lastName?.trim().length < 25 &&
        !regexp.test(lastName),
      email: access?.app_access
        ? email && email.trim() !== '' && isValidEmail(email)
        : true,
      enabled: true,
    });
    if (email) {
      if (regexp.test(lastName)) {
        setLastNameSpecialCharacterError(true);
      }
    }
  };

  useEffect(() => {
    const isValid = Object.values(validations).every((item) => item);
    if (isValid && Object.keys(validations).length) createEmpDetails();
  }, [validations]);

  const createEmpDetails = async () => {
    const payload: any = {
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      organization_id: userDetails.organization_id,
      email: email ? email : '',
      contact_number: contactNum ? contactNum : '',
      teams: selectTeamsIds,
    };

    try {
      setLoader(true);
      setIsDisabled(true);
      const response = await AddNewEmployee(
        userDetails.organization.api_url,
        payload,
      );
      if (fileUrlData) {
        imageUpload(response);
      }
      notification.success({ message: t(translate.label.employeeCreated) });
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
      setIsDisabled(false);
      Close(true);
    }
  };

  const imageUpload = async (response: any) => {
    try {
      const formData = new FormData();
      formData.append('image', fileUrlData);
      formData.append('category_id', response.id);
      formData.append('category_name', 'USER');
      await imageUploadMethod(
        userDetails.organization.api_url,
        userDetails.organization.id,
        formData,
      );
      Close(true);
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };
  return (
    <Layout>
      <div className={'rightSideWidegt ' + (toggleWidget ? 'expand' : 'hide')}>
        <div className="widgetCloseBtn">
          <img
            id={applicationIds.personnelScreen.AddEmpClose}
            src={closeGreen}
            alt="close icon"
            data-testid="modalCloseIcon-AddEmployee"
            onClick={() => Close()}
          />
        </div>
        <div className="profileEdit">
          <div className="proRow rowGapBtm13">
            <h4
              className="headline3"
              data-testid="addEmployeeHeader-AddEmployee"
            >
              {t(translate.employeeAdd.title)}
            </h4>
          </div>
          <div className="uploadImg proRow mr0 rowGapBtm2">
            <div className="userIcon">
              <div className="proRow proPic">
                {fileUrl !== '' ? (
                  <Avatar src={fileUrl}></Avatar>
                ) : (
                  <Avatar src={ProfileIcon} className="defaulticon"></Avatar>
                )}
              </div>
              <ImageCrop
                setFileUrl={setFileUrl}
                setfileUrlData={setfileUrlData}
              ></ImageCrop>
            </div>
          </div>
          <div className="proRow">
            <div className="width100 rowGapBtm3">
              <div>
                <Input
                  data-testid="firstNameInputField-AddEmployee"
                  placeholder="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                  className={classNames({
                    errorClass:
                      firstNameError ||
                      firstNameSpecialCharaterError ||
                      firstNameLongError,
                  })}
                />
              </div>
              {firstNameError && (
                <label
                  style={{ color: 'red' }}
                  data-testid="minCharReqErrorMsg-firstName-AddEmployee"
                >
                  {t(translate.errorMessages.minCharError)}
                </label>
              )}
              {firstNameError && firstNameSpecialCharaterError && <br />}
              {firstNameSpecialCharaterError && (
                <label
                  style={{ color: 'red' }}
                  data-testid="specialCharErrorMsg-firstName-AddEmployee"
                >
                  {t(translate.errorMessages.specialCharError)}
                </label>
              )}
              {firstNameSpecialCharaterError && firstNameLongError && <br />}
              {firstNameLongError && (
                <label
                  style={{ color: 'red' }}
                  data-testid="maxCharReqErrorMsg-firstName-AddEmployee"
                >
                  {t(translate.errorMessages.maxCharError)}
                </label>
              )}
            </div>
            <div className="width100 rowGapBtm3">
              <div>
                <Input
                  data-testid="lastNameInputField-AddEmployee"
                  placeholder="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                  className={classNames({
                    errorClass:
                      lastNameError ||
                      lastNameSpecialCharacterError ||
                      lastNameLongError,
                  })}
                />
              </div>
              {lastNameError && (
                <label
                  style={{ color: 'red' }}
                  data-testid="minCharectorsReqErrorMsg-lasttName-AddEmployee"
                >
                  {t(translate.errorMessages.minCharError)}
                </label>
              )}
              {lastNameError && lastNameSpecialCharacterError && <br />}
              {lastNameSpecialCharacterError && (
                <label
                  style={{ color: 'red' }}
                  data-testid="specialCharErrorMsg-lasttName-AddEmployee"
                >
                  {t(translate.errorMessages.specialCharError)}
                </label>
              )}
              {lastNameSpecialCharacterError && lastNameLongError && <br />}
              {lastNameLongError && (
                <label
                  style={{ color: 'red' }}
                  data-testid="maxCharReqErrorMsg-lasttName-AddEmployee"
                >
                  {t(translate.errorMessages.maxCharError)}
                </label>
              )}
            </div>
            <h6 className="fSbd headline4 width100 mb10">
              {t(translate.employeeAdd.teams)}
            </h6>
            <div
              className={
                teamValidation
                  ? 'width100 rowGapBtm3 teamsSelect errMsg'
                  : 'width100 rowGapBtm3 teamsSelect'
              }
            >
              <Select
                mode="multiple"
                placeholder="Teams"
                data-testid="teamsDropDown-AddEmployee"
                style={{ width: '100%' }}
                className="addEmployeeSelect multiSelectField"
                onChange={selectTeams}
                showSearch={true}
                filterOption={(input, option) => {
                  return (
                    option!.title
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {teams.map((ele: any) => {
                  return (
                    <Option
                      data-testid={`${ele.name}-AddEmployee`}
                      title={ele.name}
                      value={ele.id}
                      key={ele?.id}
                    >
                      <div className="teamsDropdownBlock">
                        <div className="teamName">{ele.name}</div>
                        <div className="teamDescription">{ele.description}</div>
                      </div>
                    </Option>
                  );
                })}
              </Select>

              {selectTeamsIds.length > 0 && (
                <div className="mt10 flex-column">
                  {access.tractor_access ? (
                    <div style={{ color: '#366376' }}>
                      <CheckOutlined />{' '}
                      {t(translate.employeeAdd.accessPermissions.tractorAccess)}
                    </div>
                  ) : (
                    <div>
                      <CloseOutlined />{' '}
                      {t(translate.employeeAdd.accessPermissions.tractorAccess)}
                    </div>
                  )}
                  {access.app_access ? (
                    <div style={{ color: '#366376' }}>
                      <CheckOutlined />
                      {t(translate.employeeAdd.accessPermissions.appAccess)}
                    </div>
                  ) : (
                    <div>
                      <CloseOutlined />
                      {t(translate.employeeAdd.accessPermissions.appAccess)}
                    </div>
                  )}
                </div>
              )}
              {firstName?.length > 2 && lastName?.length > 2 && (
                <>
                  {selectTeamsIds.length === 0 ? (
                    <label
                      style={{ color: 'red' }}
                      data-testid="minTeamReqErrorMsg-AddEmployee"
                    >
                      {t(translate.errorMessages.minTeam)}
                    </label>
                  ) : (
                    ''
                  )}
                </>
              )}
            </div>
            <h6 className="fSbd headline4 width100 mb10">
              {t(translate.employeeAdd.contact)}
            </h6>
            {access.app_access ? (
              <>
                <div className="rowGapBtm3 width100">
                  <div>
                    <Input
                      data-testid="emailInputField-AddEmployee"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className={classNames({
                        errorClass: emailError,
                      })}
                    />
                  </div>

                  {emailError && (
                    <label
                      style={{ color: 'red' }}
                      data-testid="notValidMailErr-AddEmployee"
                    >
                      {t(translate.errorMessages.invalidEmail)}
                    </label>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="rowGapBtm3 width100">
                  <Input
                    data-testid="emailInputField-AddEmployee"
                    placeholder="Email (optional)"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {emailError && (
                    <label
                      style={{ color: 'red' }}
                      data-testid="notValidMailErr-AddEmployee"
                    >
                      {t(translate.errorMessages.invalidEmail)}
                    </label>
                  )}
                </div>
              </>
            )}
            <div className="rowGapBtm3 width100">
              <Input
                data-testid="phoneNumberInputField-AddEmployee"
                placeholder="Phone Number (optional)"
                maxLength={15}
                onKeyPress={(e: any) => onlyNumbersAllow(e)}
                onChange={(e) => setContactNum(e.target.value)}
              />
            </div>{' '}
            <AppLoader loader={loader} />
            <div className="proeditBtn tcktSaveBtn saveTxtBtn">
              <Button
                id={applicationIds.ticketScreen.saveTicket}
                disabled={isDisabled}
                onClick={handleFormSubmit}
                data-testid="createButton-AddEmployee"
              >
                {t(translate.buttons.create)}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default AddEmployee;
