/* eslint-disable no-console */
import React from 'react';
import Layout from 'antd/lib/layout/layout';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import LiveMap from './LiveMap';
import RemoteDriveContext from '../remote_drive_new/RemoteDriveContext';
import DigitalPackageModal from '../common/DigitalModals/DigitalPackageModal';
import './style.css';
import userStore, { subscription } from '../store/user';
// import WelcomeModal from '../common/DigitalModals/WelcomeModal';

const MapWrapper: React.FC = () => {
  const handle = useFullScreenHandle();
  const { userData } = userStore();

  return (
    <FullScreen handle={handle}>
      <Layout>
        <Layout className="site-layout posRel">
          <RemoteDriveContext>
            <LiveMap />
            {userData?.tractor_highest_subscription ===
              subscription.ESSENTILAS && <DigitalPackageModal type="liveops" />}
          </RemoteDriveContext>
        </Layout>
      </Layout>
    </FullScreen>
  );
};

export default MapWrapper;
