/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Switch } from 'react-router-dom';
import NotFound from '../components/errors/NotFound';
import PrivateRoute from './PrivateRoute';
import RoutesConstants from './RoutesConstant';
import MapWrapper from '../components/map/MapWrapper';
import WorkInProgress from '../components/errors/WorkInProgress';
import BaseStationDetails from '../components/basestation/BaseStationDetails';
import Organization from '../components/organization/Organization';
import FleetHealthContainer from '../components/fleethealth/FleetHealthContainer';
import FleetUsageContainer from '../components/fleetusagefeatures/FleetUsageContainer';
import TaskMaster from '../components/taskmaster/TaskMaster';
import TaskDetails from '../components/taskmaster/TaskDetails';
import MapMakerBeta from '../components/mapMaker/MapMakerBeta';
import ProfileContainer from '../components/profile/ProfileContainer';
import ReportsDistribution from '../components/reports/ReportsDistrbution';
import RemoteAccess from '../components/remote_access/RemoteAccess';
import FileUploadView from '../components/FileUploadView/fileuploadview';
import DigitalApps from '../components/apps/DigitalApps';
import Trimble from '../components/apps/trimble/Trimble';
import Details from '../components/apps/trimble/Details';
import HowToManual from '../components/HowToManual/HowToManual';
import AnalyticContainer from '../components/analytics/AnalyticContainer';
import AdminDashboard from '../components/admin/dashboard/AdminDashboard';
import ImpactDashboard from '../components/admin/impactDashboard/ImpactDashboard';
import Tractors from '../components/admin/tractors/Tractors';
import Indicators from '../components/admin/indicators/Indicators';
import DealersFarmProfile from '../components/admin/farmProfile';
import DealersMaintenance from '../components/admin/maintenance';
import DealersPlanning from '../components/admin/planning';
// import Analytics from '../components/admin/analytics';
import HelpCenterSearch from '../components/HelpCenter/HelpCenterSearch';
import OperationalAnalyticsContainer from '../components/operationalAnalyticsContainer';
// import HelpCenter from '../components/HelpCenter';
import DealerAnalytics from '../components/admin/dealerAnalytics';
import MarkMaintenance from '../components/admin/markMaintainence';
import Dealers from '../components/admin/dealer';
import Tickets from '../components/dealer/maintenance/Tickets';
import Migration from '../components/dealer/maintenance/Migration';
import FleetHealth from '../components/dealer/maintenance/FleetHealth';
import UserManual from '../components/dealer/maintenance/UserManual';
import Diagnostics from '../components/dealer/maintenance/Diagnostics';
import Inventory from '../components/dealer/inventory';
import KnowledgeBase from '../components/dealer/maintenance/KnowledgeBase';
import Updates from '../components/dealer/maintenance/Updates';
import Planning from '../components/dealer/Analytics/planning';
import Reports from '../components/dealer/Analytics/Reports';
import DealerMaintenance from '../components/dealer/maintenance';
import DealerAnalyticsPage from '../components/dealer/Analytics';
import Survey from '../components/dealer/maintenance/survey';
import Resources from '../components/dealer/maintenance/Resources/Resources';
// import DealerDashboard from '../components/dealer/dashboard/DealerDashboard';
import DealerDashboard from '../components/dealer/dashboard';
import FarmProfileDashboard from '../components/farmProfileDashboard';
import Fleet from '../components/fleet/Fleet';
import FleetContainer from '../components/fleet';
import Personnel from '../components/Personnel';
import ImplementsContainer from '../components/Implements';

const Routes: React.FC = () => {
  return (
    <Switch>
      {/* <PrivateRoute
        exact={false}
        path={RoutesConstants.HelpCenter}
        component={HelpCenter}
      /> */}

      <PrivateRoute
        exact={false}
        path={RoutesConstants.HelpCenter}
        component={HelpCenterSearch}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.Profile}
        component={ProfileContainer}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.Admin}
        component={AdminDashboard}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.ImpactDashBoard}
        component={ImpactDashboard}
      />
      {/* <PrivateRoute
        exact
        path={RoutesConstants.DashboardNew}
        component={TractorResultsDashboard}
      /> */}
      <PrivateRoute
        exact
        path={RoutesConstants.DealerAnalytics}
        component={DealerAnalytics}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.markMaintenance}
        component={MarkMaintenance}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.Tractors}
        component={Tractors}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.Indicators}
        component={Indicators}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.DealersFarmProfile}
        component={DealersFarmProfile}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.DealersMaintenance}
        component={DealersMaintenance}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.DealerPlanning}
        component={DealersPlanning}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.LiveMap}
        component={MapWrapper}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.Organization}
        component={Organization}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.WorkInProgress}
        component={WorkInProgress}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.BaseStationDetails}
        component={BaseStationDetails}
      />
      <PrivateRoute exact path={RoutesConstants.Apps} component={DigitalApps} />
      <PrivateRoute exact path={RoutesConstants.Trimble} component={Trimble} />
      <PrivateRoute
        exact
        path={RoutesConstants.TrimbleDetails}
        component={Details}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.FleetHealth}
        component={FleetHealthContainer}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.Reports}
        component={ReportsDistribution}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.Taskmaster}
        component={TaskMaster}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.Taskdetails}
        component={TaskDetails}
      />

      <PrivateRoute
        exact
        path={RoutesConstants.MapMakerBeta}
        component={MapMakerBeta}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.RemoteAccess}
        component={RemoteAccess}
      />

      <PrivateRoute
        exact
        path={RoutesConstants.FileUploadView}
        component={FileUploadView}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.FleetUsage}
        component={FleetUsageContainer}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.HowToManual}
        component={HowToManual}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.Analytic}
        component={AnalyticContainer}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.OperationalAnalytics}
        component={OperationalAnalyticsContainer}
      />
      <PrivateRoute exact path={RoutesConstants.dealers} component={Dealers} />
      <PrivateRoute
        exact
        path={RoutesConstants.DealerInventory}
        component={Inventory}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.DealerMaintenance}
        component={DealerMaintenance}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.DealerTickets}
        component={Tickets}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.DealerMigrate}
        component={Migration}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.DealerFleetHealth}
        component={FleetHealth}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.DealerManuals}
        component={UserManual}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.DealerUpdates}
        component={Updates}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.DealerDiagnostics}
        component={Diagnostics}
      />

      <PrivateRoute
        exact
        path={RoutesConstants.DealerKnowledgeBase}
        component={KnowledgeBase}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.DealerAnalytic}
        component={DealerAnalyticsPage}
      />

      <PrivateRoute
        exact
        path={RoutesConstants.DealerAnalyticsTractor}
        component={Planning}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.DealerReports}
        component={Reports}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.DealerSurvey}
        component={Survey}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.DealerResources}
        component={Resources}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.DealerDashboard}
        component={DealerDashboard}
      />

      <PrivateRoute
        exact
        path={RoutesConstants.FarmProfile}
        component={FarmProfileDashboard}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.Personnel}
        component={Personnel}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.Fleet}
        component={FleetContainer}
      />
      <PrivateRoute
        exact
        path={RoutesConstants.Implements}
        component={ImplementsContainer}
      />

      <PrivateRoute exact path="" component={NotFound} />
    </Switch>
  );
};

export default Routes;
