export const mapDealerTickets = (data: any[]) =>
  data.map((ticket: any) => ({
    id: ticket.id,
    status: ticket.status,
    description: ticket.ticket_description,
    ticketId: ticket.ticket_id,
    level: ticket.ticket_level,
    createdBy: ticket.created_by,
    service_ticket_raised_date_time: ticket.service_ticket_raised_date_time,
  }));

export const mapDealerCustomers = (data: any[]) =>
  data.map((customer: any) => ({
    id: customer.customer_id,
    name: customer.customer_name,
    acreage: customer.acreage,
    cropType: customer.crop_type,
    fleetSize: customer.fleet_size,
  }));
