import React from 'react';
import { Col, Row } from 'antd';

import DealerTickets from './DealerTickets';
import DealerCustomerUsage from './DealerCustomerUsage';
import DealerSummary from './DealerSummary';
import DealerFleetHealth from './DealerFleetHealth';

import DealerFleetUsage from './DealerFleetUsage';
import './style.scss';
import moment from 'moment';

const DealderDashboard: React.FC = () => {
  return (
    <>
      <div className="dlDashboardContainer">
        {/* // Dashboard Home page starts here ************************ */}

        <div className="ms_dashboardHead mb23">
          <div className="headPlusBack">
            <span className="headHomeTxt">Home</span>
          </div>
          <div className="ms_timeStamp">{moment().format('dddd, MMM Do')}</div>
        </div>

        <DealerSummary />

        <Row gutter={[20, 20]}>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 18, offset: 0 }}
            xl={{ span: 18, offset: 0 }}
          >
            <DealerCustomerUsage />

            <Row gutter={[20, 20]}>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 10, offset: 0 }}
                xl={{ span: 10, offset: 0 }}
              >
                <DealerFleetHealth />
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 14, offset: 0 }}
                xl={{ span: 14, offset: 0 }}
              >
                <DealerFleetUsage />
              </Col>
            </Row>
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 6, offset: 0 }}
            xl={{ span: 6, offset: 0 }}
          >
            <DealerTickets />
          </Col>
        </Row>

        {/* // Dashboard Home page end here ************************ */}

        {/* // Dashboard Home page multisite here ************************ */}
      </div>
    </>
  );
};

export default DealderDashboard;
