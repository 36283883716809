import { create } from 'zustand';

export const subscription = {
  ESSENTILAS: 'essential',
  ASSIST: 'assits',
  AUTONOY: 'autonomy',
};

interface userData {
  tractor_highest_subscription: 'essential' | 'assist' | 'autonomy';
  first_name: string;
  last_name: string;
  profile_url: string;
  name: string;
  account_type: string;
}

type State = {
  userData: userData | null;
};

type Actions = {
  setUserData: (data: userData) => void;
  reset: () => void;
};

const initialState: State = {
  userData: null,
};

const userStore = create<State & Actions>()((set, get) => ({
  ...initialState,
  setUserData: (userData) =>
    set(() => ({
      userData,
    })),
  reset: () => {
    set(initialState);
  },
}));

export default userStore;
