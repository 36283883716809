import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useRouterTab = (defaultTabId = ''): [string, any] => {
  const location = useLocation();
  const state: any = location && location.state ? location.state : null;
  const [tabid, setTabid] = useState<string>(defaultTabId);

  useEffect(() => {
    if (state?.tab) setTabid(state.tab);
    return () => {
      window.history.replaceState({}, '');
    };
  }, [state?.tab]);

  return [tabid, setTabid];
};

export default useRouterTab;
