/* eslint-disable no-console */
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import in_complete_setUp from '../../assets/images/in_complete_setUp.svg';
import acres_outline from '../../assets/images/acres_outline.svg';
import charge_point_outline from '../../assets/images/charge_point_outline.svg';
import waiIcon from '../../assets/images/wai_logo.svg';
import { deleteSite } from '../../constants/Api';
import { isVineYardOrDairy } from '../../constants/Common';
import { dairyAutoDrive, vineYardAutoDrive } from '../../constants/constant';
import { SiteDetails, UserDetails } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import AppLoader from '../common/AppLoader';
import useSite from './hooks/useSite';
import SiteForm from './SiteForm';
import useFarmProileStore from './useFarmProfileStore';

const Sites: React.FC = () => {
  const { setShowSiteModal, showSiteModal, isInEssentailsPackage } =
    useFarmProileStore();
  const { sites } = useFarmProileStore();
  const [disabledSiteCreation, setDisabledSiteCreation] = useState(false);

  useEffect(() => {
    // diasbled the site creation if default site is incomplete setup
    setDisabledSiteCreation(false);
    if (sites?.length === 1) {
      const [site] = sites;
      const disabled = site?.is_setup_completed === 0 ? true : false;
      setDisabledSiteCreation(disabled);
    }
  }, [sites]);

  return (
    <>
      <div className="ms_site_section">
        <div className="ms_site_head">
          <div className="ms_site_head_txt">
            <span className="ms_site_header">Sites:</span>
            <span className="ms_site_desc">
              Set up sites to better organize your farm and coordinate MK-Vs
              between locations. At least one site is required per organization.
            </span>
          </div>
          <div>
            <div className="multiSiteHoverInfo">
              {isInEssentailsPackage && (
                <div className="essentails_tooltip">
                  Upgrade your fleet’s digital subscriptions to create and
                  manage multiple sites.
                </div>
              )}
              <Button
                className="primary-btn"
                onClick={() => setShowSiteModal(true)}
                icon={<PlusOutlined />}
                // disable the site creation if essentails package active
                // diasble the site creation if default site is incomplete setup
                disabled={disabledSiteCreation || isInEssentailsPackage}
              >
                Create Site
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="site-container_card_section">
        {sites.map((site: SiteDetails) => (
          <SiteCard key={site.name} site={site} />
        ))}
      </div>
      {showSiteModal && <SiteForm />}
    </>
  );
};

interface SiteCardProps {
  site: SiteDetails;
}

const SiteCard: React.FC<SiteCardProps> = ({ site }) => {
  const { userDetails } = useContext(ApplicationContext);
  const { setShowSiteModal, setEditSiteDetails, sites, isInEssentailsPackage } =
    useFarmProileStore();
  const { getSites } = useSite();
  const [showConfrimDelete, setShowConfrimDelete] = useState<boolean>(false);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    return () => {
      setEditSiteDetails(null);
    };
  }, []);

  const onDeleteSiteHandler = async (siteId: string) => {
    setLoader(true);
    const { organization } = userDetails;
    try {
      const { msg } = await deleteSite(organization.api_url, siteId);
      if (msg) {
        setShowConfrimDelete(false);
        notification.success({
          message: msg,
        });
        getSites(userDetails);
      }
    } catch (error: any) {
      console.log(error);
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <div className="site-container_card">
        {site.is_setup_completed == 0 && (
          <div className="inCompleteSetUp ">
            <img src={in_complete_setUp} alt="In complete SetUp" />
            <span className="inCompleteSetUpText">Incomplete Site Set Up</span>
          </div>
        )}
        <div className="ms_site-row_sec">
          <div className="ms_site-row">
            <div
              // hiding right side border when no tarctors and basestations
              className={`ms_site_col1 ${
                !(
                  site?.farmsite_basestation?.id ||
                  site?.farmsite_tractors?.length > 0
                )
                  ? 'remove_right_border'
                  : ''
              }`}
            >
              <div className="ms_site_head_block">
                <span className="ms_site_head_txt">{site.name}</span>
                <span className="ms_site_address_txt">
                  {site.address ? site.address + ', ' : ''}
                  {site.city ? site.city + ', ' : ''}
                  {site.state ? site.state + ', ' : ''}
                  {site.country} {site.zip_code}
                </span>
              </div>
              {isVineYardOrDairy(userDetails as UserDetails) ===
                dairyAutoDrive && (
                <div className="ms_site_col">
                  <div className="ms_lane_count">
                    <span className="ms_count_no">
                      {site?.total_num_of_lanes || '-'}
                    </span>
                    <span className="ms_count_lbl">Lane Count</span>
                  </div>
                  <div className="ms_lane_count">
                    <span className="ms_count_no">
                      {site?.daily_push_target || '-'}
                    </span>
                    <span className="ms_count_lbl">Daily Push Target</span>
                  </div>
                </div>
              )}
              {isVineYardOrDairy(userDetails as UserDetails) !==
                dairyAutoDrive && (
                <div className="ms_site_col">
                  {site?.total_num_of_acres > 0 && (
                    <div className="ms_lane_count">
                      <img src={acres_outline} width={16} alt="" />
                      <span className="ms_count_no">
                        {site?.total_num_of_acres || '-'}
                      </span>
                      <span className="ms_count_lbl">Total Acres</span>
                    </div>
                  )}
                  {site?.total_num_of_chargers > 0 && (
                    <div className="ms_lane_count">
                      <img src={charge_point_outline} width={16} alt="" />
                      <span className="ms_count_no">
                        {site?.total_num_of_chargers || '-'}
                      </span>
                      <span className="ms_count_lbl">Charge Points</span>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="ms_site_col2">
              {site?.farmsite_basestation?.id && (
                <div
                  className={`ms_basestation_block ${
                    site?.farmsite_tractors?.length > 0
                      ? ''
                      : 'remove_bottom_border'
                  }`}
                >
                  <div className="ms_bs_row">
                    <span className="ms_count_lbl">Base Station</span>
                    <span className="ms_count_no">
                      {site?.farmsite_basestation?.id ? 1 : '-'}
                    </span>
                  </div>
                  <div className="ms_bs_row">
                    <span className="ms_count_lbl">
                      {site?.farmsite_basestation?.basestation_id?.name || '-'}
                    </span>
                  </div>
                </div>
              )}
              {site?.farmsite_tractors?.length > 0 && (
                <>
                  <div className="ms_tractors_block">
                    <div className="ms_bs_row">
                      <span className="ms_count_lbl">Tractors</span>
                      <span className="ms_count_no">
                        {site?.farmsite_tractors?.length || '-'}
                      </span>
                    </div>
                  </div>
                  <div className="ms_tractor_block_scroll">
                    <div className="ms_tractor_row">
                      {site?.farmsite_tractors.map(({ tractor_id }) => (
                        <span key={tractor_id?.id} className="ms_tractor_name">
                          {tractor_id?.name}
                        </span>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="ms_site-row2">
            <div className="site-actions space-between-fw">
              {/* hide delete button when essentails package active */}
              {!isInEssentailsPackage && (
                <Button
                  className="default-btn"
                  disabled={sites?.length === 1}
                  onClick={() => {
                    setShowConfrimDelete(true);
                  }}
                >
                  Delete Site
                </Button>
              )}
              <Button
                className="primary-btn"
                onClick={() => {
                  setEditSiteDetails(site);
                  setShowSiteModal(true);
                }}
              >
                Edit Details
              </Button>
            </div>
          </div>
        </div>
      </div>

      {showConfrimDelete && (
        <Modal
          visible={showConfrimDelete}
          closable={false}
          footer={false}
          className="edit_operator"
        >
          <div className="popup_head">
            <img src={waiIcon} />
            <span className="head_text">Delete Site?</span>
          </div>
          <p className="popup_desc_text">
            Are you sure you want to permanently delete this site?
          </p>
          <div className="button_sec">
            <Button
              key="edit"
              className="normal_btn"
              onClick={() => setShowConfrimDelete(false)}
            >
              Cancel
            </Button>
            <Button
              key="cancel"
              className="green_btn"
              onClick={() => onDeleteSiteHandler(site?.farmsite_uuid as string)}
            >
              Delete
            </Button>
          </div>
          <AppLoader loader={loader} />
        </Modal>
      )}
    </>
  );
};

export default Sites;
