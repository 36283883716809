// Import necessary packages
import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import loading_logo from '../../assets/images/loading_logo.gif';

// Apply amCharts themes
am4core.useTheme(am4themes_animated);

interface Props {
  data?: any;
  loader: boolean;
  chartId: string;
  barColors: string[];
}

const SemiPieChart: React.FC<Props> = ({
  data,
  loader,
  chartId,
  barColors,
}) => {
  useEffect(() => {
    const chart = am4core.create(chartId, am4charts.PieChart);
    chart.logo.disabled = true;
    // Add data
    if (!loader && data?.length > 0) {
      chart.data = data;
    }
    const chartColors: any = [];

    // convert string color names to am4chart colors
    if (barColors?.length) {
      barColors.map((each: any) =>
        chartColors.push(am4core.color(each.toString())),
      );
    }

    // Add and configure Series
    const pieSeries: any = chart.series.push(new am4charts.PieSeries());

    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'category';
    pieSeries.colors.list = chartColors;
    pieSeries.ticks.template.disabled = true;
    pieSeries.alignLabels = false;
    pieSeries.tooltip.disabled = true;
    // pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";
    pieSeries.labels.template.text = '';
    pieSeries.labels.template.fontFamily = 'Montserrat';
    pieSeries.labels.template.fontWeight = '600';
    pieSeries.labels.template.fontSize = 10;
    pieSeries.labels.template.fontColor = am4core.color('white');
    pieSeries.labels.template.verticalCenter = 'middle';
    pieSeries.labels.template.horizontalCenter = 'middle';
    pieSeries.labels.template.radius = am4core.percent(-30);
    pieSeries.labels.template.fill = am4core.color('white');
    pieSeries.slices.template.stroke = am4core.color('#F7F7F7');
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.innerRadius = am4core.percent(60);

    chart.startAngle = 180;
    chart.endAngle = 360;

    // Hide labels for slices below 1 percentage
    pieSeries.labels.template.adapter.add(
      'disabled',
      (disabled: any, target: any) => {
        return target?.dataItem?.values?.value?.percent < 1;
      },
    );

    let indicator: any;
    let indicatorInterval: any;

    function showIndicator(chart: any) {
      if (!indicator) {
        indicator = chart.tooltipContainer.createChild(am4core.Container);
        indicator.background.fill = am4core.color('#fff');
        indicator.background.fillOpacity = 0.8;
        indicator.width = am4core.percent(100);
        indicator.height = am4core.percent(100);

        // const indicatorLabel = indicator.createChild(am4core.Label);
        // indicatorLabel.text = 'Loading...';
        // indicatorLabel.align = 'center';
        // indicatorLabel.valign = 'middle';
        // indicatorLabel.fontSize = 20;
        // indicatorLabel.dy = 50;

        // eslint-disable-next-line no-var
        var hourglass = indicator.createChild(am4core.Image);
        hourglass.href = loading_logo;
        hourglass.align = 'center';
        hourglass.valign = 'middle';
        hourglass.horizontalCenter = 'middle';
        hourglass.verticalCenter = 'middle';
        hourglass.scale = 0.7;
      }

      indicator.hide(0);
      indicator.show();

      // clearInterval(indicatorInterval);
      // indicatorInterval = setInterval(function () {
      //   hourglass?.animate(
      //     [
      //       {
      //         from: 0,
      //         to: 360,
      //         property: 'rotation',
      //       },
      //     ],
      //     2000,
      //   );
      // }, 3000);
    }

    function hideIndicator() {
      indicator?.hide();
      clearInterval(indicatorInterval);
    }

    loader ? showIndicator(chart) : hideIndicator();

    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [data, loader]);

  return (
    <div>
      <div id={chartId} style={{ width: '100%', height: '160px' }}></div>
    </div>
  );
};

export default SemiPieChart;
