import React, { useEffect, useState } from 'react';
import { notification } from 'antd';

import { getSeverity } from '../../fleethealth/Diagnostics';

import { useApplicationContext } from '../../../context/AppContext';
import { dealerTicketDetails } from '../../../constants/Api';

import statusIcon from '../../../assets/images/ms_status_icon.svg';
import { mapDealerTickets } from './model';
import moment from 'moment';
import { getShortUserName } from '../../../constants/Common';
import TicketStatusTag from '../../common/TicketStatusTag';
import RoutesConstants from '../../../routes/RoutesConstant';
import { useHistory } from 'react-router-dom';

const TicketItem: React.FC<any> = ({ ticket }) => {
  const { status, ticketId, level, description, createdBy } = ticket;
  return (
    <div className="ms_ticket_card">
      <div className="ms_CardRow">
        <div className="ms_CardColumn3">
          <div className="ms_ticket_no">
            <span className="ms_round ms_circle_green">
              {createdBy && getShortUserName(createdBy)}
            </span>
            <span className="ms_ticket_dtls">Ticket #{ticketId}</span>
          </div>
          <TicketStatusTag className="ms_status_chip" status={status} />
          {/* <div className="ms_status_chip ticket_open">{status}</div> */}
        </div>
        <div className="ms_CardColumn2 alignItemStart">
          <div className="ms_CardRow">
            <span className="ms_ticket_desc">{description}</span>
            <span className="ms_ticket_loc_date">
              {/* <span>{ticket.organization.name}</span> */}
              <span className="ms_dot"></span>
              <span>
                {moment(ticket?.service_ticket_raised_date_time).format(
                  'MM/D h:m A',
                )}
              </span>
            </span>
          </div>
          <div className="ms_ticket_flags">{getSeverity(level)}</div>
        </div>
      </div>
    </div>
  );
};

const DealerTickets: React.FC = () => {
  const { push } = useHistory();
  const { userDetails } = useApplicationContext();
  const [tickets, setTickets] = useState<any>([]);
  const [ticketSort, setTicketSort] = useState(false);

  useEffect(() => {
    const init = async () => {
      try {
        const { organization } = userDetails;
        const response: any = await dealerTicketDetails(organization.api_url);
        let data: any[] = Array.isArray(response?.records)
          ? response.records
          : [];
        data = mapDealerTickets(data);
        setTickets(data);
      } catch (error: any) {
        notification.error({ message: `dealer tickets : ${error.message}` });
      }
    };
    if (userDetails.organization) init();
  }, [userDetails]);

  const sortTickets = () => {
    const status: any = {
      open: 1,
      inprogress: 2,
      Forwarded: 3,
      completed: 4,
    };

    const sortedTickets = ticketSort
      ? tickets.sort((a: any, b: any) => status[a.status] - status[b.status])
      : tickets.sort((a: any, b: any) => status[b.status] - status[a.status]);

    setTickets([...sortedTickets]);
  };

  useEffect(() => {
    if (tickets.length) sortTickets();
  }, [ticketSort, tickets]);

  return (
    <div className="ms_dashboardCard">
      <div className="ms_customerUsage mb16 mh24">
        <div className="ms_blockHead">Tickets</div>
        <div className="ms_status" onClick={() => setTicketSort(!ticketSort)}>
          <span>
            <img src={statusIcon} alt="status" />
          </span>
          <span>Status</span>
        </div>
      </div>
      <div className="ms_tickets_container">
        {tickets.map((ticket: any) => (
          <TicketItem key={ticket?.id} ticket={ticket} />
        ))}
        <div
          className="reviewTicketsBlock"
          onClick={() => push(RoutesConstants.DealerTickets)}
        >
          <div className="reviewTickets">Review Tickets</div>
        </div>
      </div>
    </div>
  );
};

export default DealerTickets;
