import React from 'react';
import { Col, Row } from 'antd';
import useDealerDashboard from './hooks/useDealerDashboard';
import msFleetIcon from '../../../assets/images/ms_fleet_icon.svg';
import msEmployeesIcon from '../../../assets/images/ms_employees_icon.svg';
import msCustomersIcon from '../../../assets/images/ms_customers_icon.svg';
import msTicketsIcon from '../../../assets/images/ms_tickets_icon.svg';
import msIncIcon from '../../../assets/images/ms_increase_icon.svg';
import { useHistory } from 'react-router-dom';
import RoutesConstants from '../../../routes/RoutesConstant';

// const style: React.CSSProperties = { padding: '8px' };

interface ItemCardProps {
  title: string;
  value?: number;
  icon: any;
  onClick?: () => void;
}

const SummaryCard: React.FC<ItemCardProps> = ({
  title,
  value,
  icon,
  onClick,
}) => (
  <Col
    xs={{ span: 24, offset: 0 }}
    sm={{ span: 24, offset: 0 }}
    md={{ span: 12, offset: 0 }}
    lg={{ span: 6, offset: 0 }}
    xl={{ span: 6, offset: 0 }}
    onClick={onClick}
  >
    <div className="ms_dashboardWidgetCard cursorPointer">
      <div className="ms_CardRow">
        <div className="ms_CardColumn">
          <span>
            <img src={icon} alt="fleetIcon" />
          </span>
          <span className="ms_CardTitle">{title}</span>
        </div>
        <div className="ms_CardColumn2">
          <span className="ms_CardCount">{value}</span>
          <span className="ms_CardIndex">
            {/* <span>
              <img src={msIncIcon} alt="fleetIcon" />
            </span>
            <span className="ms_CardIncTxt incGreen">8%</span>
            <span>last month</span> */}
          </span>
        </div>
      </div>
    </div>
  </Col>
);

const DealerSummary: React.FC = () => {
  const { dealerDashboardSummary: summary } = useDealerDashboard(true);
  const { push } = useHistory();
  return (
    <Row gutter={[20, 20]} style={{ marginBottom: '20px' }}>
      <SummaryCard
        title="Your Fleet"
        icon={msFleetIcon}
        value={summary?.fleet}
        onClick={() =>
          push({
            pathname: RoutesConstants.Organization,
            state: { tab: 'Fleet' },
          })
        }
      />
      <SummaryCard
        title="Employess"
        icon={msEmployeesIcon}
        value={summary?.employees}
        onClick={() =>
          push({
            pathname: RoutesConstants.Organization,
            state: { tab: 'Personnel' },
          })
        }
      />
      <SummaryCard
        title="Customers"
        icon={msCustomersIcon}
        value={summary?.customers}
        onClick={() =>
          push({
            pathname: RoutesConstants.DealerInventory,
            state: { tab: 'customers' },
          })
        }
      />
      <SummaryCard
        title="Tickets"
        icon={msTicketsIcon}
        value={summary?.tickets}
        onClick={() =>
          push({
            pathname: RoutesConstants.DealerTickets,
            state: { tab: 'supportTickets' },
          })
        }
      />
    </Row>
  );
};

export default DealerSummary;
