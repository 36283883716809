import React, { useContext, useEffect, useState } from 'react';
import { Tabs, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import Diagnostics from './Diagnostics';
import Tickets from '../tickets/Tickets';
import { useLocation } from 'react-router-dom';
import { track } from '../../util/logger';
import FleetHealthContext from './FleetHealthContext';
import RestartManager from './RestartManager';
import { SET_LAST_TAB_INDEX } from '../../context/actions';
import { ApplicationContext } from '../../context/AppContext';
import KnowledgeBase from '../knowledgeBase/KnowlegeBase';
import Maintenancelist from '../maintenance/Maintenancelist';
import { getUpdatesCount } from '../../constants/Api';
import translate from '../../locale/en_translate.json';
import BasestationDiagnostics from '../basestationDiagnostics/BasestationDiagnostics';
import { Updates } from '../scheduleUpdates/Updates';
import { SchUpdates } from '../scheduleUpdates/SchUpdates';
import Diagnostic from './Diagnostic';

const { TabPane } = Tabs;

const FleetHealthContainer: React.FC = () => {
  const { t } = useTranslation();
  const location: any = useLocation();
  const { APPReducer, userDetails, user } = useContext(ApplicationContext);
  const [, dispatch] = APPReducer;
  const [tabid, setTabid] = useState<string | undefined>('');
  const [tractorId, setTractorId] = useState<number>(0);
  const [createTicket, setCreateTicket] = useState<boolean>(false);
  const [updateCount, setUpdateCount] = useState<number>(0);
  const [equipId, setEquipId] = useState<number>();
  const [ticketId, setTicketId] = useState<any>();

  const state: any = location && location.state ? location.state : {};

  const navigateBasedonAction = () => {
    if (location.state && location.state.action == 'INDICATOR') {
      setTabid('3');
      setTractorId(location.state.tractorId);
    }
    if (location.state && location.state.action == 'REPAIR MANAGER') {
      setTabid('5');
      setTractorId(location.state.tractorId);
    }
    if (location.state && location.state.action == 'TICKET') {
      setTabid('2');
      if (location && location.state && location.state.createTicket)
        setCreateTicket(true);
    }
    if (location.state && location.state.action == 'SOFTWARE') setTabid('5');

    if (location.state && location.state.action == 'Knowledge Base')
      setTabid('8');
  };

  useEffect(() => {
    navigateBasedonAction();
  }, [location]);

  useEffect(() => {
    if (state && Object.keys(state).includes('reset')) setTabid('2');
  }, [state.reset]);

  useEffect(() => {
    track('Fleet Health', {
      event: 'Entered Fleet Health',
      start: true,
    });
    return () => {
      track('Fleet Health', {
        event: 'Exit from Fleet Health',
        start: false,
      });
      setTractorId(0);
      setUpdateCount(0);
      setCreateTicket(false);
    };
  }, []);

  useEffect(() => {
    const lastTabIndex: string | null = localStorage.getItem('lastTabIndex');
    const tlastTabIndex: any = lastTabIndex && JSON.parse(lastTabIndex);

    if (tlastTabIndex?.catlog === 'FleetHealth') setTabid(tlastTabIndex?.tab);
    else if (location && location.state) navigateBasedonAction();
    else setTabid('2');
  }, [localStorage.getItem('lastTabIndex')]);

  useEffect(() => {
    if (userDetails && userDetails.organization) getCount();
  }, [userDetails]);

  const getCount = async () => {
    try {
      const { tractor_software_update_count } = await getUpdatesCount(
        userDetails.organization.api_url,
        userDetails.organization.id,
      );
      setUpdateCount(tractor_software_update_count);
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };

  return (
    <FleetHealthContext>
      <div className="rightSideContainer">
        <Tabs
          activeKey={tabid}
          centered
          id="fleet-tab-FleetHealthContainer"
          className="common_tabs"
          onChange={(key) => {
            setTabid(key);
            setTicketId('');
            dispatch({
              type: SET_LAST_TAB_INDEX,
              payload: {
                catlog: 'FleetHealth',
                tab: key,
              },
            });
            track(key, {
              event: `${key} Tab`,
            });
          }}
        >
          {process.env.REACT_APP_ALPHA_FEATURES &&
            process.env.REACT_APP_ALPHA_FEATURES.includes('DigitalApps') && (
              <TabPane
                tab="OVERVIEW"
                key="1"
                disabled
                className="attTableHeight mBottom0"
              >
                <h1>{t(translate.headers.overview)}</h1>
              </TabPane>
            )}
          <TabPane tab="TICKETS" key="2" className="attTableHeight mBottom0">
            {tabid == '2' && (
              <Tickets
                createTicket={createTicket}
                equipId={equipId}
                ticketId={ticketId}
              />
            )}
          </TabPane>

          <TabPane
            tab="DIAGNOSTICS"
            key="3"
            className="attTableHeight mBottom0"
          >
            {tabid == '3' && (
              <Diagnostics
                tractorID={tractorId}
                showKnowledgeBase={(error_code: number) => {
                  setTabid('7');
                }}
              />
              // <Diagnostic />
            )}
          </TabPane>
          {user?.isInternalUser() && (
            <TabPane
              tab="BASESTATION DIAGNOSTICS"
              key="4"
              className="attTableHeight mBottom0"
            >
              {tabid == '4' && <BasestationDiagnostics />}
            </TabPane>
          )}
          <TabPane
            tab={
              <div className="updateCon">
                {updateCount > 0 && <div className="updateCount" />}{' '}
                {t(translate.headers.updates)}
              </div>
            }
            key="5"
            className="attTableHeight mBottom0"
          >
            <SchUpdates updateCount={updateCount} />
            {/* <Updates
              updateCount={updateCount}
              setTabid={setTabid as any}
              setEquipId={setEquipId as any}
              setTicketId={setTicketId as any}
            /> */}
          </TabPane>
          {process.env.REACT_APP_ALPHA_FEATURES &&
            process.env.REACT_APP_ALPHA_FEATURES.includes('RepairManager') && (
              <TabPane
                tab="REPAIR MANAGER"
                key="6"
                className="attTableHeight mBottom0"
              >
                {tabid == '6' && <RestartManager />}
              </TabPane>
            )}
          {process.env.REACT_APP_ALPHA_FEATURES &&
            process.env.REACT_APP_ALPHA_FEATURES.includes('Maintenance') && (
              <TabPane
                tab="Maintenance"
                key="7"
                className="attTableHeight mBottom0"
              >
                {tabid == '7' && <Maintenancelist />}
              </TabPane>
            )}

          {/* {process.env.REACT_APP_ALPHA_FEATURES &&
            process.env.REACT_APP_ALPHA_FEATURES.includes('KnowledgeBase') && ( */}
          <TabPane
            tab="Knowledge Base"
            key="8"
            className="attTableHeight mBottom0"
          >
            {tabid == '8' && (
              <KnowledgeBase errorCode={location?.state?.errorCode} />
            )}
          </TabPane>
          {/* )} */}
        </Tabs>
      </div>
    </FleetHealthContext>
  );
};

export default FleetHealthContainer;
