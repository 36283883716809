import { useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import userStore from '../components/store/user';

const useUser = () => {
  const { setUserData, userData } = userStore();

  useEffect(() => {
    const handleUserStore = (user: any) => {
      const {
        tractor_highest_subscription,
        organization,
        first_name,
        last_name,
        profile_url,
      } = user;
      const data = {
        tractor_highest_subscription,
        account_type: organization?.account_type,
        first_name,
        last_name,
        profile_url,
        name: first_name + ' ' + last_name,
      };
      setUserData(data);
    };
    const auth = localStorage.getItem('auth');

    if (auth) {
      const user = jwt_decode(auth || '');
      if (user) handleUserStore(user);
    }
  }, [localStorage.getItem('auth')]);

  return [userData];
};

export default useUser;
