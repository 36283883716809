/* eslint-disable no-console */
/* eslint-disable react/jsx-no-duplicate-props */
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import $ from 'jquery';
import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  addRemoteAVCommand,
  getCompleteOperationPoints,
  getFeedLanesData,
  getPolygonsData,
  getPreviewPath,
  getRoutePoints,
  getTractorTraveledPointsBasedOnPlanner,
  getTractorTraveledPointsGuardRails,
  getTractorTraveledPointsIndicators,
  getVineBlockName,
  getVineLanesData,
} from '../../constants/Api';
import {
  alignItems,
  batteryValue,
  calPtsDistanceActualAllow,
  centerTheLocation,
  checkAlphaFeature,
  checkAutoDriveMonitorErrorCodes,
  checkLoginUserAndTractorConnectUserSame,
  checkTractorDrivingState,
  commands,
  // convertInchesToFeet,
  convertXyToLatLang,
  displayRecenterAllViewPortSetUp,
  displayRecenterViewPortSetUp,
  drawPoly,
  feedHeadStyle,
  feedLaneStyle,
  getBadLocalizationFromLiveMap,
  getCenterStyle,
  getDateTime,
  getDateTimes,
  getFleetUsagePointsInfo,
  getFleetUsagePointsPathInfoFromLiveMap,
  getInitials,
  getInterruptsByTractor,
  getLatitudeAndLongitude,
  // getMosCloudPauseInterrupts,
  getNameFromList,
  getOperationNewStatus,
  getPathBorderColor,
  getPathColor,
  getPathFeatureStyle,
  getPosition,
  getSateLightPolyStyle,
  getTractorIcon,
  getVineRowColorStyle,
  getVineRowStyle,
  getVineRowStyle1,
  guardRailStyle,
  IndicatorStyle,
  isFeedLaneChecked,
  isGoodLocalizationInJumpAndGo,
  isHaveErrors,
  isLoginUserAndDriveActionUserSame,
  isLowLocalization,
  isPlatFromWeb,
  isRefillPositionTopic,
  isRemainingTimeTopic,
  isVineYardOrDairy,
  pathColorConstants,
  pathConstants,
  polygonHoverLabel,
  priority,
  randomNum,
  REFILL_SEEDER,
  refillSeederStyle,
  selectedFeedLaneStyle,
  setBadLocalization,
  setBadLocalizationStyle,
  setInterruptByTractor,
  styleFun,
  togglePathStyle,
  togglePolygonSelectionStyle,
  togglePolygonStyle,
  toggleVineRowStyle,
  tractorClusterStyle,
  tractorConnected,
  tractorConstantsNew,
  TractorIconConstant,
  vineRowHoverStyle,
  wayPointClusterHoverStyle,
  wayPointHoverStyle,
} from '../../constants/Common';
import {
  ConnectionData,
  GuardRailType,
  MapFeature,
  MosEventsInterruptResp,
  MosEventsResp,
  SetUpKeys,
  TractorHeartBeat,
  TractorsList,
} from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';

import { message, notification } from 'antd';

import { Feature, Overlay, View } from 'ol';
import { ScaleLine } from 'ol/control';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import OLMap from 'ol/Map';
import * as proj from 'ol/proj';
import Cluster from 'ol/source/Cluster';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';

import { Coordinate } from 'ol/coordinate';
// import BaseStationIcon from '../../assets/images/basestation_green.png';
import ground_zero from '../../assets/images/ground_zero.svg';

import fbIcon from '../../assets/images/fbIcon.svg';
import human_pt from '../../assets/images/human_pt.svg';
import SnapIcon from '../../assets/images/m_sshot.svg';
import path_pt from '../../assets/images/path_pt.svg';
import pto_pt from '../../assets/images/pto_pt.svg';
import dataLayerIcon from '../../assets/images/tdtl_icon.svg';
import guardRails from '../../assets/images/tractor-icons/guardRails.svg';

import { PubSub } from 'aws-amplify';
import LineString from 'ol/geom/LineString';
import MultiPoint from 'ol/geom/MultiPoint';
import Polygon from 'ol/geom/Polygon';
import { useLocation } from 'react-router-dom';
import {
  AUTO_DRIVE_ERROR_TOPIC,
  AUTO_DRIVE_INTERRUPT_MESSAGE_CATEGORY,
  AUTO_DRIVE_INTERRUPT_MESSAGE_CATEGORY_ARRAY,
  AUTO_DRIVE_MONITOR_STATUS,
  AUTO_LOGOUT_CODE,
  AutoDriveTabs,
  BadLocalization,
  checkTheseErrorCodesAfterRemoteAvStart,
  CLEAR_COMMAND,
  dairyAutoDrive,
  defaultSetUpKeys,
  doNotCheckTheseCodesBeforeConnect,
  envVar,
  findArrayDifference,
  firmware_fault_other,
  GUARD_RAILS,
  HARD,
  heartBeatTopic,
  INTERNAL_MESSAGE,
  interruptKey,
  interruptMessageTypes,
  interruptPauseKey,
  MOS_CLOUD,
  MOS_EVENTS_TOPIC,
  PAUSE,
  PLANNING_MANAGER_REMOTE_AV_EXECUTION_FBK,
  POLYGON,
  POSE_MODEL_STREAMING,
  removeBelowErrorIf7011,
  SATILITE_LAYER_URL,
  SELECTED,
  SYSTEM_PAUSE,
  TRACTOR,
  types,
  VINE_BLOCK,
  VINE_ROW,
  WAY_POINT,
} from '../../constants/constant';
import {
  LOAD_REMOTE_DRIVE_TRACTORS,
  SET_AUTO_DRIVE_TRACTOR_LOST_INTERRUPT_MESSAGES,
  SET_POLYGONS_LIST,
  SET_TRACTOR_IMPLEMENT_STATE,
  SET_TRACTORS_LIST,
} from '../../context/actions';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import { FeedLaneType } from '../../lib/types';
import applicationIds from '../../locale/applicationIds.json';
import { track } from '../../util/logger';
import AddFarmObservation from '../common/FarmObservations/AddFarmObservation';
import MapLoader from '../common/MapLoader';
import Converse from '../converse/Converse';
import { getSeverity, getTags } from '../fleethealth/Diagnostics';
import DiagnosticWidget from '../fleethealth/DiagnosticWidget';
import TicketWidget from '../fleethealth/TicketWidget';
import { RoutineNewDesign } from '../livecommand/RoutineNewDesign';
import {
  AUTO_DRIVE_ERROR,
  AUTO_DRIVE_LOGOUT_ERROR_CODES,
  AUTO_DRIVE_MONITOR_ERROR_CODES,
  SELECTED_ITEM_NAME,
  SELECTED_ROUTINE,
  SELECTED_TRACTOR,
  SELECTED_TRACTORS,
  SELECTED_VINE_BLOCK,
  SET_ACTIVE_AUTODRIVE_TAB,
  SET_AUTO_DRIVE_INTERRUPT_MESSAGES,
  SET_AUTO_DRIVE_MONITOR_POP,
  SET_FEED_LANES,
  SET_REMAINING_TIME,
  SET_RESET_TIMER,
  SET_SELECTED_FEED_LANES,
  SET_SELECTED_TRACTOR_LOCATION,
  SET_SETUP_KEYS,
  // SET_SHOW_IMPLEMENT_HANDLER,
  SET_SHOW_PRESENT_COMMAND,
  SET_SHOW_REMOTE_DRIVE_DETAILS,
  SET_SHOW_ROUTINE,
  SET_SHOW_SNAPSHOT,
  SET_TRIGGER_TO_STARTING_POINT,
  SET_VELOCITY,
  SET_WAY_POINTS_FOR_REMOTE_AV,
  SHOW_AUTO_DRIVE_POOR_CONNECTIVITY_LOADER,
  SHOW_SIDE_FEED_BACK,
  SHOW_TRACTOR_COMMAND_LOADER,
  SHOW_TRACTOR_START,
} from '../remote_drive_new/actions';
import AutoDriveLogoutErrorPopup from '../remote_drive_new/AutoDriveLogoutErrorPopup';
import Errorcodepopup from '../remote_drive_new/Errorcodepopup';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';
import SnapshotView from '../snapshot/SnapshotView';
import DiagnosticsModal from './DiagnosticsModal';
import LiveMapFleetList from './LiveMapFleetList';
import MapIcons from './MapIcons';
import MapLayers from './MapLayers';
import { getVineRows } from './VineRows';
import { getWaypoints } from './Waypoint';
// import CommonPopAutoDrive from '../remote_drive_new/CommonPopAutoDrive';

// for global variables set default value on page pop
// global variables start here
let toggleForIndicator = false; // to show hide indictor pop
let allTractorsList: MapFeature[] = [];
let globalBase: [number, number] = [0, 0];
let allowLiveStream = false;
let globalSelectedTractorId = 0;
let globalShowPath = true;
let globalShowDataLayers = true;
let globalToggleSatellite = true;
let globalZoom = 0;
let globalSelectedTractors: any = null;
// let gSelectedImplementWidth = 0;
export const onGlobalZoomToShowZoomIcons = 19;
let preCommandGlobal = '';
let commandForSecondTime = '';
// const types = { POLYGON: 'Polygon', VINELANEHEAD: 'VINELANEHEAD' };

let setUpKeysGlobal: SetUpKeys = JSON.parse(JSON.stringify(defaultSetUpKeys));

const tractorsMap: Map<number, any> = new Map();
let globalShowPresentCommand = '';
let vineBlocksGlobal: any[] = [];
let selectedItemNameGlobal = '';
let globalAddStep = false;
let globalShowBadLocalization = true;
let globalAutoDriveMonitorErrorCodes: any = null;
let globalShowTractorStart: any = {};
let globalShowTractorCommandLoader: any = {};
let toggleForSideWindow = false;
let globalTractorList: any[] = [];
let globalTractorClickWait = false; // added this for wait two tractors click at a time
let globalShowRoutine = false;
let globalAutoDriveInterruptMsgs: any = {};
let globalIsPinned = false;
let globalPinnedTractors: number[] = [];
let globalPolyFeature: any = null;
let globalActiveAutoDriveTab: string = AutoDriveTabs.Parameter;
let globalShowSideFeedBack: any = {};
let globalPointsStreamToStop = '';
let globalMapLoader = false;
let globalRemainingTime = 0;
let globalAutoDriveTractorLostInterruptMsg: any = null;
let globalOrgType = 'dairy';
let globalSelectedFeedLanes: FeedLaneType[] = [];

const LiveMap: React.FC = memo(() => {
  const { userDetails, user, APPReducer, analyticDashboardData } =
    useContext(ApplicationContext);
  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const {
    setGreenPolygons,
    RDReducer,
    guardRailsCheck,
    iconsFlag,
    saveCommandTOLocalDB,
    selectedTractorsIds,
    clearCommandApiHandler,
    commandsList,
  } = useContext(RemoteDriveAppCtx);
  const [state, dispatch] = RDReducer;
  const [appState, appDispatch] = APPReducer;
  const { tractors, tractorsList, groundZero, polygonsList, showConverseChat } =
    appState;
  const [autoDriveState, autoDriveDispatch] = AutoDriveReducer;
  const {
    tractorImplementState,
    isPinned,
    remainingTime,
    autoDriveTractorLostInterruptMsg,
    showSideFeedBack,
  } = autoDriveState;
  const {
    setUpKeys,
    selectedTractor,
    selectedImplementName,
    selectedItemName,
    selectedVineBlockName,
    showRemoteDriveDetails,
    addStep,
    selectedRoutine,
    autoDriveMonitorErrorCodes,
    showTractorStart,
    showTractorCommandLoader,
    snapShotInitiated,
    showSnapshot,
    autoDriveLogoutErrorCodes,
    showRoutine,
    autoDriveMonitorPop,
    selectedTractors,
    autoDriveInterruptMsgs,
    selectedTractorLocation,
    pinnedTractors,
    triggerToStartingPoint,
    activeAutoDriveTab,
    selectedFeedLanes,
    feedAmount,
    // selectedImplementWidth,
    // showImplementWidthHandler,
  } = state;
  const [mapInitialized, setMapInitialized] = useState(false);
  const [base, setBase] = useState<[number, number]>([0, 0]);
  const [messageApi, contextHolder] = message.useMessage();
  const [toggleSatellite, setToggleSatellite] = useState(true);
  const [showTractors, setShowTractors] = useState(true);
  const [showBasestations, setShowBasestations] = useState(true);
  const [polygons, setPolygons] = useState<MapFeature[]>([]);
  const [tractorsInitialized, setTractorsInitialized] = useState(false);
  const [heartBeat, setHeartBeat] = useState<TractorHeartBeat>();
  const [selectedTractorId, setSelectedTractorId] = useState<number>(0);
  const [selectedTractorFromFleetId, setSelectedTractorFromFleetId] =
    useState<number>(0);
  const [redirectToggle, setRedirectToggle] = useState(false);
  const [errorDetails, setErrorDetails] = useState<any>();
  const [showIndicatorModel, setShowIndicatorModel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [ticketDetails, setTicketDetails] = useState(null);
  const [toggleTicketWidget, setToggleTicketWidget] = useState<any>(false);
  const [tractorId, setTractorId] = useState<any>(0);
  const [showWaypoints, setShowWaypoints] = useState(true);
  const [showGreenPolygons, setShowGreenPolygons] = useState(true);
  const [showWhitePolygons, setShowWhitePolygons] = useState(true);
  const [showGrayPolygons, setShowGrayPolygons] = useState(true);
  const [showBluePolygons, setShowBluePolygons] = useState(true);
  const [showBrownPolygons, setShowBrownPolygons] = useState(true);
  const [showDataLayers, setShowDataLayers] = useState<boolean>(false);
  const [showPath, setShowPath] = useState<boolean>(true);
  const [farm, setFarm] = useState(true);
  const [mapSnapshots, setMapSnapshots] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [leftStartVineRowName, setLeftStartVineRowName] = useState('');
  const [rightStartVineRowName, setRightStartVineRowName] = useState('');
  const [leftEndVineRowName, setLeftEndVineRowName] = useState('');
  const [rightEndVineRowName, setRightEndVineRowName] = useState('');
  const [preCommandState, setPreCommandState] = useState('');
  const [showBadLocalization, setShowBadLocalization] = useState(false);
  const mapElement: any = useRef();
  const container: any = useRef();
  const content: any = useRef();
  const tractorClusterSource: any = useRef();
  const mapRef = useRef<OLMap | null>(null);
  const vectorTileLayerRef = useRef<TileLayer<any>>();
  const satelliteTileLayerRef = useRef<TileLayer<any>>();
  const initialLayer = useRef<VectorLayer<any>>();
  const tractorPathLayerRef = useRef<VectorLayer<any>>();
  const tractorLayerRef = useRef<VectorLayer<any>>();
  const waypointsLayerRef = useRef<VectorLayer<any>>();
  const vineRowsLayerRef = useRef<VectorLayer<any>>();
  const vineBlocksLayerRef = useRef<VectorLayer<any>>();
  const pathLayerRef = useRef<VectorLayer<any>>();
  const tractorPaths = useRef<Map<string, number[][]>>();
  const tractorTraveledPathLayerRef = useRef<VectorLayer<any>>();
  const planerTractorPathLayerRef = useRef<VectorLayer<any>>();
  const plannerTractorPaths = useRef<Map<string, any>>();
  const fieldFeatureItem = useRef<any>();
  const mapIndicatorRefs = useRef<VectorLayer<any>>();
  const mapGuardRailsRefs = useRef<VectorLayer<any>>();
  const refillSeederLayerRefs = useRef<VectorLayer<any>>();
  const tractorsIndicatorMap = useRef<Map<string, GuardRailType[]>>();
  const tractorsGuardrailMap = useRef<Map<string, GuardRailType[]>>();
  const mapGuardRailsFromStreamRefs = useRef<VectorLayer<any>>();
  const mapIndicatorFromStreamRefs = useRef<VectorLayer<any>>();
  const [recenterTabId, setRecenterTabId] = useState<number>(0);
  const [showSnapShotView, setShowSnapShotView] = useState(false);
  const [snapshotContent, setSnapshotContent] = useState();
  const hoverPointLayerRef = useRef<VectorLayer<any>>();
  const greenPolygonsLayerRef = useRef<VectorLayer<any>>();
  const whitePolygonsLayerRef = useRef<VectorLayer<any>>();
  const grayPolygonsLayerRef = useRef<VectorLayer<any>>();
  const brownPolygonsLayerRef = useRef<VectorLayer<any>>();
  const bluePolygonsLayerRef = useRef<VectorLayer<any>>();
  const pointLayerRef = useRef<VectorLayer<any>>();
  const tractorStreamSubScription = useRef<any>('');
  const commandLayerRef = useRef<VectorLayer<any>>();
  const vineLaneLayerRef = useRef<VectorLayer<any>>();
  const feedLaneFeaturesLayerRefs = useRef<VectorLayer<any>>();
  const [mapLayersToggle, setMapLayersToggle] = useState(false);
  const [addobservationFlag, setAddobservationFlag] = useState<boolean>(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const [open, setOpen] = useState<boolean>(false);

  const { state: lState } = useLocation();
  const tractorObject = lState as {
    tractorId: any | undefined;
  };

  useEffect(() => {
    function onlineHandler() {
      PubSub.removePluggable('AWSIoTProvider');
      PubSub.addPluggable(
        new AWSIoTProvider({
          aws_pubsub_region: process.env.REACT_APP_REGION,
          aws_pubsub_endpoint: `wss://${process.env.REACT_APP_MQTT_ID}.iot.${process.env.REACT_APP_REGION}.amazonaws.com/mqtt`,
        }),
      );
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, []);

  // reset the timer when click on any where in browser screen start here
  useEffect(() => {
    if (!isOnline) return;
    // added to stop point loading into path key from heartbeat
    globalPointsStreamToStop = '';

    if (tractorStreamSubScription && tractorStreamSubScription.current) {
      tractorStreamSubScription.current.unsubscribe();
    }
    // console.log('hit selected tractor');
    if (selectedTractor && selectedTractor.id) {
      // ["x", "y", "z", "roll", "pitch", "yaw", "velocity", "xy_accuracy",
      // "heading_accuracy", "odometer_distance", "gps_status", "pto_state",
      // "good_localization"]
      try {
        const sub9 = PubSub.subscribe(
          `localization_lat_long_${selectedTractor.id}`,
        ).subscribe({
          next: (data: any) => {
            // console.log('iot hit');
            handleTractorSteam(JSON.stringify(data.value));
          },
          error: (error: any) => console.error(error),
        });
        tractorStreamSubScription.current = sub9;

        return () => {
          sub9.unsubscribe();
        };
      } catch (error) {
        console.log('error', error);
      }
    }

    // dead ma handle code start here
    const buttonGroupPressed = (e: any) => {
      e.stopPropagation();
      const show = $('#dead-man-handle').length;
      const isFleetBlock = $(e.target).closest('.fleetBlk').length;
      const isCamera = $(e.target).closest('.autoDriveCamViews').length;
      const connectionBtns = $(e.target).closest('.adConnectBtnSec').length;
      const deadManHandle = $(e.target).closest('#dead-man-handle').length;
      const dataLayers = $(e.target).closest('.dataLayerWrapper').length;
      const mapLayer = $(e.target).closest('.ol-layer').length;
      if (
        show &&
        !isFleetBlock &&
        !isCamera &&
        !connectionBtns &&
        !deadManHandle &&
        !dataLayers
      ) {
        autoDriveDispatch({ type: SET_RESET_TIMER, payload: true });
        console.log('timer reset');
        return;
      }
    };
    const buttonGroup: any = document.querySelector('body');
    buttonGroup.addEventListener('click', buttonGroupPressed);
    return () => {
      buttonGroup.removeEventListener('click', buttonGroupPressed);
    };
    // dead ma handle code ends here
  }, [selectedTractor, isOnline]);
  // reset the timer when click on any where in browser screen ends here

  // clearing global variables on page change
  useEffect(() => {
    return () => {
      toggleForIndicator = false; // to show hide indictor pop
      allTractorsList = [];
      globalBase = [0, 0];
      allowLiveStream = false;
      globalSelectedTractorId = 0;
      globalShowPath = true;
      globalShowDataLayers = true;
      globalToggleSatellite = true;
      globalZoom = 0;
      globalSelectedTractors = null;
      preCommandGlobal = '';
      commandForSecondTime = '';
      setUpKeysGlobal = JSON.parse(JSON.stringify(defaultSetUpKeys));
      globalShowPresentCommand = '';
      vineBlocksGlobal = [];
      selectedItemNameGlobal = '';
      globalAddStep = false;
      globalAutoDriveMonitorErrorCodes = null;
      globalShowTractorStart = {};
      globalShowTractorCommandLoader = {};
      toggleForSideWindow = false;
      globalTractorList = [];
      globalTractorClickWait = false;
      globalShowRoutine = false;
      globalAutoDriveInterruptMsgs = {};
      globalIsPinned = false;
      globalPinnedTractors = [];
      globalActiveAutoDriveTab = AutoDriveTabs.Parameter;
      globalShowSideFeedBack = {};
      globalPointsStreamToStop = '';
      globalMapLoader = false;
      globalRemainingTime = 0;
      globalAutoDriveTractorLostInterruptMsg = null;
      globalOrgType = '';
      globalSelectedFeedLanes = [];
      // gSelectedImplementWidth = 0;
    };
  }, []);

  // useEffect(() => {
  //   gSelectedImplementWidth = selectedImplementWidth;
  // }, [selectedImplementWidth]);

  useEffect(() => {
    globalAutoDriveTractorLostInterruptMsg = autoDriveTractorLostInterruptMsg;
  }, [autoDriveTractorLostInterruptMsg]);

  useEffect(() => {
    globalRemainingTime = remainingTime;
  }, [remainingTime]);
  useEffect(() => {
    globalMapLoader = loader;
  }, [loader]);

  useEffect(() => {
    // console.log('showSideFeedBack', showSideFeedBack);
    globalShowSideFeedBack = showSideFeedBack;
  }, [showSideFeedBack]);
  useEffect(() => {
    globalActiveAutoDriveTab = activeAutoDriveTab;
  }, [activeAutoDriveTab]);
  // global variable assigning start here
  // assigning pinned tractor to global var
  useEffect(() => {
    globalPinnedTractors = pinnedTractors;
  }, [pinnedTractors]);

  // assigning is pinned or not to global var
  useEffect(() => {
    globalIsPinned = isPinned;
  }, [isPinned]);

  // assigning is interrupt messages global var
  useEffect(() => {
    globalAutoDriveInterruptMsgs = autoDriveInterruptMsgs;
  }, [autoDriveInterruptMsgs]);

  // to show live tractor position routine preview path popup
  useEffect(() => {
    globalShowRoutine = showRoutine;
  }, [showRoutine]);

  useEffect(() => {
    globalTractorList = tractorsList;
  }, [tractorsList]);

  useEffect(() => {
    snapShotInitiated &&
      globalSelectedTractorId &&
      tractorConnected(selectedTractor?.heartbeat, userDetails.id) &&
      getGuardRails(Number(globalSelectedTractorId));
  }, [snapShotInitiated]);

  useEffect(() => {
    toggleForSideWindow = showSnapShotView;
  }, [showSnapShotView]);

  useEffect(() => {
    globalAddStep = addStep;
    // when go point / block enable we hiding vine row
    addStep && handleToggleLayer(VINE_ROW, false);
  }, [addStep]);

  useEffect(() => {
    globalShowTractorStart = showTractorStart;
  }, [showTractorStart]);

  useEffect(() => {
    globalShowTractorCommandLoader = showTractorCommandLoader;
  }, [showTractorCommandLoader]);

  useEffect(() => {
    globalAutoDriveMonitorErrorCodes = autoDriveMonitorErrorCodes;
  }, [autoDriveMonitorErrorCodes]);
  // global variable assigning ends here

  useEffect(() => {
    if (mapInitialized && tractorObject && tractorObject.tractorId > 0) {
      setSelectedTractorId(Number(tractorObject.tractorId));
      setSelectedTractorFromFleetId(Number(tractorObject.tractorId));
      setRedirectToggle(true);
    }
  }, [tractorObject, mapInitialized]);

  useEffect(() => {
    if (userDetails && userDetails.organization && groundZero) {
      initializeMap();
    }
  }, [userDetails, groundZero]);

  //  mapInitialization start here
  const initializeMap = async () => {
    try {
      const { latitude, longitude } = groundZero;
      globalBase = [latitude, longitude];
      setBase([latitude, longitude]);

      const baseLocation = new Feature({
        geometry: new Point(proj.fromLonLat([longitude, latitude])),
      });
      baseLocation.set('name', '');
      //  set id 1 for base station recenter
      baseLocation.setId(1);
      const style = new Style({
        image: new Icon({
          src: ground_zero,
          scale: 0.2,
        }),
        zIndex: 100,
      });
      baseLocation.setStyle(style);
      const initialFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [baseLocation],
        }),
        zIndex: priority.BASESTATION,
        visible: false,
      });

      const vectorLayer = new TileLayer({
        source: new OSM(),
        visible: false,
      });

      const satelliteLayer = new TileLayer({
        source: new XYZ({
          url: SATILITE_LAYER_URL,
          cacheSize: 1000,
        }),
        visible: true,
      });

      const initialPathLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: false,
        zIndex: priority.PREVIEWPATH,
      });
      const pointLocation: Feature<any> = new Feature();
      pointLocation.setId(999);
      const selectPointFeatureLayer = new VectorLayer({
        source: new VectorSource({
          features: [pointLocation],
        }),
        visible: true,
        style: new Style({}),
        zIndex: 111,
      });

      const greenPolygonsFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        zIndex: priority.POLYGON.green,
      });

      const whitePolygonsFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        zIndex: priority.POLYGON.white,
      });

      const grayPolygonsFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        zIndex: priority.POLYGON.gray,
      });
      const brownPolygonsFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        zIndex: priority.POLYGON.brown,
      });
      const bluePolygonsFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        zIndex: priority.POLYGON.blue,
      });

      // create and add vector source layer
      const initialPointLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        zIndex: priority.ROUTINE,
      });
      const initialCommandPointLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        zIndex: Infinity,
      });

      const rowFollowFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        style: getVineRowStyle(),
      });

      const guardRailsSource = new VectorSource({
        features: [],
      });
      const GuardRailsClusterSource = new Cluster({
        distance: 10,
        source: guardRailsSource,
      });
      const guardRailsLayer = new VectorLayer({
        source: GuardRailsClusterSource,
        visible: globalShowDataLayers,
        zIndex: priority.SNAP,
        style: function (feature: any) {
          return styleFun(feature);
        },
      });
      const refillSeederLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        style: refillSeederStyle,
        zIndex: priority.REFILL_SEEDER,
      });
      const feedLaneFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        style: feedLaneStyle,
        zIndex: priority.ROUTINE - 1,
      });
      // create map
      const initialMap = new OLMap({
        target: mapElement.current,
        layers: [
          satelliteLayer,
          vectorLayer,
          initialFeaturesLayer,
          initialPathLayer,
          selectPointFeatureLayer,
          greenPolygonsFeaturesLayer,
          whitePolygonsFeaturesLayer,
          grayPolygonsFeaturesLayer,
          bluePolygonsFeaturesLayer,
          initialPointLayer,
          rowFollowFeaturesLayer,
          initialCommandPointLayer,
          guardRailsLayer,
          brownPolygonsFeaturesLayer,
          refillSeederLayer,
          feedLaneFeaturesLayer,
        ],
        controls: [scaleControl()],
        view: new View({
          projection: 'EPSG:3857',
          center: proj.transform(
            [longitude, latitude],
            'EPSG:4326',
            'EPSG:3857',
          ),
          zoom: 17,
          maxZoom: 23,
        }),
      });
      // set flag for map initialization
      setMapInitialized(true);
      mapRef.current = initialMap;
      initialLayer.current = initialFeaturesLayer;
      pointLayerRef.current = initialPointLayer;
      commandLayerRef.current = initialCommandPointLayer;

      // initialize satellite layer
      satelliteTileLayerRef.current = satelliteLayer;
      // initialize vector layer
      vectorTileLayerRef.current = vectorLayer;

      pathLayerRef.current = initialPathLayer;
      // initialize point selection layer
      hoverPointLayerRef.current = selectPointFeatureLayer;
      // polygon layers
      greenPolygonsLayerRef.current = greenPolygonsFeaturesLayer;
      whitePolygonsLayerRef.current = whitePolygonsFeaturesLayer;
      grayPolygonsLayerRef.current = grayPolygonsFeaturesLayer;
      brownPolygonsLayerRef.current = brownPolygonsFeaturesLayer;
      bluePolygonsLayerRef.current = bluePolygonsFeaturesLayer;
      // vine lane layer
      vineLaneLayerRef.current = rowFollowFeaturesLayer;
      mapGuardRailsRefs.current = guardRailsLayer;
      refillSeederLayerRefs.current = refillSeederLayer;
      feedLaneFeaturesLayerRefs.current = feedLaneFeaturesLayer;
    } catch (err: any) {
      notification.error({
        message: err.message,
        duration: 1,
      });
    }
  };
  //  mapInitialization ends here

  const scaleControl = () => {
    const control = new ScaleLine({
      units: 'metric',
      className: 'positionMap',
    });
    return control;
  };
  // to change road map and satellite map polygons and vine row styles
  useEffect(() => {
    vectorTileLayerRef.current?.setVisible(!toggleSatellite);
    satelliteTileLayerRef.current?.setVisible(toggleSatellite);
    globalToggleSatellite = toggleSatellite;

    if (whitePolygonsLayerRef.current)
      togglePolygonStyle(whitePolygonsLayerRef.current, toggleSatellite);

    if (greenPolygonsLayerRef.current)
      togglePolygonStyle(greenPolygonsLayerRef.current, toggleSatellite);

    if (bluePolygonsLayerRef.current)
      togglePolygonStyle(bluePolygonsLayerRef.current, toggleSatellite);

    if (grayPolygonsLayerRef.current)
      togglePolygonStyle(grayPolygonsLayerRef.current, toggleSatellite);

    if (vineRowsLayerRef.current)
      toggleVineRowStyle(
        vineRowsLayerRef.current,
        toggleSatellite,
        globalZoom >= onGlobalZoomToShowZoomIcons,
      );
  }, [toggleSatellite]);
  // get tractor after map initialized
  useEffect(() => {
    if (mapInitialized && tractors && tractors.length > 0) {
      getTractors();
      if (mapRef.current) moveEndHandler(mapRef.current);
    }
  }, [userDetails, mapInitialized, tractors]);

  const moveEndHandler = (map: any) => {
    let currZoom = map?.getView().getZoom();
    map &&
      map.on('moveend', function (e: any) {
        const newZoom = map?.getView().getZoom();
        if (newZoom > 15) {
          tractorClusterSource.current.setDistance(0);
        } else {
          tractorClusterSource.current.setDistance(10);
        }
        if (currZoom != newZoom) {
          currZoom = newZoom;
          globalZoom = newZoom;
          handleLayerVisible(newZoom);
          selectedOnTractor();
        }
      });
  };
  const getZoomValue = (): number =>
    mapRef?.current?.getView().getZoom() as number;

  const getTractors = async () => {
    try {
      const records = tractors && tractors.length > 0 ? tractors : [];
      const tractorFeatures: Feature<any>[] = [];
      const tractorList: MapFeature[] = [];
      records.map((tractor: any) => {
        const {
          known_latitude,
          known_longitude,
          connection,
          known_bearing,
          heartbeat: hb,
        } = tractor;
        const id = `${POSE_MODEL_STREAMING}${tractor.id}`;

        const klong =
          known_longitude *
          Number(`1.000000${Math.floor(randomNum() * 9 + 1)}`);

        const kLat =
          known_latitude * Number(`1.000000${Math.floor(randomNum() * 9 + 1)}`);

        const tractorLocation = new Feature({
          geometry: new Point(proj.fromLonLat([klong, kLat])),
        });
        tractorLocation.setId(id);
        tractorLocation.set('name', tractor.name);
        tractorLocation.set('latLang', [klong, kLat]);
        const icon =
          connection === 'online'
            ? TractorIconConstant.ONLINE
            : TractorIconConstant.OFFLINE;
        const batValue = batteryValue(tractor);
        // only online tractor have faulty icon
        const isFaulty =
          connection === 'online' &&
          getOperationNewStatus(hb) === tractorConstantsNew.faulty.key
            ? true
            : false;
        const isAvailable = tractor.isAvailable;
        const drive_action_created_at =
          tractor?.heartbeat?.drive_action_details?.drive_action_created_at;
        const style = getTractorIcon(
          icon,
          false,
          known_bearing,
          '',
          false,
          false,
          false,
          batValue,
          false,
          tractor.name,
          false,
          tractor.id,
          isFaulty,
          isAvailable,
          drive_action_created_at,
        );
        const hoverStyle = getTractorIcon(
          icon,
          true,
          known_bearing,
          '',
          false,
          false,
          false,
          batValue,
          false,
          tractor.name,
          false,
          tractor.id,
          isFaulty,
          isAvailable,
          drive_action_created_at,
        );
        tractorLocation.set('pointType', TRACTOR);
        tractorLocation.set('tractorId', tractor.id);
        tractorLocation.set('style', style);
        tractorLocation.set('hoverStyle', hoverStyle);
        tractorLocation.set('pointTypeSelected', icon);
        tractorLocation.set('pointTypeBearing', known_bearing);
        tractorLocation.set('pointTypeIni', '');
        tractorLocation.set('pointTypeBattery', batValue);
        tractorLocation.set('isFaulty', isFaulty);
        tractorLocation.set('isAvailable', isAvailable);
        tractorLocation.set('drive_action_created_at', drive_action_created_at);
        // using for getting tractor x, y and direction
        const tractorPosition = getPosition(hb);
        tractorLocation.set('tractorPosition', tractorPosition);
        tractorLocation.setStyle(style);
        tractorFeatures.push(tractorLocation);
        tractorList.push({
          id: tractor.id,
          name: tractor.name,
          baseStationName: tractor?.basestation?.name,
          type: TRACTOR,
          mapId: id,
          visible: true,
          style,
        });
      });
      const source = new VectorSource({
        features: tractorFeatures,
      });
      tractorClusterSource.current = new Cluster({
        distance: 10,
        source: source,
        geometryFunction: function (feature) {
          const pointTypeSelected = feature.get('pointTypeSelected');
          const isAvailable = feature.get('isAvailable');
          const tractorId = feature.get('tractorId');
          if (
            isAvailable === tractorConstantsNew.off.key &&
            pointTypeSelected === TractorIconConstant.OFFLINE &&
            // tractorId !== globalSelectedTractorId &&
            !isTractorSelected(tractorId)
          ) {
            return null;
          }
          return feature.getGeometry();
        },
      });
      allTractorsList = tractorList;

      const tractorFeaturesLayer = new VectorLayer({
        source: tractorClusterSource.current,
        zIndex: priority.TRACTOR,
        style: function (feature: any) {
          const size = feature.get('features').length;
          let style = new Style({});
          if (size === 1) {
            const feat = feature.get('features');
            // style =
            //   globalZoom > onGlobalZoomToShowZoomIcons
            //     ? feat[0].get('onZoomStyle')
            //     : feat[0].get('style');
            style = feat[0].get('style');
            feature.set('name', feat[0].get('name'));
            feature.set('pointType', feat[0].get('pointType'));
          }
          if (size > 1) {
            const feat = feature.get('features');
            let names = '';
            feat.map((fe: any, index: number) => {
              names = `${names} ${fe.get('name')} ${
                index < feat.length - 1 ? ', ' : ''
              }`;
            });
            feature.set('pointType', feat[0].get('pointType'));
            feature.set('name', names);
            style = tractorClusterStyle(size, false);
          }
          return style;
        },
      });

      const tractorPathFeaturesLayer = new VectorLayer({
        source: new VectorSource(),
        zIndex: priority.PATH,
      });

      const plannerTractorPathFeaturesLayer = new VectorLayer({
        source: new VectorSource(),
        zIndex: priority.PATH,
      });
      // tractor recent traveled path jump and go
      const tractorTraveledPath = new VectorLayer({
        source: new VectorSource(),
        style: new Style({
          stroke: new Stroke({ color: pathColorConstants.DEFAULT, width: 9 }),
        }),
        zIndex: priority.PATH,
      });

      mapRef.current?.addLayer(tractorFeaturesLayer);
      mapRef.current?.addLayer(tractorPathFeaturesLayer);
      mapRef.current?.addLayer(plannerTractorPathFeaturesLayer);
      mapRef.current?.addLayer(tractorTraveledPath);

      setTractorsInitialized(true);

      tractorLayerRef.current = tractorFeaturesLayer;
      tractorPathLayerRef.current = tractorPathFeaturesLayer;
      planerTractorPathLayerRef.current = plannerTractorPathFeaturesLayer;
      // tractor traveled path
      tractorTraveledPathLayerRef.current = tractorTraveledPath;
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 1,
      });
    }
  };
  /**
   * Live Tractors initialization Ends Here
   */
  useEffect(() => {
    if (mapInitialized && !tractorsInitialized) {
      const overlay = new Overlay({
        element: container.current,
        autoPan: false,
        autoPanAnimation: {
          duration: 10,
        },
      });
      let mainPointType = '';
      let mousePointType = '';
      let selected: any = null;
      mapRef.current?.on('pointermove', function (evt) {
        const pointLayer = hoverPointLayerRef.current;
        if (selected !== null) {
          const featurePoint = pointLayer?.getSource().getFeatureById(999);
          featurePoint?.setStyle(new Style({}));
          const pointType = selected.get('pointType');
          if (
            globalAddStep &&
            globalShowPresentCommand === 'GOTO' &&
            !globalMapLoader &&
            pointType === 'Polygon'
          ) {
            const poly = selected.get('poly');
            if (poly) poly.color = 'green';

            togglePolygonSelectionStyle(
              greenPolygonsLayerRef.current,
              toggleSatellite,
              setUpKeysGlobal.mapId,
            );
          } else {
            selected?.setStyle(selected?.get('style'));
          }

          selected = null;
        }
        const features = vineLaneLayerRef?.current?.getSource().getFeatures();
        features &&
          features.map((feature: any) => {
            const pointTypeHover = feature.get('pointTypeHover');
            if (pointTypeHover && pointTypeHover === types.VINELANEHEAD) {
              feature?.setStyle(feature?.get('style'));
            }
          });
        const hit = mapRef.current?.forEachFeatureAtPixel(
          evt.pixel,
          function (feature: any) {
            const coordinate = evt.coordinate;
            const name = feature.get('name');
            const pointType = feature.get('pointType');
            const pointTypeHover = feature.get('pointTypeHover');
            mousePointType = feature.get('mousePointType');
            if (pointTypeHover && pointTypeHover === types.VINELANEHEAD) {
              selected = feature;
              feature.setStyle(
                getSateLightPolyStyle('vineLaneYellow', priority.VINEROW),
              );
            }
            if (
              globalAddStep &&
              globalShowPresentCommand === 'GOTO' &&
              !globalMapLoader &&
              pointType === 'Polygon'
            ) {
              const poly = feature.get('poly');
              if (poly && poly.color === 'green') {
                selected = feature;
                const id = feature.get('id');
                id !== setUpKeysGlobal.mapId &&
                  feature.setStyle(
                    getSateLightPolyStyle(
                      poly.color,
                      priority.POLYGON[poly.color],
                      false,
                      'HOVER',
                    ),
                  );
                return true;
              }
            }
            if (globalShowPresentCommand !== 'GOTO') {
              if (pointType && pointType === 'vinerow') {
                selected = feature;
                feature.setStyle(
                  vineRowHoverStyle(globalZoom >= onGlobalZoomToShowZoomIcons),
                );
              } else if (pointType === BadLocalization) {
                // added on hover style for bad Localization

                const startingPathPoint = setBadLocalizationStyle(true);
                selected = feature;
                selected.setStyle(startingPathPoint);

                let innerHTML = '';
                const badLocationContent = feature.get('data');
                if (
                  badLocationContent &&
                  badLocationContent.created_date_time
                ) {
                  innerHTML = getBadLocalizationFromLiveMap(badLocationContent);
                  content.current.innerHTML = innerHTML;
                  if (
                    !overlay
                      .getElement()
                      ?.classList.contains('ol-details-popup')
                  ) {
                    overlay.getElement()?.classList.add('ol-details-popup');
                  }
                  overlay.setPosition(evt.coordinate);
                  mapRef.current?.addOverlay(overlay);
                  return true;
                }
              } else if (pointType == 'SNAP') {
                const contentData = feature.get('content');
                if (
                  (contentData && contentData.created_at === GUARD_RAILS) ||
                  (contentData && contentData.title === 'guard_rails')
                ) {
                  selected = feature;
                  selected?.setStyle(guardRailStyle(1, true));
                }
              } else if (pointType === 'PATH' || pointType === 'LIVEPATH') {
                const featurePoint = pointLayer
                  ?.getSource()
                  .getFeatureById(999);
                if (featurePoint) {
                  featurePoint.setGeometry(
                    new Point(
                      proj.fromLonLat(
                        proj.transform(
                          evt.coordinate,
                          'EPSG:3857',
                          'EPSG:4326',
                        ),
                      ),
                    ),
                  );
                  featurePoint.setStyle(
                    new Style({
                      image: new CircleStyle({
                        radius: 5,
                        stroke: new Stroke({
                          color: 'blue',
                        }),
                        fill: new Fill({
                          color: '#3399CC',
                        }),
                      }),
                      zIndex: 10,
                    }),
                  );
                  selected = feature;
                  selected?.setStyle(feature.get('hoverStyle'));
                  const geometry = feature.getGeometry();
                  const point = geometry.getClosestPoint(evt.coordinate);
                  const coordinates = geometry.getCoordinates();

                  for (
                    let element = 0;
                    element < coordinates.length - 1;
                    element++
                  ) {
                    if (
                      new LineString([
                        coordinates[element],
                        coordinates[element + 1],
                      ]).intersectsCoordinate(point)
                    ) {
                      const drawPointsText = selected.get('drawPointsText');
                      const points =
                        pointType === 'PATH'
                          ? drawPointsText.path
                          : drawPointsText.points;
                      if (points && points.length && points[element]) {
                        let innerHTML = '';
                        if (pointType === 'PATH') {
                          innerHTML = getFleetUsagePointsPathInfoFromLiveMap(
                            points[element],
                          );
                        }
                        if (pointType === 'LIVEPATH') {
                          innerHTML =
                            getFleetUsagePointsPathInfoFromLiveMap(
                              drawPointsText,
                            );
                        }

                        selected.set('name', innerHTML);
                        content.current.innerHTML = innerHTML;
                        if (
                          !overlay
                            .getElement()
                            ?.classList.contains('ol-details-popup')
                        ) {
                          overlay
                            .getElement()
                            ?.classList.add('ol-details-popup');
                        }
                        overlay.setPosition(evt.coordinate);
                        mapRef.current?.addOverlay(overlay);
                        return true;
                      }
                    }
                  }
                }
              }
            }

            const feat = feature.get('features');
            // added for on hover change
            if (feat && feat.length > 0) {
              if (feat[0].get('pointType')) {
                if (feat[0].get('pointType') === 'SNAP') {
                  selected = feature;
                  selected?.setStyle(guardRailStyle(feat.length, true));
                } else if (feat[0].get('pointType') === 'INDICATOR') {
                  selected = feature;
                  feature.setStyle(IndicatorStyle(feat.length, true));
                } else if (feat[0].get('pointType') === TRACTOR) {
                  if (feat.length === 1) {
                    selected = feature;
                    feature.setStyle(feat[0].get('hoverStyle'));
                  } else {
                    selected = feature;
                    feature.setStyle(tractorClusterStyle(feat.length, true));
                  }
                } else if (feat[0].get('pointType') === 'waypoint') {
                  selected = feature;
                  if (feat.length === 1) {
                    const style = wayPointHoverStyle(
                      feat[0].get('name'),
                      globalToggleSatellite,
                    );
                    selected.setStyle(style);
                  } else {
                    const style = wayPointClusterHoverStyle(feat.length, true);
                    selected.setStyle(style);
                  }
                }
              }
            }
            mainPointType = pointType;
            if (name && pointType !== TRACTOR) {
              content.current.innerHTML = '<p>' + name ? name : '' + '</p>';
              if (pointType == 'INDICATOR') {
                const contentData = feature.get('content');
                allTractorsList.forEach(
                  (feature: { id: number; name: any }) => {
                    if (feature.id && feature.id === contentData.tractor_id) {
                      contentData.tractorName = feature.name;
                    }
                  },
                );
                content.current.innerHTML = getFleetUsagePointsInfo(
                  pointType,
                  contentData,
                );
                if (
                  !overlay.getElement()?.classList.contains('ol-details-popup')
                ) {
                  overlay.getElement()?.classList.add('ol-details-popup');
                }
              } else {
                // stop removing path class removal
                if (!['PATH', 'LIVEPATH'].includes(pointType)) {
                  overlay.getElement()?.classList.remove('ol-details-popup');
                }
              }

              overlay.setPosition(coordinate);
              mapRef.current?.addOverlay(overlay);
              return true;
            }
            // show the  tractor cluster name
            if (pointType === TRACTOR && feat.length > 0) {
              if (feat.length > 1) {
                content.current.innerHTML =
                  '<p>' + name && name != undefined ? name : '' + '</p>';
                overlay.setPosition(coordinate);
                mapRef.current?.addOverlay(overlay);
              }
              return true;
            }
            return false;
          },
        );
        if (hit) {
          if (mainPointType !== 'vinerow') {
            evt.map.getTargetElement().style.cursor = 'pointer';
          }
          if (mousePointType === types.VINELANEHEAD) {
            evt.map.getTargetElement().style.cursor = 'pointer';
          }
        } else {
          overlay.setPosition(undefined);
          mapRef.current?.addOverlay(overlay);
          evt.map.getTargetElement().style.cursor = '';
        }
      });
    }
    if (mapInitialized && tractorsInitialized) {
      mapRef.current?.on('click', function (evt) {
        mapRef.current?.forEachFeatureAtPixel(evt.pixel, function (feature) {
          const feat = feature.get('features');
          if (feat && feat.length === 1) {
            globalTractorList &&
              globalTractorList.forEach(
                (element: { mapId: string; id: number }) => {
                  // globalShowPresentCommand !== 'GOTO' --don't allow tractor click when in autodrive
                  if (
                    globalShowPresentCommand !== 'GOTO' &&
                    !globalTractorClickWait &&
                    feat[0].getId() &&
                    feat[0].getId().replace(POSE_MODEL_STREAMING, '') ==
                      element.id
                  ) {
                    globalTractorClickWait = true;
                    setTimeout(() => {
                      globalTractorClickWait = false;
                    }, 1000);
                    dispatch({
                      type: SELECTED_TRACTORS,
                      payload: [element],
                    });
                    allowLiveStream = true;
                  }
                },
              );
          }
          if (
            feat &&
            feat.length > 1 &&
            feat[0] &&
            feat[0].get('pointType') &&
            (feat[0].get('pointType') === TRACTOR ||
              feat[0].get('pointType') === 'INDICATOR')
          ) {
            tractorClusterSource.current.setDistance(0);
            feat.map((f: any) => {
              const geometry: any = feature.getGeometry();
              const co = geometry.getCoordinates();
              f.setGeometry(
                new Point([
                  co[0] * Number(`1.00000${Math.floor(randomNum() * 3)}`),
                  co[1] * Number(`1.00000${Math.floor(randomNum() * 3)}`),
                ]),
              );
            });
          }
          if (feat && feat.length > 1) {
            const geometry = feat[0].getGeometry();
            const point = geometry.getCoordinates();
            const view = mapRef.current?.getView();
            if (view) {
              const zoom = view.getZoom();
              zoom && view.setZoom(zoom + 1);
              if (point) {
                const coordinates = proj.transform(
                  point,
                  'EPSG:3857',
                  'EPSG:4326',
                );
                centerTheLocation(coordinates, mapRef.current);
              }
            }
          }
          const pointType = feature.get('pointType');
          if (pointType && pointType === 'INDICATOR') {
            if (!toggleForIndicator) {
              const content = feature.get('content');
              const { subsystem = '', functionality_affected = '' } =
                content.error_code_data ? content.error_code_data : {};
              content.functionality_affected = functionality_affected;
              content.subsystem = subsystem;
              content.indicator_type = content.indicator_name;
              content.severity = content.indicator_level;
              content.start_time = content.created_date_time
                ? getDateTimes(content.created_date_time)
                : '';
              content.counter_measure = content?.error_code_data
                ?.counter_measure
                ? content?.error_code_data?.counter_measure?.split(';')
                : '';
              setErrorDetails(content);
              setShowIndicatorModel(true);
              toggleForIndicator = true;
            } else {
              setShowIndicatorModel(false);
              toggleForIndicator = false;
            }
          }
          if (pointType == 'SNAP') {
            if (!toggleForSideWindow) {
              const content = feat[0].get('content');
              // eslint-disable-next-line no-empty
              if (content.created_at !== GUARD_RAILS) {
                const cont: any = {
                  id: content.id,
                  tractorName: content.tractor_id.name,
                  date_time: content.created_date_time,
                  created_date_time: content.created_date_time
                    ? getDateTimes(content.created_date_time)
                    : '',
                  name: content.created_by
                    ? content.created_by?.first_name
                    : '-',
                  status: content.status,
                  remarks: content.remarks,
                  tractor_id: content.tractor_id,
                  tractorId: content.tractor_id?.id,
                  transaction_id: content.transaction_id,
                  created_at: content.created_at,
                  guard_rail_type: content.guard_rail_type,
                  drive_action_uuid: content.drive_action_uuid,
                  createdAt: content.created_at.replaceAll('_', ' '),
                };
                setSnapshotContent(cont);
                setShowSnapShotView(true);
              }
            }
          }
        });
      });
      mapRef.current?.on('pointerdrag', function () {
        allowLiveStream = false;
      });

      mapRef.current?.on('click', async (event: any) => {
        if (
          globalAddStep &&
          (globalShowPresentCommand === 'GOTO' || globalOrgType === 'dairy') &&
          !globalMapLoader
        ) {
          dispatch({
            type: AUTO_DRIVE_ERROR,
            payload: {
              tractor_id: globalSelectedTractorId,
              message: '',
              errors: [],
            },
          });
          const featureData: any = await mapRef.current?.forEachFeatureAtPixel(
            event.pixel,
            function (feature) {
              return feature;
            },
          );
          goToRowFollowCode(featureData);
        }
      });
    }
  }, [userDetails, mapInitialized, tractorsInitialized]);

  useEffect(() => {
    globalSelectedFeedLanes = selectedFeedLanes;
  }, [selectedFeedLanes]);
  const handleSelectionLanes = (feedLane: FeedLaneType) => {
    let list = globalSelectedFeedLanes;
    if (isFeedLaneChecked(globalSelectedFeedLanes, feedLane)) {
      list = globalSelectedFeedLanes.filter(
        (feed: FeedLaneType) => feed.id !== feedLane.id,
      );
    } else {
      // list = [...list, feedLane];
      list = [...list, { ...feedLane, feedAmount }];
    }
    dispatch({
      type: SET_SELECTED_FEED_LANES,
      payload: list,
    });
  };

  useEffect(() => {
    if (triggerToStartingPoint) {
      globalPolyFeature && goToRowFollowCode(globalPolyFeature, true);
    }

    dispatch({
      type: SET_TRIGGER_TO_STARTING_POINT,
      payload: false,
    });
  }, [triggerToStartingPoint]);

  const handlePolySelection = async (run = false) => {
    if (run && setUpKeysGlobal && setUpKeysGlobal.isBlockSelected) {
      const polygon = globalPolyFeature.get('poly');

      if (polygon && polygon.color === 'green') {
        setPreCommandState(commands.ROW_FOLLOW);
        commandForSecondTime = commands.ROW_FOLLOW;
        const layer = greenPolygonsLayerRef.current;
        if (layer?.getVisible) {
          const { organization } = userDetails;
          setLoader(true);

          try {
            const [laneFilterContent1, vineBlocks] = await Promise.all([
              await getVineLanesData(
                organization.api_url,
                organization.farm.id,
                polygon.polygon_uuid,
              ),
              await getVineBlockName(
                organization.api_url,
                organization.farm.id,
                polygon.polygon_uuid,
              ),
            ]);
            vineBlocksGlobal = vineBlocks;
            let laneFilterContent = laneFilterContent1;
            if (
              laneFilterContent &&
              laneFilterContent.length > 0 &&
              vineBlocksGlobal &&
              vineBlocksGlobal.length > 0
            ) {
              laneFilterContent = laneFilterContent.sort(
                (a: { id: number }, b: { id: number }) => a.id - b.id,
              );
              dispatch({
                type: SELECTED_ITEM_NAME,
                payload: polygon.name,
              });
              dispatch({
                type: SET_SETUP_KEYS,
                payload: {
                  ...setUpKeysGlobal,
                  polygonUuid: polygon.polygon_uuid,
                  laneFilterContent,
                },
              });
              handleToggleLayer(VINE_ROW, false);
              setFarm(false);
              selectFarmItems(false, true);
            } else {
              resetForMapClicks('');
              setTimeout(() => {
                resetForMapClicks('GOTO');
              }, 100);
              notification.error({
                message: 'No Vine Rows found',
                duration: 1,
              });
              dispatch({
                type: SET_ACTIVE_AUTODRIVE_TAB,
                payload: AutoDriveTabs.Block,
              });
            }
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            resetForMapClicks('');
            setTimeout(() => {
              resetForMapClicks('GOTO');
            }, 100);
            notification.error({
              message: 'Failed to get VineLanes',
              duration: 1,
            });
            dispatch({
              type: SET_ACTIVE_AUTODRIVE_TAB,
              payload: AutoDriveTabs.Block,
            });
          } finally {
            setLoader(false);
          }
        }
      }
    }
  };
  const goToRowFollowCode = async (featureData: any, run = false) => {
    if (globalOrgType === 'dairy') {
      if (featureData) {
        const type = featureData.get('pointType');
        if (type === types.FEED_LANE || type === types.FEED_LANE_HEAD) {
          const record = featureData.get('data');
          handleSelectionLanes(record);
        }
      }
    }
    if (globalShowPresentCommand === 'GOTO') {
      if (featureData) {
        const type = featureData.get('pointType');
        const id = featureData.get('id');
        const polygon = featureData.get('poly');
        if (preCommandGlobal === '') {
          if (type === types.POLYGON && polygon.color === 'green') {
            globalPolyFeature = featureData;
            togglePolygonSelectionStyle(
              greenPolygonsLayerRef.current,
              toggleSatellite,
            );
            featureData.setStyle(
              getSateLightPolyStyle(
                polygon.color,
                priority.POLYGON[polygon.color],
                false,
                SELECTED,
              ),
            );

            dispatch({
              type: SET_SETUP_KEYS,
              payload: {
                ...setUpKeysGlobal,
                isBlockSelected: true,
                // mapId: 'polygon_' + id,
                mapId: id,
              },
            });
            handlePolySelection(run);
          }
        } else if (
          preCommandGlobal === commands.ROW_FOLLOW &&
          commandForSecondTime !== commands.ROW_FOLLOW_SECOND
        ) {
          if (type === types.VINELANEHEAD) {
            const startIndexValue = featureData.get('startIndex');
            const startIndexUuid = featureData.get('startIndexUuid');
            const startWithValue = featureData.get('head_name');
            // set vine block name
            const data = featureData.get('data');
            const { vine_blocks_uuid } = data;
            const [vineBlock] = vineBlocksGlobal.filter(
              (item: any) => item.vine_blocks_uuid === vine_blocks_uuid,
            );
            if (
              // !isValidBlock(gSelectedImplementWidth, vineBlock.row_width) &&
              vineBlock &&
              vineBlock.name
            ) {
              const { name } = vineBlock;
              dispatch({
                type: SELECTED_VINE_BLOCK,
                payload: name,
              });

              const laneFilterContent =
                setUpKeysGlobal.laneFilterContent.filter(
                  (item: any) => item.vine_blocks_uuid === vine_blocks_uuid,
                );
              if (!setUpKeysGlobal.isLastTime) {
                if (!setUpKeysGlobal.isFirstTime) {
                  dispatch({
                    type: SET_SETUP_KEYS,
                    payload: {
                      ...setUpKeysGlobal,
                      startIndex: startIndexValue,
                      startWith: startWithValue,
                      // isFirstTime: true, // added to html click
                      laneFilterContent,
                      startIndexUuid,
                    },
                  });
                } else {
                  // commandForSecondTime = commands.ROW_FOLLOW_SECOND;
                  dispatch({
                    type: SET_SETUP_KEYS,
                    payload: {
                      ...setUpKeysGlobal,
                      // isLastTime: true,
                      endIndexUuid: startIndexUuid,
                      endIndex: startIndexValue,
                    },
                  });
                }
              }
            } else {
              resetForMapClicks('');
              setTimeout(() => {
                resetForMapClicks('GOTO');
              }, 100);

              !(vineBlock && vineBlock.name) &&
                notification.error({
                  message: 'VineBlock Not found',
                  duration: 1,
                });
              // isValidBlock(gSelectedImplementWidth, vineBlock.row_width) &&
              //   dispatch({
              //     type: SET_SHOW_IMPLEMENT_HANDLER,
              //     payload: true,
              //   });
              dispatch({
                type: SET_ACTIVE_AUTODRIVE_TAB,
                payload: AutoDriveTabs.Block,
              });
            }
          }
        }
      }
    }
  };

  /**
   * Subscribing to IOT
   */

  useEffect(() => {
    if (userDetails && !userDetails?.organization) return;
    if (!isOnline) return;
    try {
      const tractorLayer = tractorLayerRef.current;
      if (tractorLayer?.getVisible()) {
        const sub1 = PubSub.subscribe(
          `HeartBeat/org/${userDetails.organization_id}`,
        ).subscribe({
          next: (data: any) => {
            heartBeatHandler(JSON.stringify(data.value));
          },
          error: (error: any) => console.error(error),
        });

        const sub2 = PubSub.subscribe('tractor_status').subscribe({
          next: (data: any) => {
            tractorOfflineStatusHandler(JSON.stringify(data.value));
          },
          error: (error: any) => console.error(error),
        });

        const sub3 = PubSub.subscribe(
          `${MOS_EVENTS_TOPIC}${userDetails.organization_id}`,
        ).subscribe({
          next: (data: any) => {
            mosEventsHandler(JSON.stringify(data.value));
          },
          error: (error: any) => console.error(error),
        });

        const sub4 = PubSub.subscribe(AUTO_DRIVE_ERROR_TOPIC).subscribe({
          next: (data: any) => {
            autoDriveErrorHandler(JSON.stringify(data.value));
          },
          error: (error: any) => console.error(error),
        });

        const sub5 = PubSub.subscribe(
          `remoteav_preview_${userDetails.organization_id}`,
        ).subscribe({
          next: (data: any) => {
            remoteAvPreviewForOrgHandler(JSON.stringify(data.value));
          },
          error: (error: any) => console.error(error),
        });

        const sub6 = PubSub.subscribe(
          `live_event_notifications_${userDetails.organization_id}`,
        ).subscribe({
          next: (data: any) => {
            liveEventNotificationsForOrdHandler(JSON.stringify(data.value));
          },
          error: (error: any) => console.error(error),
        });

        const sub7 = PubSub.subscribe(
          `guardrail_triggered_${globalSelectedTractorId}`,
        ).subscribe({
          next: (data: any) => {
            guardrailTriggeredForTractorHandler(JSON.stringify(data.value));
          },
          error: (error: any) => console.error(error),
        });
        // tractor_id -> tractor name
        // connection -> 'online' or 'offline'
        // tractor_serial_number -> tractor serial number
        // tractor_name -> tractor name
        const sub8 = PubSub.subscribe(
          `autodrive_tractor_status_${userDetails.organization_id}`,
        ).subscribe({
          next: (data: any) => {
            handleConnectivityLostInterrupt(JSON.stringify(data.value));
          },
          error: (error: any) => console.error(error),
        });

        return () => {
          sub1.unsubscribe();
          sub2.unsubscribe();
          sub3.unsubscribe();
          sub4.unsubscribe();
          sub5.unsubscribe();
          sub6.unsubscribe();
          sub7.unsubscribe();
          sub8.unsubscribe();
        };
      }
    } catch (error) {
      console.log(error);
    }
  }, [tractorsInitialized, userDetails, heartBeatTopic, isOnline]);

  /**
   * Draw Tractor Path Ends Here
   */
  const handleTractorSteam = (message: any) => {
    const tractorLocation = JSON.parse(message.toString());
    if (
      tractorLocation &&
      tractorLocation.tractor_id == globalSelectedTractorId
    ) {
      const rvTractor = tractorsMap.get(tractorLocation.tractor_id);
      if (!checkTractorDrivingState(rvTractor, userDetails.id)) {
        return;
      }
      const id = `${POSE_MODEL_STREAMING}${tractorLocation.tractor_id}`;
      globalPointsStreamToStop = id;
      const data =
        tractorLocation && tractorLocation.message
          ? tractorLocation.message.toString()
          : '';
      const tractorLayer = tractorLayerRef.current;
      const data1 = data ? data.split(',') : [];
      if (tractorLayer?.getVisible() && data1 && data1.length > 6) {
        const feat: any = tractorLayerRef.current?.getSource();
        const feature: any = feat.getSource().getFeatureById(id);
        if (feature) {
          autoDriveDispatch({
            type: SET_VELOCITY,
            payload: Number(data1[6]),
          });
          const latLang = [data1[0], data1[1]];
          feature.setGeometry(new Point(proj.fromLonLat(latLang)));
          feature.set('latLang', latLang);
          feature.set('pointTypeBearing', Number(data1[5]));
          selectedOnTractor();
          const existingPath = plannerTractorPaths.current;
          if (
            existingPath &&
            existingPath.get(id) &&
            existingPath.get(id).length > 0
          ) {
            let arr = existingPath.get(id);
            // console.log('code hit', arr.length - 1);

            const lastItem = arr[arr.length - 1];
            arr.push({
              ...lastItem,
              points: [latLang],
            });
            if (arr.length > 1000) {
              arr = arr.slice(-200);
              // if (arr.length > 100) {
              // arr = arr.slice(-20);
              drawJumpAndGoHandler(tractorLocation.tractor_id);
            }
            plannerTractorPaths.current = existingPath.set(id, arr);

            drawPlannerTractorPath();
          }
        }
      }
    }
  };
  // handling the connectivity lost
  // connection -> 'online' or 'offline'
  const handleConnectivityLostInterrupt = (message: any) => {
    const resp: ConnectionData = JSON.parse(message.toString());
    const tractor_lost_connection: MosEventsResp = {
      category: AUTO_DRIVE_INTERRUPT_MESSAGE_CATEGORY,
      message: PAUSE,
      organization_id: userDetails.organization_id,
      tractor_id: resp.tractor_id,
      timestamp: Date.now(),
      metadata: {
        details: interruptMessageTypes.tractor_lost_connection,
        category: SYSTEM_PAUSE,
        type: HARD,
      },
    };
    if (
      resp &&
      resp.connection &&
      resp.connection.toLowerCase() === 'poor_connectivity' &&
      resp.tractor_id == globalSelectedTractorId
    ) {
      dispatch({
        type: SHOW_AUTO_DRIVE_POOR_CONNECTIVITY_LOADER,
        payload: true,
      });
    }
    if (
      resp &&
      resp.connection &&
      resp.connection.toLowerCase() === 'offline' &&
      resp.tractor_id == globalSelectedTractorId
    ) {
      dispatch({
        type: SHOW_AUTO_DRIVE_POOR_CONNECTIVITY_LOADER,
        payload: true,
      });
      const iMsgs = getInterruptsByTractor(
        globalAutoDriveInterruptMsgs,
        resp.tractor_id,
      );
      if (!iMsgs[interruptKey(tractor_lost_connection)]) {
        iMsgs[interruptKey(tractor_lost_connection)] = tractor_lost_connection;
        const gADM = setInterruptByTractor(
          globalAutoDriveInterruptMsgs,
          iMsgs,
          resp.tractor_id,
        );
        dispatch({
          type: SET_AUTO_DRIVE_INTERRUPT_MESSAGES,
          payload: { ...gADM },
        });
        // handleIndividualInterrupt(tractor_lost_connection);
      }
      if (globalAutoDriveTractorLostInterruptMsg === null) {
        autoDriveDispatch({
          type: SET_AUTO_DRIVE_TRACTOR_LOST_INTERRUPT_MESSAGES,
          payload: tractor_lost_connection,
        });
      }
      return;
    }
    if (
      resp &&
      resp.connection &&
      resp.connection.toLowerCase() === 'online' &&
      resp.tractor_id == globalSelectedTractorId
    ) {
      dispatch({
        type: SHOW_AUTO_DRIVE_POOR_CONNECTIVITY_LOADER,
        payload: false,
      });
      globalAutoDriveTractorLostInterruptMsg !== null &&
        autoDriveDispatch({
          type: SET_AUTO_DRIVE_TRACTOR_LOST_INTERRUPT_MESSAGES,
          payload: null,
        });
      const iMsgs = getInterruptsByTractor(
        globalAutoDriveInterruptMsgs,
        resp.tractor_id,
      );

      if (iMsgs[interruptKey(tractor_lost_connection)]) {
        delete iMsgs[interruptKey(tractor_lost_connection)];
        const gADM = setInterruptByTractor(
          globalAutoDriveInterruptMsgs,
          iMsgs,
          resp.tractor_id,
        );
        dispatch({
          type: SET_AUTO_DRIVE_INTERRUPT_MESSAGES,
          payload: { ...gADM },
        });
      }
    }
  };
  const mosEventsHandler = (message: any) => {
    const msg = JSON.parse(message.toString());
    const rAvTractor = tractorsMap.get(globalSelectedTractorId);
    if (!isPlatFromWeb(rAvTractor)) {
      return;
    }
    const { id: userId, organization_id } = userDetails;
    msg &&
      msg.category &&
      msg.category === AUTO_DRIVE_MONITOR_STATUS &&
      autoDriveMonitorStatusHandle(msg.tractor_id, msg.message);
    if (
      msg &&
      msg.category &&
      msg.category === PLANNING_MANAGER_REMOTE_AV_EXECUTION_FBK &&
      msg.message &&
      msg.organization_id == organization_id &&
      msg.message.toLowerCase() === 'finished'
    ) {
      if (
        globalSelectedTractorId == msg.tractor_id &&
        checkLoginUserAndTractorConnectUserSame(
          tractorsMap,
          msg.tractor_id,
          userId,
        )
      ) {
        // only displaying the feedback for particular tractor connected user only
        globalShowSideFeedBack[globalSelectedTractorId] = true;
        autoDriveDispatch({
          type: SHOW_SIDE_FEED_BACK,
          payload: {
            ...globalShowSideFeedBack,
          },
        });
      }

      dispatch({
        type: SET_SHOW_PRESENT_COMMAND,
        payload: '',
      });
      clearAll();
      // to show hide the start button added this code
      globalShowTractorStart[globalSelectedTractorId] = false;
      dispatch({
        type: SHOW_TRACTOR_START,
        payload: {
          ...globalShowTractorStart,
        },
      });
      globalShowTractorCommandLoader[globalSelectedTractorId] = false;
      dispatch({
        type: SHOW_TRACTOR_COMMAND_LOADER,
        payload: {
          ...globalShowTractorCommandLoader,
        },
      });
    }
    // auto drive logout handler
    msg &&
      msg.category &&
      msg.category === INTERNAL_MESSAGE &&
      msg.metadata &&
      msg.metadata.user_id &&
      userId &&
      msg.metadata.user_id === userId &&
      msg.message == AUTO_LOGOUT_CODE &&
      autoDriveTractorLogoutHandle(msg.user_id, msg);
    // interrupt handler
    interruptHandler(msg);
    handlingRemainingTime(msg);
    handlingRefillPosition(msg, globalSelectedTractorId);
  };
  const handlingRefillPosition = (msg: MosEventsResp, tractorId: number) => {
    // autodrive_metric_refill_pose2D
    // tractor must in auto drive
    // only display message when message from same organization
    if (tractorsMap.get(tractorId)) {
      const { organization_id, id } = userDetails;
      const rAvTractor = tractorsMap.get(tractorId);
      if (
        checkTractorDrivingState(rAvTractor, id) &&
        isRefillPositionTopic(msg, organization_id, tractorId)
      ) {
        if (msg.metadata.lat && msg.metadata.long) {
          const feat = new Feature({
            geometry: new Point(
              proj.fromLonLat([msg.metadata.long, msg.metadata.lat]),
            ),
          });
          feat.setId(9999);
          refillSeederLayerRefs?.current?.getSource().clear();
          refillSeederLayerRefs?.current?.getSource().addFeature(feat);
          localStorage.setItem(REFILL_SEEDER, JSON.stringify(msg));
        }
      }
    }
  };
  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      mapInitialized &&
      selectedTractorId
    ) {
      const refillStr = localStorage.getItem(REFILL_SEEDER);
      if (refillStr && refillStr.length > 0) {
        const refillObj = JSON.parse(refillStr);
        handlingRefillPosition(refillObj, selectedTractorId);
      }
    }
  }, [userDetails, mapInitialized, selectedTractorId]);
  const handlingRemainingTime = (msg: MosEventsResp) => {
    // autodrive_metric_estimated_time_arrival
    // tractor must in auto drive
    // only display message when message from same organization

    if (tractorsMap.get(globalSelectedTractorId)) {
      const { organization_id, id } = userDetails;
      const rAvTractor = tractorsMap.get(globalSelectedTractorId);
      if (
        checkTractorDrivingState(rAvTractor, id) &&
        isRemainingTimeTopic(msg, organization_id, globalSelectedTractorId) &&
        globalRemainingTime != msg?.metadata?.data
      ) {
        autoDriveDispatch({
          type: SET_REMAINING_TIME,
          payload: msg?.metadata?.data || 0,
        });
      }
    }
  };
  const otherFirmwareFault = (msgs: any) => {
    const { organization_id } = userDetails;
    const err: any =
      globalAutoDriveMonitorErrorCodes?.tractorId === globalSelectedTractorId &&
      globalAutoDriveMonitorErrorCodes?.errors?.length > 0
        ? globalAutoDriveMonitorErrorCodes.errors
        : [];
    if (err.length > 0) {
      const firmwareFaultOther: MosEventsResp = firmware_fault_other;
      firmwareFaultOther.organization_id = organization_id;
      firmwareFaultOther.tractor_id =
        globalAutoDriveMonitorErrorCodes.tractorId;
      firmwareFaultOther.timestamp = Date.now();
      firmwareFaultOther.metadata.errors = err;
      msgs[interruptKey(firmwareFaultOther)] = firmwareFaultOther;
    }
    return msgs;
  };
  const tractorLostConnectionInterrupt = (msgs: any) => {
    if (globalAutoDriveTractorLostInterruptMsg !== null) {
      msgs[interruptKey(globalAutoDriveTractorLostInterruptMsg)] =
        globalAutoDriveTractorLostInterruptMsg;
    }
    return msgs;
  };

  const interruptHandler = (msg: MosEventsResp) => {
    const { organization_id } = userDetails;
    const rAvTractor = tractorsMap.get(msg.tractor_id);
    if (rAvTractor) {
      // handleIndividualInterrupt(msg);
      // multiple interrupts start here
      if (
        msg &&
        msg.category &&
        msg.category === AUTO_DRIVE_INTERRUPT_MESSAGE_CATEGORY_ARRAY &&
        msg.organization_id &&
        msg.organization_id === organization_id &&
        msg.tractor_id
        // msg.tractor_id == globalSelectedTractorId
      ) {
        const interrupts = msg.metadata.interrupt;
        let newMsgs: any = {};
        newMsgs = otherFirmwareFault(newMsgs);
        // adding tractor lost connection interrupt to list
        newMsgs = tractorLostConnectionInterrupt(newMsgs);
        interrupts?.map((interrupt: MosEventsInterruptResp) => {
          const item: MosEventsResp = {
            ...msg,
            metadata: interrupt,
            message: interrupt.message,
          };

          // added to maintain mos cloud pause states start here
          if (
            item.metadata.category &&
            (item.metadata.category === MOS_CLOUD ||
              item.metadata.category === SYSTEM_PAUSE) &&
            item.message === PAUSE
          ) {
            newMsgs[interruptKey(item)] = item;
          }
        });
        const oldIMsgs = getInterruptsByTractor(
          globalAutoDriveInterruptMsgs,
          msg.tractor_id,
        );
        const oldKeys = Object.keys(oldIMsgs);
        const newKeys = Object.keys(newMsgs);
        if (findArrayDifference(oldKeys, newKeys)) {
          // const { id: userId } = userDetails;
          // getMosCloudPauseInterrupts(
          //   globalAutoDriveInterruptMsgs,
          //   msg.tractor_id,
          // ).length === 0 &&
          //   loginUserHaveInterruptsOrNot(
          //     globalAutoDriveInterruptMsgs,
          //     msg.tractor_id,
          //     rAvTractor,
          //     userId,
          //   ) === '' &&
          //   messageApi.open({
          //     type: 'success',
          //     content: 'Issue resolved, tractor resumed operation',
          //     duration: 2,
          //     className: 'toastPlace',
          //   });
          const gADM = setInterruptByTractor(
            globalAutoDriveInterruptMsgs,
            newMsgs,
            msg.tractor_id,
          );
          dispatch({
            type: SET_AUTO_DRIVE_INTERRUPT_MESSAGES,
            payload: { ...gADM },
          });
        }
      }
      // multiple interrupts ends here
    }
  };

  // const handleIndividualInterrupt = (msg: MosEventsResp) => {
  //   const { organization_id, id: userId } = userDetails;
  //   const rAvTractor = tractorsMap.get(globalSelectedTractorId);
  //   // only display the interrupt message when tractor in remote av (auto drive)
  //   // only display the interrupt message when category is 'autodrive_interrupt_msg'
  //   // only display the interrupt message when message from same organization
  //   // only display the interrupt message when message meta category is system_pause
  //   if (
  //     checkTractorDrivingState(rAvTractor, userId) &&
  //     msg &&
  //     msg.category &&
  //     msg.category === AUTO_DRIVE_INTERRUPT_MESSAGE_CATEGORY &&
  //     msg.organization_id &&
  //     msg.organization_id === organization_id &&
  //     msg.tractor_id &&
  //     msg.tractor_id == globalSelectedTractorId
  //   ) {
  //     // added to maintain mos cloud pause states start here
  //     if (
  //       msg.metadata.category &&
  //       msg.metadata.category === MOS_CLOUD &&
  //       msg.message === PAUSE &&
  //       msg.metadata.type === HARD &&
  //       !globalAutoDriveInterruptMsgs[interruptKey(msg)]
  //     ) {
  //       globalAutoDriveInterruptMsgs[interruptKey(msg)] = msg;
  //       dispatch({
  //         type: SET_AUTO_DRIVE_INTERRUPT_MESSAGES,
  //         payload: { ...globalAutoDriveInterruptMsgs },
  //       });
  //     }
  //     // added to maintain mos cloud pause states ends here

  //     if (msg.metadata.category && msg.metadata.category === SYSTEM_PAUSE) {
  //       if (
  //         msg.metadata.type === SOFT &&
  //         msg.message === RESUME &&
  //         globalAutoDriveInterruptMsgs[interruptPauseKey(msg)]
  //       ) {
  //         delete globalAutoDriveInterruptMsgs[interruptPauseKey(msg)];
  //         loginUserHaveInterruptsOrNot(
  //           globalAutoDriveInterruptMsgs,
  //           globalSelectedTractorId,
  //           rAvTractor,
  //           userId,
  //         ) === '' &&
  //           messageApi.open({
  //             type: 'success',
  //             content: 'Issue resolved, tractor resumed operation',
  //             duration: 2,
  //             className: 'toastPlace',
  //           });

  //         dispatch({
  //           type: SET_AUTO_DRIVE_INTERRUPT_MESSAGES,
  //           payload: { ...globalAutoDriveInterruptMsgs },
  //         });
  //       } else if (
  //         msg.message === PAUSE &&
  //         !globalAutoDriveInterruptMsgs[interruptKey(msg)]
  //       ) {
  //         //  accepting hard/soft pause messages
  //         globalAutoDriveInterruptMsgs[interruptKey(msg)] = msg;
  //         dispatch({
  //           type: SET_AUTO_DRIVE_INTERRUPT_MESSAGES,
  //           payload: { ...globalAutoDriveInterruptMsgs },
  //         });
  //       } else if (
  //         msg.metadata.type === HARD &&
  //         msg.message === RESUME &&
  //         msg.metadata.details === interruptMessageTypes.implement_refill &&
  //         globalAutoDriveInterruptMsgs[interruptPauseKey(msg)]
  //       ) {
  //         // if we get hard resume implement refill => delete hard pause
  //         delete globalAutoDriveInterruptMsgs[interruptPauseKey(msg)];
  //         dispatch({
  //           type: SET_AUTO_DRIVE_INTERRUPT_MESSAGES,
  //           payload: { ...globalAutoDriveInterruptMsgs },
  //         });
  //       }
  //     }
  //   }
  // };

  const setTractorsOfflineStatus = (tractorId: number) => {
    const sTractors = globalSelectedTractors;
    if (sTractors && sTractors.length > 0) {
      for (const tractor of sTractors) {
        if (tractor && tractor.id && tractorId && tractor.id == tractorId) {
          tractor.isAvailable = tractorConstantsNew.off.key;
          // to update tractor details
          globalSelectedTractorId &&
            globalSelectedTractorId == tractor.id &&
            dispatch({
              type: SELECTED_TRACTOR,
              payload: tractor,
            });
          dispatch({
            type: SELECTED_TRACTORS,
            payload: sTractors,
          });
        }
      }
    }
    let tractorsConst: TractorsList[] = globalTractorList;
    if (tractorsConst && tractorsConst.length > 0) {
      for (const tractor of tractorsConst) {
        if (tractor && tractor.id && tractorId && tractor.id == tractorId) {
          tractor.isAvailable = tractorConstantsNew.off.key;
          tractorsConst = alignItems(tractorsConst, userDetails.id);
          appDispatch({
            type: SET_TRACTORS_LIST,
            payload: tractorsConst,
          });
        }
      }
    }
  };
  // to handle the offline tractor status
  const tractorOfflineStatusHandler = (message: any) => {
    const feat: any = tractorLayerRef.current?.getSource();
    const status = JSON.parse(message.toString());
    const { id } = status;
    setTractorsOfflineStatus(id);
    const feature = feat
      ?.getSource()
      .getFeatureById(`${POSE_MODEL_STREAMING}${id}`);
    const pointTypeBattery =
      feature?.get('pointTypeBattery') > 0
        ? feature?.get('pointTypeBattery')
        : 0;

    const drive_action_created_at = feature?.get('drive_action_created_at')
      ? feature?.get('drive_action_created_at')
      : '';
    const tractorName = feature?.get('name') ? feature?.get('name') : '';
    const isAvailable = feature?.get('isAvailable')
      ? feature?.get('isAvailable')
      : '';
    const isFaulty = false; // offline tractor not have faulty option
    const isSelected = isTractorSelected(id);
    const pointTypeIsSelectedForToolTip = isTractorSelected(id);
    const style = getTractorIcon(
      TractorIconConstant.OFFLINE,
      false,
      0,
      '',
      isSelected,
      false,
      false,
      pointTypeBattery,
      false,
      tractorName,
      pointTypeIsSelectedForToolTip,
      id,
      isFaulty,
      isAvailable,
      drive_action_created_at,
    );
    const hoverStyle = getTractorIcon(
      TractorIconConstant.OFFLINE,
      true,
      0,
      '',
      isSelected,
      false,
      false,
      pointTypeBattery,
      false,
      tractorName,
      pointTypeIsSelectedForToolTip,
      id,
      isFaulty,
      isAvailable,
      drive_action_created_at,
    );

    feature?.setStyle(style);
    feature?.set('style', style);
    feature?.set('hoverStyle', hoverStyle);
    // on click change icon
    feature?.set('pointTypeSelected', TractorIconConstant.OFFLINE);
    feature?.set('pointTypeIni', '');
    feature?.set('pointTypeBearing', 0);
    feature?.set('pointTypeBattery', 0);
  };
  // to handle the monitor status error
  const autoDriveErrorHandler = (message: any) => {
    const msg = JSON.parse(message.toString());
    const rAvTractor = tractorsMap.get(globalSelectedTractorId);
    if (!isPlatFromWeb(rAvTractor)) {
      return;
    }
    if (globalSelectedTractorId == msg.tractor_id && msg && msg.message) {
      let clearErrors = false;
      if (msg.message == CLEAR_COMMAND) {
        clearErrors = true;
      }
      // setting auto-drive error from ot
      dispatch({
        type: AUTO_DRIVE_ERROR,
        payload: {
          ...msg,
          errors: clearErrors ? [] : [msg.message],
        },
      });
    }
  };
  const guardrailTriggeredForTractorHandler = (message: any) => {
    const pathData = message.toString().split(',');
    let guardRail = pathData[0];
    guardRail = guardRail.replace(/_/g, ' ');
    const triggerState = pathData.length === 7 ? pathData[6] : '';
    guardRailsCheck(guardRail, triggerState.trim());
  };
  const liveEventNotificationsForOrdHandler = (message: any) => {
    const messageData = message.toString().trim();
    try {
      const jsonData = JSON.parse(messageData);
      if (jsonData && jsonData.title && jsonData.title === 'guard_rails') {
        setTractorGuardRailsPoints(jsonData);
      }
      if (jsonData && jsonData.title && jsonData.title === 'Indicator') {
        setTractorIndicatorsPoints(jsonData);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
  const remoteAvPreviewForOrgHandler = (message: any) => {
    const preview = JSON.parse(message.toString());
    if (preview && preview.tractor_id) {
      const previewPath =
        preview && preview.message ? preview.message.toString() : '';
      // after getting preview path we will show start auto drive button
      // only displaying preview if tractor selected
      if (preview.tractor_id == globalSelectedTractorId) {
        globalShowTractorCommandLoader[preview.tractor_id] = false;
        dispatch({
          type: SHOW_TRACTOR_COMMAND_LOADER,
          payload: { ...globalShowTractorCommandLoader },
        });
        globalShowTractorStart[preview.tractor_id] = true;
        dispatch({
          type: SHOW_TRACTOR_START,
          payload: { ...globalShowTractorStart },
        });
        if (
          globalShowPresentCommand === 'GOTO' &&
          globalActiveAutoDriveTab !== AutoDriveTabs.Block
        ) {
          resetForMapClicks('');
        }
        if (previewPath && previewPath.length > 0) {
          // clear the routine
          clearRoutine();
          handlePreview(previewPath, Number(preview.tractor_id));
        }
      }
    }
  };
  const heartBeatHandler = (message: any) => {
    const heartBeat: TractorHeartBeat = JSON.parse(message.toString());
    if (
      heartBeat &&
      heartBeat.organization_id &&
      userDetails.organization_id &&
      heartBeat.organization_id === userDetails.organization_id
    ) {
      if (!heartBeat?.localization) {
        return;
      }
      // heartBeat.autodrive_monitor_status = '76200,76150';
      // heartBeat.autodrive_monitor_status =
      //   '7001,7002,7004,7011,76001, 76002, 76003, 76004, 76005, 76006, 76007, 76008, 76009, 76010, 76011,76012, 76013, 76014, 76015, 76016, 76017, 76018, 76019, 76020, 76021, 76022,  76023, 76024, 76025, 76026, 76027, 76028, 76029, 76030, 76031, 76032';

      autoDriveMonitorStatusHandle(
        heartBeat.tractor_id,
        heartBeat.autodrive_monitor_status,
      );
      setHeartBeat(heartBeat);
      // clear preview path with heartbeat
      // if no jump and go clear preview path
      if (
        !(
          heartBeat.drive_action_details &&
          heartBeat.drive_action_details.current_drive_action &&
          heartBeat.drive_action_details.current_drive_action.length > 0
        )
      ) {
        clearPreviewPath(heartBeat.tractor_id);
      }
      // drawing preview path using planner ends here
      const id = `${POSE_MODEL_STREAMING}${heartBeat.tractor_id}`;
      tractorsMap.set(heartBeat.tractor_id, heartBeat);
      const list: any = [];
      const entries = Array.from(tractorsMap.entries());
      if (entries && entries.length > 0) {
        entries.forEach((item: any) => {
          list.push(item[1]);
        });
      }
      // maintain the all tractors heartbeat in app context
      autoDriveDispatch({
        type: LOAD_REMOTE_DRIVE_TRACTORS,
        payload: list,
      });
      const tractorLayer = tractorLayerRef.current;
      if (tractorLayer?.getVisible()) {
        const feat: any = tractorLayerRef.current?.getSource();
        const feature: any = feat.getSource().getFeatureById(id);
        const tractorTransparent =
          !setUpKeysGlobal.isFirstTime &&
          globalShowPresentCommand === 'GOTO' &&
          preCommandGlobal === commands.ROW_FOLLOW &&
          commandForSecondTime !== commands.ROW_FOLLOW_SECOND
            ? true
            : false;
        // on start point select remove the tractor live path and jump and go path
        if (tractorTransparent) {
          clearTractorPaths(
            id,
            heartBeat?.planning_manager?.planner_name,
            heartBeat.tractor_name,
            heartBeat.date_time,
          );
          if (tractorTraveledPathLayerRef.current) {
            const defaultLayer = tractorTraveledPathLayerRef.current;
            defaultLayer?.getSource().clear();
          }
        }
        const pointTypeLowLocalization = isLowLocalization(
          heartBeat.localization,
        );
        // pointTypeLowLocalization = true;
        const isFaulty =
          getOperationNewStatus(heartBeat) === tractorConstantsNew.faulty.key
            ? true
            : false;
        // isFaulty = true;

        let latLang: number[] = [
          heartBeat.localization.longitude,
          heartBeat.localization.latitude,
        ];
        // don't draw tractor path if tractor in low localization or faulty
        if (!pointTypeLowLocalization && !isFaulty) {
          // in path key we have the tractor moved path
          if (heartBeat.path && heartBeat.path.length > 0) {
            let listForPath: any = heartBeat.path.split('|');
            listForPath = listForPath.map((listItem: any) =>
              listItem.split(','),
            );
            listForPath = listForPath.map((listItem: any[]) =>
              listItem.map(Number),
            );
            let latLangs;
            if (
              listForPath &&
              listForPath.length > 0 &&
              listForPath[0].length > 4
            ) {
              // taking lat langs instead of x and y
              latLangs = listForPath.map((item: any) => [item[4], item[3]]);
            } else {
              // if not lat lang take x and y
              latLangs = convertXyToLatLang(
                listForPath,
                globalBase[0],
                globalBase[1],
              );
            }
            if (latLangs) {
              let operatorName = '-';
              if (heartBeat?.drive_action_details) {
                const { operator_firstname, operator_lastname } =
                  heartBeat.drive_action_details;
                if (operator_firstname || operator_lastname)
                  operatorName = operator_firstname + ' ' + operator_lastname;
              }
              const avg_speed =
                heartBeat &&
                heartBeat?.localization &&
                heartBeat?.localization?.velocity;
              setPlannerTractorPaths(
                id,
                heartBeat?.planning_manager?.planner_name,
                latLangs,
                heartBeat.tractor_name,
                heartBeat.date_time,
                operatorName,
                avg_speed,
              );
              latLang = latLangs[latLangs.length - 1];
            }
          } else {
            // to draw all tractors path maintain points
            if (
              heartBeat?.localization?.longitude &&
              heartBeat?.localization?.latitude
            ) {
              setTractorPoints(id, latLang);
            } else {
              latLang = [];
            }
          }
        }
        if (feature && latLang && latLang.length) {
          const pointTypeBearing = heartBeat.localization.yaw;
          let pointTypeIni = '';
          let pointTypeSelected = TractorIconConstant.ONLINE;
          const isSelected = isTractorSelected(heartBeat.tractor_id);
          const pointTypeIsSelectedForToolTip = isTractorSelected(
            heartBeat.tractor_id,
          );
          let pointTypeIsMoving = false;

          const pointTypeBattery = batteryValue({
            heartbeat: heartBeat,
          });
          const tractorName = feature.get('name') ? feature.get('name') : '';
          if (
            heartBeat.drive_action_details &&
            heartBeat?.drive_action_details.operator_firstname &&
            heartBeat?.drive_action_details.operator_lastname
          ) {
            pointTypeIni = getInitials(
              heartBeat?.drive_action_details.operator_firstname,
              heartBeat?.drive_action_details.operator_lastname,
            );
          }
          // checking the low localization status
          // faulty and low localization tractor not update tractor location
          if (!pointTypeLowLocalization && !isFaulty) {
            // only update when not low localization
            pointTypeIsMoving = true;
            feature.setGeometry(new Point(proj.fromLonLat(latLang)));
            feature.set('latLang', latLang);
            if (
              allowLiveStream &&
              latLang &&
              latLang.length > 0 &&
              latLang[0] != undefined &&
              heartBeat.tractor_id == globalSelectedTractorId
            ) {
              // mapRef?.current?.getView().setCenter(proj.fromLonLat(latLang));
              centerTheLocation(latLang, mapRef.current);
            }
          }

          // tractor in charging state
          if (
            getOperationNewStatus(heartBeat as TractorHeartBeat) ===
            tractorConstantsNew.charging.key
          ) {
            pointTypeSelected = TractorIconConstant.CHARGING;
            clearTractorPaths(
              id,
              heartBeat?.planning_manager?.planner_name,
              heartBeat.tractor_name,
              heartBeat.date_time,
            );
          }
          const isAvailable = getOperationNewStatus(
            heartBeat as TractorHeartBeat,
          );
          const drive_action_created_at = heartBeat?.drive_action_details
            ?.drive_action_created_at as string;
          // normal tractor style
          const style = getTractorIcon(
            pointTypeSelected,
            false,
            pointTypeBearing,
            pointTypeIni,
            isSelected,
            pointTypeIsMoving,
            globalZoom > onGlobalZoomToShowZoomIcons,
            pointTypeBattery,
            pointTypeLowLocalization,
            tractorName,
            pointTypeIsSelectedForToolTip,
            heartBeat.tractor_id,
            isFaulty,
            isAvailable,
            drive_action_created_at,
            tractorTransparent,
          );
          // tractor on hover
          const hoverStyle = getTractorIcon(
            pointTypeSelected,
            true,
            pointTypeBearing,
            pointTypeIni,
            isSelected,
            pointTypeIsMoving,
            globalZoom > onGlobalZoomToShowZoomIcons,
            pointTypeBattery,
            pointTypeLowLocalization,
            tractorName,
            pointTypeIsSelectedForToolTip,
            heartBeat.tractor_id,
            isFaulty,
            isAvailable,
            drive_action_created_at,
            tractorTransparent,
          );
          feature.setStyle(style);
          feature.set('style', style);
          feature.set('hoverStyle', hoverStyle);

          feature.set('pointTypeIni', pointTypeIni);
          feature.set('pointTypeSelected', pointTypeSelected);
          feature.set('pointTypeBearing', pointTypeBearing);
          feature.set('pointTypeBattery', pointTypeBattery);
          feature.set('pointTypeIsMoving', pointTypeIsMoving);
          feature.set('pointTypeLowLocalization', pointTypeLowLocalization);
          feature.set('isFaulty', isFaulty);
          feature.set('isAvailable', isAvailable);
          feature.set('drive_action_created_at', drive_action_created_at);
          // using for getting tractor x, y and direction
          const tractorPosition = getPosition(heartBeat);
          feature.set('tractorPosition', tractorPosition);
          //  tractor transparent icon setup
          feature.set('tractorTransparent', tractorTransparent);
          setSelectedTractorLoc(heartBeat.tractor_id, feature);
        }
      }
    }
  };
  const setSelectedTractorLoc = (id: number, feature: any) => {
    if (globalSelectedTractorId === id && globalShowRoutine) {
      dispatch({
        type: SET_SELECTED_TRACTOR_LOCATION,
        payload: null,
      });
      setTimeout(() => {
        dispatch({
          type: SET_SELECTED_TRACTOR_LOCATION,
          payload: feature,
        });
      }, 100);
    }
  };
  // handling auto drive monitor status starts here
  const autoDriveMonitorStatusHandle = (tractorId: number, message: string) => {
    if (globalSelectedTractorId == tractorId) {
      if (message && message.length > 0) {
        let errors = message.split(',').map((element: string) => {
          return Number(element);
        });
        const rvTractor = tractorsMap.get(tractorId);
        if (rvTractor) {
          const { id: userId } = userDetails;
          if (checkTractorDrivingState(rvTractor, userId)) {
            //  after connect consider these only monitor errors
            errors = errors.filter((error) =>
              checkTheseErrorCodesAfterRemoteAvStart.includes(error),
            );
          } else {
            // const isHave7011 = errors
            // in error contain 7011 dont check below error codes
            if (errors.includes(7011)) {
              errors = errors.filter(
                (error) => !removeBelowErrorIf7011.includes(error),
              );
            }
            // dont check these error codes before tractor connect
            errors = errors.filter(
              (error) => !doNotCheckTheseCodesBeforeConnect.includes(error),
            );
          }
          if (
            globalAutoDriveMonitorErrorCodes &&
            globalAutoDriveMonitorErrorCodes.tractorId == tractorId
          ) {
            if (globalAutoDriveMonitorErrorCodes.message !== message) {
              otherFirmwareFaultRemove(tractorId, errors);
              dispatch({
                type: AUTO_DRIVE_MONITOR_ERROR_CODES,
                payload: {
                  message,
                  errors,
                  tractorId,
                },
              });
            }
          } else {
            otherFirmwareFaultRemove(tractorId, errors);
            dispatch({
              type: AUTO_DRIVE_MONITOR_ERROR_CODES,
              payload: { message, errors, tractorId },
            });
          }
        }
      } else {
        // clear the error codes if not get message
        otherFirmwareFaultRemove(tractorId, []);
        dispatch({
          type: AUTO_DRIVE_MONITOR_ERROR_CODES,
          payload: {
            message: '',
            errors: [],
            tractorId,
          },
        });
      }
    }
  };
  const otherFirmwareFaultRemove = (tractorId: number, errors: any[]) => {
    const { organization_id } = userDetails;
    const firmwareFaultOther: MosEventsResp = firmware_fault_other;
    if (tractorId === globalSelectedTractorId) {
      firmwareFaultOther.organization_id = organization_id;
      firmwareFaultOther.tractor_id = tractorId;
      firmwareFaultOther.timestamp = Date.now();
      firmwareFaultOther.metadata.errors = errors;
      const iMsgs = getInterruptsByTractor(
        globalAutoDriveInterruptMsgs,
        tractorId,
      );
      if (errors && errors.length > 0) {
        if (!iMsgs[interruptKey(firmwareFaultOther)]) {
          iMsgs[interruptPauseKey(firmwareFaultOther)] = firmwareFaultOther;
          const gADM = setInterruptByTractor(
            globalAutoDriveInterruptMsgs,
            iMsgs,
            tractorId,
          );
          dispatch({
            type: SET_AUTO_DRIVE_INTERRUPT_MESSAGES,
            payload: { ...gADM },
          });
        }
      } else {
        if (iMsgs[interruptKey(firmwareFaultOther)]) {
          delete iMsgs[interruptPauseKey(firmwareFaultOther)];
          const gADM = setInterruptByTractor(
            globalAutoDriveInterruptMsgs,
            iMsgs,
            tractorId,
          );
          dispatch({
            type: SET_AUTO_DRIVE_INTERRUPT_MESSAGES,
            payload: { ...gADM },
          });
        }
      }
    }
  };
  // handling auto drive monitor ends starts here
  // automatically logout the user from tractor from iot event
  const autoDriveTractorLogoutHandle = (userId: number, message: any) => {
    resetForMapClicks('');
    dispatch({
      type: AUTO_DRIVE_LOGOUT_ERROR_CODES,
      payload: {
        message,
        errors: [AUTO_LOGOUT_CODE],
        userId,
      },
    });
  };

  // clearing the tractor path start here
  const clearTractorPaths = async (
    id: string,
    planner: string,
    tractorName: string,
    created_date_time: number,
  ) => {
    const existingPath = plannerTractorPaths.current;
    if (existingPath) {
      existingPath.set(id, [
        {
          planner,
          planner_name: planner,
          tractorName,
          created_date_time,
          points: [],
        },
      ]);
      plannerTractorPaths.current = existingPath;
    } else {
      const existingPath = new Map<string, any>();
      const arr = [
        {
          planner,
          planner_name: planner,
          tractorName,
          created_date_time,
          points: [],
        },
      ];
      existingPath.set(id, arr);
      plannerTractorPaths.current = existingPath;
    }
    drawPlannerTractorPath();
  };
  // clearing the tractor path ends here
  // handling the guard rails points from streaming start here
  const setTractorGuardRailsPoints = (points: GuardRailType) => {
    const existingGuardRails = tractorsGuardrailMap?.current;
    const id = `${points.tractor_id}`;
    if (existingGuardRails) {
      const path = existingGuardRails.get(id);
      if (path && path.length > 0) {
        path?.push(points);
        existingGuardRails.set(id, path);
        tractorsGuardrailMap.current = existingGuardRails;
      } else {
        existingGuardRails.set(id, [points]);
        tractorsGuardrailMap.current = existingGuardRails;
      }
    } else {
      const existingPath = new Map<string, any>();
      existingPath.set(id, [points]);
      tractorsGuardrailMap.current = existingPath;
    }
    drawGuardRailsFromStream();
  };
  // handling the guard rails points from streaming ends here

  // handling the indicator points from streaming start here

  const setTractorIndicatorsPoints = (points: GuardRailType) => {
    const existingIndicators = tractorsIndicatorMap?.current;
    const id = `${points.tractor_id}`;
    if (existingIndicators) {
      const path = existingIndicators.get(id);
      if (path && path.length > 0) {
        path?.push(points);
        existingIndicators.set(id, path);
        tractorsIndicatorMap.current = existingIndicators;
      } else {
        existingIndicators.set(id, [points]);
        tractorsIndicatorMap.current = existingIndicators;
      }
    } else {
      const existingPath = new Map<string, any>();
      existingPath.set(id, [points]);
      tractorsIndicatorMap.current = existingPath;
    }
    drawIndicatorsFromStreams();
  };
  // handling the indicator points from streaming ends here

  /**
   * Draw  planner Tractor Path starts Here
   */
  const drawPlannerTractorPath = () => {
    const defaultLayer = planerTractorPathLayerRef.current;
    defaultLayer?.setVisible(globalShowPath);
    defaultLayer?.getSource().clear();
    const existingPath = plannerTractorPaths.current;
    if (existingPath) {
      existingPath.forEach((value, key) => {
        let lastElement: any;
        value.forEach((element: any, index: number) => {
          const color = getPathColor(element.planner);
          const borderColor = getPathBorderColor(element.planner);
          const style = getPathFeatureStyle(element.planner, getZoomValue());
          const coordinates: Coordinate[] = [];
          // if previous and present planner same add last planner point to latest planner
          if (
            lastElement &&
            lastElement.points &&
            lastElement.points.length > 0 &&
            element &&
            element.points &&
            element.points.length > 0
          ) {
            const allowToCombinePath = calPtsDistanceActualAllow([
              {
                latitude: lastElement.points[lastElement.points.length - 1][1],
                longitude: lastElement.points[lastElement.points.length - 1][0],
              },
              {
                latitude: element.points[0][1],
                longitude: element.points[0][0],
              },
            ]);
            const point = lastElement.points[lastElement.points.length - 1];
            if (allowToCombinePath) {
              coordinates.push(proj.fromLonLat([point[0], point[1]]));
            } else {
              const coordinates = proj.fromLonLat([point[0], point[1]]);
              const startingPathPoint = setBadLocalization(
                coordinates,
                globalShowBadLocalization,
                {
                  ...lastElement,
                  next_created_date_time: element.created_date_time,
                },
              );
              defaultLayer?.getSource().addFeature(startingPathPoint);
            }
          }
          element &&
            element.points &&
            element.points.forEach((item: any) => {
              coordinates.push(proj.fromLonLat([item[0], item[1]]));
            });
          let tractorLocation: Feature<Geometry> = new Feature();
          if (defaultLayer) {
            tractorLocation = defaultLayer
              ?.getSource()
              .getFeatureById(key + index);
          }
          if (!tractorLocation) {
            tractorLocation = new Feature();
          }
          tractorLocation.setGeometry(new LineString(coordinates));
          tractorLocation.setId(key + index);
          tractorLocation.set('color', color);
          tractorLocation.set('pointType', 'LIVEPATH');
          tractorLocation.set('borderColor', borderColor);
          tractorLocation.set('drawPointsText', element);
          allTractorsList.forEach((feature: { mapId: string; name: any }) => {
            if (feature.mapId && key && feature.mapId === key) {
              tractorLocation.set('name', feature.name);
            }
          });
          tractorLocation.setStyle(style);
          tractorLocation.set('style', style);
          tractorLocation.set('hoverStyle', style);
          defaultLayer?.getSource().addFeature(tractorLocation);
          // added last planner data
          lastElement = element;
        });
      });
    }
  };

  const setPlannerTractorPaths = async (
    id: string,
    planner: string,
    passedPoints: [number, number][],
    tractorName: string,
    created_date_time: number,
    operatorName?: string,
    avg_speed?: number,
  ) => {
    const existingPath = plannerTractorPaths.current;
    let arrData: any = [];
    const mainArrData: any = [];
    let pushIndex = 0;

    if (passedPoints && passedPoints.length > 0) {
      passedPoints.slice(1).forEach((v: [number, number], i) => {
        const allowToCombinePath = calPtsDistanceActualAllow([
          { latitude: passedPoints[i][1], longitude: passedPoints[i][0] },
          {
            latitude: v[1],
            longitude: v[0],
          },
        ]);
        if (allowToCombinePath) {
          arrData.push(v);
          mainArrData[pushIndex] = arrData;
          return v;
        } else {
          if (arrData && arrData.length > 0) {
            arrData[arrData.length - 1] = {
              ...arrData[arrData.length - 1],
            };
            mainArrData[pushIndex] = arrData;
            pushIndex = pushIndex + 1;
          }
          arrData = [];
        }
      });
    }
    mainArrData.map((points: any) => {
      if (globalPointsStreamToStop === id) {
        points = [];
      }
      if (existingPath) {
        if (existingPath.get(id)) {
          const arr = existingPath.get(id);
          arr.push({
            planner,
            planner_name: planner,
            tractorName,
            operatorName,
            avg_speed,
            created_date_time,
            points,
          });
          existingPath.set(id, arr);
          plannerTractorPaths.current = existingPath;
        } else {
          existingPath.set(id, [
            {
              planner,
              planner_name: planner,
              tractorName,
              operatorName,
              avg_speed,
              created_date_time,
              points,
            },
          ]);
          plannerTractorPaths.current = existingPath;
        }
      } else {
        const existingPath = new Map<string, any>();
        const arr = [
          {
            planner,
            planner_name: planner,
            tractorName,
            operatorName,
            avg_speed,
            created_date_time,
            points,
          },
        ];
        existingPath.set(id, arr);
        plannerTractorPaths.current = existingPath;
      }
    });
    drawPlannerTractorPath();
  };
  /**
   * Draw  planner Tractor Path ends Here
   */
  const setTractorPoints = (id: string, points: number[]) => {
    const existingPath = tractorPaths.current;
    if (existingPath) {
      let path = existingPath.get(id);
      if (path) {
        if (path.length > 5000) {
          path = path.splice(2500);
        }
        path?.push([points[0], points[1]]);
        existingPath.set(id, path);
        tractorPaths.current = existingPath;
      } else {
        existingPath.set(id, [points]);
        tractorPaths.current = existingPath;
      }
    } else {
      const existingPath = new Map<string, number[][]>();
      existingPath.set(id, [points]);
      tractorPaths.current = existingPath;
    }
    drawTractorPath();
  };

  const drawTractorPath = () => {
    const tractorStyle = new Style({
      image: new CircleStyle({
        radius: 3,
        fill: new Fill({
          color: pathColorConstants.DEFAULT,
        }),
      }),
      stroke: new Stroke({
        color: pathColorConstants.DEFAULT,
        width: globalZoom > onGlobalZoomToShowZoomIcons ? 9 : 3,
      }),
      fill: new Fill({
        color: pathColorConstants.DEFAULT,
      }),
      zIndex: 400,
    });

    const existingPath = tractorPaths.current;
    const defaultLayer = tractorPathLayerRef.current;
    defaultLayer?.setVisible(globalShowPath);
    defaultLayer?.getSource().clear();
    if (existingPath) {
      existingPath.forEach((value, key) => {
        let coordinates: Coordinate[] = [];
        value.forEach((item) => {
          coordinates.push(proj.fromLonLat([item[0], item[1]]));
        });
        if (coordinates.length > 2500) {
          coordinates = coordinates.splice(1000);
        }
        let tractorLocation: Feature<Geometry> = new Feature();
        if (defaultLayer) {
          tractorLocation = defaultLayer?.getSource().getFeatureById(key);
        }
        if (!tractorLocation) {
          tractorLocation = new Feature();
        }
        tractorLocation.setGeometry(new MultiPoint(coordinates));
        tractorLocation.setId(key);
        allTractorsList.forEach((feature: { mapId: string; name: any }) => {
          if (feature.mapId && key && feature.mapId === key) {
            tractorLocation.set('name', feature.name);
          }
        });
        tractorLocation.setStyle(tractorStyle);
        defaultLayer?.setZIndex(0);
        defaultLayer?.getSource().addFeature(tractorLocation);
      });
    }
  };

  const initTravelledPath = async (tractorId: number, tractors?: any[]) => {
    // console.log('tractorId', tractorId);
    setRecenterTabId(Number(tractorId));
    setSelectedTractorId(Number(tractorId));
    // clearing the refill seeder
    if (refillSeederLayerRefs && refillSeederLayerRefs.current) {
      refillSeederLayerRefs?.current?.getSource().clear();
    }
    //clearing jump and go path
    if (tractorTraveledPathLayerRef.current) {
      const defaultLayer = tractorTraveledPathLayerRef.current;
      defaultLayer?.getSource().clear();
    }
    //clearing jump and go Indicators
    if (mapIndicatorRefs.current) {
      const defaultLayer1 = mapIndicatorRefs.current;
      defaultLayer1?.getSource().clear();
      mapRef.current?.removeLayer(mapIndicatorRefs.current);
    }
    //clearing jump and go gurd rails
    if (mapGuardRailsRefs.current) {
      const defaultLayer = mapGuardRailsRefs.current;
      defaultLayer?.getSource()?.getSource()?.clear();
      // mapRef.current?.removeLayer(mapGuardRailsRefs.current);
    }
    // clearing guard rails from stream
    if (mapGuardRailsFromStreamRefs.current) {
      const defaultLayer = mapGuardRailsFromStreamRefs.current;
      defaultLayer?.getSource().clear();
      mapRef.current?.removeLayer(mapGuardRailsFromStreamRefs.current);
    }
    const tractorsGuardrail = tractorsGuardrailMap?.current;
    tractorsGuardrail && tractorsGuardrail.set(`${tractorId}`, []);
    if (tractorsGuardrail) tractorsGuardrailMap.current = tractorsGuardrail;
    // clearing indicators from stream
    if (mapIndicatorFromStreamRefs.current) {
      const defaultLayer = mapIndicatorFromStreamRefs.current;
      defaultLayer?.getSource().clear();
      mapRef.current?.removeLayer(mapIndicatorFromStreamRefs.current);
    }
    const tractorsIndicator = tractorsIndicatorMap?.current;
    tractorsIndicator && tractorsIndicator.set(`${tractorId}`, []);
    if (tractorsIndicator) tractorsIndicatorMap.current = tractorsIndicator;
    // clearing all preview paths
    pathLayerRef?.current?.getSource().clear();

    // if no tractor don't get the data about tractor
    if (!tractorId) {
      return;
    }
    const [tractorDetails] = selectedTractors;
    if (tractorDetails && tractorDetails.id && tractorDetails.id == tractorId) {
      if (
        tractorDetails?.heartbeat &&
        tractorDetails?.heartbeat?.planning_manager &&
        tractorDetails?.heartbeat?.planning_manager.planner_name &&
        tractorDetails?.heartbeat?.drive_action_details &&
        tractorDetails?.heartbeat?.drive_action_details.current_drive_action
      ) {
        onAutoDriveStart(
          tractorDetails?.heartbeat?.drive_action_details?.current_drive_action,
          tractorDetails.id,
        );
      }
    }
    let allow = false;
    if (tractors && tractors.length) {
      for (const tractor of tractors) {
        const hB = tractor.heartbeat;
        if (
          hB &&
          hB.tractor_id == tractorId &&
          hB.drive_action_details &&
          hB.drive_action_details.current_drive_action &&
          hB.drive_action_details.current_drive_action.length > 0
        ) {
          allow = true;
        }
      }
    }
    if (!allow) return;
    try {
      if (userDetails && userDetails.organization) {
        getIndicators(tractorId);
        getGuardRails(Number(tractorId));
        drawJumpAndGoHandler(Number(tractorId));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
  const drawJumpAndGoHandler = async (tractorId: number) => {
    const { organization } = userDetails;
    const response = await getTractorTraveledPointsBasedOnPlanner(
      organization.api_url,
      tractorId,
    );
    if (response && response.records && response.records.length > 0) {
      let operatorName = '-';
      if (response?.operator) {
        const { first_name, last_name } = response.operator;
        operatorName = first_name + ' ' + last_name;
      }
      if (tractorTraveledPathLayerRef.current) {
        const defaultLayer = tractorTraveledPathLayerRef.current;
        defaultLayer?.getSource().clear();
      }
      drawJAG(response.records, response.tractor.name, operatorName);
    }
  };
  const getGuardRails = async (tractorId: number) => {
    if (tractorId) {
      try {
        const guardRails = await getTractorTraveledPointsGuardRails(
          userDetails.organization.api_url,
          tractorId,
        );
        setMapSnapshots(guardRails);
      } catch (error) {
        // error
      }
    }
  };
  useEffect(() => {
    if (mapSnapshots) {
      const defaultLayer = mapGuardRailsRefs.current;
      defaultLayer?.getSource()?.getSource()?.clear();
      mapSnapshots?.records?.map((snapshot: any) => {
        if (snapshot && snapshot.latitude && snapshot.longitude) {
          const coordinates = [snapshot.latitude, snapshot.longitude];
          let icon = SnapIcon;
          let name = 'SNAPSHOT';
          let scale = 1.5;
          if (snapshot.created_at === GUARD_RAILS) {
            icon = guardRails;
            name = 'Guard Rails';
            if (snapshot.guard_rail_type === 'human_protection') {
              icon = human_pt;
              name = 'Human Protection';
            } else if (snapshot.guard_rail_type === 'path_protection') {
              icon = path_pt;
              name = 'Path Protection';
            } else if (snapshot.guard_rail_type === 'pto_protection') {
              icon = pto_pt;
              name = 'Pto Protection';
            }
            scale = 0.7;
          }
          const style = new Style({
            image: new Icon({
              src: icon,
              scale,
            }),
            zIndex: 100,
          });
          const hoverStyle = new Style({
            image: new Icon({
              src: icon,
              scale: scale * 1.2,
            }),
            zIndex: 100,
          });
          const feature = new Feature({
            geometry: new Point(
              proj.fromLonLat([coordinates[1], coordinates[0]]),
            ),
            name: name,
            pointType: 'SNAP',
            content: snapshot,
            style,
            point: [coordinates[1], coordinates[0]],
          });
          feature.setStyle(style);
          feature.set('style', style);
          feature.set('hoverStyle', hoverStyle);
          defaultLayer?.getSource()?.getSource()?.addFeature(feature);
        }
      });
    }
  }, [mapSnapshots]);

  const drawJAG = (paths: any, tractorName: string, operatorName = '') => {
    const defaultLayer = tractorTraveledPathLayerRef.current;
    let lastPlannerLastPoint: any = null;
    let start = false;
    if (paths && paths.length > 0) {
      paths.map((point: any) => {
        let arrData: any = [];
        let pushIndex = 0;
        point.paths = [];
        point.path.slice(1).map((v: any, i: number) => {
          if (
            calPtsDistanceActualAllow([point.path[i], v]) &&
            ((i === 0 &&
              isGoodLocalizationInJumpAndGo(point.path[i]) &&
              isGoodLocalizationInJumpAndGo(v)) ||
              (i !== 0 && isGoodLocalizationInJumpAndGo(v)))
          ) {
            arrData.push(v);
            point.paths[pushIndex] = arrData;
          } else {
            if (arrData && arrData.length > 0) {
              pushIndex = pushIndex + 1;
            }
            arrData = [];
          }
        });
      });
    }
    paths.map((path1: any) => {
      path1.paths.map((path: any) => {
        const color = getPathColor(path1.planner);
        const borderColor = getPathBorderColor(path1.planner);
        const arr: any[] = [];
        if (lastPlannerLastPoint && path.length > 0) {
          if (calPtsDistanceActualAllow([lastPlannerLastPoint, path[0]])) {
            arr.push(
              proj.fromLonLat([
                lastPlannerLastPoint.longitude,
                lastPlannerLastPoint.latitude,
              ]),
            );
          } else {
            const coordinates = proj.fromLonLat([
              lastPlannerLastPoint.longitude,
              lastPlannerLastPoint.latitude,
            ]);
            const startingPathPoint = setBadLocalization(
              coordinates,
              globalShowBadLocalization,
              path1,
            );
            defaultLayer?.getSource().addFeature(startingPathPoint);
          }
        }
        path.map(
          (pathPoint: {
            longitude: number;
            latitude: number;
            created_date_time: number;
            dTime: string;
            tractorName: string;
            operatorName: string;
          }) => {
            pathPoint.tractorName = tractorName;
            pathPoint.operatorName = operatorName || '-';
            pathPoint.dTime = getDateTime(pathPoint.created_date_time);
            arr?.push(
              proj.fromLonLat([pathPoint.longitude, pathPoint.latitude]),
            );
          },
        );
        let tractorLocation: Feature<any> = new Feature();
        if (!tractorLocation) {
          tractorLocation = new Feature();
        }
        tractorLocation.setGeometry(new LineString(arr));
        // path style
        const style = getPathFeatureStyle(path1.planner, getZoomValue());
        if (!start && arr.length > 0) {
          start = true;
          // set starting point
          const startingPathPoint = new Feature({
            geometry: new Point(arr[0]),
            color,
            borderColor,
            pointType: 'startPoint',
          });
          // set circle style
          const circleStyle = getCenterStyle(
            color,
            borderColor,
            globalZoom > onGlobalZoomToShowZoomIcons,
          );
          startingPathPoint.setStyle(circleStyle);
          defaultLayer?.getSource().addFeature(startingPathPoint);
        }
        tractorLocation.setStyle(style);
        tractorLocation.set('style', style);
        tractorLocation.set('hoverStyle', style);
        // color for in zoom and zoom out style
        tractorLocation.set('color', color);
        tractorLocation.set('borderColor', borderColor);
        tractorLocation.set('name', tractorName);
        tractorLocation.set('drawPointsText', { path });
        tractorLocation.set('pointType', 'PATH');
        tractorLocation.set('tractorId', '');
        defaultLayer?.getSource().addFeature(tractorLocation);
        if (arr && arr.length) {
          lastPlannerLastPoint = path[path.length - 1];
        }
      });
    });

    defaultLayer?.setVisible(globalShowPath);
  };
  const getIndicators = async (tractorId: number) => {
    if (tractorId) {
      try {
        if (mapIndicatorRefs.current) {
          mapRef.current?.removeLayer(mapIndicatorRefs.current);
        }
        const indicators = await getTractorTraveledPointsIndicators(
          userDetails.organization.api_url,
          tractorId,
        );
        drawIndicators(indicators);
      } catch (error) {
        // error
      }
    }
  };

  const drawIndicators = (indicators: any) => {
    const indicators_data = indicators[0]?.indicators_data;
    if (indicators_data && indicators_data.length > 0) {
      const features: Feature<any>[] = [];
      indicators_data.map((indicator: any) => {
        if (indicator && indicator.latitude && indicator.longitude) {
          const coordinates = [indicator.latitude, indicator.longitude];
          const feature = new Feature({
            geometry: new Point(
              proj.fromLonLat([coordinates[1], coordinates[0]]),
            ),
            name: indicator.indicator_name,
            pointType: 'INDICATOR',
            content: indicator,
            point: [coordinates[1], coordinates[0]],
          });
          features.push(feature);
        }
      });

      const source = new VectorSource({
        features: features,
      });
      const clusterSource = new Cluster({
        distance: 10,
        source: source,
      });
      const layer = new VectorLayer({
        source: clusterSource,
        zIndex: priority.INDICATOR,
        visible: globalShowDataLayers,
        style: function (feature: any) {
          const size = feature.get('features').length;
          let style = new Style({});
          if (size === 1) {
            const feat = feature.get('features');
            style = IndicatorStyle(1);
            feature.set('name', feat[0].get('name'));
            feature.set('pointType', feat[0].get('pointType'));
            feature.set('content', feat[0].get('content'));
          }
          if (size > 1) {
            const feat = feature.get('features');
            let names = '';
            feat.map((fe: any, index: number) => {
              names = `${names} ${fe.get('name')}${
                index < feat.length - 1 ? ', ' : ''
              } `;
            });
            feature.set('name', names);
            style = IndicatorStyle(size);
          }
          return style;
        },
      });
      mapIndicatorRefs.current = layer;
      mapRef.current?.addLayer(layer);
    }
  };
  const onCreateTicket = () => {
    setTicketDetails(errorDetails);
    setTractorId(errorDetails?.tractor_id);
    setShowIndicatorModel(false);
    setToggleTicketWidget(true);
    track('CreateTicket', {
      event: 'Create Ticket',
    });
  };

  /**
   * Polygons initialization Starts Here
   */
  useEffect(() => {
    const init = async () => {
      try {
        const { organization } = userDetails;
        let recordsList = [];
        if (polygonsList && polygonsList.length > 0) {
          recordsList = polygonsList;
        } else {
          const response = await getPolygonsData(
            organization.api_url,
            organization.farm.id,
          );
          const { records } = response;
          recordsList =
            records && records.length && records.length > 0 ? records : [];
        }
        drawPolyAndPrepareLayers(
          recordsList,
          polygonsList.length > 0 ? false : true,
        );
      } catch (error: any) {
        notification.error({
          message: error.message,
          duration: 1,
        });
      }
    };
    if (userDetails && userDetails.organization && mapInitialized && base) {
      init();
    }
  }, [userDetails, mapInitialized, base]);

  const drawPolyAndPrepareLayers = async (polyList: any, save = true) => {
    const listItems: MapFeature[] = [];
    const greenPolyItems: MapFeature[] = [];
    polyList.map(async (record: any, index: number) => {
      let layer = null;
      if (record.color === 'white') {
        layer = whitePolygonsLayerRef.current;
      }
      if (record.color === 'green') {
        layer = greenPolygonsLayerRef.current;
      }
      if (record.color === 'blue') {
        layer = bluePolygonsLayerRef.current;
      }
      if (record.color === 'gray') {
        layer = grayPolygonsLayerRef.current;
      }
      if (record.color === 'brown') {
        layer = brownPolygonsLayerRef.current;
      }
      const { polygonFeature, polygonItem } = await drawPoly(
        userDetails,
        base,
        layer,
        1,
        1,
        record,
        false, // suggested as false
        !save, // call api to get vertices -> false call api , -> true don't call api
      );
      listItems.push(polygonItem as MapFeature);
      // assign for recenter the white field
      if (record.color === 'white') {
        fieldFeatureItem.current = polygonFeature;
      }
      if (record.color === 'green') {
        greenPolyItems.push(polygonItem as MapFeature);
      }
      if (polygonItem && polygonItem.vertices) {
        record.vertices = polygonItem.vertices;
      }
      if (polyList && polyList.length - 1 === index) {
        setPolygons(listItems);
        // polygons added to maintain app context start
        save &&
          appDispatch({
            type: SET_POLYGONS_LIST,
            payload: polyList,
          });
        // polygons added to maintain app context end

        setGreenPolygons(greenPolyItems);
      }
    });
    polygonHoverLabel(mapRef.current);
  };

  useEffect(() => {
    const init = async () => {
      try {
        const { layer, list }: { layer: any; list: MapFeature[] } =
          await getWaypoints(userDetails, base);
        // list && list.length > 0 && setwaypointsForRemoteAv(list);
        list &&
          list.length > 0 &&
          dispatch({
            type: SET_WAY_POINTS_FOR_REMOTE_AV,
            payload: list,
          });

        if (layer) {
          mapRef.current?.addLayer(layer);
          waypointsLayerRef.current = layer;
        }
      } catch (error: any) {
        notification.error({
          message: error.message,
          duration: 1,
        });
      }
      try {
        const { layer }: { layer: any } = await getVineRows(userDetails, base);
        if (layer) {
          mapRef.current?.addLayer(layer);
          vineRowsLayerRef.current = layer;
        }
      } catch (error: any) {
        notification.error({
          message: error.message,
          duration: 1,
        });
      }
    };
    if (userDetails && userDetails.organization && mapInitialized && base) {
      init();
    }
  }, [userDetails, mapInitialized, base]);
  /**
   * Polygons initialization Ends Here
   */

  const getFeatureLayer = (type: string) => {
    let layer: VectorLayer<any> | any;
    if (type === TRACTOR) {
      layer = tractorLayerRef.current;
    } else if (type === WAY_POINT) {
      layer = waypointsLayerRef.current;
    } else if (type === VINE_ROW) {
      layer = vineRowsLayerRef.current;
    } else if (type === VINE_BLOCK) {
      layer = vineBlocksLayerRef.current;
    } else if (type === 'BASESTATION') {
      layer = initialLayer.current;
    } else if (type === 'GREENPOLYGON') {
      layer = greenPolygonsLayerRef.current;
    } else if (type === 'WHITEPOLYGON') {
      layer = whitePolygonsLayerRef.current;
    } else if (type === 'GRAYPOLYGON') {
      layer = grayPolygonsLayerRef.current;
    } else if (type === 'BROWNPOLYGON') {
      layer = brownPolygonsLayerRef.current;
    } else if (type === 'BLUEPOLYGON') {
      layer = bluePolygonsLayerRef.current;
    } else if (type === 'INDICATOR') {
      layer = mapIndicatorRefs.current;
    } else if (type === 'PATH') {
      layer = planerTractorPathLayerRef.current;
    } else if (type === 'SNAP') {
      layer = mapGuardRailsRefs.current;
    } else if (type === 'SNAP_STREAM') {
      layer = mapGuardRailsFromStreamRefs.current;
    } else if (type === 'INDICATOR_STREAM') {
      layer = mapIndicatorFromStreamRefs.current;
    }

    return layer;
  };
  /** To toggle map layers from remoteAV popup */
  const handleToggleLayer = (featureLayerType: string, isVisible: boolean) => {
    const layer: VectorLayer<any> | any = getFeatureLayer(featureLayerType);
    if (layer) {
      layer.setVisible(isVisible);
      if (featureLayerType === TRACTOR) {
        tractorPathLayerRef?.current?.setVisible(isVisible);
        if (!isVisible) {
          tractorPathLayerRef.current
            ?.getSource()
            .getFeatures()
            .forEach((feature: any) => {
              tractorPathLayerRef.current?.getSource().removeFeature(feature);
            });
          const clear = tractorPaths.current;
          tractorPaths.current?.clear();
          tractorPaths.current = clear;

          // clear the planner layers points
          const clear1 = plannerTractorPaths.current;
          plannerTractorPaths.current?.clear();
          plannerTractorPaths.current = clear1;
        }
      }
    }
    if (featureLayerType === 'PATH') {
      planerTractorPathLayerRef?.current?.setVisible(isVisible);
      tractorPathLayerRef?.current?.setVisible(isVisible);
      tractorTraveledPathLayerRef?.current?.setVisible(isVisible);
      pathLayerRef?.current?.setVisible(isVisible);
    }
  };

  const handleLayerVisible = (zoom: number) => {
    handleToggleLayer(VINE_ROW, false);
    // displaying vine row when not goto Point / Block
    if (zoom >= 17 && !globalAddStep && setUpKeysGlobal.polygonUuid === '') {
      handleToggleLayer(VINE_ROW, true);
    }
    toggleVineRowStyle(
      vineRowsLayerRef.current,
      toggleSatellite,
      globalZoom >= onGlobalZoomToShowZoomIcons,
    );
    if (planerTractorPathLayerRef && planerTractorPathLayerRef.current) {
      togglePathStyle(planerTractorPathLayerRef.current, getZoomValue());
    }
    if (tractorTraveledPathLayerRef && tractorTraveledPathLayerRef.current) {
      togglePathStyle(tractorTraveledPathLayerRef.current, getZoomValue());
    }
    if (pointLayerRef && pointLayerRef.current) {
      togglePathStyle(pointLayerRef.current, getZoomValue());
    }

    if (pathLayerRef && pathLayerRef.current) {
      togglePathStyle(pathLayerRef.current, getZoomValue());
    }
  };

  const recenterTractor = (tractorId: number, zoom = 19) => {
    const layer: VectorLayer<any> | any = getFeatureLayer(TRACTOR);
    if (layer) {
      layer.setVisible(true);
      setShowTractors(true);
      const feat = layer.getSource();
      const feature: any = feat
        .getSource()
        .getFeatureById(`${POSE_MODEL_STREAMING}${tractorId}`);
      if (feature) {
        const point = feature.getGeometry().getCoordinates();
        const coordinates = proj.transform(point, 'EPSG:3857', 'EPSG:4326');
        // mapRef.current?.getView().setCenter(proj.fromLonLat(latLang));
        // centerTheLocation(coordinates, mapRef.current, autoDrive ? 20 : 19);
        centerTheLocation(coordinates, mapRef.current, zoom);
      }
    }
  };
  useEffect(() => {
    track('Live Map', {
      event: 'Entered Live Map',
      start: true,
    });
    return () => {
      track('Live Map', {
        event: 'Exit from Live Map',
        start: false,
      });
    };
  }, []);
  useEffect(() => {
    if (recenterTabId && mapInitialized && tractorsInitialized) {
      recenterTractor(recenterTabId);
    }
  }, [recenterTabId, mapInitialized, tractorsInitialized]);
  const recenterItem = (mapFeature: MapFeature) => {
    if (mapRef.current) {
      if (mapFeature.type === TRACTOR) {
        const layer: VectorLayer<any> | any = getFeatureLayer(mapFeature.type);
        layer?.setVisible(true);
        setShowTractors(true);
        const feat: any = layer?.getSource();
        const tractorFeature: any = feat
          .getSource()
          .getFeatureById(mapFeature.mapId);
        const point = tractorFeature.getGeometry().getCoordinates();
        const coordinates = proj.transform(point, 'EPSG:3857', 'EPSG:4326');
        // mapRef.current.getView().setCenter(proj.fromLonLat(coordinates));
        centerTheLocation(coordinates, mapRef.current);
      } else if (
        mapFeature.type === WAY_POINT ||
        mapFeature.type === 'BASESTATION'
      ) {
        const layer: VectorLayer<any> | any = getFeatureLayer(mapFeature.type);
        layer.setVisible(true);
        let feature;
        if (mapFeature.type === 'BASESTATION') {
          setShowBasestations(true);
          feature = layer.getSource().getFeatureById(mapFeature.mapId);
        }
        if (mapFeature.type === WAY_POINT) {
          const source: any = layer.getSource();
          feature = source.getSource().getFeatureById(mapFeature.mapId);
        }

        if (feature) {
          const point = feature.getGeometry().getCoordinates();
          if (point) {
            const coordinates = proj.transform(point, 'EPSG:3857', 'EPSG:4326');
            coordinates &&
              coordinates.length > 0 &&
              // mapRef.current.getView().setCenter(proj.fromLonLat(coordinates));
              centerTheLocation(coordinates, mapRef.current);
          }
        }
      } else if (mapFeature.type === POLYGON) {
        const greenPolyLayer = getFeatureLayer('GREENPOLYGON');
        const whitePolyLayer = getFeatureLayer('WHITEPOLYGON');
        const grayPolyLayer = getFeatureLayer('GRAYPOLYGON');
        const brownPolyLayer = getFeatureLayer('BROWNPOLYGON');
        const bluePolyLayer = getFeatureLayer('BLUEPOLYGON');
        if (greenPolyLayer) {
          const greenFeature = greenPolyLayer
            ?.getSource()
            ?.getFeatureById(mapFeature.mapId);
          const whiteFeature = whitePolyLayer
            ?.getSource()
            ?.getFeatureById(mapFeature.mapId);
          const grayFeature = grayPolyLayer
            ?.getSource()
            ?.getFeatureById(mapFeature.mapId);
          const brownFeature = brownPolyLayer
            ?.getSource()
            ?.getFeatureById(mapFeature.mapId);
          const blueFeature = bluePolyLayer
            ?.getSource()
            ?.getFeatureById(mapFeature.mapId);
          if (greenFeature) {
            setShowGreenPolygons(true);
            greenPolyLayer?.setVisible(true);
          }
          if (whiteFeature) {
            setShowWhitePolygons(true);
            whitePolyLayer?.setVisible(true);
          }
          if (grayFeature) {
            setShowGreenPolygons(true);
            grayPolyLayer?.setVisible(true);
          }
          if (brownFeature) {
            setShowBrownPolygons(true);
            brownPolyLayer?.setVisible(true);
          }
          if (blueFeature) {
            setShowBluePolygons(true);
            bluePolyLayer?.setVisible(true);
          }

          if (
            greenFeature ||
            whiteFeature ||
            grayFeature ||
            brownFeature ||
            blueFeature
          ) {
            const f =
              greenFeature ||
              whiteFeature ||
              grayFeature ||
              brownFeature ||
              blueFeature;
            displayRecenterViewPortSetUp(
              f as Feature<Geometry>,
              mapRef.current,
            );
          }
        }
      }
    }
  };
  // };

  useEffect(() => {
    handleToggleLayer('BASESTATION', showBasestations);
  }, [showBasestations]);

  useEffect(() => {
    handleToggleLayer(TRACTOR, showTractors);
  }, [showTractors]);

  useEffect(() => {
    handleToggleLayer(WAY_POINT, showWaypoints);
  }, [showWaypoints]);

  useEffect(() => {
    handleToggleLayer('GREENPOLYGON', showGreenPolygons);
  }, [showGreenPolygons]);

  useEffect(() => {
    handleToggleLayer('BROWNPOLYGON', showBrownPolygons);
  }, [showBrownPolygons]);

  useEffect(() => {
    handleToggleLayer('WHITEPOLYGON', showWhitePolygons);
  }, [showWhitePolygons]);

  useEffect(() => {
    handleToggleLayer('GRAYPOLYGON', showGrayPolygons);
  }, [showGrayPolygons]);

  useEffect(() => {
    handleToggleLayer('BLUEPOLYGON', showBluePolygons);
  }, [showBluePolygons]);

  const onAutoDriveStart = (uuid: string, tractorId: number) => {
    const { organization } = userDetails;
    getPreviewPath(organization.api_url, uuid)
      .then((response: { data: any }) => {
        handlePreview(response.data, tractorId);
      })
      .catch((err) => {
        notification.error({
          message: err.message,
          duration: 1,
        });
      });
  };
  //  clearing the preview path
  const clearPreviewPath = (id: number) => {
    const rAvTractor = tractorsMap.get(id);
    // dont clear preview path when tractor in remote av (auto drive)
    if (
      rAvTractor &&
      rAvTractor.planning_manager &&
      rAvTractor.planning_manager.planner_name &&
      rAvTractor.planning_manager.planner_name ===
        tractorConstantsNew.remote_av.key
    ) {
      return;
    }
    const feature =
      id && pathLayerRef?.current?.getSource()?.getFeatureById(id)
        ? pathLayerRef?.current?.getSource()?.getFeatureById(id)
        : null;
    id &&
      feature &&
      pathLayerRef &&
      pathLayerRef?.current?.getSource()?.removeFeature(feature);
  };
  const handlePreview = (data: string, tractorId: number) => {
    let tractorName = '';
    allTractorsList.forEach((feature: { id: number; name: any }) => {
      if (feature.id && tractorId && feature.id == tractorId) {
        tractorName = feature.name;
      }
    });
    const latlongTextList = data.split('|');
    const coordinates: Coordinate[] = [];
    latlongTextList.forEach((record: any) => {
      const latlong = record?.split(',');
      coordinates.push(proj.fromLonLat([latlong[1], latlong[0]]));
    });

    const tractorLocationFeature: Feature<Geometry> = new Feature({
      color: getPathColor(pathConstants.PREVIEW_PATH),
      borderColor: getPathBorderColor(pathConstants.PREVIEW_PATH),
    });
    tractorLocationFeature.setGeometry(new LineString(coordinates));
    const style = getPathFeatureStyle(
      pathConstants.PREVIEW_PATH,
      getZoomValue(),
    );
    // Path Ahead Fill #F1CE90 Stroke #E9881F
    tractorLocationFeature.setStyle(style);
    tractorLocationFeature.setId(tractorId);
    tractorLocationFeature.set('name', `${tractorName} Preview path`);
    tractorLocationFeature.set('tractorId', tractorId);
    tractorLocationFeature.set('pointType', pathConstants.PREVIEW_PATH);
    pathLayerRef.current?.getSource().addFeature(tractorLocationFeature);
    pathLayerRef.current?.setVisible(globalShowPath);
  };

  useEffect(() => {
    if (!showIndicatorModel) {
      toggleForIndicator = false;
    }
  }, [showIndicatorModel]);

  const onCloseTicketWidget = () => {
    setShowIndicatorModel(false);
    setToggleTicketWidget(false);
  };

  const selectFarmItems = (farm: boolean, handleBluePoly = false) => {
    setShowWaypoints(farm);
    setShowGreenPolygons(farm);
    setShowWhitePolygons(farm);
    setShowGrayPolygons(farm);
    !handleBluePoly && setShowBluePolygons(farm);
    setShowBrownPolygons(farm);
  };
  useEffect(() => {
    handleToggleLayer('INDICATOR', showDataLayers);
    handleToggleLayer('SNAP', showDataLayers);
    handleToggleLayer('INDICATOR_STREAM', showDataLayers);
    handleToggleLayer('SNAP_STREAM', showDataLayers);
    setShowBadLocalization(showDataLayers);
    globalShowDataLayers = showDataLayers;
  }, [showDataLayers]);

  useEffect(() => {
    handleToggleLayer('PATH', showPath);
    globalShowPath = showPath;
  }, [showPath]);

  useEffect(() => {
    globalShowBadLocalization = showBadLocalization;
    // show  hide bad localization code starts here
    const defaultLayer = planerTractorPathLayerRef?.current;
    const defaultLayer1 = tractorTraveledPathLayerRef?.current;
    let features = defaultLayer?.getSource().getFeatures();
    const features1 = defaultLayer1?.getSource().getFeatures();
    const f1 = features && features.length > 0 ? features : [];
    const f2 = features1 && features1.length > 0 ? features1 : [];
    features = [...f1, ...f2];
    if (features && features.length > 0) {
      features?.map((feature: any) => {
        if (feature.get('pointType') == BadLocalization) {
          if (showBadLocalization) {
            feature.setStyle(feature.get('style'));
          } else {
            feature.setStyle(new Style({})); // to hide the item
          }
        }
      });
    }
    // show  hide bad localization code ends here
  }, [showBadLocalization]);
  // check box checking options
  useEffect(() => {
    if (
      showWaypoints &&
      showGreenPolygons &&
      showWhitePolygons &&
      showGrayPolygons &&
      showBrownPolygons &&
      showBluePolygons &&
      showBasestations
    ) {
      setFarm(true);
    } else {
      if (
        !showWaypoints ||
        !showGreenPolygons ||
        !showWhitePolygons ||
        !showGrayPolygons ||
        !showBrownPolygons ||
        !showBluePolygons ||
        !showBasestations
      ) {
        setFarm(false);
      }
    }
  }, [
    showWaypoints,
    showGreenPolygons,
    showWhitePolygons,
    showGrayPolygons,
    showBrownPolygons,
    showBluePolygons,
    showBasestations,
  ]);

  // added to maintain the selected tarctorid
  useEffect(() => {
    globalSelectedTractorId = selectedTractorId;
    selectedOnTractor();
  }, [selectedTractorId]);
  // selected tractor handler
  const isTractorSelected = (tractorId: number): boolean => {
    if (
      globalIsPinned &&
      globalPinnedTractors &&
      globalPinnedTractors.length > 0
    ) {
      return globalPinnedTractors.includes(tractorId) ? true : false;
    }
    return tractorId == globalSelectedTractorId;
  };

  const selectedOnTractor = () => {
    const features = tractorLayerRef?.current
      ?.getSource()
      ?.getSource()
      ?.getFeatures();
    if (features) {
      features.map((feature: any) => {
        if (feature) {
          const tractorId = Number(
            feature.getId().replace(POSE_MODEL_STREAMING, ''),
          );
          const isSelected = isTractorSelected(tractorId);

          const pointTypeSelected = feature.get('pointTypeSelected');
          const pointTypeBearing: any = feature.get('pointTypeBearing');
          const pointTypeBattery: any = feature.get('pointTypeBattery');
          const pointTypeIni: any = feature.get('pointTypeIni');
          const tractorName: any = feature.get('name');
          const isAvailable = feature.get('isAvailable');
          const drive_action_created_at = feature.get(
            'drive_action_created_at',
          );
          const tractorTransparent =
            tractorId == globalSelectedTractorId &&
            feature.get('tractorTransparent')
              ? true
              : false;
          const isFaulty = feature.get('isFaulty') ? true : false;
          const pointTypeIsSelectedForToolTip = isTractorSelected(tractorId);
          const pointTypeIsMoving = feature.get('pointTypeIsMoving')
            ? true
            : false;
          const pointTypeLowLocalization = feature.get(
            'pointTypeLowLocalization',
          )
            ? true
            : false;
          let style = null;
          let hoverStyle = null;
          if (pointTypeSelected) {
            const onZoom = globalZoom > onGlobalZoomToShowZoomIcons;
            style = getTractorIcon(
              pointTypeSelected,
              false, // with out hover
              pointTypeBearing,
              pointTypeIni,
              isSelected,
              pointTypeIsMoving,
              onZoom,
              pointTypeBattery,
              pointTypeLowLocalization,
              tractorName,
              pointTypeIsSelectedForToolTip,
              tractorId,
              isFaulty,
              isAvailable,
              drive_action_created_at,
              tractorTransparent,
            );
            hoverStyle = getTractorIcon(
              pointTypeSelected,
              true, // with hover
              pointTypeBearing,
              pointTypeIni,
              isSelected,
              pointTypeIsMoving,
              onZoom,
              pointTypeBattery,
              pointTypeLowLocalization,
              tractorName,
              pointTypeIsSelectedForToolTip,
              tractorId,
              isFaulty,
              isAvailable,
              drive_action_created_at,
              tractorTransparent,
            );
          }
          feature.setStyle(style);
          feature.set('style', style);
          feature.set('hoverStyle', hoverStyle);
          setSelectedTractorLoc(tractorId, feature);
        }
      });
    }
  };

  const drawGuardRailsFromStream = () => {
    if (mapGuardRailsFromStreamRefs.current) {
      const defaultLayer = mapGuardRailsFromStreamRefs.current;
      defaultLayer?.getSource().clear();
      mapRef.current?.removeLayer(mapGuardRailsFromStreamRefs.current);
    }
    const existingItems = tractorsGuardrailMap.current;
    const records = existingItems?.get(`${globalSelectedTractorId}`);
    if (records && records.length > 0) {
      const guardRailsFeatures: Feature<any>[] = [];
      records?.map((snapshot: any) => {
        snapshot = { ...snapshot, ...snapshot.data };
        if (
          snapshot &&
          snapshot.latitude &&
          snapshot.longitude &&
          snapshot.title === 'guard_rails'
        ) {
          const coordinates = [
            Number(snapshot.latitude),
            Number(snapshot.longitude),
          ];
          const icon = guardRails;
          const name = 'Guard Rails';
          // if (
          //   snapshot &&
          //   snapshot.guard_rail_type &&
          //   snapshot.guard_rail_type === 'human_protection'
          // ) {
          //   icon = human_pt;
          //   name = 'Human Protection';
          // } else if (
          //   snapshot &&
          //   snapshot.guard_rail_type &&
          //   snapshot.guard_rail_type === 'path_protection'
          // ) {
          //   icon = path_pt;
          //   name = 'Path Protection';
          // } else if (
          //   snapshot &&
          //   snapshot.guard_rail_type &&
          //   snapshot.guard_rail_type === 'pto_protection'
          // ) {
          //   icon = pto_pt;
          //   name = 'Pto Protection';
          // }
          // const scale = 0.7;
          const style = guardRailStyle(1);
          // const style = new Style({
          //   image: new Icon({
          //     src: icon,
          //     // scale,
          //   }),
          //   zIndex: 100,
          // });
          const hoverStyle = guardRailStyle(1, true);
          const feature = new Feature({
            geometry: new Point(
              proj.fromLonLat([coordinates[1], coordinates[0]]),
            ),
            name: name,
            pointType: 'SNAP',
            content: snapshot,
            style,
            point: [coordinates[1], coordinates[0]],
          });
          feature.setStyle(style);
          feature.set('style', style);
          feature.set('hoverStyle', hoverStyle);
          guardRailsFeatures.push(feature);
        }
      });
      // gaurd rails features
      const guardRailssource = new VectorSource({
        features: guardRailsFeatures,
      });
      const GuardRailsClusterSource = new Cluster({
        distance: 10,
        source: guardRailssource,
      });
      const guardRailslayer = new VectorLayer({
        source: GuardRailsClusterSource,
        visible: globalShowDataLayers,
        zIndex: priority.SNAP,
        style: function (feature: any) {
          return styleFun(feature);
        },
      });
      mapGuardRailsFromStreamRefs.current = guardRailslayer;
      mapRef.current?.addLayer(guardRailslayer);
    }
  };

  const drawIndicatorsFromStreams = () => {
    if (mapIndicatorFromStreamRefs.current) {
      const defaultLayer = mapIndicatorFromStreamRefs.current;
      defaultLayer?.getSource().clear();
      mapRef.current?.removeLayer(mapIndicatorFromStreamRefs.current);
    }
    const existingItems = tractorsIndicatorMap.current;
    const records = existingItems?.get(`${globalSelectedTractorId}`);
    if (records && records.length > 0) {
      const features: Feature<any>[] = [];
      records.map((indicator: any) => {
        indicator = { ...indicator, ...indicator.data };
        if (indicator && indicator.latitude && indicator.longitude) {
          const coordinates = [
            Number(indicator.latitude),
            Number(indicator.longitude),
          ];
          const feature = new Feature({
            geometry: new Point(
              proj.fromLonLat([coordinates[1], coordinates[0]]),
            ),
            name: indicator.indicator_name,
            pointType: 'INDICATOR',
            content: indicator,
            point: [coordinates[1], coordinates[0]],
          });
          features.push(feature);
        }
      });

      const source = new VectorSource({
        features: features,
      });
      const clusterSource = new Cluster({
        distance: 10,
        source: source,
      });
      const layer = new VectorLayer({
        source: clusterSource,
        zIndex: priority.INDICATOR,
        visible: globalShowDataLayers,
        style: function (feature: any) {
          const size = feature.get('features').length;
          let style = new Style({});
          if (size === 1) {
            const feat = feature.get('features');
            style = IndicatorStyle(1);
            feature.set('name', feat[0].get('name'));
            feature.set('pointType', feat[0].get('pointType'));
            feature.set('content', feat[0].get('content'));
          }
          if (size > 1) {
            const feat = feature.get('features');
            let names = '';
            feat.map((fe: any, index: number) => {
              names = `${names} ${fe.get('name')}${
                index < feat.length - 1 ? ', ' : ''
              } `;
            });
            feature.set('name', names);
            style = IndicatorStyle(size);
          }
          return style;
        },
      });
      mapIndicatorFromStreamRefs.current = layer;
      mapRef.current?.addLayer(layer);
    }
  };

  useEffect(() => {
    setUpKeysGlobal = setUpKeys;
    if (setUpKeys && setUpKeys.mapId === '') {
      if (greenPolygonsLayerRef.current)
        togglePolygonStyle(greenPolygonsLayerRef.current, toggleSatellite);
    }
  }, [setUpKeys]);
  useEffect(() => {
    if (mapInitialized && setUpKeys.polygonUuid !== '') {
      getVineLanes();
    }
  }, [mapInitialized, setUpKeys]);
  /**
   * Vine lanes initialization Starts Here
   */

  const getVineLanes = async () => {
    vineLaneLayerRef.current?.getSource().clear();
    try {
      const vineLaneFeatures: Feature<Geometry>[] = [];
      const pointAFeatures: Feature<Geometry>[] = [];
      const pointBFeatures: Feature<Geometry>[] = [];
      const vineRowFeatures: Feature<Geometry>[] = [];

      let coordinates: any = [];
      const blueDotStyle = new Style({
        image: new CircleStyle({
          radius: 12,
          stroke: new Stroke({
            color: tractorConstantsNew.remote_av.color,
          }),
          fill: new Fill({
            color: tractorConstantsNew.remote_av.color,
          }),
        }),
        zIndex: 10,
      });
      const dottedList: { A: [number, number]; B: [number, number] }[] = [];
      const laneFilterContent = setUpKeys.laneFilterContent
        ? setUpKeys.laneFilterContent
        : [];
      laneFilterContent.map((vineLane: any, index: number) => {
        const id = `vineLane_${vineLane.id}`;
        coordinates = [];
        if (
          vineLane.vertices &&
          vineLane.vertices.length &&
          vineLane.vertices.length > 0
        ) {
          const { latitude, longitude } = vineLane.vertices[0];
          let xyOrLatLong = true;
          if (latitude && longitude) {
            xyOrLatLong = true;
          } else {
            xyOrLatLong = false;
          }
          if (xyOrLatLong) {
            vineLane.vertices.map((item: any) => {
              const { latitude, longitude } = item;
              coordinates.push(proj.fromLonLat([longitude, latitude]));
            });
          } else {
            vineLane.vertices.map((pointData: any) => {
              pointData.xNew = pointData.x / 100;
              pointData.yNew = pointData.y / 100;
              const latAndLong: [number, number] = getLatitudeAndLongitude(
                [pointData.yNew, pointData.xNew],
                base,
                true,
              );
              coordinates.push(proj.fromLonLat(latAndLong));
            });
          }
        }
        if (coordinates && coordinates.length > 0) {
          const vinelanePoly = new Feature({
            type: 'Polygon',
            geometry: new Polygon([coordinates]),
            pointId: vineLane.id,
            name: vineLane.name,
            isVineLaneRow: true,
          });

          vinelanePoly.setId(id);
          vinelanePoly.set('id', vineLane.id);
          vinelanePoly.set('data', vineLane);
          vineLaneFeatures.push(vinelanePoly);
        }
      });
      const temporaryArray: any = [];
      const everyFilter =
        Number(setUpKeys.everyFilter) > 0 ? Number(setUpKeys.everyFilter) : 1;
      let startIndex = 0;
      let endIndex = 0;
      if (setUpKeys.startIndexUuid !== '') {
        laneFilterContent.map((content: any, index: number) => {
          if (content.vine_lane_uuid === setUpKeys.startIndexUuid)
            startIndex = index;
        });
      }
      if (setUpKeys.endIndexUuid !== '') {
        laneFilterContent.map((content: any, index: number) => {
          if (content.vine_lane_uuid === setUpKeys.endIndexUuid)
            endIndex = index;
        });
      }
      let temporaryArray1 = [];
      if (
        setUpKeys.startWith &&
        (setUpKeys.startWith === 'A' || setUpKeys.startWith === 'B')
      ) {
        if (setUpKeys.endIndexUuid) {
          if (startIndex > endIndex) {
            temporaryArray1 = laneFilterContent.slice(endIndex, startIndex + 1);
            temporaryArray1 = temporaryArray1.sort(
              (a: any, b: any) => b.id - a.id,
            );
          } else {
            temporaryArray1 = laneFilterContent.slice(startIndex, endIndex + 1);
          }
        } else {
          temporaryArray1 = laneFilterContent.slice(
            startIndex,
            laneFilterContent.length,
          );
        }
      } else {
        temporaryArray1 = laneFilterContent.slice(0, laneFilterContent.length);
      }
      for (let i = 0; i < temporaryArray1.length; i += everyFilter) {
        if (i === 0) {
          setLeftStartVineRowName(temporaryArray1[i].left_row_name);
          setRightStartVineRowName(temporaryArray1[i].right_row_name);
        }
        setLeftEndVineRowName(temporaryArray1[i].left_row_name);
        setRightEndVineRowName(temporaryArray1[i].right_row_name);
        temporaryArray.push(temporaryArray1[i]); // Add the element at index i to a temporary array
      }
      temporaryArray.forEach((vineLane: any, index: number) => {
        const vineRow = [];
        if (vineLane && vineLane.vertices.length > 0) {
          const id1 = `vineLane_Head_A_${vineLane.id}`;
          const {
            head_a_x,
            head_a_y,
            head_b_x,
            head_b_y,
            head_a_latitude,
            head_a_longitude,
            head_b_latitude,
            head_b_longitude,
          } = vineLane;
          const latAndLong1: [number, number] =
            head_a_latitude && head_a_longitude
              ? [head_a_longitude, head_a_latitude]
              : getLatitudeAndLongitude(
                  [head_a_y / 100, head_a_x / 100],
                  base,
                  true,
                );
          const latAndLong2: [number, number] =
            head_b_latitude && head_b_longitude
              ? [head_b_longitude, head_b_latitude]
              : getLatitudeAndLongitude(
                  [head_b_y / 100, head_b_x / 100],
                  base,
                  true,
                );

          vineRow.push(proj.fromLonLat(latAndLong1));
          vineRow.push(proj.fromLonLat(latAndLong2));
          const vineLocation = new Feature({
            geometry: new LineString(vineRow),
            pointType: 'vinerow',
            name: vineLane.name,
          });
          const id3 = `vineLane_vineRow_duplicate_${vineLane.id}`;
          const rowStyle = new Style({
            stroke: new Stroke({
              color: '#546CB2',
              width: 10,
            }),
            fill: new Fill({
              color: 'rgba(180, 130, 130, 0.85)',
            }),
          });
          vineLocation.setStyle(rowStyle);
          vineLocation.setId(id3);

          dottedList.push({ A: latAndLong1, B: latAndLong2 });
          if (index === 0 && setUpKeys.startIndexUuid) {
            vineLocation.setStyle(getVineRowColorStyle());
            vineRowFeatures.push(vineLocation);
          }
          if (setUpKeys.startIndexUuid && setUpKeys.endIndexUuid) {
            vineLocation.setStyle(getVineRowColorStyle());
            vineRowFeatures.push(vineLocation);
          }
        }
      });

      laneFilterContent.forEach((vineLane: any, index: number) => {
        const vineRow = [];
        if (vineLane && vineLane.vertices.length > 0) {
          const id1 = `vineLane_Head_A_${vineLane.id}`;
          const {
            head_a_x,
            head_a_y,
            head_b_x,
            head_b_y,
            head_a_latitude,
            head_a_longitude,
            head_b_latitude,
            head_b_longitude,
          } = vineLane;
          const latAndLong1: [number, number] =
            head_a_latitude && head_a_longitude
              ? [head_a_longitude, head_a_latitude]
              : getLatitudeAndLongitude(
                  [head_a_y / 100, head_a_x / 100],
                  base,
                  true,
                );
          const pointLocation1 = new Feature({
            geometry: new Point(proj.fromLonLat(latAndLong1)),
            // name: 'Click here to select Head A',
            name: 'Start Here',
            head_name: 'A',
            headLatLong: latAndLong1,
            data: vineLane,
            pointType: types.VINELANEHEAD,
            mousePointType: types.VINELANEHEAD,
            startIndex: index,
            startIndexUuid: vineLane.vine_lane_uuid,
          });
          pointLocation1.setId(id1);
          pointLocation1.setStyle(blueDotStyle);
          const id2 = `vineLane_Head_B_${vineLane.id}`;
          const latAndLong2: [number, number] =
            head_b_latitude && head_b_longitude
              ? [head_b_longitude, head_b_latitude]
              : getLatitudeAndLongitude(
                  [head_b_y / 100, head_b_x / 100],
                  base,
                  true,
                );
          const pointLocation2 = new Feature({
            geometry: new Point(proj.fromLonLat(latAndLong2)),
            // name: 'Click here to select Head B',
            name: 'Start Here',
            head_name: 'B',
            headLatLong: latAndLong2,
            data: vineLane,
            pointType: types.VINELANEHEAD,
            mousePointType: types.VINELANEHEAD,
            startIndex: index,
            startIndexUuid: vineLane.vine_lane_uuid,
          });
          pointLocation2.setStyle(blueDotStyle);
          pointLocation2.setId(id2);

          vineRow.push(proj.fromLonLat(latAndLong1));
          vineRow.push(proj.fromLonLat(latAndLong2));
          const vineLocation = new Feature({
            geometry: new LineString(vineRow),
            data: vineLane,
            name: vineLane.name,
            pointType: types.VINELANEHEAD,
            startIndex: index,
            startIndexUuid: vineLane.vine_lane_uuid,
          });
          const id3 = `vineLane_vineRow_${vineLane.id}`;
          vineLocation.setStyle(getVineRowStyle1());
          vineLocation.setId(id3);

          dottedList.push({ A: latAndLong1, B: latAndLong2 });

          ////////// poly code

          const id = `vineLane_overlay_${vineLane.id}`;
          coordinates = [];
          if (
            vineLane.vertices &&
            vineLane.vertices.length &&
            vineLane.vertices.length > 0
          ) {
            const { latitude, longitude } = vineLane.vertices[0];
            let xyOrLatLong = true;
            if (latitude && longitude) {
              xyOrLatLong = true;
            } else {
              xyOrLatLong = false;
            }
            if (xyOrLatLong) {
              vineLane.vertices.map((item: any) => {
                const { latitude, longitude } = item;
                coordinates.push(proj.fromLonLat([longitude, latitude]));
              });
            } else {
              vineLane.vertices.map((pointData: any) => {
                pointData.xNew = pointData.x / 100;
                pointData.yNew = pointData.y / 100;
                const latAndLong: [number, number] = getLatitudeAndLongitude(
                  [pointData.yNew, pointData.xNew],
                  base,
                  true,
                );
                coordinates.push(proj.fromLonLat(latAndLong));
              });
            }
          }
          if (coordinates && coordinates.length > 0) {
            const vinelanePoly = new Feature({
              type: 'Polygon',
              geometry: new Polygon([coordinates]),

              data: vineLane,
              name: vineLane.name,
              pointType: types.VINELANEHEAD,
              pointTypeHover: types.VINELANEHEAD,
              mousePointType: types.VINELANEHEAD,
              startIndex: index,
              startIndexUuid: vineLane.vine_lane_uuid,
              style: getSateLightPolyStyle('vineLaneGreen', priority.VINEROW),
            });

            vinelanePoly.setId(id);
            vinelanePoly.setStyle(
              getSateLightPolyStyle('vineLaneGreen', priority.VINEROW),
            );

            if (
              setUpKeys.startWith &&
              (setUpKeys.startWith === 'A' || setUpKeys.startWith === 'B')
            ) {
              if (setUpKeys.isFirstTime) {
                vineRowFeatures.push(vinelanePoly);
              }
            }
          }
          if (!setUpKeys.isFirstTime) {
            pointAFeatures.push(pointLocation1);
            pointBFeatures.push(pointLocation2);
          }
        }
      });
      const feats = [
        ...vineLaneFeatures,
        ...pointAFeatures,
        ...pointBFeatures,
        ...vineRowFeatures,
      ];
      vineLaneLayerRef.current?.getSource().clear();
      vineLaneLayerRef.current?.getSource().addFeatures(feats);

      if (setUpKeys && setUpKeys.startWith === '') {
        recenterTractor(Number(selectedTractorId), 23);
      }
      // isLastTime --> true => redirect to polygon
      if (
        setUpKeys &&
        ((setUpKeys.isFirstTime && setUpKeys.endIndexUuid === '') ||
          setUpKeys.isLastTime)
      ) {
        displayRecenterAllViewPortSetUp(feats, mapRef.current, 150, 1000);
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  /**
   * VineLanes initialization Ends Here
   */
  const clearAll = () => {
    // to call clear command api
    clearCommandApiHandler(globalSelectedTractorId, 'LiveMap');
    resetForMapClicks('GOTO');
    // clear the preview path
    clearPreviewPath(globalSelectedTractorId);
    // to reset implement start here
    // after clea command from mqtt clear implement selection
    tractorImplementState[globalSelectedTractorId] = false;
    autoDriveDispatch({
      type: SET_TRACTOR_IMPLEMENT_STATE,
      payload: tractorImplementState,
    });
    // to reset implement ends here
  };
  const resetForMapClicks = (item: string) => {
    dispatch({ type: SET_SHOW_PRESENT_COMMAND, payload: item });
    globalShowPresentCommand = item;
    setPreCommandState('');

    commandForSecondTime = '';
    pointLayerRef?.current?.getSource().clear();
    vineLaneLayerRef.current?.getSource().clear();
    setFarm(true);
    selectFarmItems(true);
    dispatch({
      type: SET_SETUP_KEYS,
      payload: defaultSetUpKeys,
    });
    // for selected polygon name
    dispatch({
      type: SELECTED_ITEM_NAME,
      payload: '',
    });
    dispatch({
      type: SELECTED_VINE_BLOCK,
      payload: '',
    });
    // to clear the routine selection
    dispatch({
      type: SELECTED_ROUTINE,
      payload: null,
    });
    // no need to close pop when resetting
  };

  const addRowFollowRVCommand = async () => {
    try {
      const { organization } = userDetails;
      if (organization && organization.api_url) {
        const commandData: any = {
          command: 'vine_block',
          block_name: selectedVineBlockName,
          vinerow_name_1: leftStartVineRowName,
          vinerow_name_2: rightStartVineRowName,
          vinerow_name_3: leftEndVineRowName,
          vinerow_name_4: rightEndVineRowName,
          head_name: setUpKeys.startWith,
          row_seq: setUpKeys.everyFilter,
          implement: selectedImplementName ? selectedImplementName : null,
        };
        await addRemoteAVCommand(
          organization.api_url,
          [commandData],
          selectedTractor?.heartbeat?.drive_action_details
            ?.current_drive_action as string,
        );

        commandData.name = selectedItemNameGlobal;
        selectedTractor &&
          selectedTractor.id &&
          saveCommandTOLocalDB(selectedTractor.id, commandData);
      }
    } catch (err: any) {
      notification.error({
        message: err.message,
        duration: 1,
      });
    } finally {
      // console.log('error');
    }
  };
  useEffect(() => {
    preCommandGlobal = preCommandState;
  }, [preCommandState]);

  useEffect(() => {
    selectedItemNameGlobal = selectedItemName;
  }, [selectedItemName]);
  // routine clear starts here
  const clearRoutine = () => {
    const defaultLayer = pointLayerRef.current;
    const features = defaultLayer?.getSource().getFeatures();
    const feats: any[] = [];
    features &&
      features.map((feature: any) => {
        const pointType = feature.get('pointType');
        if (pointType && pointType !== pathConstants.ROUTINE) {
          feats.push(feature);
        }
      });
    defaultLayer?.getSource().clear();
    defaultLayer?.getSource().addFeatures(feats);
  };
  // routine clear ends here

  useEffect(() => {
    // clear the routine
    clearRoutine();
    userDetails &&
      userDetails.organization &&
      selectedRoutine &&
      (selectedRoutine.route_uuid ||
        selectedRoutine.incomplete_operation_uuid) &&
      getRoutinePoints();
  }, [selectedRoutine, userDetails, showRoutine]);
  const getRoutinePoints = async () => {
    setLoader(true);
    try {
      const routinePoints =
        selectedRoutine && selectedRoutine.incomplete_operation_uuid
          ? await getCompleteOperationPoints(
              userDetails.organization.api_url,
              selectedRoutine.incomplete_operation_uuid,
            )
          : await getRoutePoints(
              userDetails.organization.api_url,
              userDetails.organization.farm.id,
              selectedRoutine.id,
            );

      if (
        (selectedRoutine.incomplete_operation_uuid &&
          routinePoints.path.length > 0) ||
        (routinePoints && routinePoints.length > 0)
      ) {
        const coordinates: any = [];
        if (selectedRoutine.incomplete_operation_uuid) {
          const listData = routinePoints.path.split('|');
          listData.forEach((element: any) => {
            coordinates.push(proj.fromLonLat(element.split(',').reverse()));
          });
        } else {
          routinePoints.forEach((element: any) => {
            coordinates.push(
              proj.fromLonLat([element.longitude, element.latitude]),
            );
          });
        }

        const layerLines = new Feature({
          geometry: new LineString(coordinates),
          name: `Routine: ${
            selectedRoutine?.name || selectedRoutine?.param_name
          }`,
          pointType: pathConstants.ROUTINE,
          color: getPathColor(pathConstants.ROUTINE),
          borderColor: getPathBorderColor(pathConstants.ROUTINE),
        });
        layerLines.setStyle(
          getPathFeatureStyle(pathConstants.ROUTINE, getZoomValue()),
        );
        pointLayerRef?.current?.getSource().addFeature(layerLines);

        if (mapRef.current && selectedTractorLocation && layerLines) {
          displayRecenterAllViewPortSetUp(
            [layerLines as Feature<Geometry>, selectedTractorLocation],
            mapRef.current,
          );
        }
      }
    } catch (error: any) {
      notification.error({
        message: 'No Data Found',
        duration: 1,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (showSnapshot) {
      setSnapshotContent(snapShotInitiated);
      setShowSnapShotView(true);
    }
    return () => {
      dispatch({
        type: SET_SHOW_SNAPSHOT,
        payload: false,
      });
    };
  }, [showSnapshot]);

  const Close = () => {
    setAddobservationFlag(false);
  };

  const ChatIcon = useMemo(
    () => <div className="mapLayerIcon2" onClick={() => setOpen(true)}></div>,
    [user],
  );
  useEffect(() => {
    userDetails &&
      userDetails.organization &&
      mapInitialized &&
      isVineYardOrDairy(userDetails) === dairyAutoDrive &&
      getFeedLanes();
  }, [userDetails, mapInitialized]);
  const getFeedLanes = async () => {
    const { organization } = userDetails;
    try {
      const list = await getFeedLanesData(
        organization.api_url,
        organization.farm.id,
      );
      feedLaneFeaturesLayerRefs?.current?.getSource().clear();
      const lanesContent =
        list && list.records && list.records.length > 0 ? list.records : [];
      dispatch({ type: SET_FEED_LANES, payload: lanesContent });
      lanesContent.forEach((feedLane: any) => {
        const vineRow = [];
        const {
          head_a_x,
          head_a_y,
          head_b_x,
          head_b_y,
          head_a_latitude,
          head_a_longitude,
          head_b_latitude,
          head_b_longitude,
          head_a_altitude,
          head_a_bearing,
        } = feedLane;

        const latAndLong1: [number, number] =
          head_a_latitude && head_a_longitude
            ? [head_a_longitude, head_a_latitude]
            : getLatitudeAndLongitude(
                [head_a_y / 100, head_a_x / 100],
                base,
                true,
              );
        const latAndLong2: [number, number] =
          head_b_latitude && head_b_longitude
            ? [head_b_longitude, head_b_latitude]
            : getLatitudeAndLongitude(
                [head_b_y / 100, head_b_x / 100],
                base,
                true,
              );

        vineRow.push(proj.fromLonLat(latAndLong1));
        vineRow.push(proj.fromLonLat(latAndLong2));
        const laneFeature = new Feature({
          geometry: new LineString(vineRow),
          data: feedLane,
          name: feedLane.name,
          id: feedLane.id,
          pointType: types.FEED_LANE,
        });
        const id3 = `vineLane_vineRow_${feedLane.id}`;
        const feedHead = new Feature({
          geometry: new Point(proj.fromLonLat(latAndLong1)),
          name: feedLane.name,
          data: feedLane,
          pointType: types.FEED_LANE_HEAD,
        });
        feedHead.setId(id3 + '_1');
        feedHead.setStyle(feedHeadStyle(head_a_bearing));
        laneFeature.setStyle(feedLaneStyle);
        laneFeature.setId(id3);
        feedLaneFeaturesLayerRefs.current?.getSource().addFeature(laneFeature);
        feedLaneFeaturesLayerRefs.current?.getSource().addFeature(feedHead);
      });
    } catch (error) {
      console.log('Error in FeedLane drawing');
    }
  };
  const showTabs = () =>
    commandsList &&
    selectedTractor &&
    selectedTractor.id &&
    commandsList[selectedTractor.id] &&
    commandsList[selectedTractor.id].length > 0 &&
    showTractorStart &&
    showTractorStart[selectedTractor.id]
      ? true
      : false;
  useEffect(() => {
    mapInitialized && handleFeedLanes();
  }, [
    mapInitialized,
    selectedFeedLanes,
    selectedTractor,
    commandsList,
    showTractorStart,
  ]);
  const handleFeedLanes = async () => {
    const names = selectedFeedLanes.map((feed: FeedLaneType) => feed.id);
    const layer = feedLaneFeaturesLayerRefs.current;
    const features = layer?.getSource().getFeatures();
    features.map((feature: any) => {
      const pointType = feature.get('pointType');
      if (pointType === types.FEED_LANE) {
        feature.setStyle(feedLaneStyle);
        const id = feature.get('id');
        if (!showTabs() && names.includes(id)) {
          feature.setStyle(selectedFeedLaneStyle);
        }
      }
    });
  };

  return (
    <>
      <div
        className="mapLayerIcon"
        data-testid="mapLayer-LiveMap"
        onClick={(e) => {
          e.stopPropagation();
          setMapLayersToggle(!mapLayersToggle);
        }}
      ></div>
      {checkAlphaFeature(envVar.FarmObservations) && (
        <div className="fbsSearchIcon">
          <img
            src={fbIcon}
            alt="famrobs"
            onClick={() => setAddobservationFlag(true)}
          />
        </div>
      )}
      {showConverseChat && ChatIcon}
      {open && <Converse open={open} setOpen={setOpen} />}
      <LiveMapFleetList
        setRecenterTabId={setRecenterTabId}
        initTravelledPath={initTravelledPath}
        heartBeat={heartBeat as TractorHeartBeat}
        selectedTractorFromFleetId={selectedTractorFromFleetId as number}
        polygons={polygons}
        recenterItem={(item) => recenterItem(item)}
        clearPreviewPath={clearPreviewPath}
        setSelectedTractorsForMaps={(items) => (globalSelectedTractors = items)}
        handleResetCommand={(item: string) => {
          resetForMapClicks(item);
        }}
        addRowFollowRVCommand={addRowFollowRVCommand}
        preCommandState={preCommandState}
        setRedirectToggle={setRedirectToggle}
        redirectToggle={redirectToggle}
      />
      <MapIcons
        selectedTractorId={selectedTractorId}
        recenterTractor={recenterTractor}
        map={mapRef.current}
        fieldFeatureItem={fieldFeatureItem.current as Feature<Geometry>}
        displayRecenterViewPortSetUp={displayRecenterViewPortSetUp}
        toggleSatellite={toggleSatellite}
        setToggleSatellite={setToggleSatellite}
      />
      <MapLayers
        mapLayersToggle={mapLayersToggle}
        setMapLayersToggle={setMapLayersToggle}
        showBasestations={showBasestations}
        setShowBasestations={setShowBasestations}
        farm={farm}
        setFarm={setFarm}
        showWaypoints={showWaypoints}
        setShowWaypoints={setShowWaypoints}
        showPath={showPath}
        setShowPath={setShowPath}
        showDataLayers={showDataLayers}
        setShowDataLayers={setShowDataLayers}
        showWhitePolygons={showWhitePolygons}
        setShowWhitePolygons={setShowWhitePolygons}
        showGrayPolygons={showGrayPolygons}
        selectFarmItems={selectFarmItems}
        showGreenPolygons={showGreenPolygons}
        setShowGreenPolygons={setShowGreenPolygons}
        showBrownPolygons={showBrownPolygons}
        setShowBrownPolygons={setShowBrownPolygons}
        showBluePolygons={showBluePolygons}
        setShowBluePolygons={setShowBluePolygons}
      />
      <div
        ref={mapElement}
        style={{ height: '100vh' }}
        className={`posHd ${
          selectedTractor && selectedTractor.id && showRemoteDriveDetails
            ? 'afterMapRightAlign'
            : ''
        }`}
      >
        <MapLoader loader={loader} />
        {selectedTractor && selectedTractor.id && (
          <div
            id={applicationIds.liveMap.rowBtn}
            className="dataLayerBtn"
            onClick={() =>
              dispatch({
                type: SET_SHOW_REMOTE_DRIVE_DETAILS,
                payload: !showRemoteDriveDetails,
              })
            }
          >
            <span className="dataArrow"></span>
            <span className="dLIcon">
              <img src={dataLayerIcon} alt="dataLayer" height="18" />
            </span>
            <span className="dLTxt">TRACTOR DETAILS</span>
          </div>
        )}
      </div>
      <div ref={container} className="ol-popup" style={{ display: 'block' }}>
        <div ref={content}></div>
      </div>
      {/* displaying the indicator details */}
      {showIndicatorModel && (
        <DiagnosticWidget
          isEdit={isEdit}
          details={errorDetails}
          setIsEdit={setIsEdit}
          setToggleWidget={setShowIndicatorModel}
          getSeverity={getSeverity}
          getTags={getTags}
          onCreateTicket={onCreateTicket}
          toggleWidget={showIndicatorModel}
          hideVideos={true}
        />
      )}
      {/* displaying the ticket details */}
      {toggleTicketWidget && (
        <TicketWidget
          details={ticketDetails}
          onCloseTicketWidget={onCloseTicketWidget}
          getSeverity={getSeverity}
          toggleTicketWidget={toggleTicketWidget}
          tractorId={tractorId}
        />
      )}
      {/* displaying the routene selection popup, displaing the routen path on map */}
      {isLoginUserAndDriveActionUserSame(selectedTractor, userDetails.id) &&
        showRoutine && (
          <RoutineNewDesign
            showModal={showRoutine}
            tractorName={getNameFromList(allTractorsList, selectedTractorId)}
            handleCancel={(routine?: any) => {
              dispatch({ type: SET_SHOW_ROUTINE, payload: false });
              if (routine && routine.id) {
                dispatch({
                  type: SET_SHOW_PRESENT_COMMAND,
                  payload: 'ROUTINE',
                });
              }
            }}
          />
        )}
      {iconsFlag && <DiagnosticsModal iconsFlag={iconsFlag} />}
      {/* displaying the snapshot details */}
      {showSnapShotView && (
        <SnapshotView
          Close={() => setShowSnapShotView(false)}
          snapshotFlag={showSnapShotView}
          snapshotList={snapshotContent}
          hideAnalytics={true}
          showLoader={true}
        />
      )}
      {/* displaying the logout popup */}
      {isHaveErrors(autoDriveLogoutErrorCodes) && (
        <AutoDriveLogoutErrorPopup
          autoDriveLogoutErrorCodes={autoDriveLogoutErrorCodes}
          showModal={isHaveErrors(autoDriveLogoutErrorCodes)}
        />
      )}
      {/* displaying error codes */}
      {selectedTractorsIds &&
        selectedTractorsIds.length > 0 &&
        checkAutoDriveMonitorErrorCodes(
          autoDriveMonitorErrorCodes,
          selectedTractorsIds[0],
        ) &&
        autoDriveMonitorPop && (
          <Errorcodepopup
            showModal={autoDriveMonitorPop}
            closeModel={() =>
              dispatch({ type: SET_AUTO_DRIVE_MONITOR_POP, payload: false })
            }
          />
        )}
      {addobservationFlag && (
        <AddFarmObservation
          addobservationFlag={addobservationFlag}
          Close={Close}
        />
      )}
      {contextHolder}
      {/* {showImplementWidthHandler && (
        <CommonPopAutoDrive
          showModal={true}
          closeModel={(state: boolean) => {
            // first=> button return always false
            // second => button return always true
            // setShowEditConfirm(false);
            // state => false change the tab

            state &&
              dispatch({
                type: SET_SHOW_IMPLEMENT_HANDLER,
                payload: false,
              });
          }}
          title="Rows Too narrow"
          description={`This implement can only operate in blocks with minimum row width of ${(
            convertInchesToFeet(selectedImplementWidth) + 2
          ).toFixed(2)} feet (${
            (convertInchesToFeet(selectedImplementWidth) + 2) * 12
          }  inches)`}
          firstButtonText="Yes, Edit Field"
          secondButtonText="Select Another Block"
          showFirstButton={false}
        />
      )} */}
    </>
  );
});

LiveMap.displayName = 'LiveMap';

export default LiveMap;
