import helpCenterIcon from './images/knowledgebase_2.svg';
import permissionsIcon from './images/permissions.svg';
import ticketsIcon from './images/tickets_outline-01.svg';
import tractorUpdateIcon from './images/tractor-update.svg';

export const accessItems = [
  {
    name: 'Create New Personnel',
    checked: true,
  },
  {
    name: 'Access Operator Codes',
    checked: true,
  },
  {
    name: 'Launch Autodrives',
    checked: false,
  },
  {
    name: 'Operator Assist Mode',
    checked: true,
  },
  {
    name: 'Schedule Updates',
    checked: false,
  },
];

export const welcomeListItems = [
  {
    title: 'Tractor Updates',
    icon: tractorUpdateIcon,
    description:
      'Unlock continuous tractor improvement by scheduling and monitoring your software updates from anywhere.',
  },
  {
    title: 'Tickets',
    icon: ticketsIcon,
    description:
      'Request support for tractor issues by capturing relevant field details and submitting them for review.',
  },
  {
    title: 'Help Center',
    icon: helpCenterIcon,
    description:
      "Refresh yourself on Monarch's products with training videos and reference materials.",
  },
  {
    title: 'Manage Personeel',
    icon: permissionsIcon,
    description:
      'Control MK-V access and user permissions for each employee in your organization.',
  },
];
