/* eslint-disable no-console */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import classNames from 'classnames';

interface Props {
  label?: any;
  value?: any;
  cssClass?: any;
  setValue?: (data: any) => void;
  options?: any;
  optionKey?: any;
  optionDisplay?: any;
  optionDisplay2?: string;
  jsonFormat?: boolean;
  required?: any;
  validations?: any;
  testId?: any;
  isAll?: boolean;
  className?: string;
  ketValue?: any;
  showSearch?: boolean;
  isState?: boolean;
}

const CustomStateSelect: React.FC<Props> = ({
  label,
  value,
  setValue,
  cssClass,
  options,
  optionKey = 'id',
  optionDisplay = 'name',
  optionDisplay2 = '',
  jsonFormat,
  required,
  validations,
  testId,
  isAll,
  className,
  ketValue,
  showSearch,
  isState = false,
}: Props) => {
  const [focus, setFocus] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const isEmpty = (value: any) => {
    if (typeof value === 'undefined') return true;
    if (typeof value === 'string' && value.trim() === '' && !isAll) return true;
    if (typeof value === 'number' && isNaN(value)) return true;
    return false;
  };
  const labelClass = focus || !isEmpty(value) ? 'label label-float' : 'label';
  const handleDropdownVisibleChange = (open: boolean) => {
    console.log('Dropdown Open:', open);
    setIsDropdownOpen(open);
  };
  return (
    <div
      className={`float-label ${className}`}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Select
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        data-testid={testId}
        style={{ width: '100%' }}
        value={value}
        onSelect={(e: any) => {
          setValue && setValue(e);
        }}
        showSearch={!!showSearch}
        optionFilterProp="children"
        className={
          !validations && required
            ? classNames({
                errorClass: !validations && required,
              })
            : cssClass
        }
        onDropdownVisibleChange={handleDropdownVisibleChange}
      >
        {isAll && <option value="">All</option>}
        {options &&
          options?.length > 0 &&
          options?.map((data: any) => {
            return (
              <option
                value={jsonFormat ? JSON.stringify(data) : data[optionKey]}
                key={data[optionKey]}
                data-testid={`${
                  optionDisplay ? data[optionDisplay] : data[optionKey]
                }-CustomSelect`}
                disabled={data && data.disabled ? true : false}
              >
                {isState ? (
                  isDropdownOpen ? (
                    data[optionDisplay]
                  ) : (
                    data[optionDisplay2]
                  )
                ) : (
                  <>
                    {optionDisplay ? (
                      optionDisplay2 ? (
                        <span
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            padding: '0px 8px',
                          }}
                        >
                          <span>{data[optionDisplay]}</span>
                          <span>{data[optionDisplay2]}</span>
                        </span>
                      ) : (
                        data[optionDisplay]
                      )
                    ) : (
                      data[optionKey]
                    )}
                  </>
                )}
                {ketValue || ''}
              </option>
            );
          })}
      </Select>
      <label className={labelClass}>
        {label} {required && <span className="float-span">*</span>}
      </label>
    </div>
  );
};

export default CustomStateSelect;
