import React from 'react';
import { useHistory } from 'react-router-dom';
import './styles.scss';

interface CardProps {
  title: string;
  description: string;
  path: string;
  icon: string;
}

export const CardBlock: React.FC<CardProps> = ({
  path,
  title,
  description,
  icon,
}) => {
  const { push } = useHistory();

  return (
    <div
      className="cardsBlock"
      onClick={() => {
        push({
          pathname: path,
        });
      }}
    >
      <div className="cardIcon cardsmtb">
        <img src={icon} alt="" />
      </div>
      <div className="cardsInfoSec">
        <div className="cardsHeadTxt">{title}</div>
        <div className="cardsDesc">{description}</div>
      </div>
    </div>
  );
};

const DealerDashboardPage: React.FC<{
  title: string;
  children: React.ReactElement | React.ReactElement[];
}> = ({ title, children }) => {
  return (
    <>
      <div className="cardsContainer">
        <div className="cardsHeader"></div>
        <div className="cardsContentArea">
          <div className="cardsTitle mb20">{title}</div>
          <div className="cardsBlockParent">{children}</div>
        </div>
      </div>
    </>
  );
};

export default DealerDashboardPage;
