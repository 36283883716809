/* eslint-disable react/jsx-key */
/* eslint-disable no-console */
import { Col, Menu, Row, notification } from 'antd';
import $ from 'jquery';
import React, { useContext, useEffect, useState } from 'react';

import { ApplicationContext } from '../../context/AppContext';
import AppLoader from '../common/AppLoader';
import { useHelpContext } from './HelpCenterContext';
import { getSubCategories } from './services/API';

import '../HelpCenter/styles.css';
import { Viewer } from './common/Viewer';
import useArticles from './hooks/useArticle';

interface Props {
  title: string;
}

const SubCategoryContent: React.FC<Props> = ({ title }) => {
  const { HCReducer } = useHelpContext();
  const { userDetails } = useContext(ApplicationContext);
  const [state] = HCReducer;
  const { category, video, subCategory } = state;
  const [subCategories, setSubCategories] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const fetchSubCategories = async () => {
    try {
      setLoader(true);
      const { organization } = userDetails;
      const { api_url } = organization;
      const result = await getSubCategories(api_url, category?.id);
      const data = Array.isArray(result?.data) ? result?.data : [];
      if (data.length) setSelectedKeys([data[0].id]);
      setSubCategories(data);
    } catch (error: any) {
      notification.error({ message: error.message });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (userDetails?.organization && category) fetchSubCategories();
  }, [category]);

  useEffect(() => {
    function onScroll(event: any) {
      const scrollPos = $(document).scrollTop();
      $('#menu-center .dataId').each(function () {
        const currLink: any = $(this);
        const refElement: any = $(`#${currLink.data('id')}`);
        // if (
        //   refElement.position().top <= Number(scrollPos) &&
        //   refElement.position().top + refElement.height() > Number(scrollPos)
        // ) {
        if (refElement.position().top - 36 <= Number(scrollPos)) {
          $('#menu-center  .dataId').removeClass('active');
          // currLink.addClass('active');
          setSelectedKeys([currLink.data('id')]);
        } else {
          currLink.removeClass('active');
        }
      });
    }

    const videoContainerDiv =
      document.getElementsByClassName('videoContainer')[0];
    if (videoContainerDiv)
      videoContainerDiv.addEventListener('scroll', onScroll);
  }, [document]);

  const handleSelect = (id: string) => {
    $('#menu-center  .dataId').removeClass('active');
    const element = document?.getElementById(id);
    element && element?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (subCategory) {
      setLoader(true);
      setTimeout(() => {
        setLoader(false);
        handleSelect(subCategory);
      }, 15 * 1000);
    }
  }, [subCategory]);

  return (
    <>
      {!video && (
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <div className="left_section_scroll">
              <div className="byCat_section">
                <h2 className="byCat_head">By Section</h2>
                <div className="categoryItemsList">
                  <ul id="menu-center">
                    <Menu selectedKeys={selectedKeys} mode="vertical">
                      {subCategories &&
                        subCategories.map((item: any, i: number) => {
                          return (
                            <Menu.Item
                              key={item?.id ? item.id : i}
                              onClick={() => handleSelect(item.id)}
                            >
                              <span className="dataId" data-id={item?.id}>
                                {item.name}
                              </span>
                              {item?.count && <span>(0)</span>}
                            </Menu.Item>
                          );
                        })}
                    </Menu>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            <div className="tabHead_Content">
              <div className="videoContainer" style={{ width: '69vw' }}>
                {subCategories &&
                  subCategories.map((ele: any, i: number) => (
                    <div id={`${ele.id}`}>
                      <div className="tabHead_section">
                        <div className="tabHead_count">
                          {i == 0 && (
                            <>
                              <h1 className="tabTitle_head">{title}</h1>
                              <p className="tabTitle_desc">
                                {subCategories?.length &&
                                  subCategories[0]?.description}
                              </p>
                            </>
                          )}
                          <span>{ele?.name}</span>
                        </div>
                      </div>
                      <DocumentView key={ele.id} subCategoryId={ele.id} />
                    </div>
                  ))}
                <AppLoader loader={loader} />
              </div>
            </div>
          </Col>
        </Row>
      )}

      {/* <AppLoader loader={loader} /> */}
    </>
  );
};

interface Props1 {
  subCategoryId: number;
}

const DocumentView: React.FC<Props1> = ({ subCategoryId }) => {
  const { data, setSubCategoryId, loader } = useArticles();

  useEffect(() => {
    if (subCategoryId) setSubCategoryId(subCategoryId);
  }, [subCategoryId]);

  return (
    <div className="tabHead_Content">
      <AppLoader loader={loader} />
      <Viewer data={data} />
    </div>
  );
};

export default SubCategoryContent;
