/* eslint-disable react/jsx-key */
import { Button, Space } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { track } from '../../util/logger';
import { useHistory, useLocation } from 'react-router-dom';
import closeGreen from '../../assets/images/closeGreen.svg';
import msgIcon from '../../assets/images/msgIcon.svg';
import kbIcon from '../../assets/images/kb-icon-sg-round.svg';
import { getVideoURL } from '../../constants/Api';
import {
  initClearSelection,
  sortCameraViews,
  tractorCameras,
} from '../../constants/Common';
import { privilagesConstants } from '../../constants/Privilages';
import { TOGGLE_VIDEO_WIDGET } from '../../context/actions';
import { ApplicationContext } from '../../context/AppContext';
import applicationIds from '../../locale/applicationIds.json';
import translate from '../../locale/en_translate.json';
import RoutesConstants from '../../routes/RoutesConstant';

interface Props {
  details: any;
  isEdit: boolean;
  setIsEdit: (key: boolean) => void;
  setToggleWidget: (key: boolean) => void;
  getSeverity: (key: number) => void;
  getTags: (record: any) => any;
  onCreateTicket?: () => void;
  toggleWidget: boolean;
  tractorId?: number;
  hideVideos?: boolean; // optional
  showKnowledgeBase?: (error_code: number) => void;
}

const DiagnosticWidget: React.FC<Props> = ({
  details,
  setToggleWidget,
  isEdit,
  setIsEdit,
  getSeverity,
  getTags,
  onCreateTicket,
  toggleWidget,
  tractorId,
  hideVideos,
  showKnowledgeBase,
}) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { APPReducer, privilegeChecker, userDetails } =
    useContext(ApplicationContext);
  const [showVideos, setShowVideos] = useState(false); // true -> default show videos, false -> to hide(from live map)
  const [state, dispatch] = APPReducer;

  useEffect(() => {
    hideVideos && setShowVideos(false);
  }, [hideVideos]);

  useEffect(() => {
    const checkVideos = async () => {
      setShowVideos(false);
      if (tractorId) {
        const response = await getVideoURL(
          userDetails.organization.api_url,
          userDetails.organization.farm.id,
          tractorId,
          details?.created_date_time,
        );
        const result = sortCameraViews(response, 'direction', 'videos').filter(
          (item: any) =>
            Object.values(tractorCameras).join(',').includes(item.direction),
        );
        setShowVideos(result.length > 0);
      }
    };
    if (userDetails?.organization && tractorId && details) checkVideos();
  }, [details]);

  const handleVideos = () => {
    const { created_date_time: videoTime, tractor_id: tractorId } = details;
    dispatch({
      type: TOGGLE_VIDEO_WIDGET,
      payload: {
        toggle: !state.videoWidgetData.toggle,
        videoTime: videoTime,
        tractorId: tractorId,
      },
    });
  };

  return (
    <>
      {details && (
        <>
          <div
            className={'rightSideWidegt ' + (toggleWidget ? 'expand' : 'hide')}
          >
            <div className="widgetCloseBtn">
              <img
                id={applicationIds.diagnosticsScreen.diaCloseBtn}
                src={closeGreen}
                alt="close icon"
                onClick={() => {
                  setToggleWidget(false);
                  setIsEdit(false);
                  initClearSelection();
                  track('CloseDiagnostics', {
                    event: 'Diagnostics Close',
                  });
                }}
              />
            </div>
            {!isEdit ? (
              <>
                <div className="profileView rightWidgetView widget_card">
                  <div className="proRow tcktInfo">
                    <h4 className="headline3">
                      {t(translate.diagnsotics.errorCode)} {details.error_code}
                    </h4>
                    <h3 className="overline1"> {details.modified_date_time}</h3>
                  </div>

                  <div className="proRowType rowGapBtm3">
                    <h5 className="subFlex">
                      <span className="subTitles headline4">
                        {t(translate.headers.severityLevel)}
                      </span>
                      <span className="subdesc body2">
                        {getSeverity(details.severity)}
                        {details.severity === 1 && (
                          <>{t(translate.diagnsotics.severity.low)}</>
                        )}
                        {details.severity === 2 && (
                          <>{t(translate.diagnsotics.severity.medium)}</>
                        )}
                        {details.severity === 3 && (
                          <>{t(translate.diagnsotics.severity.high)}</>
                        )}
                      </span>
                    </h5>
                  </div>
                  <div className="proRowType rowGapBtm3">
                    <Space style={{ display: 'inline' }}>
                      <h5 className="subFlex">
                        <span className="subTitles headline4">
                          {t(translate.headers.indicatorType)}
                        </span>
                        <span className="subdesc body2">
                          {details.indicator_type}
                        </span>
                      </h5>
                    </Space>
                  </div>
                  <div className="proRowType rowGapBtm3">
                    <Space style={{ display: 'inline' }}>
                      <h5 className="subFlex">
                        <span className="subTitles headline4">
                          {t(translate.headers.issue)}
                        </span>
                        <span className="subdesc body2">
                          {details.subsystem}
                        </span>
                      </h5>
                    </Space>
                  </div>
                  <div className="proRowType rowGapBtm3">
                    <h5 className=" headline4">
                      {t(translate.headers.description)}
                    </h5>
                    <div className="body2 txtLftGap mt14 w100 desription">
                      {details.description}
                    </div>
                  </div>
                  <div className="proRowType rowGapBtm3 functionalityTag">
                    <h5 className=" headline4">
                      {t(translate.headers.affectedFunctionality)}
                    </h5>
                    <div className="proRoleValue mt14">{getTags(details)}</div>
                  </div>
                  {privilegeChecker(
                    privilagesConstants.Create_a_ticket_from_diagnostic_code,
                  ) && (
                    <div className="proRowType rowGapBtm3">
                      <h5 className=" headline4">
                        {t(translate.diagnsotics.ticketsAndTasks)}
                      </h5>
                      <h6
                        id={applicationIds.diagnosticsScreen.createTicket}
                        className="createTcktBtn mt16 txtLftGap"
                        onClick={onCreateTicket}
                      >
                        <span className="addCBtn createTcktTxt">
                          <img src={msgIcon} height="43px" />
                        </span>
                        <span className="ticketComments headline5">
                          {t(translate.tickets.createTicketTitle)}
                        </span>
                      </h6>
                    </div>
                  )}

                  {process.env.REACT_APP_ALPHA_FEATURES &&
                    process.env.REACT_APP_ALPHA_FEATURES.includes(
                      'KnowledgeBase',
                    ) &&
                    pathname.includes(RoutesConstants.FleetHealth) && (
                      <div className="proRowType rowGapBtm3">
                        <h5 className="fSbd overline1">
                          {t(translate.diagnsotics.learnMore)}
                        </h5>
                        <h6
                          id={applicationIds.diagnosticsScreen.createTicket}
                          className="createTcktBtn mt16 txtLftGap"
                          onClick={() => {
                            push({
                              pathname: RoutesConstants.FleetHealth,
                              state: {
                                action: 'Knowledge Base',
                                errorCode: details?.error_code,
                              },
                            });
                            showKnowledgeBase &&
                              showKnowledgeBase(details.error_code);
                          }}
                        >
                          <span className="addCBtn createTcktTxt">
                            <img src={kbIcon} height="43px" />
                          </span>
                          <span className="ticketComments">
                            {t(translate.diagnsotics.knowledgeBase)}
                          </span>
                        </h6>
                      </div>
                    )}

                  {showVideos && (
                    <Button
                      type="primary"
                      className="employeeCreate w160"
                      onClick={handleVideos}
                    >
                      {t(translate.diagnsotics.viewVideos)}
                    </Button>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default DiagnosticWidget;
