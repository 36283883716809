import React from 'react';
import msUserIcon from '../../assets/images/ms_usericon.svg';
import msFleetBoxIcon from '../../assets/images/ms_fleet_iconbox.svg';
import msImplementBoxIcon from '../../assets/images/ms_implement_iconbox.svg';
import './styles.scss';

interface Props {
  title: string;
  handleBack: () => void;
}

const MSHeader: React.FC<Props> = ({ title, handleBack }) => {
  return (
    <div className="historyBackHead mb20">
      <div className="headPlusBack">
        <div className="backButtonBig" onClick={handleBack}></div>
        <span className="headPlusTxt">{title}</span>
      </div>
      <div>
        {title == 'Personal Directory' && (
          <img src={msUserIcon} alt="usericon" />
        )}
        {title == 'Fleet Directory' && (
          <img src={msFleetBoxIcon} alt="usericon" />
        )}
        {title == 'Implements Directory' && (
          <img src={msImplementBoxIcon} alt="usericon" />
        )}
      </div>
    </div>
  );
};

export default MSHeader;
