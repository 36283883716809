import { forwardRef, useImperativeHandle, useState } from 'react';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Checkbox, Col, Row, Slider, Spin, notification } from 'antd';
import useOperationalAnalyticsSearchStore from './store/useOperationalAnalyticsSearchStore';
import useAnalyticStore from './store/operationAnalytics';
import useTractorAnalyticDetailsStore from './store/tractorAnalyticDetails';
import downArrow from './images/down-arrow.svg';
import impIcon from './images/hitch_implement.svg';
import durationIcon from './images/du_hr.svg';
import tkt_icon from './images/tktIcon.svg';
import gdr_icon from './images/gdrIcon.svg';
import alt_icon from './images/altIcon.svg';
import cam_icon from './images/camIcon.svg';
import distanceIcon from './images/distancenimp.svg';
import { getAnalyticResults } from './services/api';
import {
  fetchName,
  getHrsMinsFromMins,
  getUserName,
  isActiveClass,
  isEmpty,
  removeItem,
} from './common';
import InfiniteScroll from 'react-infinite-scroll-component';
import AppLoader from '../common/AppLoader';

interface SliderFilter {
  tickets: [number, number];
  errorCodes: [number, number];
  guardrails: [number, number];
}

const defaultSliderFilter: SliderFilter = {
  tickets: [0, 100],
  errorCodes: [0, 100],
  guardrails: [0, 100],
};

const driveModes = ['manual', 'idle', 'autodrive', 'op_assist'];
const pageSize = 25;

const SearchResult: React.FC<any> = forwardRef(({ isSearch }: any, ref) => {
  const { analyticsFilter, selectedDateRange } =
    useOperationalAnalyticsSearchStore();
  const { setFromView, view, setSelectedDate } = useAnalyticStore();
  const { selectTractor } = useTractorAnalyticDetailsStore();
  const [results, setResults] = useState<any[]>([]);
  const [filtersResults, setFilterResults] = useState<any[]>([]);
  const [implementsList, setImplementsList] = useState<string[]>([]);
  const [selectedImplements, setSelectedImplements] = useState<string[]>([]);
  const [selectedDriveModes, setSelectedDriveModes] =
    useState<string[]>(driveModes);
  const [sliderFilter, setSliderFilter] = useState(defaultSliderFilter);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState(0);
  useImperativeHandle(ref, () => ({
    handleSearch(filter: any) {
      setFilterResults([]);
      if (pageNumber === 1) {
        init(filter);
      } else {
        setPageNumber(1);
      }
    },
  }));

  const mapData = (results: any[]) => {
    return results?.map((item, i: number) => {
      const {
        manual_hours,
        ideal_hours,
        autodrive_hours,
        operator_assist_hours,
        drive_action_uuids,
      } = item;
      return Object.assign({
        operationType: item.operation_type,
        date: item.date,
        drive_action_uuids,
        tractor: item.tractor,
        implementName: item.implement_name,
        tractorPin: item?.tractor?.tractor_pin,
        tractorShortPin: item?.tractor?.tractor_pin?.slice(-5),
        ticketsCount: item?.tickets_count,
        snapshotsCount: item?.snapshots_count,
        errorCodesCount: item?.error_codes_count,
        manual_hours,
        idle_hours: ideal_hours,
        autodrive_hours,
        op_assist_hours: operator_assist_hours,
        operationalHours: getHrsMinsFromMins(item?.total_operational_hours),
        distance: item?.distance?.toFixed(2),
        operators: item.operators_usage?.map((operator: any) => {
          const { user_first_name, user_last_name, operator_id } = operator;
          return Object.assign({
            id: operator_id,
            shortName: fetchName(user_first_name, user_last_name),
            name: getUserName(user_first_name, user_last_name),
            acreCovered: operator.total_coverge_area,
            operationalHours: getHrsMinsFromMins(
              operator.total_operational_hours,
            ),
          });
        }),
      });
    });
  };

  const init = async (filter = null) => {
    try {
      setLoading(true);
      const [fromDate, toDate] = selectedDateRange;
      const { operationTypes, tractorIds, operatorIds } = filter
        ? filter
        : analyticsFilter;
      const result: any = await getAnalyticResults(
        moment(fromDate).startOf('day')?.toDate()?.getTime(),
        moment(toDate).endOf('day')?.toDate()?.getTime(),
        operationTypes ?? [],
        tractorIds ?? [],
        operatorIds ?? [],
        pageNumber,
        pageSize,
      );
      setTotalCount(0);
      if (
        result &&
        result._meta_data &&
        result._meta_data.total_records_count
      ) {
        const count = result._meta_data.total_records_count
          ? result._meta_data.total_records_count
          : 0;
        setTotalCount(count);
      }
      let data: any[] = Array.isArray(result.records) ? result.records : [];
      data = mapData(data);
      if (data.length === 0) {
        if (pageNumber === 1) setResults([]);
        setHasMore(false);
      } else {
        if (pageNumber === 1) {
          setResults(data);
          let fImplements = data?.map(({ implementName }) => implementName);
          fImplements = fImplements?.filter(
            (item, i) => fImplements.indexOf(item) === i,
          );
          setImplementsList([...fImplements]);
          setSelectedImplements([...fImplements]);
        } else {
          const resData = [...results, ...data];
          let fImplements = resData?.map(({ implementName }) => implementName);
          fImplements = fImplements?.filter(
            (item, i) => fImplements.indexOf(item) === i,
          );
          setImplementsList([...fImplements]);
          setSelectedImplements([...fImplements]);
          setResults([...resData]);
        }
        setHasMore(data.length >= 24);
      }
    } catch (error: any) {
      notification.error({ message: error.message });
    } finally {
      setLoading(false);
    }
  };

  const keyMap: any = {
    manual: 'manual_hours',
    auto_drive: 'auto_drive_hours',
    idle: 'idle_hours',
    op_assist: 'op_assist_hours',
  };

  const filterByModes = (data: any[], filter: string) =>
    data.filter((item) => Boolean(item[keyMap[filter]]));

  const handleFilter = () => {
    const { tickets, guardrails, errorCodes } = sliderFilter;
    let fResult: any[] = [];
    if (selectedDriveModes.includes('manual'))
      fResult = [...fResult, ...filterByModes(results, 'manual')];
    if (selectedDriveModes.includes('auto_drive'))
      fResult = [...fResult, ...filterByModes(results, 'auto_drive')];
    if (selectedDriveModes.includes('idle'))
      fResult = [...fResult, ...filterByModes(results, 'idle')];
    if (selectedDriveModes.includes('op_assist'))
      fResult = [...fResult, ...filterByModes(results, 'op_assist')];
    fResult = fResult.filter(
      (item: any, i: number) =>
        fResult.findIndex(
          ({ drive_action_uuids }: any) =>
            drive_action_uuids === item.drive_action_uuids,
        ) === i,
    );
    fResult = fResult.filter((item: any) => {
      return (
        item.ticketsCount >= tickets[0] &&
        item.ticketsCount <= tickets[1] &&
        item.errorCodesCount >= errorCodes[0] &&
        item.errorCodesCount <= errorCodes[1] &&
        item.snapshotsCount >= guardrails[0] &&
        item.snapshotsCount <= guardrails[1] &&
        selectedImplements.includes(item.implementName)
      );
    });
    setFilterResults([...fResult]);
  };

  const clearFilter = () => {
    setSliderFilter({ ...defaultSliderFilter });
    setSelectedDriveModes([]);
    setSelectedImplements([]);
  };

  const handleSelect = (item: string, type = 'implements') => {
    const list =
      type === 'implements' ? selectedImplements : selectedDriveModes;
    let newList: string[] = [];
    if (list.includes(item)) {
      newList = removeItem(list, item);
    } else {
      newList = [...list, item];
    }
    type === 'implements'
      ? setSelectedImplements([...newList])
      : setSelectedDriveModes([...newList]);
  };

  const navigateToAnalytics = (item: any) => {
    const record = {
      date: Number(item.date),
      tractor: item.tractor,
      tractorId: item?.tractor?.id,
      tractorPin: item?.tractor?.tractor_pin,
      tractorShortPin: item?.tractor?.tractor_pin?.slice(-5),
      implement: item.implementName,
      implementName: item.implementName,
      operationType: item.operationType,
    };
    setFromView(view);
    selectTractor(record);
    setSelectedDate(moment(record.date));
  };

  useEffect(() => {
    init();
  }, [pageNumber]);

  useEffect(() => {
    handleFilter();
  }, [sliderFilter, selectedImplements, results, selectedDriveModes]);

  const handleLoadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  if (isSearch) return <></>;

  return (
    <>
      <Row gutter={[11, 40]}>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
        >
          <div className="search-result-container">
            <Row gutter={[20, 11]}>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 6, offset: 0 }}
                lg={{ span: 6, offset: 0 }}
                xl={{ span: 6, offset: 0 }}
              >
                <div className="filter-container">
                  <div className="head-section">
                    <h3 className="filter-heading">Filters</h3>
                    <span className="clear-all" onClick={clearFilter}>
                      Clear All
                    </span>
                  </div>
                  <div className="filter-content-section">
                    <div className="each-item-sec">
                      <h4 className="filter-item-heading">Implements</h4>
                      <div className="content-wrapper">
                        {implementsList
                          ?.filter((item: any) => item !== null)
                          ?.map((item) => (
                            <span
                              onClick={() => {
                                handleSelect(item);
                              }}
                              key={item}
                              className={`chip-item ${isActiveClass(
                                selectedImplements,
                                item,
                                'op-chip-item-active',
                              )}`}
                            >
                              {item}
                            </span>
                          ))}
                      </div>
                    </div>
                    <div className="each-item-sec">
                      <h4 className="filter-item-heading">Drive Mode</h4>
                      <div className="content-wrapper2">
                        <div className="nor-item">
                          <span className="chkbox">
                            <Checkbox
                              checked={selectedDriveModes.includes('manual')}
                              onChange={(e) => {
                                handleSelect('manual', 'drive_modes');
                              }}
                            />
                          </span>
                          <span>Manual Drive</span>
                        </div>
                        <div className="nor-item">
                          <span className="chkbox">
                            <Checkbox
                              checked={selectedDriveModes.includes('autodrive')}
                              onChange={(e) => {
                                handleSelect('autodrive', 'drive_modes');
                              }}
                            />
                          </span>
                          <span>Autodrive</span>
                        </div>
                        <div className="nor-item">
                          <span className="chkbox">
                            <Checkbox
                              checked={selectedDriveModes.includes('op_assist')}
                              onChange={(e) => {
                                handleSelect('op_assist', 'drive_modes');
                              }}
                            />
                          </span>
                          <span>Operator Assist</span>
                        </div>
                        <div className="nor-item">
                          <span className="chkbox">
                            <Checkbox
                              checked={selectedDriveModes.includes('idle')}
                              onChange={(e) => {
                                handleSelect('idle', 'drive_modes');
                              }}
                            />
                          </span>
                          <span>Idle time</span>
                        </div>
                      </div>
                    </div>
                    <div className="each-item-sec2">
                      <div className="head-section">
                        <h4 className="filter-item-heading">Tickets</h4>
                        <img src={downArrow} />
                      </div>

                      <div className="slider-bar">
                        <Slider
                          range
                          value={sliderFilter.tickets}
                          onChange={(value: [number, number]) =>
                            setSliderFilter({ ...sliderFilter, tickets: value })
                          }
                        />
                      </div>
                      <div className="content-wrapper3">
                        <div className="tickets-dur">
                          <span className="tickets-lbl">From</span>
                          <span className="ticket-count">
                            <input
                              placeholder="0"
                              onChange={(e: any) => {
                                if (e.target.value < sliderFilter.tickets[1])
                                  setSliderFilter({
                                    ...sliderFilter,
                                    tickets: [
                                      e.target.value,
                                      sliderFilter.tickets[1],
                                    ],
                                  });
                              }}
                              value={sliderFilter.tickets[0]}
                            />
                          </span>
                        </div>
                        <div className="tickets-dur">
                          <span className="tickets-lbl">To</span>
                          <span className="ticket-count">
                            <input
                              placeholder="6"
                              onChange={(e: any) => {
                                if (e.target.value > sliderFilter.tickets[0])
                                  setSliderFilter({
                                    ...sliderFilter,
                                    tickets: [
                                      sliderFilter.tickets[0],
                                      e.target.value,
                                    ],
                                  });
                              }}
                              value={sliderFilter.tickets[1]}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="each-item-sec2">
                      <div className="head-section">
                        <h4 className="filter-item-heading">Guardrails</h4>
                        <img src={downArrow} />
                      </div>

                      <div className="slider-bar">
                        <Slider
                          range
                          value={sliderFilter.guardrails}
                          onChange={(value: [number, number]) =>
                            setSliderFilter({
                              ...sliderFilter,
                              guardrails: value,
                            })
                          }
                        />
                      </div>
                      <div className="content-wrapper3">
                        <div className="tickets-dur">
                          <span className="tickets-lbl">From</span>
                          <span className="ticket-count">
                            <input
                              placeholder="0"
                              onChange={(e: any) => {
                                if (e.target.value < sliderFilter.guardrails[1])
                                  setSliderFilter({
                                    ...sliderFilter,
                                    guardrails: [
                                      e.target.value,
                                      sliderFilter.guardrails[1],
                                    ],
                                  });
                              }}
                              value={sliderFilter.guardrails[0]}
                            />
                          </span>
                        </div>
                        <div className="tickets-dur">
                          <span className="tickets-lbl">To</span>
                          <span className="ticket-count">
                            <input
                              placeholder="6"
                              onChange={(e: any) => {
                                if (e.target.value > sliderFilter.guardrails[0])
                                  setSliderFilter({
                                    ...sliderFilter,
                                    guardrails: [
                                      sliderFilter.guardrails[0],
                                      e.target.value,
                                    ],
                                  });
                              }}
                              value={sliderFilter.guardrails[1]}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="each-item-sec2">
                      <div className="head-section">
                        <h4 className="filter-item-heading">Error Codes</h4>
                        <img src={downArrow} />
                      </div>

                      <div className="slider-bar">
                        <Slider
                          range
                          value={sliderFilter.errorCodes}
                          onChange={(value: [number, number]) =>
                            setSliderFilter({
                              ...sliderFilter,
                              errorCodes: value,
                            })
                          }
                        />
                      </div>
                      <div className="content-wrapper3">
                        <div className="tickets-dur">
                          <span className="tickets-lbl">From</span>
                          <span className="ticket-count">
                            <input
                              placeholder="0"
                              onChange={(e: any) => {
                                if (e.target.value < sliderFilter.errorCodes[1])
                                  setSliderFilter({
                                    ...sliderFilter,
                                    errorCodes: [
                                      e.target.value,
                                      sliderFilter.errorCodes[1],
                                    ],
                                  });
                              }}
                              value={sliderFilter.errorCodes[0]}
                            />
                          </span>
                        </div>
                        <div className="tickets-dur">
                          <span className="tickets-lbl">To</span>
                          <span className="ticket-count">
                            <input
                              placeholder="6"
                              onChange={(e: any) => {
                                if (e.target.value > sliderFilter.errorCodes[0])
                                  setSliderFilter({
                                    ...sliderFilter,
                                    errorCodes: [
                                      sliderFilter.errorCodes[0],
                                      e.target.value,
                                    ],
                                  });
                              }}
                              value={sliderFilter.errorCodes[1]}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 18, offset: 0 }}
                lg={{ span: 18, offset: 0 }}
                xl={{ span: 18, offset: 0 }}
              >
                <div className="search-result-right-section">
                  {!loading && (
                    <div className="result-count">
                      <span>{totalCount}</span>
                      <span>results found</span>
                    </div>
                  )}

                  <AppLoader loader={loading} />
                  <InfiniteScroll
                    scrollThreshold={0.95}
                    height={500}
                    dataLength={filtersResults.length} //This is important field to render the next data
                    next={() => handleLoadMore()}
                    hasMore={hasMore}
                    loader={<AppLoader loader={loading} />}
                    className="sResultsScroll"
                  >
                    <div className="ticket-container">
                      {filtersResults?.map((item) => {
                        return (
                          <ResultCard
                            onClick={() => navigateToAnalytics(item)}
                            key={item.drive_action_uuids}
                            data={item}
                          />
                        );
                      })}
                    </div>
                  </InfiniteScroll>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
});

interface ResultCardProps1 {
  data: any;
  onClick: () => void;
}

const ResultCard: React.FC<ResultCardProps1> = ({ data, onClick }) => {
  const {
    implementName,
    tractorShortPin,
    operators,
    distance,
    operationalHours,
    ticketsCount,
    errorCodesCount,
    snapshotsCount,
  } = data;

  return (
    <div className="tickets-box" onClick={onClick}>
      <div className="ticket-box">
        <div className="ticket-dtl-left">
          <div className="ticket-op-dtl">
            <div>
              <h2 className="ticket-no">{tractorShortPin}</h2>
            </div>
            <div className="op-dtl">
              <div className="tk-implement-type">
                <span className="tk-imp-icon">
                  <img src={impIcon} />
                </span>
                <span className="tk-imp-name">
                  {!isEmpty(implementName) ? implementName : 'No Implement'}
                </span>
              </div>
              <div className="tk-op-names">
                {operators?.map((operator: any) => (
                  <OperatorItem key={operator?.id} operator={operator} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="ticket-dtl-right">
          <div className="op-coverage-sec">
            <div className="op-coverage-items">
              <div className="opCardBox">
                <div>
                  <img src={distanceIcon} />
                </div>
                <div className="tk-box-title-sec">
                  <span className="tk-box-title">Distance (mi)</span>
                  <span className="tk-box-value">{distance}</span>
                </div>
              </div>
              <div className="opCardBox">
                <div>
                  <img src={durationIcon} />
                </div>
                <div className="tk-box-title-sec">
                  <span className="tk-box-title">Duration</span>
                  <span className="tk-box-value">
                    {operationalHours?.hours}h {operationalHours?.minutes}m
                  </span>
                </div>
              </div>
            </div>
            <div className="recorded-cat-sec">
              <div className="rec-cat-item">
                <img src={tkt_icon} />
                <span className="count">{ticketsCount}</span>
              </div>
              <div className="rec-cat-item">
                <img src={gdr_icon} />
                <span className="count">0</span>
              </div>
              <div className="rec-cat-item">
                <img src={alt_icon} />
                <span className="count">{errorCodesCount}</span>
              </div>
              <div className="rec-cat-item">
                <img src={cam_icon} />
                <span className="count">{snapshotsCount}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface OperatorProps {
  operator: any;
}

const OperatorItem: React.FC<OperatorProps> = ({ operator }) => {
  const { name, shortName, operationalHours } = operator;
  const { hours, minutes } = operationalHours || {};
  return (
    <div className="tk-op-item">
      <div className="tk-avatar-circle">
        <span className="tk-op-nm">{shortName}</span>
      </div>
      <div className="tk-name-duration">
        <span className="tk-operator-name">{name}:</span>
        <span className="tk-h-m">
          {hours}h {minutes}m
        </span>
      </div>
    </div>
  );
};

SearchResult.displayName = 'SearchResult';

export default SearchResult;
