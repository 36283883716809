import { notification } from 'antd';
import dealerStore from '../components/store/dealer';
import jwt_decode from 'jwt-decode';

const useDealer = () => {
  const { setDealerData } = dealerStore();

  const initDealer = async () => {
    try {
      const token = localStorage.getItem('dealer_api_token');
      if (token) {
        const tokenData: any = jwt_decode(token || '');
        const { id, org_type } = tokenData.organization;
        const { first_name, last_name, profile_url, group_list } = tokenData;
        if (org_type === 'DEALER') {
          setDealerData({
            organization_id: id,
            first_name,
            last_name,
            profile_url,
            name: first_name + ' ' + last_name,
            group_list,
          });
          localStorage.setItem('dealer_org_id', id);
        }
      }
    } catch (error: any) {
      notification.error({ message: `initDealer: ${error?.message}` });
    }
  };

  return { initDealer };
};

export default useDealer;
