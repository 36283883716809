import React, { useEffect, useState } from 'react';
import PieChart from '../../charts/PieChart';
import { pathColorCodes } from '../../operationalAnalytics/constants';
import { useDealerFleetUsage } from './hooks/useDealerDashboard';
import { PATH_COLOR_CODES } from '../../../constants/constant';

const DealerFleetUsage: React.FC = () => {
  const { fleetUsageData, loading } = useDealerFleetUsage();
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    setData([
      {
        category: 'Manual',
        value: fleetUsageData.manual,
      },
      {
        category: 'AUto Drive',
        value: fleetUsageData.autonomy,
      },
      {
        category: 'Op Assist',
        value: fleetUsageData.opAssist,
      },
      {
        category: 'idle',
        value: fleetUsageData.idleTime,
      },
    ]);
  }, [fleetUsageData]);

  return (
    <div className="ms_dashboardCard fh_min_height">
      <div className="ms_customerUsage mb10">
        <div className="ms_blockHead">Your Fleet Usage</div>
      </div>
      <div className="fleet_usage_sec">
        <div>
          <p className="fleet_drivemode_txt mb16">
            Your fleet operated
            <span className="fleethrs_bold">{fleetUsageData.totalHours}</span>
            this week across different drive modes.
          </p>
          <div className="fleet_drivemode_sec">
            <div
              className="fleet_drivemode_item"
              style={{ borderColor: PATH_COLOR_CODES.MANUAL }}
            >
              <span className="ms_blockHead">{fleetUsageData.manualHours}</span>
              <span className="fleet_drivemode_type">Manual</span>
            </div>
            <div
              className="fleet_drivemode_item"
              style={{ borderColor: PATH_COLOR_CODES.AUTONOMY }}
            >
              <span className="ms_blockHead">
                {fleetUsageData.autonomyHours}
              </span>
              <span className="fleet_drivemode_type">Autonomy</span>
            </div>
            <div
              className="fleet_drivemode_item"
              style={{ borderColor: PATH_COLOR_CODES.OP_ASSIST }}
            >
              <span className="ms_blockHead">
                {fleetUsageData.opAssistHours}
              </span>
              <span className="fleet_drivemode_type">Op Assist</span>
            </div>
            <div
              className="fleet_drivemode_item"
              style={{ borderColor: PATH_COLOR_CODES.IDLE }}
            >
              <span className="ms_blockHead">
                {fleetUsageData.idleTimeHours}
              </span>
              <span className="fleet_drivemode_type">Idle time</span>
            </div>
          </div>
        </div>
        <div>
          <div className="fh_chart_sec">
            <PieChart
              loader={loading}
              chartId="DealerfleetUsageChart"
              data={data}
              barColors={[
                pathColorCodes?.manual,
                pathColorCodes?.autonomy,
                pathColorCodes?.op_assists,
                pathColorCodes?.idle,
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealerFleetUsage;
