/* eslint-disable no-console */
import { Button, Col, Modal, notification, Row, Space } from 'antd';
import { Country, State } from 'country-state-city';
import React, { useContext, useEffect, useState } from 'react';
import acres_outline from '../../assets/images/acres_outline.svg';
import charge_point_outline from '../../assets/images/charge_point_outline.svg';
import siteLane2Image from '../../assets/images/site_lane2_image.png';
import waiIcon from '../../assets/images/wai_logo.svg';

import {
  addSite,
  getSiteBaseStations,
  getSiteTractors,
  updateSite,
} from '../../constants/Api';
import { isVineYardOrDairy } from '../../constants/Common';
import {
  dairyAutoDrive,
  getSiteFormData,
  vineYardAutoDrive,
} from '../../constants/constant';
import {
  FarmsiteTractor,
  SiteBaseStation,
  SiteCreateEdit,
  SiteCreateEditPayload,
  SiteTractor,
  StateItem,
  UserDetails,
} from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import AppLoader from '../common/AppLoader';
import CustomInput from '../common/CustomInput';
import CustomMultiSelectForMultiSite from '../common/CustomMultiSelectForMultiSite';
import CustomSelect from '../common/CustomSelect';
import useSite from './hooks/useSite';
import useFarmProileStore from './useFarmProfileStore';
import CustomStateSelect from '../common/CustomStateSelect';
const SiteForm: React.FC = () => {
  const { getSites } = useSite();

  const { userDetails } = useContext(ApplicationContext);
  const [baseStationsData, setbaseStationsData] = useState<SiteBaseStation[]>(
    [],
  );
  const [tractorsData, setTractorsData] = useState<SiteTractor[]>([]);
  const [baseStations, setbaseStations] = useState<any[]>([]);
  const [tractors, settractors] = useState<any[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [loader, setLoader] = useState(false);
  const [tractorsLoader, setTractorsLoader] = useState(false);
  const [confirmSave, setConfirmSave] = useState(false);
  const [states, setStates] = useState<StateItem[]>([]);
  const [formData, setFormData] = useState<SiteCreateEdit>({
    siteName: '',
    address: '',
    city: '',
    country: '',
    state: '',
    zipCode: '',
    basestation: [],
    tractors: [],

    laneCount: '',
    dailyPushTarget: '',
    total_num_of_acres: '',
    total_num_of_chargers: '',
  });
  const {
    setShowSiteModal,
    editSiteDetails,
    setEditSiteDetails,
    isInEssentailsPackage,
  } = useFarmProileStore();

  useEffect(() => {
    if (editSiteDetails) {
      const resp = getSiteFormData(editSiteDetails, isInEssentailsPackage);
      setFormData(resp);
    }
  }, [editSiteDetails]);

  useEffect(() => {
    return () => setEditSiteDetails(null);
  }, []);

  const saveSiteHandler = () => {
    const baseStationNew =
      Number(formData.basestation[0]) > 0
        ? Number(formData.basestation[0])
        : null;
    // for new site craetion
    if (!editSiteDetails) {
      // prepare changed basestation to assign by other site
      const filteredBaseStationData = baseStationsData.filter(
        (item: SiteBaseStation) =>
          item.basestation_id === baseStationNew &&
          item?.site_details?.farmsite_id,
      );

      // prepare changed tractors to assign by other site
      const filteredTractorsData = tractorsData.filter(
        (item: SiteTractor) =>
          formData.tractors.includes(`${item.id}`) &&
          item?.site_details?.farmsite_id,
      );
      // checking if have changed tractor or basestation for other sites
      if (
        filteredBaseStationData?.length > 0 ||
        filteredTractorsData?.length > 0
      ) {
        setConfirmSave(true);
      } else {
        onFinish(editSiteDetails ? true : false);
      }
    } else if (editSiteDetails) {
      const tractorsNew = formData.tractors.map(Number);
      const sortedTractorsNew = tractorsNew.sort((a, b) => a - b);
      const { farmsite_basestation, farmsite_tractors } = editSiteDetails
        ? editSiteDetails
        : { farmsite_basestation: null, farmsite_tractors: [] };
      const baseStationOld =
        farmsite_basestation &&
        farmsite_basestation.basestation_id &&
        farmsite_basestation.basestation_id.basestation_id &&
        Number(farmsite_basestation.basestation_id.basestation_id)
          ? Number(farmsite_basestation.basestation_id.basestation_id)
          : null;
      const tractorsOld = farmsite_tractors.map(
        (tractor: FarmsiteTractor) => tractor.tractor_id.id,
      );
      const sortedTractorsOld = tractorsOld.sort(
        (a: number, b: number) => a - b,
      );
      const areArraysEqual = (arr1: number[], arr2: number[]): boolean => {
        return JSON.stringify(arr1) === JSON.stringify(arr2);
      };
      // checking basestatins and tractors are same or not (old and new)
      if (
        baseStationNew === baseStationOld &&
        areArraysEqual(sortedTractorsOld, sortedTractorsNew)
      ) {
        onFinish(editSiteDetails ? true : false);
      } else {
        const filteredBaseStationData = baseStationsData.filter(
          (item: SiteBaseStation) =>
            item.basestation_id === baseStationNew &&
            item?.site_details?.farmsite_id,
        );
        const filteredTractorsData = tractorsData.filter(
          (item: SiteTractor) =>
            formData.tractors.includes(`${item.id}`) &&
            item?.site_details?.farmsite_id &&
            item?.site_details?.farmsite_id !== editSiteDetails?.id,
        );
        if (
          (baseStationNew !== baseStationOld &&
            filteredBaseStationData?.length > 0) ||
          (!areArraysEqual(sortedTractorsOld, sortedTractorsNew) &&
            filteredTractorsData?.length > 0)
        ) {
          setConfirmSave(true);
        } else {
          onFinish(editSiteDetails ? true : false);
        }
      }
    }
  };

  const onFinish = async (isUpdate = false) => {
    if (loader) return;
    setLoader(true);
    try {
      let payload: SiteCreateEditPayload;
      if (!isUpdate) {
        // CRETE
        payload = {
          name: formData.siteName,
          address: formData.address,
          city: formData.city,
          state: formData.state,
          zip_code: formData.zipCode,
          country: formData.country,
          basestation_id:
            Number(formData.basestation[0]) > 0
              ? Number(formData.basestation[0])
              : null,
          tractor_ids: formData.tractors.map(Number),
        };
        // for other vineyard org params
        if (isVineYardOrDairy(userDetails as UserDetails) !== dairyAutoDrive) {
          payload.total_num_of_acres = Number(formData.total_num_of_acres);
          payload.total_num_of_chargers = Number(
            formData.total_num_of_chargers,
          );
        }
        // for dairy org params
        if (isVineYardOrDairy(userDetails as UserDetails) === dairyAutoDrive) {
          payload.total_num_of_lanes = Number(formData.laneCount);
          payload.daily_push_target = Number(formData.dailyPushTarget);
        }
      } else {
        // Update
        const name = editSiteDetails ? editSiteDetails.name : '';
        payload = {
          // name: formData.siteName,
          address: formData.address,
          city: formData.city,
          state: formData.state,
          zip_code: formData.zipCode,
          country: formData.country,
          basestation_id:
            Number(formData.basestation[0]) > 0
              ? Number(formData.basestation[0])
              : null,
          tractor_ids: formData.tractors.map(Number),
        };
        // for other vineyard org params
        if (isVineYardOrDairy(userDetails as UserDetails) !== dairyAutoDrive) {
          payload.total_num_of_acres = Number(formData.total_num_of_acres);
          payload.total_num_of_chargers = Number(
            formData.total_num_of_chargers,
          );
        }

        // for dairy org params
        if (isVineYardOrDairy(userDetails as UserDetails) === dairyAutoDrive) {
          payload.total_num_of_lanes = Number(formData.laneCount);
          payload.daily_push_target = Number(formData.dailyPushTarget);
        }

        // only update if its changed
        if (name != formData.siteName) {
          payload.name = formData.siteName;
        }
      }
      const { msg } = isUpdate
        ? await updateSite(
            userDetails.organization.api_url,
            editSiteDetails?.farmsite_uuid as string,
            payload,
          )
        : await addSite(userDetails.organization.api_url, payload);
      if (msg) {
        notification.success({
          message: msg,
        });
        getSites(userDetails);
        setShowSiteModal(false);
      }
    } catch (error: any) {
      console.log(error);
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getTractors();
      getBaseStations();
    }
  }, [userDetails]);

  const getBaseStations = async () => {
    setLoader(true);

    try {
      const data: SiteBaseStation[] = await getSiteBaseStations(
        userDetails.organization.api_url,
      );
      const orgList = data.map((data: any) => ({
        title: (
          <div className="ms_mvalues">
            <div className="ms_mtractor">{data?.name}</div>
            <div className="ms_msites">{data?.site_details?.farmsite_name}</div>
          </div>
        ),
        value: `${data.basestation_id}`,
      }));
      setbaseStationsData(data);
      setbaseStations(orgList);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const getTractors = async () => {
    setTractorsLoader(true);
    try {
      const data: SiteTractor[] = await getSiteTractors(
        userDetails.organization.api_url,
      );
      const orgList = data.map((dataItem: any, index: number) => {
        return {
          title: (
            <div className="ms_mvalues">
              <div className="ms_mtractor">
                {dataItem?.name}
                {index < data.length - 1 && (
                  <span className="commaCode">,</span>
                )}
              </div>
              <div className="ms_msites">
                {dataItem?.site_details?.farmsite_name}
              </div>
            </div>
          ),
          value: `${dataItem?.id || ''}`,
        };
      });
      settractors(orgList);
      setTractorsData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setTractorsLoader(false);
    }
  };

  useEffect(() => {
    if (
      formData &&
      formData.siteName &&
      formData.siteName.trim().length > 0 &&
      ((isVineYardOrDairy(userDetails as UserDetails) === dairyAutoDrive &&
        Number(formData.laneCount) > 0 &&
        Number(formData.dailyPushTarget) > 0) ||
        (isVineYardOrDairy(userDetails as UserDetails) !== dairyAutoDrive &&
          Number(formData.total_num_of_acres) > 0 &&
          Number(formData.total_num_of_chargers) > 0))
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    const statesList = formData.country
      ? State.getStatesOfCountry(formData.country).map((state: any) => {
          return {
            name: state?.name,
            value: state?.isoCode,
            isoCode: state?.isoCode,
          };
        })
      : [];
    setStates(statesList);
  }, [formData]);

  return (
    <Modal
      visible={true}
      className="create_site_popup"
      footer={false}
      // title="Create Site"
      width={860}
      closable={false}
    >
      <>
        <div className="create_site_popup_container">
          <div className="create_site_popup_head">
            {editSiteDetails ? 'Edit Site Details' : 'Create Site'}
          </div>
          <div className="create_site_popup_wrapper">
            <Row
              gutter={[16, 16]}
              className="widget_card1"
              style={{ minHeight: '407px' }}
            >
              <Col span={12} className="siteRightCol">
                <Space
                  className="flex-space-between-block"
                  direction="vertical"
                  size="middle"
                >
                  <div>
                    <label className="input-label">Site Name</label>
                    <CustomInput
                      cssClass="w100"
                      label="Enter Site Name"
                      value={formData.siteName}
                      required={true}
                      setValue={(value) =>
                        setFormData({ ...formData, siteName: value })
                      }
                    />
                  </div>
                  <div>
                    <label className="input-label">
                      <span>Address</span>
                      <span className="site_optional">(optional)</span>
                    </label>
                    <Space direction="vertical" size={16}>
                      <CustomSelect
                        label="Country"
                        showSearch={true}
                        value={formData.country}
                        setValue={(value) => {
                          setFormData({
                            ...formData,
                            country: value,
                            state: '',
                          });
                        }}
                        options={Country.getAllCountries().map(
                          (country: any) => {
                            return {
                              name: country?.name,
                              value: country?.isoCode,
                            };
                          },
                        )}
                        optionKey="value"
                        optionDisplay="name"
                      />
                      <CustomInput
                        cssClass="w100"
                        label="Street Address"
                        value={formData.address}
                        setValue={(value) =>
                          setFormData({ ...formData, address: value })
                        }
                      />
                      {/* <Space size={16}></Space> */}
                      <Space size={16}>
                        <div className="StateCityCode">
                          <CustomInput
                            label="City"
                            cssClass="w100"
                            value={formData.city}
                            setValue={(value) =>
                              setFormData({ ...formData, city: value })
                            }
                          />
                          <CustomStateSelect
                            cssClass="w100"
                            label="State"
                            showSearch={true}
                            value={formData.state}
                            setValue={(value) =>
                              setFormData({ ...formData, state: value })
                            }
                            options={states}
                            optionKey="value"
                            optionDisplay="name"
                            optionDisplay2="isoCode"
                            isState
                          />

                          <CustomInput
                            label="Zip Code"
                            value={formData.zipCode}
                            setValue={(value) =>
                              setFormData({ ...formData, zipCode: value })
                            }
                          />
                        </div>
                      </Space>
                    </Space>
                  </div>
                  <div className="posrel">
                    <label className="input-label">
                      <span>Is there a Base Station at this site?</span>
                      <span className="site_optional">(optional)</span>
                    </label>
                    <CustomMultiSelectForMultiSite
                      handleTreeSelect={(ids: string[]) => {
                        if (ids.length > 0) {
                          setFormData({
                            ...formData,
                            basestation: [ids[ids.length - 1]],
                          });
                        } else {
                          setFormData({
                            ...formData,
                            basestation: [],
                          });
                        }
                      }}
                      hideSelectAll={true}
                      selectedId={formData.basestation}
                      list={baseStations}
                      label="Select Base Station"
                      extraClass="msSelect msTractorOS arowSpl w1"
                    />
                  </div>
                </Space>
              </Col>
              <Col span={12} className="siteLeftCol">
                <Space
                  direction="vertical"
                  size={16}
                  className="flex-space-around-block"
                >
                  <div>
                    <label className="input-label">
                      <span>Which tractors operate at this site?</span>
                      <span className="site_optional">(optional)</span>
                    </label>
                    <div className="multiSiteHoverInfo">
                      {isInEssentailsPackage && (
                        <div className="essentails_tooltip">
                          Upgrade fleet to Assist or Automate to edit this
                          field.
                        </div>
                      )}
                      <CustomMultiSelectForMultiSite
                        disabled={isInEssentailsPackage}
                        handleTreeSelect={(ids: string[]) =>
                          setFormData({ ...formData, tractors: ids })
                        }
                        selectedId={formData.tractors}
                        list={tractors}
                        label="Select Tractors"
                        extraClass="msSelect msTractorOS arowSpl"
                        maxTagCount={
                          formData.tractors.length > 0
                            ? formData.tractors.length
                            : 1
                        }
                      />
                    </div>
                  </div>
                  {isVineYardOrDairy(userDetails as UserDetails) !==
                    dairyAutoDrive && (
                    <>
                      <div>
                        <div className="iconWithTxt gap12">
                          <div>
                            <img
                              src={acres_outline}
                              alt=""
                              width={24}
                              height={24}
                            />
                          </div>
                          <div>
                            <p className="site_txt">
                              What is the total acreage of this site?
                            </p>
                            <p className="site_txt2 mb16">
                              Include any areas that MK-Vs operate in.
                            </p>
                          </div>
                        </div>
                        <div className="multiSiteHoverInfo">
                          {isInEssentailsPackage && (
                            <div className="essentails_tooltip">
                              Upgrade fleet to Assist or Automate to edit this
                              field.
                            </div>
                          )}
                          <CustomInput
                            cssClass="w100"
                            label="Enter Total Acres"
                            value={formData.total_num_of_acres}
                            required={true}
                            min={1}
                            max={10000}
                            type="Number"
                            disabled={isInEssentailsPackage}
                            setValue={(value) =>
                              setFormData({
                                ...formData,
                                total_num_of_acres: value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <div className="iconWithTxt gap6 mb16">
                          <div>
                            <img
                              src={charge_point_outline}
                              alt=""
                              width={24}
                              height={24}
                            />
                          </div>
                          <div>
                            <p className="site_txt">
                              How many MK-V chargers are at this site?
                            </p>
                          </div>
                        </div>
                        <div className="multiSiteHoverInfo">
                          {isInEssentailsPackage && (
                            <div className="essentails_tooltip">
                              Upgrade fleet to Assist or Automate to edit this
                              field.
                            </div>
                          )}
                          <CustomInput
                            cssClass="w100"
                            label="Enter Number Of Chargers"
                            value={formData.total_num_of_chargers}
                            required={true}
                            min={1}
                            max={10000}
                            type="Number"
                            disabled={isInEssentailsPackage}
                            setValue={(value) =>
                              setFormData({
                                ...formData,
                                total_num_of_chargers: value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {isVineYardOrDairy(userDetails as UserDetails) ===
                    dairyAutoDrive && (
                    <>
                      {' '}
                      <div>
                        <p className="site_txt">
                          How many lanes are at this site?
                        </p>
                        <p className="site_txt2">
                          Each side of the alley must be counted individually to
                          give an accurate total.
                        </p>
                        <div>
                          <div>
                            <img alt="" />
                          </div>
                          <div className="mb16 site_lane_count">
                            <span>
                              <img src={siteLane2Image} alt="laneImg" />
                            </span>
                            <span className="site_txt2">
                              For example, this would count as 2 lanes.
                            </span>
                          </div>
                        </div>
                        <CustomInput
                          cssClass="w100"
                          label="Enter Number of Lanes"
                          value={formData.laneCount}
                          required={true}
                          min={1}
                          max={10000}
                          type="Number"
                          setValue={(value) =>
                            setFormData({ ...formData, laneCount: value })
                          }
                        />
                      </div>
                      <div>
                        <p className="site_txt">
                          {' '}
                          How often daily should the lanes be pushed?
                        </p>
                        <p className="site_txt2 mb16">
                          Some reports will use this to indicate missed or extra
                          pushes.
                        </p>

                        <CustomInput
                          cssClass="w100"
                          label="Enter Daily Push Target"
                          value={formData.dailyPushTarget}
                          required={true}
                          min={1}
                          max={10000}
                          type="Number"
                          setValue={(value) =>
                            setFormData({ ...formData, dailyPushTarget: value })
                          }
                        />
                      </div>
                    </>
                  )}
                </Space>
              </Col>
            </Row>
          </div>
          <div className="create_site_popup_buttons">
            <Button
              className="default-btn"
              onClick={() => setShowSiteModal(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={saveSiteHandler}
              // onClick={() => setConfirmSave(true)}
              className="primary-btn"
              disabled={isDisabled}
            >
              Save Site
            </Button>
          </div>
        </div>
      </>
      <AppLoader loader={loader || tractorsLoader} />
      {confirmSave && (
        <Modal
          visible={confirmSave}
          closable={false}
          footer={false}
          className="edit_operator site_Save"
        >
          <div className="popup_head">
            <img src={waiIcon} />
            <span className="head_text">
              Reassign Tractors or Base Station?
            </span>
          </div>
          <p className="popup_desc_text">
            Some of the items you have selected are currently assigned to other
            sites. Are you sure you&#39;d like to reassign them?
          </p>
          <div className="button_sec">
            <Button
              key="edit"
              className="normal_btn"
              onClick={() => setConfirmSave(false)}
            >
              Cancel
            </Button>
            <Button
              key="cancel"
              className="green_btn"
              onClick={() => onFinish(editSiteDetails ? true : false)}
            >
              Save Site
            </Button>
          </div>
          <AppLoader loader={loader} />
        </Modal>
      )}
    </Modal>
  );
};

export default SiteForm;
