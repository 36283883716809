import React from 'react';
import { Space } from 'antd';

import { accessItems } from './constants';

import logoLight from './images/monarch_logo_light.svg';
import tractor from './images/tractor.svg';
import tractorAutonomy from './images/tractor_autonomy.svg';
import logoDark from './images/monarch_logo_dark.svg';
import tractorDetailsIcon from './images/tractor_details.svg';
import liveMap from './images/live_map.svg';
import userIcon from './images/user_icon.svg';
import emptyCheck from './images/emptyCheck.svg';
import fillCheck from './images/fillCheck.svg';

import report from './images/report.svg';
import reportDashboard from './images/report_dashboard.svg';
import cameraLeft from './images/camera_1.svg';
import cameraRight from './images/camera_2.svg';

import './styles.scss';

const getModalData = (type: string) => {
  const data: any = {
    liveops: {
      title: 'Live Operations',
      subTitle: 'Follow the Action',
      description:
        'Gain visibility across your fleet with real-time tractor status and videos. Unlock automated driving with Assist or Automate for smoother operations.',
    },
    analytics: {
      title: 'Digital Insights',
      subTitle: 'Track and Optimize your Fleet',
      description:
        'Track progress on cost and sustainability goals with interactive dashboards and downloadable usage summaries. Replay tractor operations videos to review work quality or safety incidents.',
    },
  };
  return data[type];
};

const PlansContent = () => {
  const plans = [
    {
      title: 'Essentials',
      description: 'Electrify Your Operations',
      image: tractor,
    },
    {
      title: 'Assist',
      description: 'Enhance with Digital Insights',
      image: logoLight,
    },
    {
      title: 'Automate',
      description: 'Unlock Labor Savings',
      image: tractorAutonomy,
    },
  ];
  return (
    <div className="plans-container">
      {plans.map(({ title, description, image }) => {
        return (
          <div className="plan-card" key={title}>
            <div className="plan-section">
              <div className="plan-title">{title}</div>
              <div className="plan-desc">{description}</div>
            </div>
            <div className="plan-icon">
              <img src={image} alt="" width={38} height={38} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const TicketsContent = () => {
  return (
    <div className="dgps-ticket-section">
      {Array.from({ length: 3 }).map((ticket, i) => {
        return (
          <div className="dgps-ticket" key={i}>
            <img src={logoDark} width={26} height={26} />
            <div className="ticket-content">
              <div className="ticket-header">
                <div className="tractor-name">M-10667</div>
                <div className="created-time">6:45 PM</div>
              </div>
              <div className="ticket-desc">
                Charging started with battery at 23%.
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const MapUserContent = () => {
  return (
    <div className="map-user-container">
      <div className="dgps-map-section">
        <img src={liveMap} width={156} height={128} />
        <img src={tractorDetailsIcon} width={148} height={128} />
      </div>
      <div className="dgps-user-section">
        <div className="user-block">
          <div className="user-img-block">
            <img src={userIcon} width={21} height={21} alt="" />
          </div>
          <div className="user-info">
            <div className="user-name">Timothy Stein</div>
            <div className="user-tag">Crew Supervisor</div>
          </div>
        </div>
        <div className="access-block">
          {accessItems.map(({ name, checked }: any) => {
            return (
              <div className="access-block-item" key={name}>
                <div className="access-check">
                  <img src={checked ? fillCheck : emptyCheck} alt="" />
                </div>
                <div className="access-label">{name}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const AnalyticContent = () => {
  return (
    <div className="analytic-reports-camera-container">
      {/* <div className="dgps-reports-section"> */}
      <img src={report} alt="" width={104} height={136} />
      <img src={reportDashboard} alt="" width={203} height={126} />
      {/* </div> */}
      <div className="dgps-camera-section">
        <img src={cameraLeft} alt="" width={91} height={114} />
        <img src={cameraRight} alt="" width={91} height={114} />
      </div>
    </div>
  );
};

interface Props {
  type: 'liveops' | 'analytics';
}

const DigitalPackageModal: React.FC<Props> = ({ type }) => {
  const data = getModalData(type);
  const { title, subTitle, description } = data;
  return (
    <div className="dgps-modal-container">
      <div className="dgps-modal">
        <div className="dgps-modal-content">
          <div className="dgps-modal-header">
            <img src={logoLight} width={38} height={38} />
            <Space align="start" direction="vertical" size={10}>
              <div className="dgps-modal-title">{title}</div>
              <div className="dgps-modal-description">{subTitle}</div>
            </Space>
          </div>
          <div className="dgps-modal-body">
            <div className="dgps-section-1">
              <div className="text">{description}</div>
              {type === 'liveops' ? (
                <>
                  <MapUserContent />
                  <TicketsContent />
                </>
              ) : (
                <AnalyticContent />
              )}
            </div>
            <div className="dgps-section-2">
              <PlansContent />
            </div>
            <div className="dgps-section-3">
              <div className="contact-text">
                Contact your Monarch representative to upgrade your fleet.
              </div>
              <div className="learnMoreBtn">Learn More</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalPackageModal;
