import { Button, Modal, notification } from 'antd';
import React, { createRef, useEffect, useState } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import { useTranslation } from 'react-i18next';

import plusImg from '../../../assets/images/plus_white.svg';
import translate from '../../../locale/en_translate.json';

import {
  checkForFileTypes,
  fileTypesAccepted,
} from '../../../constants/Common';

import 'cropperjs/dist/cropper.css';
import './style.scss';

interface Props {
  setFileUrl: (page: any) => void;
  setfileUrlData: (page: any) => void;
  uploadAnImage?: boolean;
  icon?: any;
}
const SiteImageCrop: React.FC<Props> = ({
  setFileUrl,
  setfileUrlData,
  uploadAnImage,
  icon,
}) => {
  const { t } = useTranslation();
  const [modelFlag, setModelFlag] = useState<boolean>(false);
  const [image, setImage] = useState<string>('');
  const [name, setName] = useState<string>('');
  const cropperRef = createRef<ReactCropperElement>();

  useEffect(() => {
    return () => {
      setImage('');
      setName('');
      setModelFlag(false);
    };
  }, []);

  const handleClose = () => {
    const doc: any = document;
    doc.querySelector('#upload-form').reset();
    setModelFlag(false);
  };

  const beforeUpload = (file: any) => {
    const fileTypeAccepted = checkForFileTypes(file?.type);
    if (!fileTypeAccepted) {
      notification.error({
        message: 'This file type is not accepted',
      });
      return false;
    } else {
      return true;
    }
  };

  const onChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target?.files?.length) {
      files = e.target.files;
      const [file] = e.target.files;
      if (file) setName(file.name);
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
      setModelFlag(true);
    };
    if (files?.length) {
      reader.readAsDataURL(files[0]);
      beforeUpload(e.target.files[0]);
    }
  };

  const getCropData = async () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      setFileUrl(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      const file = await fetch(
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL(),
      )
        .then((res) => res.blob())
        .then((blob) => {
          return new File([blob], name, { type: 'image/png' });
        });
      setfileUrlData(file);
      setModelFlag(false);
    }
  };

  return (
    <>
      <form id="upload-form">
        <input
          accept={fileTypesAccepted}
          type="file"
          id="upload-button"
          style={{ display: 'none' }}
          onChange={onChange}
        />
      </form>
      {uploadAnImage ? (
        <Button
          className="btnUpload"
          data-testid="uploadImage-ImageUpload"
          onClick={() => {
            const doc: any = document;
            doc.querySelector('#upload-button').click();
          }}
        >
          {t(translate.buttons.uploadAnImage)}
        </Button>
      ) : (
        <label htmlFor="upload-button" className="editImgs">
          <div className="ms_add_photo">
            <span>
              <img src={plusImg} alt="plus" />
            </span>
            <span className="ms_add_photoTxt">Add Photo</span>
          </div>
        </label>
      )}

      {image && modelFlag && (
        <Modal
          title="Edit Image"
          visible={modelFlag}
          className="thubnailVideosFull editImageModal"
          footer={null}
          width="600px"
          closable={false}
        >
          <>
            <div style={{ width: '100%' }}>
              <Cropper
                ref={cropperRef}
                style={{ height: '450px', width: '100%' }}
                zoomTo={0.2}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                guides={true}
              />
            </div>
            <div className="cropBtn">
              <Button onClick={handleClose} className="cancelBtn Button2">
                Cancel
              </Button>
              <Button onClick={getCropData} className="addNewBtn Button2">
                Ok
              </Button>
            </div>
          </>
        </Modal>
      )}
    </>
  );
};

export default SiteImageCrop;
