import { create } from 'zustand';

interface DealerData {
  first_name: string;
  last_name: string;
  profile_url: string;
  name: string;
  organization_id: number;
  group_list: any;
}

type State = {
  dealerData: DealerData | null;
};

type Actions = {
  setDealerData: (data: DealerData) => void;
  reset: () => void;
};

const initialState: State = {
  dealerData: null,
};

const dealerStore = create<State & Actions>()((set, get) => ({
  ...initialState,
  setDealerData: (dealerData) =>
    set(() => ({
      dealerData,
    })),
  reset: () => {
    set(initialState);
  },
}));

export default dealerStore;
