/* eslint-disable no-console */
import { CameraFilled } from '@ant-design/icons';
import { Button, Col, notification, Row, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  updateFleetSettingData,
  updateorganization,
  updateProfileImageUpload,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import translate from '../../locale/en_translate.json';
import AppLoader from '../common/AppLoader';
import CustomInput from '../common/CustomInput';
import SiteImageCrop from '../widget/ImageCrop/SiteImageCrop';
import useSite from './hooks/useSite';
import useFarmProileStore from './useFarmProfileStore';

const { Title } = Typography;

const EditProfile: React.FC = () => {
  const { userDetails } = useContext(ApplicationContext);
  const [profileLoader, setProfileLoader] = useState(false);
  const { getMultiSiteProfile } = useSite();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    organizationName: '',
    organizationType: '',
    avgDieselCost: '',
    avgElectricityRate: '',
    image: '',
  });
  const [fileUrl, setFileUrl] = useState<any>('');
  const [fileUrlData, setfileUrlData] = useState<any>('');
  const { setShowEditFarmModal, farmDetails, isInEssentailsPackage } =
    useFarmProileStore();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (farmDetails) {
      const { name, farmType, dieselCost, electricityRate, image } =
        farmDetails;
      setFormData({
        organizationName: name,
        organizationType: farmType,
        avgDieselCost: dieselCost as string,
        avgElectricityRate: electricityRate as string,
        image,
      });
    }
  }, [farmDetails]);

  const handleFormSubmit = async () => {
    setProfileLoader(true);
    const payload = {
      name: formData?.organizationName?.trim(),
    };
    try {
      await updateorganization(
        userDetails.organization.api_url,
        userDetails.organization.id,
        payload,
      );
      await updateFleetSetting();
      if (fileUrlData) {
        imageUpload();
      } else {
        setShowEditFarmModal(false);
        notification.success({
          message: t(translate.label.farmProfileUpdatd),
        });
      }
      getMultiSiteProfile(userDetails);
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    } finally {
      setProfileLoader(false);
    }
  };
  const updateFleetSetting = async () => {
    try {
      const payload = [
        {
          name: 'electricity_rate',
          value: formData?.avgElectricityRate,
        },
        {
          name: 'diesel_cost',
          value: formData?.avgDieselCost,
        },
      ];
      await updateFleetSettingData(
        userDetails.organization.api_url,
        userDetails.organization.id,
        payload,
      );
    } catch (error: any) {
      notification.error({
        message: error?.message,
      });
    }
  };
  const imageUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('image', fileUrlData);
      await updateProfileImageUpload(
        userDetails.organization.api_url,
        userDetails.organization.id,
        formData,
      );
      getMultiSiteProfile(userDetails);
      notification.success({
        message: t(translate.label.farmProfileUpdatd),
      });
      setShowEditFarmModal(false);
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    }
  };

  useEffect(() => {
    if (
      (formData &&
        formData.organizationName.trim().length > 0 &&
        Number(formData?.avgDieselCost) > 0 &&
        Number(formData?.avgElectricityRate) > 0 &&
        !isInEssentailsPackage) ||
      (isInEssentailsPackage && formData.organizationName != farmDetails?.name)
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [formData]);

  return (
    <>
      <div className="ms_button_sec h36 mb55"></div>
      <div className="ms_org_section">
        <div className="ms_org_head_dtls_sec">
          <Row gutter={[20, 20]}>
            <Col lg={{ span: 22, offset: 2 }} xl={{ span: 22, offset: 2 }}>
              <div className="ms_org_head mb48">
                <div className="ms_org_logo">
                  {fileUrl !== '' ? (
                    <>
                      <img src={fileUrl} width={100} height={100} />
                    </>
                  ) : (
                    <>
                      {formData.image ? (
                        <>
                          <img src={formData.image} width={100} height={100} />
                        </>
                      ) : (
                        <>
                          <CameraFilled className="camera-icon" />
                        </>
                      )}
                    </>
                  )}
                  <div className="CropEditPosition">
                    <SiteImageCrop
                      setFileUrl={setFileUrl}
                      setfileUrlData={setfileUrlData}
                    ></SiteImageCrop>
                  </div>
                </div>
                <div className="ms_org_title">{farmDetails?.name}</div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="edit-profile-container">
        <div className="edit-profile-form widget_card">
          <Title level={5}>Edit Organization</Title>
          <CustomInput
            label="Organization Name"
            value={formData.organizationName}
            required={true}
            setValue={(value) =>
              setFormData({ ...formData, organizationName: value })
            }
          />
          <div className="multiSiteHoverInfo">
            <div className="essentails_tooltip">
              This field is not editable. File a ticket with Support to make any
              changes.
            </div>
            <CustomInput
              label="Organization Type"
              disabled={true}
              value={formData.organizationType}
              required={true}
              setValue={(value) =>
                setFormData({ ...formData, organizationType: value })
              }
            />
          </div>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="multiSiteHoverInfo">
                {isInEssentailsPackage && (
                  <div className="essentails_tooltip">
                    Upgrade your fleet to edit this field.
                  </div>
                )}
                <CustomInput
                  label="Avg Diesel Cost"
                  value={`$${formData.avgDieselCost}`}
                  required={true}
                  disabled={isInEssentailsPackage}
                  setValue={(value) => {
                    const result = value.replace(/\$/g, '');
                    setFormData({ ...formData, avgDieselCost: result });
                  }}
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="multiSiteHoverInfo">
                {isInEssentailsPackage && (
                  <div className="essentails_tooltip">
                    Upgrade your fleet to edit this field.
                  </div>
                )}
                <CustomInput
                  label="Avg Electricity Rate"
                  value={`$${formData.avgElectricityRate}`}
                  required={true}
                  disabled={isInEssentailsPackage}
                  setValue={(value) => {
                    const result = value.replace(/\$/g, '');
                    setFormData({
                      ...formData,
                      avgElectricityRate: result,
                    });
                  }}
                  showLabel="/kWh"
                />
              </div>
            </Col>
          </Row>

          <div className="msEditButtons">
            <Button
              onClick={() => setShowEditFarmModal(false)}
              className="default-btn w122 nbdr"
            >
              Cancel
            </Button>
            <Button
              className="primary-btn w250"
              onClick={() => handleFormSubmit()}
              disabled={isDisabled}
            >
              Save Changes
            </Button>
          </div>
        </div>
        <AppLoader loader={profileLoader} />
      </div>
    </>
  );
};

export default EditProfile;
