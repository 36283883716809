/* eslint-disable no-console */
import { Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import rsLeftArrow from '../../../../assets/images/rs_left_arrow.svg';
import pdfIcon from '../../../../assets/images/rs_pdf_icon.svg';
import powerPoint from '../../../../assets/images/rs_powerPoint_icon.svg';
import excelIcon from '../../../../assets/images/rs_text_icon.svg';
import videoIcon from '../../../../assets/images/rs_video_icon.svg';
import { getDealerData } from '../../../../constants/Api';
import { ApplicationContext } from '../../../../context/AppContext';
import RoutesConstants from '../../../../routes/RoutesConstant';
import hc_monarch_logo from './../../../HelpCenter/images/hc_monarch_logo.svg';

import rs_doc_icon from '../../../../assets/images/rs_doc_icon.svg';
import folder_icon from '../../../../assets/images/rs_folder_icon.svg';
import image_icon from '../../../../assets/images/rs_image_icon.svg';
import { getDateAndTime, initScroller } from '../../../../constants/Common';
import usePaginate from '../../../../hooks/usePaginate';
import InfiniteScrollTable from '../../../common/InfiniteScrollTable';
import { downloadFile } from '../../common/common';

const Resources: React.FC = () => {
  const { goBack } = useHistory();
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const { userDetails } = useContext(ApplicationContext);
  const [parentId, setparentId] = useState<number[]>([]);
  const [currentDirectoryId, setcurrentDirectoryId] = useState('1');
  const [totalCount, setTotalCount] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<number>(25);
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();

  const getDealerResources = async () => {
    try {
      setLoader(true);
      if (checkActivity()) initScroller();
      const response = await getDealerData(
        userDetails.organization.api_url,
        currentDirectoryId,
        pageNumber,
        pageSize,
      );
      setTotalCount(response?._metadata?.total_records_count);
      const { data } = response;
      const data1 = Array.isArray(data) ? data : [];
      setData(data1);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const processDocIcons = (media_type: string) => {
    switch (media_type) {
      case 'image':
        return image_icon;
      case 'folder':
        return folder_icon;
      case 'pdf':
        return pdfIcon;
      case 'video':
        return videoIcon;
      case 'presentation':
        return powerPoint;
      case 'excel':
        return excelIcon;
      case 'document':
        return excelIcon;
      default:
        break;
    }
  };

  const columns = [
    {
      title: (
        <div className="colIcon">
          <img
            style={{ width: '30px', height: '30px' }}
            src={rs_doc_icon}
            alt=""
          />
          Name
        </div>
      ),
      width: '50%',
      dataIndex: 'name',
      key: 'name',
      render: (_: any, record: any) => (
        <div className="colIcon">
          <img
            style={{ width: '30px', height: '30px' }}
            src={processDocIcons(record.media_type)}
            alt=""
          />{' '}
          {record && record.name ? record.name : record.title}
        </div>
      ),
      onCell: (record: any) => ({
        onClick: () => handleRowClick(record),
      }),
    },
    {
      title: 'Modified',
      dataIndex: 'modified_date_time',
      key: 'modified',
      render: (modified_date_time: number) =>
        getDateAndTime(modified_date_time),
      onCell: (record: any) => ({
        onClick: () => handleRowClick(record),
      }),
    },
    {
      title: 'Modified by',
      dataIndex: 'created_by',
      key: 'modifiedby',
      onCell: (record: any) => ({
        onClick: () => handleRowClick(record),
      }),
    },
  ];

  const handleRowClick = (record: any) => {
    if (record && record.media_type === 'folder') {
      setparentId([...parentId, record.parent_id]);
      setcurrentDirectoryId(record.id);
    } else {
      fetch(record.file_url);
      downloadFile(record.file_url, record.title);
    }
  };

  const handleGoBack = () => {
    setcurrentDirectoryId(String(parentId[parentId.length - 1]));
    setparentId(parentId.slice(0, -1)); // Remove last element
  };
  const loadData = () => {
    if (!userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getDealerResources();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getDealerResources();
    }
  };
  useEffect(() => {
    if (userDetails?.organization && pageSize && pageNumber) loadData();
  }, [userDetails, pageNumber, pageSize]);

  useEffect(() => {
    activity.current = 'refresh';
    if (userDetails?.organization) loadData();
  }, [currentDirectoryId]);

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();
    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  return (
    <div className="common_tabs">
      <div className="help_center_container resources">
        <div className="hc_top_bg"></div>
        <div className="hc_logo_section mb26 mh32">
          <div
            className="flex cursorPointer"
            onClick={() => {
              history.push(RoutesConstants.DealerMaintenance);
            }}
          >
            <div>
              <img src={hc_monarch_logo} alt="logo" />
              <h4 className="hc_title_top">Resources</h4>
            </div>
          </div>
        </div>
        <div className="top_search_bar posrel mb26">
          <div className="backbtnPos">
            <div
              onClick={() => goBack()}
              className="back-button-wbdr backtoCardsBtn"
            />
          </div>
          <Row gutter={[20, 0]}>
            <Col xl={{ span: 24, offset: 0 }} className="hc_search mw400">
              {/* <ResourceSearch flag={false}></ResourceSearch> */}
            </Col>
          </Row>
        </div>

        <div className="resourcesWrapper">
          <div className="common_wrapper resViewPort">
            <div className="filters_card spaceBtnAlignCenter">
              <div className="docHeadTxt">
                {currentDirectoryId !== '1' && (
                  <span onClick={() => handleGoBack()}>
                    <img
                      src={rsLeftArrow}
                      alt="leftarrow"
                      className="rsLeftArrow"
                    />
                  </span>
                )}
                <span>Documents</span>
              </div>
            </div>
            <div className="common_table">
              <InfiniteScrollTable
                columns={columns}
                loading={loader}
                hasMore={hasMore}
                filterData={filterData}
                totalcount={totalCount}
                handleLoadMore={handleLoadMore}
                filename="Dealer Resources"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
