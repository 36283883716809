import { create } from 'zustand';

interface DealerDashboardSummary {
  fleet: number;
  employees: number;
  tickets: number;
  customers: number;
}

interface DealerFleetHealth {
  level1: number;
  level2: number;
  level3: number;
  total: number;
}
interface DealerFleetUsage {
  manual: number;
  autonomy: number;
  idleTime: number;
  opAssist: number;
  total: number;
  manualHours: string;
  autonomyHours: string;
  idleTimeHours: string;
  opAssistHours: string;
  totalHours: string;
}

type State = {
  dealerDashboardSummary: DealerDashboardSummary | null;
  fleetUsageData: DealerFleetUsage;
  fleetHealthData: DealerFleetHealth;
};

type Actions = {
  setDealerDashboardSummary: (data: DealerDashboardSummary) => void;
  setFleetUsageData: (data: DealerFleetUsage) => void;
  setFleetHealthData: (data: DealerFleetHealth) => void;
  reset: () => void;
};

const initialState: State = {
  dealerDashboardSummary: null,
  fleetUsageData: {
    manual: 0,
    autonomy: 0,
    idleTime: 0,
    opAssist: 0,
    total: 0,
    manualHours: '0:0',
    autonomyHours: '0:0',
    idleTimeHours: '0:0',
    opAssistHours: '0:0',
    totalHours: '0:0',
  },
  fleetHealthData: {
    level1: 0,
    level2: 0,
    level3: 0,
    total: 0,
  },
};

const useDealerDashboardStore = create<State & Actions>()((set, get) => ({
  ...initialState,
  setDealerDashboardSummary: (dealerDashboardSummary) =>
    set(() => ({
      dealerDashboardSummary,
    })),
  setFleetUsageData: (fleetUsageData) =>
    set(() => ({
      fleetUsageData,
    })),
  setFleetHealthData: (fleetHealthData) =>
    set(() => ({
      fleetHealthData,
    })),
  reset: () => {
    set(initialState);
  },
}));

export default useDealerDashboardStore;
