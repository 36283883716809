import React from 'react';
import MSHeader from '../layouts/MSHeader';
import Fleet from './Fleet';
import { useHistory } from 'react-router-dom';
import RoutesConstants from '../../routes/RoutesConstant';

const FleetContainer: React.FC = () => {
  const { push } = useHistory();
  return (
    <div className="dashboardContainer comCont">
      <MSHeader
        title="Fleet Directory"
        handleBack={() => push(RoutesConstants.FarmProfile)}
      />
      <Fleet />
    </div>
  );
};

export default FleetContainer;
