import React from 'react';
import DealerDashboardPage, { CardBlock } from '../common/DashboardCard';
import RoutesConstants from '../../../routes/RoutesConstant';
import ReportsIcon from '../../../assets/images/reports_icon.svg';
import TractorUsageReportIcon from '../../../assets/images/tractor_usage_report_icon.svg';

interface CardProps {
  title: string;
  description: string;
  path: string;
  icon: string;
}

const Items: CardProps[] = [
  {
    title: 'Tractor Usage Report',
    description:
      'View detailed usage reports at both organization and individual tractor levels for performance and insights.',
    path: RoutesConstants.DealerAnalyticsTractor,
    icon: TractorUsageReportIcon,
  },
  {
    title: 'Reports',
    description:
      'Get daily insights into fleet performance with summaries and key metrics across all vehicles.',
    path: RoutesConstants.DealerReports,
    icon: ReportsIcon,
  },
];

const DealerAnalyticsPage: React.FC = () => {
  return (
    <DealerDashboardPage title={'Analytics'}>
      {Items.map((props: CardProps) => (
        <CardBlock key={props.title} {...props} />
      ))}
    </DealerDashboardPage>
  );
};

export default DealerAnalyticsPage;
