import React from 'react';
import { Tabs } from 'antd';
import SupportTickets from './SupportTickets';
import Tickets from '../../../tickets/Tickets';
import SupportTicketsHotLine from '../supportTicketHotLine/SupportTicketHotLine';
import useRouterTab from '../../../../hooks/useRouterTab';
import '.././style.css';

const { TabPane } = Tabs;

const Index: React.FC = () => {
  const [tabid, setTabid] = useRouterTab('internalTickets');

  return (
    <div className="rightSideContainer">
      <Tabs
        activeKey={tabid}
        centered
        id="org-tab-Organization"
        className="common_tabs implementTab"
        onChange={(key) => {
          setTabid(key);
        }}
      >
        <TabPane
          tab="Internal Tickets"
          key="internalTickets"
          className="attTableHeight mBottom0"
        >
          {tabid === 'internalTickets' && <Tickets />}
        </TabPane>
        <TabPane
          tab="Support Tickets"
          key="supportTickets"
          className="attTableHeight mBottom0"
        >
          {tabid === 'supportTickets' && <SupportTickets />}
        </TabPane>
        <TabPane
          tab="Support Ticket Escalation HotLine"
          key="supportTicketEscalationHotLine"
          className="attTableHeight mBottom0"
        >
          {tabid === 'supportTicketEscalationHotLine' && (
            <SupportTicketsHotLine />
          )}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Index;
