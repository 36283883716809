/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from 'react';
import { Checkbox, Tag, TreeSelect } from 'antd';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';

interface Props {
  handleTreeSelect: (value?: any) => void;
  selectedId: any[];
  list: any;
  label: string;
  testId?: string;
  tagRequired?: boolean;
  hideSelectAll?: boolean;
  extraClass?: string;
  maxTagCount?: number;
  disabled?: boolean;
}

const CustomMultiSelectForMultiSite: React.FC<Props> = ({
  handleTreeSelect,
  selectedId,
  list,
  label,
  testId,
  tagRequired,
  hideSelectAll = false,
  extraClass = '',
  maxTagCount = 1,
  disabled = false,
}: Props) => {
  const { t } = useTranslation();
  const [focus, setFocus] = useState(false);

  const isEmpty = (value: any) => {
    if (typeof value === 'undefined') return true;
    if (typeof value === 'string' && value.trim() === '') return true;
    if (typeof value === 'number' && isNaN(value)) return true;
    if (value?.length === 0) return true;
    return false;
  };

  const labelClass =
    focus || !isEmpty(selectedId) ? 'label label-float' : 'label';

  const filterTreeNode = (inputValue: string, treeNode: any) => {
    if (!inputValue) {
      return true;
    }
    const titleText = treeNode?.title?.props?.children;
    if (
      typeof titleText === 'string' &&
      titleText?.toLowerCase()?.includes(inputValue?.toLowerCase())
    ) {
      return true;
    } else {
      const spanElement = treeNode?.title?.props?.children[0];
      const textContent = spanElement?.props?.children || '';
      return textContent?.toLowerCase()?.includes(inputValue?.toLowerCase());
    }
  };

  const handleSelectAllChange = (status: string) => {
    if (status === 'select') {
      const allIds = list?.map(({ value }: any) => String(value));
      handleTreeSelect(allIds);
    } else {
      handleTreeSelect([]);
    }
  };

  const renderTreeNodeTitle = (node: any) => {
    return (
      <>
        {node.title}
        {tagRequired && node?.isGoldBuild && (
          <Tag color="gold" className="tagGold">
            {t(translate.updates.gold)}
          </Tag>
        )}
      </>
    );
  };

  return (
    <div
      className={`float-label ${extraClass} 
      ${selectedId && selectedId.length === 1 ? 'haveSingleRecord' : ''} `}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <TreeSelect
        onClear={() => handleTreeSelect([])}
        allowClear
        showSearch
        data-testid={testId}
        treeCheckable
        defaultValue={selectedId}
        showCheckedStrategy={TreeSelect.SHOW_CHILD}
        className="treeSelect"
        style={{ minWidth: '250px' }}
        onChange={handleTreeSelect}
        value={selectedId}
        maxTagCount={maxTagCount}
        filterTreeNode={filterTreeNode}
        maxTagPlaceholder={(omittedValues: any) => `+ ${omittedValues.length}`}
        disabled={disabled}
        dropdownRender={(menu) => (
          <div>
            {!hideSelectAll ? (
              selectedId?.length && selectedId?.length === list.length ? (
                <Checkbox
                  onChange={() => handleSelectAllChange('unselect')}
                  checked={selectedId.length === list.length}
                  className="ant-select-tree-checkbox custom-select-dropdown"
                  data-testid="unSelectAllCheckbox-OrganisationSelect"
                >
                  Unselect All
                </Checkbox>
              ) : (
                <Checkbox
                  onChange={() => handleSelectAllChange('select')}
                  checked={selectedId?.length === list?.length}
                  className="ant-select-tree-checkbox custom-select-dropdown"
                  data-testid="selectAllCheckbox-OrganisationSelect"
                >
                  Select All
                </Checkbox>
              )
            ) : (
              <>
                <div style={{ height: 5 }}>&nbsp;</div>
              </>
            )}
            {menu}
          </div>
        )}
        treeData={
          tagRequired
            ? list?.map((item: any) => ({
                ...item,
                title: renderTreeNodeTitle(item),
              }))
            : list
        }
      />
      <label className={labelClass}>{label}</label>
    </div>
  );
};

export default CustomMultiSelectForMultiSite;
