/* eslint-disable no-console */
import { notification } from 'antd';
import { getMultiSiteProfileData, getSitesData } from '../../../constants/Api';
import {
  FarmDetails,
  MultiSiteProfile,
  SiteDetails,
  UserDetails,
} from '../../../constants/types';
import useFarmProileStore from '../useFarmProfileStore';

const useSite = () => {
  const { setSites, setFarmDetails, setMultiSiteLoader } = useFarmProileStore();

  const getSites = async (userDetails: UserDetails) => {
    setMultiSiteLoader(true);
    try {
      const { farmsites }: { farmsites: SiteDetails[] } = await getSitesData(
        userDetails.organization.api_url,
      );
      setSites(farmsites);
    } catch (error: any) {
      notification.error({ message: error.message });
    } finally {
      setMultiSiteLoader(false);
    }
  };
  const getMultiSiteProfile = async (userDetails: UserDetails) => {
    setMultiSiteLoader(true);

    try {
      const data: MultiSiteProfile[] = await getMultiSiteProfileData(
        userDetails.organization.api_url,
        userDetails.organization.id,
      );
      data && data.length > 0 && transformData(data);
    } catch (error: any) {
      notification.error({ message: error.message });
    } finally {
      setMultiSiteLoader(false);
    }
  };
  const transformData = (input: MultiSiteProfile[]): void => {
    if (input.length === 0) return;
    const organization = input[0].organization_id;
    const output: FarmDetails = {
      name: organization.name,
      farmType: organization.crop_type,
      titleSites: organization.farmsite_count,
      image: organization.profile_url,
    };
    for (const item of input) {
      if (item.name === 'diesel_cost') {
        output['dieselCost'] = item.value;
      }
      if (item.name === 'electricity_rate') {
        output['electricityRate'] = item.value;
      }
    }
    setFarmDetails(output);
  };
  return { getMultiSiteProfile, getSites };
};

export default useSite;
