/* eslint-disable no-console */
/* eslint-disable react/jsx-no-undef */
import { Button, Dropdown, Menu, Tooltip } from 'antd';
import React, { useContext } from 'react';

import chargePoint from '../../assets/images/charge_point.svg';
import polygon_icon from '../../assets/images/polygon_icon.svg';
import vine from '../../assets/images/Vine.svg';
import Vine_Block from '../../assets/images/Vine_Block.svg';
import Vine_Lane from '../../assets/images/Vine_Lane.svg';
import waypoint from '../../assets/images/Waypoint.svg';
import translate from '../../locale/en_translate.json';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { isVineYardOrDairy } from '../../constants/Common';
import {
  AB_BLOCK,
  AB_LINE,
  CHARGE_POINTS,
  dairyAutoDrive,
  listVisibility,
  POLYGON,
  SURVEY_VINE_ROW,
  SURVEY_VINE_ROW_NEW,
  VINE,
  VINE_BLOCK,
  VINE_LANE,
  VINE_ROW,
  vineYardAutoDrive,
  WAY_POINT,
} from '../../constants/constant';
import {
  DrawItem,
  GenerateItem,
  UserDetails,
  VineRowPayload,
  VineRowPayloadSingle,
} from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import RoutesConstants from '../../routes/RoutesConstant';
import dealerStore from '../store/dealer';
interface Props {
  activeDrawAction: DrawItem['item'];
  setActiveDrawAction: (activeDrawAction: DrawItem['item']) => void;
  vineRowPayload: VineRowPayloadSingle | null;
  vineRowModel2LastPayload: VineRowPayload | null;
  vineRowModal: DrawItem['vineRowModal'];
  showSave: boolean;
  globalVineRowModal: DrawItem['vineRowModal'];
  cancelOperations: () => void;
  onSaveClick: () => void;
  handleToggleLayer: (toggle: boolean, item: string, position: number) => void;
  drawWayPoint: () => void;
  drawChargePoint: () => void;
  drawVineBlockFun: () => void;
  drawVine: () => void;
  drawPolygon: () => void;
  drawAbLine1: () => void;
  drawVineLaneFun: () => void;
  drawVineRow: () => void;
  setShowVineRow: (state: boolean) => void;
  setVineRowModal: (level: DrawItem['vineRowModal']) => void;
  setVineLaneModal: (level: 1 | 2) => void;
  drawSurveyVertices: () => void;
  setShowVineBlockFileUpload: (status: boolean) => void;
  brownPolygonsLength: number;
  brownConfirm: () => void;
  setGenerateFromRowOrAbLane: (state: GenerateItem['type']) => void;
  setShowFeedLine: (show: boolean) => void;
}
const MapMakerHeader: React.FC<Props> = ({
  activeDrawAction,
  setActiveDrawAction,
  vineRowPayload,
  vineRowModel2LastPayload,
  vineRowModal,
  showSave,
  globalVineRowModal,
  cancelOperations,
  onSaveClick,
  handleToggleLayer,
  drawWayPoint,
  drawChargePoint,
  drawVineBlockFun,
  drawVine,
  drawPolygon,
  drawAbLine1,
  drawVineLaneFun,
  drawVineRow,
  setShowVineRow,
  setVineRowModal,
  setVineLaneModal,
  drawSurveyVertices,
  setShowVineBlockFileUpload,
  brownPolygonsLength,
  brownConfirm,
  setGenerateFromRowOrAbLane,
  setShowFeedLine,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const { dealerData } = dealerStore();

  const { t } = useTranslation();
  const { push } = useHistory();
  // vine lane menu
  const vineLaneMenu = () => (
    <Menu>
      <Menu.Item
        className="pMenu"
        onClick={() => {
          setVineLaneModal(1);
          listVisibility.VINEROW = true;
          listVisibility.VINELANE = true;
          handleToggleLayer(true, VINE_ROW, 3);
          setTimeout(() => {
            handleToggleLayer(true, VINE_LANE, 9);
          }, 1000);
          cancelOperations();
          drawVineLaneFun();
        }}
      >
        Single {t(translate.map.crop_lane)}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setVineLaneModal(2);
          listVisibility.VINEROW = true;
          listVisibility.VINELANE = true;
          handleToggleLayer(true, VINE_ROW, 3);
          setTimeout(() => {
            handleToggleLayer(true, VINE_LANE, 9);
          }, 1000);
          cancelOperations();
          drawVineLaneFun();
        }}
      >
        Auto Generate {t(translate.map.crop_lane)}
      </Menu.Item>
      {/* <Menu.Item
        onClick={() => {
          cancelOperations();
          setShowFeedLine(true);
        }}
      >
        Feed Lane
      </Menu.Item> */}
    </Menu>
  );
  // vine rows menu
  const vineRowMenu = () => (
    <Menu>
      <Menu.Item
        className="pMenu"
        onClick={() => {
          setVineRowModal(1);
          // globalVineRowModal = 1;
          handleToggleLayer(true, VINE_ROW, 3);
          listVisibility.VINEROW = true;
          cancelOperations();
          drawVineRow();
        }}
      >
        Single {t(translate.map.crop_row)}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setVineRowModal(2);
          // globalVineRowModal = 2;
          handleToggleLayer(true, VINE_ROW, 3);
          listVisibility.VINEROW = true;
          cancelOperations();
          drawVineRow();
        }}
      >
        Auto Generate {t(translate.map.crop_row)}
      </Menu.Item>
      <Menu.Item
        className="pMenu"
        onClick={() => {
          setVineRowModal(3);
          // globalVineRowModal = 3;
          handleToggleLayer(true, VINE_ROW, 3);
          listVisibility.VINEROW = true;
          cancelOperations();
          // drawVineRow();
          setShowVineRow(true);
        }}
      >
        Generate using Jump and Go
      </Menu.Item>
      <Menu.Item
        className="pMenu"
        onClick={() => {
          setGenerateFromRowOrAbLane(VINE_ROW);
          setVineRowModal(5);
          // globalVineRowModal = 5;
          handleToggleLayer(true, SURVEY_VINE_ROW, 13);
          listVisibility.SURVEYVINROW = true;
          setTimeout(() => {
            handleToggleLayer(true, SURVEY_VINE_ROW_NEW, 14);
          }, 100);
          listVisibility.SURVEY_VINE_ROW_NEW = true;

          cancelOperations();
          // drawVineRow();
          setShowVineRow(true);
          drawSurveyVertices();
        }}
      >
        Generate Using {t(translate.map.crop_row)} Mapping
      </Menu.Item>
      {/* <Menu.Item
        className="pMenu"
        onClick={() => {
          setVineRowModal(6);
          globalVineRowModal = 6;
          handleToggleLayer(true, VINE_ROW, 3);
          listVisibility.VINEROW = true;
          cancelOperations();
          setShowVineRow(true);
        }}
      >
        Generate Using Jump and Go
      </Menu.Item> */}
    </Menu>
  );
  // ab line menu
  const abLineMenu = () => (
    <Menu>
      {/* <Menu.Item
        className="pMenu"
        onClick={() => {
          handleToggleLayer(true, AB_LINE, 11);
          listVisibility.ABLINE = true;
          cancelOperations();
          drawAbLine();
        }}
      >
        Single Ab Line
      </Menu.Item> */}
      <Menu.Item
        onClick={() => {
          handleToggleLayer(true, AB_LINE, 11);
          listVisibility.ABLINE = true;
          cancelOperations();
          drawAbLine1();
        }}
      >
        Auto Generate Ab Line
      </Menu.Item>
      <Menu.Item
        className="pMenu"
        onClick={() => {
          setGenerateFromRowOrAbLane(AB_LINE);
          setVineRowModal(5);
          // globalVineRowModal = 5;
          handleToggleLayer(true, SURVEY_VINE_ROW, 13);
          listVisibility.SURVEYVINROW = true;

          setTimeout(() => {
            handleToggleLayer(true, SURVEY_VINE_ROW_NEW, 14);
          }, 100);
          listVisibility.SURVEY_VINE_ROW_NEW = true;

          cancelOperations();
          // drawVineRow();
          setShowVineRow(true);
          drawSurveyVertices();
        }}
      >
        Generate Using {t(translate.map.crop_row)} Mapping
      </Menu.Item>
    </Menu>
  );
  const cropBlockMenu = () => (
    <Menu>
      <Menu.Item
        onClick={() => {
          listVisibility.VINEBLOCK = true;
          listVisibility.VINEROW = true;
          handleToggleLayer(true, VINE_BLOCK, 4);
          setTimeout(() => {
            handleToggleLayer(true, VINE_ROW, 3);
          }, 1000);
          cancelOperations();
          drawVineBlockFun();
        }}
      >
        Single CropBlock
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          listVisibility.VINEBLOCK = true;
          listVisibility.VINEROW = true;
          handleToggleLayer(true, VINE_BLOCK, 4);
          setTimeout(() => {
            handleToggleLayer(true, VINE_ROW, 3);
          }, 1000);
          cancelOperations();
          setShowVineBlockFileUpload(true);
        }}
      >
        Generate CropBlock using File upload
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="livemapTopBar">
      <Tooltip title="Back" placement="right">
        <div
          className="leftTIconClose"
          data-testid="backArrowIcon-MapMakerMap"
          onClick={() => {
            const path = dealerData
              ? RoutesConstants.Organization
              : RoutesConstants.FarmProfile;
            push(path);
          }}
        ></div>
      </Tooltip>
      <div className="topMenuTypeTxt">
        {activeDrawAction !== 'NONE' && (
          <>
            <div className="drawBtnsSec">
              <div className="pBtnsRow">
                <Button
                  key="back"
                  className="pcancelBtn"
                  data-testid="cancelButton-MapMakerMap"
                  onClick={() => cancelOperations()}
                >
                  Cancel
                </Button>
                {(activeDrawAction === 'BASESTATION' ||
                  activeDrawAction === 'MARK' ||
                  activeDrawAction === WAY_POINT ||
                  activeDrawAction === CHARGE_POINTS ||
                  activeDrawAction === VINE ||
                  activeDrawAction === POLYGON ||
                  activeDrawAction === 'POLYGONEDIT' ||
                  (activeDrawAction === VINE_ROW &&
                    ((vineRowModal === 1 &&
                      vineRowPayload &&
                      vineRowPayload.vineRowXY &&
                      vineRowPayload?.vineRowXY.length > 0) ||
                      (vineRowModal === 2 &&
                        vineRowModel2LastPayload &&
                        vineRowModel2LastPayload.vineRowXY &&
                        vineRowModel2LastPayload.vineRowXY.length > 0))) ||
                  activeDrawAction === 'VINEROWEDIT' ||
                  activeDrawAction === VINE_BLOCK ||
                  activeDrawAction === 'VINEBLOCKEDIT' ||
                  activeDrawAction === AB_LINE ||
                  activeDrawAction === 'ABLINEEDIT' ||
                  activeDrawAction === AB_BLOCK ||
                  activeDrawAction === 'ABBLOCKEDIT' ||
                  activeDrawAction === 'POLYLINEEDIT') &&
                  showSave && (
                    <Button
                      key="submit"
                      className="psaveBtn"
                      data-testid="nextButtonWaypoint-MapMakerMap"
                      onClick={() => onSaveClick()}
                    >
                      Next
                    </Button>
                  )}
              </div>
              {!showSave && (
                <>
                  {activeDrawAction === WAY_POINT && (
                    <span className="pHelpTxt">
                      Select point on the map to create
                      {t(translate.map.waypoint)}
                    </span>
                  )}
                  {activeDrawAction === VINE && (
                    <span className="pHelpTxt">
                      Select point on the map to create {t(translate.map.crop)}
                    </span>
                  )}
                  {activeDrawAction === POLYGON && (
                    <span className="pHelpTxt">
                      Select points on the map to draw{' '}
                      {t(translate.map.boundary)}
                    </span>
                  )}
                  {activeDrawAction === VINE_ROW && (
                    <>
                      <span className="pHelpTxt">
                        Select two points on map to draw{' '}
                        {t(translate.map.crop_row)}
                      </span>
                    </>
                  )}
                  {activeDrawAction === 'VINEROWFROMJUMPANDGO' && (
                    <span className="pHelpTxt">
                      Select four points on Jump and Go to generate{' '}
                      {t(translate.map.crop_row)}
                    </span>
                  )}
                  {activeDrawAction === VINE_BLOCK && (
                    <span className="pHelpTxt">
                      Select {t(translate.map.crop_rows)} to create{' '}
                      {t(translate.map.crop_block)}
                    </span>
                  )}
                  {activeDrawAction === VINE_LANE && (
                    <span className="pHelpTxt">
                      Select any two {t(translate.map.crop_row)} to draw
                      {t(translate.map.crop_lane)}
                    </span>
                  )}
                  {activeDrawAction === AB_LINE && (
                    <span className="pHelpTxt">
                      Select two points on map to draw Ab line
                    </span>
                  )}
                  {activeDrawAction === CHARGE_POINTS && (
                    <span className="pHelpTxt">
                      Select point on the map to create{' '}
                      {t(translate.map.charge_point)}
                    </span>
                  )}
                </>
              )}
            </div>
          </>
        )}
        {activeDrawAction === 'NONE' && (
          <div>
            <ul>
              <li
                onClick={() => {
                  handleToggleLayer(true, WAY_POINT, 0);
                  listVisibility.WAYPOINT = true;
                  cancelOperations();
                  drawWayPoint();
                }}
              >
                <img src={waypoint} data-testid="waypointMaker-MapMakerMap" />
                {t(translate.map.waypoint)}
              </li>
              <li
                onClick={() => {
                  if (brownPolygonsLength === 0) {
                    brownConfirm();
                  } else {
                    handleToggleLayer(true, POLYGON, 1);
                    listVisibility.POLYGON = true;
                    cancelOperations();
                    drawPolygon();
                  }
                }}
              >
                <img
                  src={polygon_icon}
                  data-testid="polygonMaker-MapMakerMap"
                />
                {t(translate.map.boundary)}
              </li>
              {isVineYardOrDairy(userDetails as UserDetails) ===
                dairyAutoDrive && (
                <li
                  onClick={() => {
                    cancelOperations();
                    setShowFeedLine(true);
                  }}
                >
                  <img src={Vine_Lane} data-testid="" />
                  {t(translate.map.feed_line)}
                </li>
              )}
              {isVineYardOrDairy(userDetails as UserDetails) ===
                vineYardAutoDrive && (
                <>
                  <li
                    onClick={() => {
                      handleToggleLayer(true, VINE, 2);
                      listVisibility.VINE = true;
                      cancelOperations();
                      drawVine();
                    }}
                  >
                    <img src={vine} data-testid="vineMaker-MapMakerMap" />
                    {t(translate.map.crop)}
                  </li>
                  <li>
                    <Dropdown overlay={vineRowMenu}>
                      <a onClick={(e) => e.preventDefault()}>
                        <img
                          src={Vine_Lane}
                          data-testid="vineRowMaker-MapMakerMap"
                        />
                        {t(translate.map.crop_row)}
                      </a>
                    </Dropdown>
                  </li>
                  <li>
                    <Dropdown overlay={cropBlockMenu}>
                      <a onClick={(e) => e.preventDefault()}>
                        <img
                          src={Vine_Block}
                          data-testid="vineBlockMaker-MapMakerMap"
                        />
                        {t(translate.map.crop_block)}
                      </a>
                    </Dropdown>
                  </li>
                  <li>
                    <Dropdown overlay={vineLaneMenu}>
                      <a onClick={(e) => e.preventDefault()}>
                        <img
                          src={Vine_Lane}
                          data-testid="vineLaneMaker-MapMakerMap"
                        />
                        {t(translate.map.crop_lane)}
                      </a>
                    </Dropdown>
                  </li>
                  {/* <li
                    onClick={() => {
                      handleToggleLayer(true, AB_LINE, 11);
                      listVisibility.ABLINE = true;
                      cancelOperations();
                      drawAbLine();
                    }}
                  >
                    <img src={Vine_Lane} />
                    Ab line
                  </li> */}
                  <li>
                    <Dropdown overlay={abLineMenu}>
                      <a onClick={(e) => e.preventDefault()}>
                        <img
                          src={Vine_Lane}
                          data-testid="abLaneMaker-MapMakerMap"
                        />
                        Ab Line
                      </a>
                    </Dropdown>
                  </li>
                </>
              )}

              <li
                onClick={() => {
                  handleToggleLayer(true, CHARGE_POINTS, 14);
                  listVisibility.CHARGEPOINTS = true;
                  cancelOperations();
                  drawChargePoint();
                }}
              >
                <img src={chargePoint} />
                {t(translate.map.charge_point)}
              </li>
              {/* <li
                    onClick={() => {
                      handleToggleLayer(true, 'ABBLOCK', 12);
                      listVisibility.ABBLOCK = true;
                      cancelOperations();
                      drawAbBlock();
                    }}
                  >
                    <img src={polygon_icon} />
                    Ab Block
                  </li> */}
            </ul>
            <h5>Add Map Element</h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default MapMakerHeader;
