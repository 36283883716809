import React from 'react';
import ticketIcon from '../../../assets/images/ticket_icon.svg';
import migrationIcon from '../../../assets/images/migration_icon.svg';
import fllethealthIcon from '../../../assets/images/fllethealth_icon.svg';
import manualIcon from '../../../assets/images/manual_icon.svg';
import updatesIcon from '../../../assets/images/updates_icon.svg';
import diagnosticsIcon from '../../../assets/images/diagnostics_icon.svg';
import surveyIcon from '../../../assets/images/survey_icon.svg';
import dealerResourceIcon from '../../../assets/images/dealer_resource_icon.svg';
import { useHistory } from 'react-router-dom';
import RoutesConstants from '../../../routes/RoutesConstant';
import DealerDashboardPage, { CardBlock } from '../common/DashboardCard';

interface CardProps {
  title: string;
  description: string;
  path: string;
  icon: string;
}

const Items: CardProps[] = [
  {
    title: 'Maintenance',
    description:
      'Manage support tickets with status, priority, alerts, and quick actions for easy resolution.',
    path: RoutesConstants.DealerTickets,
    icon: ticketIcon,
  },
  {
    title: 'Migration',
    description:
      'Easily migrate tractors or basestations between organizations with streamlined status updates and quick actions.',
    path: RoutesConstants.DealerMigrate,
    icon: migrationIcon,
  },
  {
    title: 'Fleet Health',
    description:
      'Monitor fleet health with real-time status, alerts, and quick actions to maintain optimal performance.',
    path: RoutesConstants.DealerFleetHealth,
    icon: fllethealthIcon,
  },
  {
    title: 'Manuals',
    description:
      'Access equipment manuals quickly for troubleshooting, maintenance, and operational guidance.',
    path: RoutesConstants.DealerManuals,
    icon: manualIcon,
  },
  {
    title: 'Updates',
    description:
      'Manage over-the-air updates seamlessly for firmware and software, ensuring devices stay up-to-date.',
    path: RoutesConstants.DealerUpdates,
    icon: updatesIcon,
  },
  {
    title: 'Diagnostics',
    description:
      'Access Fleet Indicator History and view error codes with detailed descriptions for efficient troubleshooting.',
    path: RoutesConstants.DealerDiagnostics,
    icon: diagnosticsIcon,
  },
  {
    title: 'Survey',
    description:
      'Perform precise surveys to capture and record basestation locations for optimal positioning and performance.',
    path: RoutesConstants.DealerSurvey,
    icon: surveyIcon,
  },
  {
    title: 'Dealer Resources',
    description:
      'Click to explore and manage your dealership sales & marketing resources easily.',
    path: RoutesConstants.DealerResources,
    icon: dealerResourceIcon,
  },
];

const DealerMaintenance: React.FC = () => {
  return (
    <DealerDashboardPage title={'Maintenance'}>
      {Items.map((props: CardProps) => (
        <CardBlock key={props.title} {...props} />
      ))}
    </DealerDashboardPage>
  );
};

export default DealerMaintenance;
