import { Style, Fill, Stroke, Text, Icon } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import manualDirectionIcon from './../../assets/images/tractor-icons/manualDirectionIcon.svg';

export const completedWorkInfoContent =
  'Daily summary of work done by a tractor and implement pair ';
export const avgBatteryInfoContent =
  'Battery Range is based on driving technique and the power needs of this implement type.';

// ['#67afb7', '#ec9322', '#f1ce90', '#cfcfcf'];

export const pathColorCodes = {
  idle: '#cfcfcf',
  manual: '#67afb7',
  op_assists: '#f1ce90',
  autonomy: '#ec9322',
  coverage: '#4078C0',
};

export const errorLevelColorCodes = {
  level1: '#F1CE90',
  level2: '#EC9322',
  level3: '#D43D38',
};

export const roundStyle = (operator: {
  first_name: string;
  last_name: string;
  knownBearing: number;
}): any[] => [
  new Style({
    image: new CircleStyle({
      radius: 22,
      fill: new Fill({ color: '#B5B5B6' }),
      stroke: new Stroke({
        color: '#57B848',
        width: 1,
      }),
    }),
    text: new Text({
      text: `${
        operator?.first_name?.charAt(0) + operator?.last_name?.charAt(0) || ''
      }`,
      fill: new Fill({
        color: '#ffffff',
      }),
      font: '19px Montserrat-SemiBold',
      // offsetY: -3.5,
    }),
    zIndex: 1,
  }),
  new Style({
    image: new Icon({
      src: manualDirectionIcon,
      anchor: [0.5, 3],
      rotation: ((90 - operator.knownBearing) * Math.PI) / 180,
    }),
    zIndex: 2,
  }),
];

export const splitArrayIntoChunks = (array: any[], size = 5): any[] => {
  const chunks = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
};

export const unSelectedType = 'unselected';

export const selectedCameraMap = 'map';
