import { create } from 'zustand';
import { FarmDetails, SiteDetails } from '../../constants/types';

type State = {
  showSiteModal: boolean;
  showEditFarmModal: boolean;
  farmDetails: FarmDetails | null;
  editSiteDetails: SiteDetails | null;
  sites: SiteDetails[];
  multiSiteLoader: boolean;
  isInEssentailsPackage: boolean;
};

type Actions = {
  setShowSiteModal: (state: boolean) => void;
  setShowEditFarmModal: (state: boolean) => void;
  setFarmDetails: (data: FarmDetails) => void;
  setEditSiteDetails: (data: SiteDetails | null) => void;
  setSites: (data: SiteDetails[]) => void;
  reset: () => void;
  setMultiSiteLoader: (state: boolean) => void;
  setIsInEssentailsPackage: (state: boolean) => void;
};

const initialState: State = {
  showSiteModal: false,
  showEditFarmModal: false,
  farmDetails: null,
  editSiteDetails: null,
  sites: [],
  multiSiteLoader: false,
  isInEssentailsPackage: false,
};

const useFarmProileStore = create<State & Actions>()((set, get) => ({
  ...initialState,
  setShowSiteModal: (showSiteModal) =>
    set((state) => ({
      showSiteModal,
    })),
  setShowEditFarmModal: (showEditFarmModal) =>
    set((state) => ({
      showEditFarmModal,
    })),
  setFarmDetails: (farmDetails) =>
    set((state) => ({
      farmDetails,
    })),
  setEditSiteDetails: (editSiteDetails) =>
    set((state) => ({
      editSiteDetails,
    })),
  setSites: (sites) =>
    set((state) => ({
      sites,
    })),
  setMultiSiteLoader: (multiSiteLoader) =>
    set((state) => ({
      multiSiteLoader,
    })),
  setIsInEssentailsPackage: (isInEssentailsPackage) =>
    set((state) => ({
      isInEssentailsPackage,
    })),
  reset: () => {
    set(initialState);
  },
}));

export default useFarmProileStore;
