import { Col, notification, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import CustomSelect from '../../common/CustomSelect';
import InfiniteScrollTable from '../../common/InfiniteScrollTable';
import usePaginate from '../../../hooks/usePaginate';

import { useApplicationContext } from '../../../context/AppContext';
import { dealerCustomers } from '../../../constants/Api';
import { mapDealerCustomers } from './model';
import {
  getShortUserNameFromString,
  initScroller,
} from '../../../constants/Common';
import DealerOperationalHoursCard from './DealerOperationalHoursCard';
import msFleetIcon from '../../../assets/images/ms_fleet_icon.svg';
import userNoData from './images/user_outlinedd.svg';
import { getDealerCropTypes } from '../API';

interface DropdownListType {
  name: string;
  value: string;
}

export const EmptyView: React.FC<{ image: string }> = ({ image }) => {
  return (
    <div className="ddNodata">
      <div className="ddNodataWrapper">
        <span>
          <img src={image} alt="" />
        </span>
        <span className="ddNodatatxt">No data</span>
      </div>
    </div>
  );
};

const DealerCustomerUsage: React.FC = () => {
  const { userDetails } = useApplicationContext();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState<number>();
  const [cropType, setCropType] = useState('');
  const [cropTypeList, setCropTypeList] = useState<DropdownListType[]>([]);
  const { filterData, setData, activity, hasMore, checkActivity } =
    usePaginate();
  const [pageNumber, setPageNumber] = useState(1);

  const initDealerCustomerDetails = async () => {
    try {
      setLoading(true);
      const { organization } = userDetails;
      const result = await dealerCustomers(organization.api_url, cropType);
      const { records } = result;
      let data = Array.isArray(records) ? records : [];
      setTotal(result._metadata.total_records_count);
      data = mapDealerCustomers(data);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: `initDealerCustomerDetails: ${error?.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initCropTypes = async () => {
      let data = await getDealerCropTypes();
      data = data.map(({ name }: any) => ({ name, value: name }));
      setCropTypeList(data);
    };
    if (userDetails?.organization) initCropTypes();
  }, [userDetails]);

  useEffect(() => {
    if (userDetails?.organization) loadData();
  }, [userDetails, cropType]);

  const columns: any = [
    {
      title: 'Customer Name',
      dataIndex: 'name',
      key: 'name',
      width: '40%',
      render: (name: string) => {
        return (
          <Space size={'small'}>
            <span className="ms_round ms_circle_green">
              {name && getShortUserNameFromString(name)}
            </span>
            {name}
          </Space>
        );
      },
    },
    {
      title: 'Fleet Size',
      dataIndex: 'fleetSize',
      key: 'fleetSize',
      render: (fleetSize: number) => {
        return (
          <Space size={'small'}>
            <img src={msFleetIcon} alt="" />
            {fleetSize}
          </Space>
        );
      },
      width: '15%',
    },
    {
      title: 'Crop Type',
      dataIndex: 'cropType',
      key: 'cropType',
      width: '25%',
    },
    // {
    //   title: 'Acreage',
    //   dataIndex: 'acreage',
    //   key: 'acreage',
    //   width: '20%',
    // },
  ];

  const loadData = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : initDealerCustomerDetails();
    } else if (activity.current === 'paginate' || activity.current === '') {
      initDealerCustomerDetails();
    }
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  return (
    <Row gutter={[20, 20]} style={{ marginBottom: '20px' }}>
      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 24, offset: 0 }}
        md={{ span: 24, offset: 0 }}
        lg={{ span: 24, offset: 0 }}
        xl={{ span: 24, offset: 0 }}
      >
        <div className="ms_dashboardCard">
          <div className="ms_customerUsage">
            <div className="ms_blockHead mh32">Customer Usage</div>
            <div className=" filters_card pad0">
              <CustomSelect
                label="Crop Types"
                cssClass="cropType"
                value={cropType}
                setValue={setCropType}
                options={cropTypeList}
                optionKey="value"
                optionDisplay="name"
                isAll
              />
            </div>
          </div>
          <Row gutter={[20, 20]}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 10, offset: 0 }}
            >
              <div className="dealer-customer-table">
                {!loading && !filterData?.length ? (
                  <EmptyView image={userNoData} />
                ) : (
                  <InfiniteScrollTable
                    columns={columns}
                    className="customer_usage_tbl"
                    loading={loading}
                    hasMore={hasMore}
                    handleLoadMore={handleLoadMore}
                    filterData={filterData}
                    totalcount={total}
                    filename="customers"
                  />
                )}
              </div>
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 14, offset: 0 }}
            >
              <DealerOperationalHoursCard cropType={cropType} />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default DealerCustomerUsage;
