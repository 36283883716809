import { Button, Modal, notification, Spin } from 'antd';
import 'antd/dist/antd.css';
import Layout from 'antd/lib/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import baseStationImg from '../../assets/images/base_station_new.svg';
import waiIcon from '../../assets/images/wai_logo.svg';
import closeGreen from '../../assets/images/X_icon-bs.svg';
import { UpdateBasestation } from '../../constants/Api';
import { getDateTimes, getTractorStatusClass } from '../../constants/Common';
import { privilagesConstants } from '../../constants/Privilages';
import { ApplicationContext } from '../../context/AppContext';
import applicationIds from '../../locale/applicationIds.json';
import translate from '../../locale/en_translate.json';
import { track } from '../../util/logger';
import CustomInput from '../common/CustomInput';
import './style.css';

interface Props {
  baseobj: any;
  Close: () => void;
  setRefresh: () => void;
  baseflag: boolean;
  baseStationNames: string[];
}
const Editbasestation: React.FC<Props> = ({
  baseobj,
  Close,
  setRefresh,
  baseflag,
  baseStationNames,
}: Props) => {
  const [editflag, seteditflag] = useState<boolean>(false);
  const { t } = useTranslation();
  const [Name, setName] = useState('');
  const { userDetails, privilegeChecker } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [showConfirmPop, setShowConfirmPop] = useState(false);

  useEffect(() => {
    if (baseobj) {
      setName(baseobj.name);
      seteditflag(false);
    }
  }, [baseobj]);

  const Editdetails = () => {
    seteditflag(true);
    track('Edit BaseStation', {
      event: 'Edit BaseStation Details Clicked',
    });
  };

  const checkDuplicate = () =>
    baseStationNames.filter((item) => item !== baseobj.name).includes(Name);

  const saveBasestation = async () => {
    const data = {
      name: Name,
    };
    if (checkDuplicate())
      return notification.error({
        message: 'basestaion name already exists!',
      });
    try {
      setLoader(true);
      await UpdateBasestation(
        userDetails.organization.api_url,
        userDetails.organization_id,
        baseobj?.basestation_id,
        data,
      );
      notification.success({ message: t(translate.label.baseStationUpdated) });
      seteditflag(false);
      setRefresh();
      track('Edit BaseStation', {
        event: 'BaseStation Edited Successfully',
      });
    } catch (err: any) {
      track('Edit BaseStation', {
        event: 'Edit BaseStation failed',
      });
      notification.error({
        message: err.message,
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <Layout>
      <div className={'rightSideWidegt ' + (baseflag ? 'expand' : 'hide')}>
        {!editflag && (
          <>
            <div className="closeIconGray">
              <img
                id={applicationIds.baseStationScreen.baseStationCloseBtn}
                src={closeGreen}
                alt="close icon"
                onClick={Close}
                data-testid="baseStationModelCloseIcon-Editbasestation"
              />
            </div>
            <div className="dpPulloutScroll">
              <div className="baseStationDtlHead">
                <div className="bsCard">
                  <div className="bsIconSec">
                    <img src={baseStationImg} alt="" />
                  </div>
                  <div className="bsNameStatus">
                    <span className="bsNameHead">{baseobj?.name}</span>
                    <span
                      className={`bsStatus  ${getTractorStatusClass(
                        baseobj.status,
                      )}`}
                    >
                      {baseobj.status}
                    </span>
                  </div>
                </div>
              </div>
              <div className="bsContent mb32">
                <div className="bsRow">
                  <span className="bslabel">
                    {t(translate.baseStation.assignedSite)}
                  </span>
                  <span className="bsValue">
                    {baseobj?.site_details?.farmsite_name ?? 'Unassigned'}
                  </span>
                </div>
                <div className="bsRow">
                  <span className="bslabel">
                    {t(translate.baseStation.pin)}
                  </span>
                  <span className="bsValue">{baseobj?.bsin}</span>
                </div>
              </div>
              <div className="bsContent2 mb32">
                <div className="bsRow2">
                  <span className="bslabelHead">Usage</span>
                  <span className="bsValue"></span>
                </div>
                <div className="bsRow2">
                  <span className="bslabel">
                    {t(translate.editBasestation.downTime)}
                  </span>
                  <span className="bsValue">
                    {baseobj?.basestation_down_time
                      ? getDateTimes(baseobj?.basestation_down_time)
                      : '-'}
                  </span>
                </div>
                <div className="bsRow2">
                  <span className="bslabel">
                    {t(translate.editBasestation.lastConnected)}
                  </span>
                  <span className="bsValue">
                    {baseobj?.last_basestation_connected_datetime
                      ? getDateTimes(
                          baseobj?.last_basestation_connected_datetime,
                        )
                      : '-'}
                  </span>
                </div>
              </div>
              <div className="bsContent2">
                <div className="bsRow2">
                  <span className="bslabelHead">Versions</span>
                  <span className="bsValue"></span>
                </div>
                <div className="bsRow2">
                  <span className="bslabel">Software</span>
                  <span className="bsValue">
                    {baseobj?.current_software?.software_version}
                  </span>
                </div>
              </div>
            </div>
            <div className="bsButtonSec">
              {privilegeChecker(
                privilagesConstants.Edit_base_station_information,
              ) && (
                <button
                  className="bsButton bsBtnGreen"
                  id={applicationIds.baseStationScreen.editDetails}
                  onClick={Editdetails}
                  data-testid="baseStationEditDetailsLink-Editbasestation"
                >
                  {t(translate.buttons.editNAme)}
                </button>
              )}
            </div>
          </>
        )}
        {editflag && (
          <div>
            {/* <div className="height40"></div> */}
            <div className="closeIconGray">
              <img
                id={applicationIds.baseStationScreen.baseStationCloseBtn}
                src={closeGreen}
                alt="close icon"
                onClick={Close}
                data-testid="baseStationModelCloseIcon-Editbasestation"
              />
            </div>
            <div className="baseStationDtlHead">
              <div className="bsCard">
                <div className="bsIconSec">
                  <img src={baseStationImg} alt="" />
                </div>
                <div className="bsNameStatus">
                  <span
                    className={`bsStatus  ${getTractorStatusClass(
                      baseobj.status,
                    )}`}
                  >
                    {baseobj.status}
                  </span>
                </div>
              </div>
            </div>
            <div className="bsContent mb32">
              <div className="bsRow2">
                <span className="bslabelHead mb10">Edit Base Station Name</span>
              </div>
              <div className="bsRow2">
                <span className="bslabelFull">
                  This change will be reflected on all accounts across the
                  organization.
                </span>
              </div>
            </div>
            <div className="bsContent mb32 widget_card">
              <CustomInput
                label="Base Station Name"
                value={Name}
                required={true}
                setValue={(value) => setName(value)}
                testId="basestationEditInputField-Editbasestation"
              />
            </div>
            <div className="bsButtonSec">
              <Button
                className="bsButton bsBtnGreen"
                id={applicationIds.baseStationScreen.saveBaseStation}
                onClick={() => setShowConfirmPop(true)}
                disabled={baseobj.name === Name || Name.length == 0}
                data-testid="baseStationSaveButton-Editbasestation"
              >
                {t(translate.buttons.saveChanges)}
              </Button>
            </div>
            <div className="edit-loader">
              {loader && (
                <div className="loader">
                  <Spin size="large" />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {showConfirmPop && (
        <Modal
          visible={showConfirmPop}
          closable={false}
          footer={false}
          className="edit_operator edit_fleet"
        >
          <div className="popup_head">
            <img src={waiIcon} />
            <span className="head_text">Save Changes</span>
          </div>
          <p className="popup_desc_text">
            Are you sure you want to exit? Your changes will not be saved.
          </p>
          <div className="button_sec">
            <Button
              key="edit"
              className="normal_btn"
              onClick={() => setShowConfirmPop(false)}
            >
              Exit Without Saving
            </Button>
            <Button
              key="cancel"
              className="green_btn edit_fleet_save"
              onClick={() => {
                setShowConfirmPop(false);
                saveBasestation();
              }}
            >
              Save
            </Button>
          </div>
        </Modal>
      )}
    </Layout>
  );
};
export default Editbasestation;
