/* eslint-disable no-console */
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  authDomain: 'monarch-8f138.firebaseapp.com',
  projectId: 'monarch-8f138',
  storageBucket: 'monarch-8f138.appspot.com',
  messagingSenderId: '876814212079',
  appId: '1:876814212079:web:b02bf7f4480d16c124341a',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const getFCMToken = async () => {
  const messaging = getMessaging(app);
  const token = await getToken(messaging, {
    vapidKey:
      'BHjLOPqvr0I7802-bddE4INNiYNDD-qkN2rLKnc3s1MmnWWv6RhdF6FMfuu09UKm5aBP5qwouWNDIKK6X0zYFaA',
  });
  return token;
};

export const onMessageListener = () => {
  try {
    const messaging = getMessaging(app);
    return new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });
  } catch (error) {
    // console.error(error);
    return new Promise((resolve) => {
      resolve(undefined);
    });
  }
};
