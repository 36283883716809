import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Layout } from 'antd';

import Routes from '../../routes/Routes';
import AppSideMenu from './AppSideMenu';
import { MenuContext } from '../../context/MenuContext';
import SessionTimer from './SessionTimer';

import 'antd/dist/antd.css';
import './style.css';
import AdminSideMenu from '../admin/layout/AdminSideMenu';
import { adminRoutes } from '../../constants/constant';
import DealerSideMenu from '../dealer/layout/DealerSideMenu';
import { ApplicationContext } from '../../context/AppContext';
import dealerStore from '../store/dealer';

const { Content, Sider } = Layout;

const AppLayout: React.FC = () => {
  const { userDetails } = useContext(ApplicationContext);
  const { dealerData } = dealerStore();
  const [collapsed, setCollapsed] = useState(false);
  const [resetSidebar, setResetSidebar] = useState(false);
  const { push } = useHistory();
  const { pathname } = useLocation();

  return (
    <MenuContext.Provider value={{ collapsed }}>
      <Layout style={{ minHeight: '100vh' }}>
        {!window.location.pathname.includes('remote_access_grant') && (
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            onCollapse={setCollapsed}
            style={{
              background: '#2F333B',
              color: '#fff',
            }}
            className="sideMenuSec"
            width="100"
          >
            {adminRoutes.includes(pathname) ? (
              <AdminSideMenu
                onLinkClick={(pathname) => {
                  push({
                    pathname,
                    state: {
                      reset: !resetSidebar,
                    },
                  });
                  setResetSidebar(!resetSidebar);
                }}
              />
            ) : (
              <>
                {dealerData ? (
                  <DealerSideMenu
                    onLinkClick={(pathname) => {
                      push({
                        pathname,
                        state: {
                          reset: !resetSidebar,
                        },
                      });
                      setResetSidebar(!resetSidebar);
                    }}
                  />
                ) : (
                  userDetails?.organization && (
                    <AppSideMenu
                      onLinkClick={(pathname) => {
                        push({
                          pathname,
                          state: {
                            reset: !resetSidebar,
                          },
                        });
                        setResetSidebar(!resetSidebar);
                      }}
                    />
                  )
                )}
              </>
            )}
          </Sider>
        )}
        <Layout className="ad-site-layout ad_posrel">
          <Content>
            <Routes />
          </Content>
        </Layout>
      </Layout>
      <SessionTimer />
    </MenuContext.Provider>
  );
};

export default AppLayout;
