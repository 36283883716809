/* eslint-disable no-console */
import { Button, Col, Row } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import cameraIcon from '../../assets/images/cameraIcon.svg';
import RoutesConstants from '../../routes/RoutesConstant';
import Sites from './Sites';
import useFarmProileStore from './useFarmProfileStore';

interface FarmSummaryItemProps {
  title?: string | number;
  description: string;
}

export const FarmSummaryItem: React.FC<FarmSummaryItemProps> = ({
  title,
  description,
}) => (
  <div className="ms_org_dtl_col">
    <span className="ms_org_type">{title}</span>
    <span className="ms_org_value">{description}</span>
  </div>
);
const ProfileDetails: React.FC = () => {
  const { push } = useHistory();
  const { setShowEditFarmModal, farmDetails, isInEssentailsPackage } =
    useFarmProileStore();

  return (
    <>
      <div className="ms_button_sec mb55">
        {/* <Button className="ms_btn">
          <span>MOS Features</span>
        </Button> */}
        {/* dont display the open map when essentials */}
        {!isInEssentailsPackage && (
          <Button
            className="ms_btn"
            onClick={() => push(RoutesConstants.MapMakerBeta)}
          >
            <span className="openMapIcon"></span>
            <span>Open Map</span>
          </Button>
        )}
        <Button
          onClick={() => setShowEditFarmModal(true)}
          className="ms_btn ms_btn_sel"
        >
          <span className="editOrgIcon"></span>
          <span>Edit Org</span>
        </Button>
      </div>

      <div className="ms_org_section">
        <div className="ms_org_head_dtls_sec splPad50">
          <Row gutter={[20, 20]}>
            <Col lg={{ span: 22, offset: 2 }} xl={{ span: 22, offset: 2 }}>
              <div className="ms_org_head mb48">
                <div className="ms_org_logo">
                  <img
                    src={farmDetails?.image ? farmDetails?.image : cameraIcon}
                    className="camIcon"
                  />
                </div>
                <div className="ms_org_title">{farmDetails?.name}</div>
              </div>
              <div className="ms_org_dtls">
                <FarmSummaryItem
                  title={farmDetails?.farmType || '-'}
                  description="Organization Type"
                />
                <FarmSummaryItem
                  title={farmDetails?.titleSites || '-'}
                  description="Total Sites"
                />
                <FarmSummaryItem
                  title={`${
                    Number(farmDetails?.dieselCost) > 0
                      ? `$${Number(farmDetails?.dieselCost).toFixed(2)}`
                      : '-'
                  } `}
                  description="Diesel Cost"
                />
                <FarmSummaryItem
                  title={`${
                    Number(farmDetails?.electricityRate) > 0
                      ? `$${Number(farmDetails?.electricityRate)?.toFixed(
                          2,
                        )}/kWH`
                      : '-'
                  }  `}
                  description="Electricity Rate"
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <Sites />
      </div>
    </>
  );
};

export default ProfileDetails;
