import React from 'react';
import moment from 'moment';
import './styles.scss';
import deliveryScheduledIcon from '../../assets/images/service_status_icons/delivery_scheduled.svg';
import repairInProgress from '../../assets/images/service_status_icons/repair_progress.svg';
import pickUpScheduledIcon from '../../assets/images/service_status_icons/pick_up_required.svg';
import scheduleDeliveryIcon from '../../assets/images/service_status_icons/schedule_delivery.svg';
import deliveredIcon from '../../assets/images/service_status_icons/view_service.svg';

export const TractorStatus: any = {
  delivered: 'View Service Summary',
  delivery_pending: 'Delivery Scheduled',
  schedule_delivery: 'Schedule Delivery',
  in_service: 'Reparis In Progress',
  pick_up_pending: 'Pick Up Scheduled',
  schedule_pick_up: 'Pick Up Required',
};

export const TractorStatusIcon: any = {
  delivered: deliveredIcon,
  delivery_pending: deliveryScheduledIcon,
  schedule_delivery: scheduleDeliveryIcon,
  in_service: repairInProgress,
  pick_up_pending: deliveryScheduledIcon,
  schedule_pick_up: pickUpScheduledIcon,
};

interface Props {
  tractor: any;
}

const InProgressBlock: React.FC<Props> = ({ tractor }) => {
  return (
    <>
      <div className="reparisInProgressTitle">
        <div>Repair in Progress</div>
        <div>3-4 Weeks</div>
      </div>
      <div className="barsContainer">
        <div
          className={tractor.is_tractor_at_hq ? 'stepBarActive' : 'stepBar'}
        ></div>
        <div
          className={
            isTractorServiceCompleted(tractor) ? 'stepBarActive' : 'stepBar'
          }
        ></div>
        <div className="stepBar"> </div>
      </div>
    </>
  );
};

const isTractorServiceCompleted = (tractor: any) => {
  return !tractor.schedule_delivery_date_time && tractor.is_service_completed;
};

const tractorInService = (tractor: any) => {
  return (
    tractor.status === 'in_service'
    //   (tractor.status === 'schedule_delivery' &&
    //     isTractorServiceCompleted(tractor))
  );
};

const getTimeByStatus = (tractor: any) => {
  const {
    schedule_delivery_date_time,
    service_completed_date_time,
    schedule_pick_up_time,
    status,
  } = tractor;
  if (tractor.is_tractor_delivered)
    return (
      'Delivered: ' + moment(schedule_delivery_date_time).format('ddd, MMM D')
    );
  if (isTractorServiceCompleted(tractor))
    return (
      'Repairs Completed: ' +
      moment(service_completed_date_time).format('ddd, MMM D')
    );

  if (status == 'pick_up_pending')
    return moment(schedule_pick_up_time).format('ddd, MMM Do [at] h:mm A');
  if (status == 'delivery_pending')
    return moment(schedule_delivery_date_time).format(
      'ddd, MMM Do [at] h:mm A',
    );
};

const StatusBlock: React.FC<Props> = ({ tractor }) => {
  const { status } = tractor;
  return (
    <>
      <div
        className="reparisInProgressTitle"
        style={{ flexDirection: 'column' }}
      >
        <div className="inServiceStatusText">{TractorStatus[status]}</div>
        <div className="inServiceDateTime">{getTimeByStatus(tractor)}</div>
      </div>
    </>
  );
};

const RepairInProgress: React.FC<Props> = ({ tractor }) => {
  const { status } = tractor;
  return (
    <div className="reparisInProgressContainer">
      <div className="leftIconSection">
        <img className="serviceIcon" src={TractorStatusIcon[status]} />
      </div>
      <div className="rightContentSection">
        {tractorInService(tractor) ? (
          <InProgressBlock tractor={tractor} />
        ) : (
          <StatusBlock tractor={tractor} />
        )}
        <div className="reparisInProgressText">
          {tractor.is_tractor_delivered
            ? 'Review work order on mobile app'
            : 'Track service progress on mobile app'}
        </div>
      </div>
    </div>
  );
};

export default RepairInProgress;
