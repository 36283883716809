import React, { useEffect, useState } from 'react';
import SemiPieChart from '../../charts/SemiPieChart';
import { errorLevelColorCodes } from '../../operationalAnalytics/constants';
import {
  useDealerFleetHelath,
  useDealerTractors,
} from './hooks/useDealerDashboard';
import CustomSelect from '../../common/CustomSelect';

import FlagGrayIcon from '../../../assets/images/flag_gray.svg';
import FlagOrangeIcon from '../../../assets/images/flag_orange.svg';
import FlagRedIcon from '../../../assets/images/flag_red.svg';

const ErrorItem: React.FC<{ level: number; value: number }> = ({
  level,
  value,
}) => {
  return (
    <div className="fh_error_item">
      <div className="fh_error_item">
        <span>Level 1</span>
        <span className="fh_error_flags">
          <img src={[1, 2].includes(level) ? FlagOrangeIcon : FlagRedIcon} />
          <img
            src={
              level === 1
                ? FlagGrayIcon
                : level === 2
                ? FlagOrangeIcon
                : FlagRedIcon
            }
          />
          <img src={[1, 2].includes(level) ? FlagGrayIcon : FlagRedIcon} />
        </span>
      </div>
      <span className="fh_f10">{value}</span>
    </div>
  );
};

const DealerFleetHealth: React.FC = () => {
  const [tractors] = useDealerTractors();
  const { fleetHealthData, loading, tractorId, setTractorId } =
    useDealerFleetHelath();
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (tractors.length > 0) {
      setTractorId(tractors[0].value);
    }
  }, [tractors]);

  useEffect(() => {
    setData([
      {
        category: 'Level1',
        value: fleetHealthData.level1,
      },
      {
        category: 'Level2',
        value: fleetHealthData.level2,
      },
      {
        category: 'Level2',
        value: fleetHealthData.level3,
      },
    ]);
  }, [fleetHealthData]);

  return (
    <div className="ms_dashboardCard fh_min_height">
      <div className="ms_customerUsage mb16 mh32">
        <div className="ms_blockHead">Your Fleet Health</div>
        <div className=" filters_card pad0">
          <CustomSelect
            label="Tractors"
            cssClass="cropType"
            value={tractorId}
            setValue={setTractorId}
            options={tractors}
            optionKey="value"
            optionDisplay="name"
          />
        </div>
      </div>
      <div className="fh_active_errors_sec">
        <div>
          <p className="fh_error_code_title">Active Errors</p>
          <p className="fh_error_code_count">{fleetHealthData.total}</p>
          <div className="fh_error_codes">
            <ErrorItem level={1} value={fleetHealthData.level1} />
            <ErrorItem level={2} value={fleetHealthData.level2} />
            <ErrorItem level={3} value={fleetHealthData.level3} />
          </div>
        </div>
        <div className="fh_chart_sec">
          <SemiPieChart
            loader={loading}
            chartId="OperationalHour"
            data={data}
            barColors={[
              errorLevelColorCodes?.level1,
              errorLevelColorCodes?.level2,
              errorLevelColorCodes?.level3,
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default DealerFleetHealth;
