/* eslint-disable no-console */
import { Col, notification, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import msArrowRight from '../../assets/images/ms_arrow_right.svg';
import employeeIcon from '../../assets/images/ms_employees_icon.svg';
import flletIcon from '../../assets/images/ms_fleet_icon.svg';
import implementIcon from '../../assets/images/ms_implement_icon.svg';
import implementDisable from '../../assets/images/implement_disable.svg';
import {
  getFleetSummaryData,
  getImplementSummeryData,
  getPersonalSummaryData,
} from '../../constants/Api';
import {
  FleetSummary,
  ImplementSummary,
  PersonalSummary,
} from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import RoutesConstants from '../../routes/RoutesConstant';
import AppLoader from '../common/AppLoader';
import EditProfile from './EditProfile';
import useSite from './hooks/useSite';
import ProfileDetails from './ProfileDetails';
import './styles.scss';
import farmProfileStore from './useFarmProfileStore';

const FarmProfileDashboard: React.FC = () => {
  const { userDetails } = useContext(ApplicationContext);
  const [personalLoader, setPersonalLoader] = useState(false);
  const [implementLoader, setImplementLoader] = useState(false);
  const [fleetLoader, setfleetLoader] = useState(false);
  const [personalDetails, setPersonalDetails] = useState<PersonalSummary>();
  const [fleetSummary, setFleetSummary] = useState<FleetSummary>();
  const [implementSummary, setImplementSummary] = useState<ImplementSummary>();

  const { push } = useHistory();
  const { showEditFarmModal, multiSiteLoader, isInEssentailsPackage } =
    farmProfileStore();
  const { getMultiSiteProfile, getSites } = useSite();
  useEffect(() => {
    userDetails && userDetails.organization && getSites(userDetails);
  }, [userDetails]);
  useEffect(() => {
    userDetails && userDetails.organization && getMultiSiteProfile(userDetails);
  }, [userDetails]);
  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getPersonalSummary();
      getFleetSummary();
      getImaplementSummary();
    }
  }, [userDetails]);
  const getPersonalSummary = async () => {
    setPersonalLoader(true);
    try {
      const data: PersonalSummary = await getPersonalSummaryData(
        userDetails.organization.api_url,
      );
      setPersonalDetails(data);
    } catch (error: any) {
      notification.error({ message: error.message });
    } finally {
      setPersonalLoader(false);
    }
  };
  const getFleetSummary = async () => {
    setfleetLoader(true);
    try {
      const data: FleetSummary = await getFleetSummaryData(
        userDetails.organization.api_url,
      );
      setFleetSummary(data);
    } catch (error: any) {
      notification.error({ message: error.message });
    } finally {
      setfleetLoader(false);
    }
  };
  const getImaplementSummary = async () => {
    setImplementLoader(true);
    try {
      const data: ImplementSummary = await getImplementSummeryData(
        userDetails.organization.api_url,
      );
      setImplementSummary(data);
    } catch (error: any) {
      notification.error({ message: error.message });
    } finally {
      setImplementLoader(false);
    }
  };
  return (
    <>
      <div className="dashboardContainer mtb0">
        <div className="ms_org_profile_container">
          <Row gutter={[20, 20]}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 16, offset: 0 }}
              xl={{ span: 16, offset: 0 }}
            >
              <div className="ms_org_card">
                <div className="ms_top_bg_gd"></div>
                {showEditFarmModal ? <EditProfile /> : <ProfileDetails />}
              </div>
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 8, offset: 0 }}
              xl={{ span: 8, offset: 0 }}
            >
              <div className="ms_right_sec">
                <div className="ms_org_card2 justify-content-sb">
                  <div className="ms_card_head">
                    <div className="ms_card_title">
                      <span>
                        <img src={employeeIcon} alt="personnel" />
                      </span>
                      <span className="ms_card_txt">Personnel</span>
                    </div>
                    <div
                      className="msArrowPointer"
                      onClick={() => push(RoutesConstants.Personnel)}
                    >
                      <img src={msArrowRight} alt="personnel" />
                    </div>
                  </div>
                  <div className="ms_CardCount">
                    {personalDetails?.total_personnel}
                  </div>
                  <div className="ms_card_login_dtl alignItemCenter">
                    <div className="ms_username_email">
                      <span className="ms_username">
                        {personalDetails?.admin_details?.first_name || '-'}{' '}
                        {personalDetails?.admin_details?.last_name || '-'}{' '}
                      </span>
                      <span className="ms_email">
                        {personalDetails?.admin_details?.email}
                      </span>
                    </div>
                    <div className="ms_login_admin">Admin</div>
                  </div>
                </div>

                <div className="ms_org_card2 justify-content-sb">
                  <div className="ms_card_head">
                    <div className="ms_card_title">
                      <span>
                        <img
                          src={
                            isInEssentailsPackage
                              ? implementDisable
                              : implementIcon
                          }
                          alt="personnel"
                        />
                      </span>
                      <span
                        className={`ms_card_txt ${
                          isInEssentailsPackage ? 'UpdateYourFleetDisable' : ''
                        }`}
                      >
                        Implements
                      </span>
                    </div>
                    {!isInEssentailsPackage && (
                      <div
                        className="msArrowPointer"
                        onClick={() => push(RoutesConstants.Implements)}
                      >
                        <img src={msArrowRight} alt="personnel" />
                      </div>
                    )}
                  </div>
                  {isInEssentailsPackage ? (
                    <div className="UpdateYourFleet">
                      Upgrade your fleet to access the Implement Directory
                    </div>
                  ) : (
                    <>
                      <div className="ms_CardCount">
                        {implementSummary?.total_implements || '-'}
                      </div>
                      <div className="ms_card_category_dtl alignItemCenter">
                        <div className="ms_implement_dtl bdrBtm">
                          <span className="square sq_orange"></span>
                          <span className="ms_imp_count">
                            {implementSummary?.registered_for_autonomy || '-'}
                          </span>
                          <span className="ms_imp_category">
                            Registered for Autonomy
                          </span>
                        </div>
                        <div className="ms_implement_dtl">
                          <span className="square sq_red"></span>
                          <span className="ms_imp_count">
                            {implementSummary?.missing_information || '-'}
                          </span>
                          <span className="ms_imp_category">
                            Missing Information
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="ms_org_card2 justify-content-sb">
                  <div className="ms_card_head">
                    <div className="ms_card_title">
                      <span>
                        <img src={flletIcon} alt="personnel" />
                      </span>
                      <span className="ms_card_txt">Fleet</span>
                    </div>
                    <div
                      className="msArrowPointer"
                      onClick={() => push(RoutesConstants.Fleet)}
                    >
                      <img src={msArrowRight} alt="personnel" />
                    </div>
                  </div>
                  <div className="ms_CardCount">
                    {fleetSummary?.total_tractors || '-'}
                  </div>
                  <div className="ms_card_category_dtl2 alignItemCenter">
                    <div className="bdrBtm dFlex justify-start">
                      <div className="ms_implement_dtl w50Per ">
                        <span className="square sq_sgreen"></span>
                        <span className="ms_imp_count">
                          {fleetSummary?.operable || '-'}
                        </span>
                        <span className="ms_imp_category">Operable</span>
                      </div>
                      {/* hide chargig details when essentails package active */}
                      {!isInEssentailsPackage && (
                        <div className="ms_implement_dtl w50Per">
                          <span className="square sq_green"></span>
                          <span className="ms_imp_count">
                            {fleetSummary?.charging || '-'}
                          </span>
                          <span className="ms_imp_category">Charging</span>
                        </div>
                      )}
                    </div>
                    <div className="dFlex">
                      <div className="ms_implement_dtl w50Per ">
                        <span className="square sq_red"></span>
                        <span className="ms_imp_count">
                          {fleetSummary?.error || '-'}
                        </span>
                        <span className="ms_imp_category">Error</span>
                      </div>
                      <div className="ms_implement_dtl w50Per">
                        <span className="square sq_gray"></span>
                        <span className="ms_imp_count">
                          {fleetSummary?.offline || '-'}
                        </span>
                        <span className="ms_imp_category">
                          Offline / In Service
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <AppLoader
          loader={
            personalLoader || fleetLoader || implementLoader || multiSiteLoader
          }
        />
      </div>
    </>
  );
};

export default FarmProfileDashboard;
