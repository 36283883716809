import { Row, Col } from 'antd';
import React, { useState } from 'react';
import { Page, Document } from 'react-pdf';
import './styles.css';

interface Props {
  data: any[];
}

export const Viewer: React.FC<Props> = ({ data }) => {
  const [numPages, setNumPages] = useState(0);
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <Row gutter={[16, 16]}>
      {data &&
        data.map((ele: any, i: number) => (
          <Col xs={12} sm={12} md={24} lg={24} xl={24} key={i}>
            <div className="bigVide_frame pdf">
              <div className="video-container">
                {ele && ele?.media_type === 'video' && (
                  <video
                    controls
                    src={ele?.file_url}
                    className="ifram_video_big"
                  />
                )}
                {ele && ele?.media_type === 'pdf' && (
                  // <Document
                  //   file={ele?.file_url}
                  //   onLoadSuccess={onDocumentLoadSuccess}
                  //   className="pdf-document"
                  // >
                  //   {Array.from(new Array(numPages), (el, index) => (
                  //     <Page
                  //       key={`page_${index + 1}`}
                  //       className="pdf-page"
                  //       pageNumber={index + 1}
                  //       scale={1.5}
                  //     />
                  //   ))}
                  // </Document>
                  <iframe
                    src={`${ele?.file_url}#toolbar=0&zoom=137`}
                    width="100vw"
                    height="1000"
                    title="Iframe Example"
                    frameBorder="0"
                    className="iframeForOperatorsGuide"
                    style={{
                      backgroundColor: 'white',
                      border: 'none',
                      background: 'white',
                      width: '100%',
                    }}
                    allowFullScreen
                  />
                )}
              </div>
            </div>
          </Col>
        ))}
    </Row>
  );
};
