/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-console */
import { notification } from 'antd';
import jwt_decode from 'jwt-decode';
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  getAutoDrivePermissions,
  getGroundZero,
  getOrgCodes,
  getShowAnalyticDashboard,
  getTractorsData,
  getUserProfile,
  updateVideoLog,
} from '../constants/Api';
import { AUTODRIVE, CONVERSE } from '../constants/Common';
import { MosFeature, UserDetails } from '../constants/types';
import { User } from '../lib/user';
import RoutesConstants from '../routes/RoutesConstant';
import AppReducer, { initialState } from './AppReducer';
import {
  SET_GROUND_ZERO,
  SET_SHOW_AUTO_DRIVE,
  SET_SHOW_CONVERSE,
  SET_TRACTORS,
  SET_TRACTORS_LOADER,
} from './actions';

export const ApplicationContext = createContext({
  user: null,
  userDetails: {},
  updateUserDetails: () => {},
  privilages: [],
  privilegeChecker: (code: number) => {},
  fullScreen: false,
  updateFullScreen: (state: boolean) => {},
  openCameraWindow: (
    orgId: number,
    tractorId: number,
    cameraView: string,
    tractorName: string,
  ) => {},
  APPReducer: [],
  getOrgCodesData: () => {},
} as {
  user: User | null;
  userDetails: UserDetails;
  updateUserDetails: () => void;
  privilages: any;
  privilegeChecker: (code: number) => boolean;
  fullScreen: boolean;
  updateFullScreen: (code: boolean) => void;
  openCameraWindow: (
    orgId: number,
    tractorId: number,
    cameraView: string,
    tractorName: string,
  ) => void;
  APPReducer: any;
  getOrgCodesData: () => void;
  getGroundZeroData: () => void;
  analyticDashboardData: any;
  checkShowAnalyticDashboard: () => void;
});

const AppContext: React.FC = ({ children }) => {
  const [userDetails, setUserDetails] = useState({} as UserDetails);
  const [user, setUser] = useState<User | null>(null);
  const location = useLocation();
  const { push } = useHistory();
  const [state, dispatch] = useReducer(AppReducer, initialState);
  const [privilages, setPrivilages] = useState<number[]>([]);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const cameraWindows = useRef<Map<string, any | undefined>>(
    new Map<string, any | undefined>(),
  );
  const [analyticDashboardData, setAnalyticDashboardData] = useState(null);
  const { pathname } = location;

  useEffect(() => {
    userDetails && userDetails.organization && getTractorsDataHandler();
  }, [userDetails]);

  const getTractorsDataHandler = async () => {
    dispatch({
      type: SET_TRACTORS_LOADER,
      payload: true,
    });
    try {
      const { organization } = userDetails;
      const response = await getTractorsData(
        organization.api_url,
        organization.id,
        1,
        1,
      );
      const { _metadata } = response;
      const total_records_count = _metadata.total_records_count;
      const batchSize = 25; // Fetch 25 records at a time
      const totalBatches = Math.ceil(total_records_count / batchSize);
      // Create an array of promises for each batch
      const promises = Array.from({ length: totalBatches }, (_, index) =>
        getTractorsData(
          organization.api_url,
          organization.id,
          index + 1,
          batchSize,
        ),
      );
      const results = await Promise.all(promises);
      const records = results.flatMap((item) => item.records);
      // Flatten the array and update state
      dispatch({
        type: SET_TRACTORS,
        payload: records,
      });
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      dispatch({
        type: SET_TRACTORS_LOADER,
        payload: false,
      });
    }
  };

  const checkShowAnalyticDashboard = async () => {
    if (!user) return;
    const {
      id,
      organization_id,
      organization: { api_url },
    } = user;
    try {
      const [result] = await getShowAnalyticDashboard(
        api_url,
        organization_id,
        id,
      );
      setAnalyticDashboardData(result);
    } catch (error: any) {
      notification.error(error.message);
    }
  };

  useEffect(() => {
    getGroundZeroData();
    checkShowAnalyticDashboard();
  }, [user]);

  const getGroundZeroData = async () => {
    try {
      if (!user) return;
      const { organization } = user;
      const resp = await getGroundZero(
        organization.api_url,
        organization.farm.id,
      );
      dispatch({
        type: SET_GROUND_ZERO,
        payload: resp,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const updateFullScreen = (state: boolean) => {
    setFullScreen(state);
  };

  const updateUser = (user: any) => {
    const usert: any = user;
    const tuser = new User(usert);
    setUser(tuser);
  };

  const updateUserDetails = async () => {
    try {
      if (localStorage.getItem('auth')) {
        const auth = localStorage.getItem('auth');
        const user: UserDetails = jwt_decode(auth || '');
        const data = await getUserProfile(user.organization.api_url, user.id);
        user.profile_url = data.profile_url;
        user.roles = data.roles;
        user.is_active = data.is_active;
        user.is_org_admin = data.is_org_admin;
        user.first_name = data.first_name;
        user.last_name = data.last_name;
        user.is_support_user = data.is_support_user;
        user.ops_code = data.ops_code;
        user.organization = { ...user.organization, ...data.organization };
        localStorage.setItem('Avatar', data?.profile_url);
        setUserDetails(user);
        updateUser(user);
      }
    } catch (error: any) {
      push(RoutesConstants.Login);
      console.error(error);
    }
  };

  const getOrgCodesData = async () => {
    try {
      if (!user) return;
      const { organization } = user;
      const { privileges }: { privileges: number[] } = await getOrgCodes(
        organization.api_url,
        organization.id,
        user.id,
      );
      const data = privileges && privileges.length > 0 ? privileges : [];
      setPrivilages(data);
    } catch (error) {
      console.error(error);
    }
  };

  const privilegeChecker = (code: number) => privilages.includes(code);

  const skipResetPassword = () =>
    ![RoutesConstants.ForgotPassword, RoutesConstants.Login].includes(pathname);

  useEffect(() => {
    notification.config({
      duration: 4,
    });
    if (skipResetPassword()) updateUserDetails();
  }, []);

  const loadInitials = () => {
    getOrgCodesData();
  };

  useEffect(() => {
    if (user) loadInitials();
    // if (
    //   user?.organization &&
    //   !pathname.includes(RoutesConstants.CameraView) &&
    //   !pathname.includes(RoutesConstants.AllCameraView) &&
    //   isMonarchDigital(user?.organization)
    //   // isDealer(user?.organization)
    // ) {
    //   !adminRoutes.includes(pathname) && push(RoutesConstants.Admin);
    // } else {
    //   if (user?.organization)
    //     adminRoutes.includes(pathname) && push(RoutesConstants.Attention);
    // }
  }, [user]);

  useEffect(() => {
    if (state.navigate) {
      const { pathname, data, meta } = state.navigate;
      push({
        pathname,
        state: {
          action: meta.action,
          data: data,
        },
      });
    }
  }, [state.navigate]);

  const openCameraWindow = (
    orgId: number,
    tractorId: number,
    cameraView: string,
    tractorName: string,
  ) => {
    const url = window.location.origin;
    const view = window.open(
      `${url}${RoutesConstants.CameraView}/${tractorId}/stream/${tractorName}/${cameraView}/true?orgId=${orgId}`,
      `${tractorId}${cameraView}`,
      'width=850px, height=480px,min-width: 450px,max-width: 100%,left=600,top=300',
    );
    cameraWindows.current.set(cameraView, view);

    view?.addEventListener('unload', () => {
      if (view.document.URL !== 'about:blank') {
        const successId = localStorage.getItem(view.location.href);
        if (successId) {
          const url = view.location.href;
          const origin = view.location.origin;
          const popUrl = url.replace(RoutesConstants.CameraView + '/', '');
          const srcString = popUrl.replace(origin, '');
          const viewData = srcString;
          const tractorId = viewData.split('/')[0];
          const { organization } = userDetails;
          updateVideoLog(organization.api_url, organization.id, tractorId, {
            remarks: 'Just stopped',
            id: successId,
          }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error('error occured ', error);
          });
        }
      }
    });
  };

  useEffect(() => {
    user && user.organization && getAutoDrivePermissionsHandler();
  }, [user]);

  const getAutoDrivePermissionsHandler = async () => {
    try {
      if (!user) return;
      const { organization } = user;
      const resp: MosFeature[] = await getAutoDrivePermissions(
        organization.api_url,
        organization.id,
      );
      const [permission] =
        resp &&
        resp.filter(
          (permission: MosFeature) => permission.feature_code === AUTODRIVE,
        );
      const status = permission && permission.is_subscribed ? true : false;
      dispatch({
        type: SET_SHOW_AUTO_DRIVE,
        payload: status,
      });

      const [permission1] =
        resp &&
        resp.filter(
          (permission: MosFeature) => permission.feature_code === CONVERSE,
        );
      const status1 = permission1 && permission1.is_subscribed ? true : false;
      dispatch({
        type: SET_SHOW_CONVERSE,
        payload: status1,
      });
    } catch (err: any) {
      notification.error({
        message: err.message,
      });
    }
  };

  return (
    <ApplicationContext.Provider
      value={{
        user,
        userDetails,
        updateUserDetails,
        privilages,
        privilegeChecker,
        fullScreen,
        updateFullScreen,
        openCameraWindow,
        APPReducer: [state, dispatch],
        getOrgCodesData,
        getGroundZeroData,
        analyticDashboardData,
        checkShowAnalyticDashboard,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export default AppContext;

export const useApplicationContext = () => useContext(ApplicationContext);
