import React from 'react';
import TeamsAppContext from '../../context/TeamContext';
import Employees from '../organization/Employees';
import MSHeader from '../layouts/MSHeader';
import './styles.scss';
import RoutesConstants from '../../routes/RoutesConstant';
import { useHistory } from 'react-router-dom';

const Personnel: React.FC = () => {
  const { push } = useHistory();
  return (
    <TeamsAppContext>
      <div className="dashboardContainer comCont">
        <MSHeader
          title="Personal Directory"
          handleBack={() => push(RoutesConstants.FarmProfile)}
        />
        <Employees />
      </div>
    </TeamsAppContext>
  );
};

export default Personnel;
