import {
  notification,
  Spin,
  Upload,
  Avatar,
  Image,
  Modal,
  Table,
  Button,
  Radio,
} from 'antd';

import Layout from 'antd/lib/layout/layout';
import { track } from '../../util/logger';
import React, { useContext, useEffect, useRef, useState } from 'react';
import 'antd/dist/antd.css';
import './TicketsStyle.css';
import closeGreen from '../../assets/images/right_widget_close_icon.svg';
import FlagGrayIcon from '../../assets/images/flag_gray.svg';
import FlagOrangeIcon from '../../assets/images/flag_orange.svg';
import FlagRedIcon from '../../assets/images/flag_red.svg';
import addCBtn from '../../assets/images/addCBtn.svg';
import {
  getOrgTags,
  getOrgEquipments,
  updateTicket,
  addComment,
  getTasksearch,
  getTicketById,
  getCommentsTickets,
  DeleteAttachmentFile,
  GetAWSUploadDetails,
  uploadtoS3,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import errorCodeIcon from '../../assets/images/errorCodeIcon.svg';

import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CloseOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Taskview, Taskviewdetails } from '../../constants/types';
import { Tag } from 'antd';
import { getDateTimes } from '../../constants/Common';
import applicationIds from '../../locale/applicationIds.json';
import Interweave from 'interweave';
import { privilagesConstants } from '../../constants/Privilages';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import ticketFwd from '../../assets/images/ticketFwd.gif';
import ticketSucess from '../../assets/images/ticket_success.svg';
import Assigned_to from '../../assets/images/assigned_to.png';
import { RcFile } from 'antd/lib/upload';
import { speCharRegX } from '../../constants/constant';
import AppLoader from '../common/AppLoader';
import CustomSeverityLevel from '../common/CustomSeverityLevel';
import CustomSelect from '../common/CustomSelect';
import CustomTextArea from '../common/CustomTextArea';
import ForwardTicketModal from './ForwardTicketModal';
import ResolveTicketModal from './ResolveTicketModal';
import CustomDate from '../common/CustomDate';
import moment from 'moment';
import { useErrorCodes } from '../../hooks/useErrorCodes';

const { CheckableTag } = Tag;

interface Props {
  ticketObj?: any;
  Close: (action?: string) => void;
  toggleWidget: boolean;
}
const TicketViewEdit: React.FC<Props> = ({
  ticketObj,
  Close,
  toggleWidget,
}: Props) => {
  const { privilegeChecker } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [editflag, seteditflag] = useState<boolean>(false);
  const [editflagBody, seteditflagBody] = useState<boolean>(false);
  const [description, setDescription] = useState<any>();
  const { userDetails } = useContext(ApplicationContext);
  const errorCodes = useErrorCodes();
  const [loader, setLoader] = useState<boolean>(false);
  const [tagsList, setTagsList] = useState<any[]>([]);
  const [equipmentsList, setEquipmentsList] = useState<any[]>([]);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [selectedEquipment, setSelectedEquipment] = useState<any>();
  const [commentflag, setComment] = useState<boolean>(false);
  const [commentData, setCommentData] = useState<any>();
  const [result, setResult] = useState<Taskviewdetails[]>([]);
  const [assignid, setAssignid] = useState<any>('');
  const [TickObj, setticketObj] = useState<any>();
  const [incidentTime, setIncidentTime] = useState<any>();
  const [CommentsData, setCommentsData] = useState<any>([]);
  const [FleetData, setFleetData] = useState<any>([]);
  const [stateVarible, setStateVarible] = useState<any>(1);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [toggleSwitch, setToggleSwitch] = useState<boolean>();
  const [forwardTicketLoader, setForwardTicketLoader] =
    useState<boolean>(false);
  const [ticketLoader, setTicketLoader] = useState<boolean>(false);
  const [ticketObjectLoaing, setTicketObjectLoading] = useState<boolean>(false);
  const [errCode, setErrCodes] = useState<any>();

  const [forwardticketmodal, setForwardTicketModel] = useState(false);
  const [statusmodal, setstatusmodal] = useState(false);
  const [errorcodeFlag, setErrorcodeFlag] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [currentIndex, setCurrentIndex] = useState<any>();
  const [descriptionCount, setDescriptionCount] = useState<number>(0);
  const [uuidnum, setuuid] = useState<any>();
  const [search, setSearch] = useState<string>('');
  const searchString = useRef<string>('');
  const [validations, setValidations] = useState<any>({
    assignid: true,
    selectedEquipment: true,
    enabled: false,
  });

  const saveUpdatedTicket = () => {
    setValidations({
      ...validations,
      assignid: assignid ? true : false,
      selectedEquipment: selectedEquipment ? true : false,
      enabled: true,
    });
  };

  useEffect(() => {
    const isValid = Object.values(validations).every((item) => item);
    if (isValid && Object.keys(validations).length) {
      updateTicketDetails();
    }
  }, [validations]);

  const onChange = (info: any) => {
    const { file, fileList } = info;
    if (fileList.length && file.status === 'uploading' && file.percent === 0) {
      fileList[fileList.length - 1].status = 'done';
      SaveAttach(file);
      track('TicketViewEdit', {
        event: 'Added Ticket Attachment',
      });
    } else if (file?.id) {
      DeleteAttachment(file);
    }
  };

  const [stauslable, SetStatusLable] = useState<any>();
  const [descriptionResolve, setDescriptionResolve] = useState<any>();

  const getComments = async () => {
    setCommentsData([]);
    const datakey: any = 'COMMENTS';
    const result: any = await getCommentsTickets(
      userDetails.organization.api_url,
      ticketObj?.fleet_id,
      ticketObj?.id,
      datakey,
    );
    setCommentsData(result);
  };

  const getbyticketbyid = async () => {
    try {
      setTicketObjectLoading(true);
      const result: any = await getTicketById(
        userDetails.organization.api_url,
        ticketObj?.fleet_id,
        ticketObj?.id,
      );
      if (result.error_codes) {
        const commaData = result.error_codes.includes(',');
        const pipeData = result.error_codes.includes('|');
        if (commaData) {
          const error_codes = result.error_codes.split(',');
          const errordata = errorCodes.filter((x: any) =>
            error_codes.includes(x.indicator_error_code.toString()),
          );
          setErrCodes(errordata);
        } else if (pipeData) {
          const error_codes = result.error_codes.split('|');
          const errordata = errorCodes.filter((x: any) =>
            error_codes.includes(x.indicator_error_code.toString()),
          );
          setErrCodes(errordata);
        }
      }

      const dataarray: any[] = [];
      result?.Attachments?.map((ele: any, index: any) => {
        const obj = {
          uid: index.toString(),
          name: '',
          status: 'done',
          url: ele.image_url ? ele.image_url : '',
          id: ele.id,
          fleet_maintenance_log_id: ele?.fleet_maintenance_log_id,
          fleet_id: result?.fleet_id,
          index: index,
        };
        dataarray.push(obj);
      });
      setFleetData(dataarray);
      if (result?.status === 'open' || result?.status === 'inprogress') {
        SetStatusLable('Unresolved');
      } else {
        SetStatusLable('Resolved');
      }
      if (result.assignee) {
        const { first_name, last_name } = result.assignee;
        result.assignee = `${first_name} ${last_name}`;
      }
      if (result.created_by_user) {
        const { first_name, last_name } = result.created_by_user;
        result.created_by_user = `${first_name} ${last_name}`;
      }
      if (result.monarch_service_user) {
        const { first_name, last_name } = result.monarch_service_user;
        result.monarch_service_user = `${first_name} ${last_name}`;
      }
      const fleetTags: any = [];
      result?.Tags?.map((tag: any) => {
        fleetTags.push(tag?.data_value);
      });
      result.tags = fleetTags;
      setticketObj(result);
      setTicketObjectLoading(false);
      seteditflag(editflag ?? false);
      // setForwardTicketModel(false);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const wrapAssinged = async (search: string) => {
    const data: Taskviewdetails = await getTasksearch(
      userDetails.organization.api_url,
      search.length > 2 ? search : '',
    );
    return {
      searchKey: search,
      data,
    };
  };

  const getassigned = async () => {
    const { data, searchKey }: any = await wrapAssinged(search);
    data.records.map((ele: Taskview) => {
      ele.fullname = ele.first_name + ' ' + ele.last_name;
    });
    const { records } = data;
    if (searchKey === searchString.current) setResult(records);
  };

  const editTicketDetails = () => {
    if (!TickObj?.equipment_id) {
      setSelectedEquipment('Other');
    } else if (TickObj?.equipment) {
      setSelectedEquipment(TickObj?.equipment?.name);
    }
    if (tagsList && TickObj?.Tags) {
      for (const iterator of tagsList) {
        for (const iterator2 of TickObj.Tags) {
          if (iterator.name === iterator2.data_value) {
            iterator.selected = true;
          }
        }
      }
    }

    setSelectedTags(tagsList);
    setDescription(TickObj?.ticket_description);
    setStateVarible(TickObj?.ticket_level);
    setToggleSwitch(TickObj?.tractor_operational);
    setIncidentTime(
      TickObj?.issue_occurred_date_time
        ? moment(TickObj?.issue_occurred_date_time)
        : moment(),
    );
    seteditflag(true);
    track('EditTicket', {
      event: 'Ticket Edit',
    });
  };

  const loadTags = async () => {
    const resultTags: any = await getOrgTags(
      userDetails.organization.api_url,
      userDetails.organization_id,
    );
    resultTags.map((tag: any) => {
      tag.selected = false;
    });
    setTagsList(resultTags);
  };

  const loadEquipments = async () => {
    let result: any = await getOrgEquipments(
      userDetails.organization.api_url,
      userDetails.organization_id,
    );

    result = result.filter(
      (equipment: any) => equipment.equipment_type !== 'None',
    );
    setSelectedEquipment(result[0].name);
    setuuid(1);
    result = result.map((equip: any, i: any) => {
      if (ticketObj?.equipment?.name === equip.name) {
        setuuid(i + 1);
      }
      const { name } = equip;
      equip.uuid = i + 1;
      equip.name = !speCharRegX?.test(name) ? encodeURIComponent(name) : name;
      return equip;
    });

    setEquipmentsList(result);
  };

  const equipmentSelect = (e: any) => {
    const raw = JSON.parse(e);
    equipmentsList.map((ele: any) => {
      if (ele.uuid === raw.uuid) {
        setuuid(ele.uuid);
        setSelectedEquipment(ele.name);
      }
    });
    track('TicketViewEdit', {
      event: `Select Equipment ${e}`,
    });
  };

  const getSeverityIcon = (level: number, orderNumber: number) => {
    if (level >= 3) {
      return FlagRedIcon;
    }
    if (orderNumber <= level) {
      return FlagOrangeIcon;
    }
    return FlagGrayIcon;
  };

  const handleChange = (tag: any, checked: any) => {
    tag.selected = checked;
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t: any) => t !== tag);
    setSelectedTags(nextSelectedTags);
    if (checked) {
      track('TicketViewEdit', {
        event: 'Select Tags',
      });
    } else {
      track('TicketViewEdit', {
        event: 'Remove Tags',
      });
    }
  };

  const addcomment = () => {
    setCommentData('');
    setComment(true);
    track('AddedTicketComment', {
      event: 'Ticket Added Comment',
    });
  };

  const onSelect = (e: any) => {
    setAssignid(e);
    track('TicketViewEdit', {
      event: `Select Assign ${e}`,
    });
  };

  const Closebtn = () => {
    Close();
    track('CloseTicket', {
      event: 'Ticket Close',
    });
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const onPreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
    );
    setCurrentIndex(file.index);
  };

  const beforeUpload = (file: any) => {
    const imgSize = file.size / 1024 / 1024 < 8;
    const fileTypeAccepted =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg';
    if (!fileTypeAccepted) {
      notification.error({
        message: `${file.type} is not accepted`,
      });
      return Upload.LIST_IGNORE;
    }
    if (!imgSize) {
      notification.error({
        message: 'file size must be smaller than 8MB!',
      });
    }
    return imgSize || Upload.LIST_IGNORE;
  };

  const forwaredTicketModal = () => {
    setForwardTicketModel(true);
  };
  const StatusonChangeModal = () => {
    setstatusmodal(true);
  };

  const forwardCancel = () => {
    setForwardTicketModel(false);
  };
  const resolveCancel = () => {
    setDescriptionResolve('');
    setstatusmodal(false);
  };

  const errorCodesLink = () => {
    setErrorcodeFlag(true);
  };
  const errorcodeCancel = () => {
    setErrorcodeFlag(false);
  };

  const ticketCancel = () => {
    seteditflag(false);
  };

  const leftarrowClick = async () => {
    const leftindex = currentIndex + 1;

    setCurrentIndex(leftindex);
    setPreviewTitle(
      FleetData[leftindex].name ||
        FleetData[leftindex].url!.substring(
          FleetData[leftindex].url!.lastIndexOf('/') + 1,
        ),
    );
    setPreviewImage(FleetData[leftindex].url);
  };

  const rightarrowClick = async () => {
    const rightindex = currentIndex - 1;

    setCurrentIndex(rightindex);
    setPreviewTitle(
      FleetData[rightindex].name ||
        FleetData[rightindex].url!.substring(
          FleetData[rightindex].url!.lastIndexOf('/') + 1,
        ),
    );
    setPreviewImage(FleetData[rightindex].url);
  };

  const handleCancel = () => setPreviewOpen(false);

  const updateTicketDetails = async () => {
    if (description === '') {
      notification.error({
        message: `Ticket description is required and can't be blank.`,
        duration: 2,
      });
      return;
    }

    const obj: any = {};
    const selTags: any[] = [];
    let equipmentType = '';
    let equipment_id: any = 0;

    tagsList.map((tag: any) => {
      if (tag.selected) {
        selTags.push(tag.name);
      }
    });
    equipmentsList.map((equip: any) => {
      if (equip.uuid == uuidnum) {
        equipmentType = equip.equipment_type?.trim();
        equipment_id = equip.equipment_id;
      }
    });
    (obj.ticket_description = description), (obj.ticket_level = stateVarible);
    if (equipment_id !== 'NULL') {
      obj.tractor_id = equipment_id;
    }
    (obj.assignee_id = assignid),
      (obj.tags = selTags),
      (obj.equipment_id = equipment_id),
      (obj.equipment_type = equipmentType),
      (obj.id = TickObj?.id),
      (obj.ticket_id = TickObj?.ticket_id),
      (obj.issue_occurred_date_time = incidentTime.valueOf()),
      (obj.tractor_operational = toggleSwitch),
      (obj.status = stauslable === 'Resolved' ? 'completed' : 'open');

    try {
      setLoader(true);
      await updateTicket(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        ticketObj.id,
        obj,
      );
      track('UpdateTicket', {
        event: 'Ticket Updated Successfully',
      });
      notification.success({
        message: t(translate.label.ticketUpdated),
        duration: 2,
      });
      Close('update');
      // getbyticketbyid();
    } catch (err: any) {
      track('UpdateTicketFailed', {
        event: 'Ticket Update Failed',
      });
      notification.error({
        message: err.message,
        duration: 2,
      });
    } finally {
      setLoader(false);
    }
  };

  const SaveComment = async () => {
    if (commentData && commentData.trim()) {
      const obj = {
        data_key: 'COMMENTS',
        data_text: commentData.trim(),
        data_values: commentData.trim(),
      };
      try {
        setLoader(true);
        await addComment(
          userDetails.organization.api_url,
          TickObj.fleet_id,
          TickObj.id,
          obj,
        );
        notification.success({
          message: t(translate.label.ticketCommentAdded),
          duration: 2,
        });
        setComment(false);
        getComments();
        track('TicketComment', {
          event: 'Ticket Comment Sucessfully',
        });
      } catch (err: any) {
        track('TicketCommentFailed', {
          event: 'Ticket Comment Failed',
        });
        notification.error({
          message: err.message,
          duration: 2,
        });
      } finally {
        setLoader(false);
      }
    } else {
      notification.error({
        message: 'Added Comments',
        duration: 2,
      });
    }
  };

  const DeleteAttachment = async (file: any) => {
    try {
      setLoader(true);
      const payload = {
        data_key: 'ATTACHMENTS',
      };
      await DeleteAttachmentFile(
        userDetails.organization.api_url,
        file?.fleet_id,
        file?.id,
        file?.fleet_maintenance_log_id,
        payload,
      );
      notification.success({
        message: t(translate.label.ticketAttachmentDeleted),
        duration: 2,
      });
      getbyticketbyid();
    } catch (err: any) {
      notification.error({
        message: err.result?.data.error.message,
        duration: 2,
      });
    } finally {
      setLoader(false);
    }
  };

  const SaveAttach = async (file: any) => {
    try {
      setTicketObjectLoading(true);
      setLoader(true);

      const aws = await GetAWSUploadDetails(
        userDetails.organization.api_url,
        TickObj?.fleet_id,
        TickObj?.id,
        { file_name: file.originFileObj.name, media: 'image' },
      );

      const { upload_file_response } = aws;
      const { fields, url } = upload_file_response;

      const form = new FormData();
      form.append('AWSAccessKeyId', fields.AWSAccessKeyId);
      form.append('key', fields.key);
      form.append('policy', fields.policy);
      form.append('signature', fields.signature);
      form.append('x-amz-security-token', fields['x-amz-security-token']);
      form.append('file', file.originFileObj);
      await uploadtoS3(url, form);
      notification.success({
        message: 'Ticket Attachment Added Sucessfully',
        duration: 2,
      });
      track('TicketViewEdit', {
        event: 'Ticket Attachment Added Sucessfully',
      });
      getbyticketbyid();
    } catch (e: any) {
      track('TicketViewEdit', {
        event: 'Ticket Attachment Failed',
      });
    } finally {
      setTicketObjectLoading(false);
      setLoader(false);
    }
  };

  const columns = [
    {
      title: 'Severity',
      dataIndex: 'level',
      key: 'level',
      width: '110px',
      render: (level: any) => {
        return (
          <div className="description">
            <img className="mr7" src={getSeverityIcon(level, 1)} />
            <img className="mr7" src={getSeverityIcon(level, 2)} />
            <img className="mr7" src={getSeverityIcon(level, 3)} />
          </div>
        );
      },
    },
    {
      title: 'Error Code',
      dataIndex: 'indicator_error_code',
      key: 'indicator_error_code',
      width: '120px',
      render: (indicator_error_code: any) => <>{indicator_error_code}</>,
    },
    {
      title: 'Indicator Types',
      width: '200px',
      dataIndex: 'indicator',
      key: 'indicator',
    },
    {
      title: 'Issues',
      width: '160px',
      dataIndex: 'subsystem',
      key: 'subsystem',
      render: (subsystem: string) => {
        return <span className="description">{subsystem}</span>;
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '200px',
      render: (description: string) => {
        return (
          <div className="description">
            <div className="diagnosticsdesc">{description}</div>
          </div>
        );
      },
    },
  ];

  const onSearch = (e: any) => {
    const value = e.trim();
    setSearch(value);
    searchString.current = value;
  };

  useEffect(() => {
    if (search) {
      getassigned();
    }
  }, [search]);

  useEffect(() => {
    seteditflagBody(editflag);
  }, [editflag]);

  useEffect(() => {
    if (
      stateVarible > 0 &&
      description &&
      description.trim().length > 2 &&
      selectedEquipment
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [stateVarible, description, selectedEquipment]);

  useEffect(() => {
    if (description !== undefined) setDescriptionCount(description.length);
  }, [description]);

  useEffect(() => {
    if (descriptionResolve !== undefined)
      setDescriptionCount(descriptionResolve.length);
  }, [descriptionResolve]);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      setAssignid('');
      loadTags();
      loadEquipments();
      getassigned();
    }
  }, [ticketObj]);

  useEffect(() => {
    if (toggleWidget) {
      seteditflag(false);
    }
  }, [toggleWidget]);

  useEffect(() => {
    if (ticketObj?.id) {
      setComment(false);
      getbyticketbyid();
      getComments();
    }
  }, [ticketObj]);

  useEffect(() => {
    if (result.length > 0 && TickObj && assignid === '') {
      const [tresult]: any = result.filter(
        (item: any) => item.id === TickObj?.assignee_id,
      );
      if (!tresult && TickObj) {
        if (TickObj?.assignee_id) setAssignid(TickObj?.assignee_id);
        const temp: any[] = [
          ...result,
          {
            id: TickObj?.assignee_id,
            fullname: TickObj?.assignee,
          },
        ];
        setResult(temp);
      } else {
        setAssignid(tresult.id);
      }
    }
  }, [result, TickObj]);

  return (
    <Layout>
      <div className={'rightSideWidegt ' + (toggleWidget ? 'expand' : 'hide')}>
        <div className="widgetCloseBtn flexBtn">
          {!editflag && (
            <img
              id={applicationIds.ticketScreen.editClose}
              src={closeGreen}
              alt="close icon"
              onClick={Closebtn}
              data-testid="modelCloseIcon-TicketViewEdit"
            />
          )}

          {privilegeChecker(privilagesConstants.Edit_a_fleet_ticket) &&
            TickObj &&
            !loader &&
            !TickObj?.is_service_ticket_raised &&
            !forwardticketmodal &&
            !forwardTicketLoader &&
            !ticketLoader &&
            ((!editflag && ticketObj?.assignee_id === userDetails?.id) ||
              (!editflag && ticketObj?.created_by === userDetails?.id)) && (
              <span
                data-testid="editButton-TicketViewEdit"
                className="editTxtBtn"
                onClick={() => {
                  editTicketDetails();
                }}
              >
                {t(translate.buttons.edit)}
              </span>
            )}
          <span
            data-testid="editButton-TicketViewEdit"
            className="editTxtBtn"
            onClick={() => {
              editTicketDetails();
            }}
          >
            {t(translate.buttons.edit)}
          </span>
        </div>

        <div className="profileEdit widget_card">
          <div className="proRow tcktInfo">
            <h4 className="posRel errorTcktTitle headline3">
              {editflag && <span>{t(translate.buttons.edit)}</span>}
              <span style={{ fontFamily: 'Montserrat-SemiBold' }}>
                {t(translate.tickets.ticket)} #{TickObj?.ticket_id}
              </span>
            </h4>
            <h3 className="overline1">
              {getDateTimes(TickObj?.created_date_time)}
            </h3>
          </div>
          {!editflag && (
            <>
              {!editflagBody && (
                <>
                  <div className="proRowType rowGapBtm3">
                    <h5 className="subFlex alignCenter">
                      <>
                        <span className="headline4 subTitles">
                          {t(translate.headers.severityLevel)}
                        </span>
                        <span className="width50 body2">
                          <img
                            className="mr7"
                            src={getSeverityIcon(TickObj?.ticket_level, 1)}
                          />
                          <img
                            className="mr7"
                            src={getSeverityIcon(TickObj?.ticket_level, 2)}
                          />
                          <img
                            className="mr7"
                            src={getSeverityIcon(TickObj?.ticket_level, 3)}
                          />
                          {TickObj?.ticket_level === 1 && (
                            <>{t(translate.tickets.ticketSeverity.low)}</>
                          )}
                          {TickObj?.ticket_level === 2 && (
                            <>{t(translate.tickets.ticketSeverity.medium)}</>
                          )}
                          {TickObj?.ticket_level === 3 && (
                            <>{t(translate.tickets.ticketSeverity.high)}</>
                          )}
                        </span>
                      </>
                    </h5>
                  </div>
                  <div className="proRowType rowGapBtm3">
                    <h5 className="subFlex alignCenter">
                      <span className="headline4 subTitles">
                        {t(translate.fleetticketsHistory.status)}
                      </span>
                      <span className="width50 tickStatus">
                        {TickObj?.view_ticket_status === 'completed' ? (
                          <Tag
                            className="resolveStyle"
                            style={{ borderRadius: '12px' }}
                          >
                            {t(translate.tickets.status.resolved)}
                          </Tag>
                        ) : (
                          <>
                            {TickObj?.view_ticket_status === 'inprogress' ? (
                              <Tag className="inProgressStyle">
                                {' '}
                                {t(translate.tickets.status.inProgress)}
                              </Tag>
                            ) : (
                              <>
                                {TickObj?.view_ticket_status === 'Forwarded' ? (
                                  <Tag className="forwardStyle">
                                    {TickObj?.view_ticket_status}
                                  </Tag>
                                ) : (
                                  <Tag color="red" className="openStyle">
                                    {TickObj ? 'Open' : ''}
                                  </Tag>
                                )}{' '}
                              </>
                            )}
                          </>
                        )}
                      </span>
                    </h5>
                  </div>
                  {TickObj?.equipment ? (
                    <div className="proRowType rowGapBtm3">
                      <h5 className="subFlex alignCenter">
                        <span className="headline4 subTitles">
                          {t(translate.tickets.equipment)}
                        </span>
                        <span className="width50 body2">
                          <label>{TickObj?.equipment?.name}</label>
                        </span>
                      </h5>
                    </div>
                  ) : (
                    <div className="proRowType rowGapBtm3">
                      <h5 className="subFlex alignCenter">
                        <span className="headline4 subTitles">
                          {' '}
                          {t(translate.tickets.equipment)}
                        </span>
                        <span className="width50 body2">
                          <label>{t(translate.tickets.other)}</label>
                        </span>
                      </h5>
                    </div>
                  )}
                  {
                    <div className="proRowType rowGapBtm3">
                      <h5 className="subFlex alignCenter">
                        <span className="headline4 subTitles">
                          {t(translate.fleetticketsHistory.creator)}
                        </span>
                        <span className="width50 body2 subdesc">
                          {TickObj?.monarch_service_user ? (
                            <label>{TickObj?.monarch_service_user}</label>
                          ) : (
                            <label>{TickObj?.created_by_user}</label>
                          )}
                        </span>
                      </h5>
                    </div>
                  }
                  {TickObj?.assignee && (
                    <div className="proRowType rowGapBtm3">
                      <h5 className="subFlex alignCenter">
                        <span className="headline4 subTitles">
                          {t(translate.fleetticketsHistory.assignto)}
                        </span>
                        <span className="width50 body2 subdesc">
                          {TickObj?.is_service_ticket_raised ? (
                            <label className="status_value status_value_forward">
                              <img src={Assigned_to}></img>
                              {t(translate.tickets.defaultAssignee)}
                            </label>
                          ) : TickObj?.assignee ? (
                            <label>{TickObj?.assignee}</label>
                          ) : (
                            <label></label>
                          )}
                        </span>
                      </h5>
                    </div>
                  )}
                  {TickObj?.issue_occurred_date_time && (
                    <div className="proRowType rowGapBtm3">
                      <h5 className="subFlex alignCenter">
                        <span className="headline4 subTitles">
                          {t(translate.fleetticketsHistory.incidentTime)}
                        </span>
                        <span className="width50 body2 subdesc">
                          {TickObj?.issue_occurred_date_time
                            ? getDateTimes(TickObj?.issue_occurred_date_time)
                            : '-'}
                        </span>
                      </h5>
                    </div>
                  )}
                  {TickObj && stauslable === 'Resolved' && (
                    <>
                      <div className="proRowType">
                        <h5 className="subFlex alignCenter">
                          <span className="headline4 subTitles">
                            {t(translate.tickets.resolvedBy)}
                          </span>
                          <span className="width50 body2 subdesc">
                            {TickObj?.resolved_by || '-'}
                          </span>
                        </h5>
                      </div>

                      <div className="proRowType">
                        <h5 className="subFlex alignCenter">
                          <span className="headline4 subTitles">
                            {t(translate.tickets.repairType)}
                          </span>
                          <span className="width50 body2 subdesc">
                            {TickObj?.repair_type || '-'}
                          </span>
                        </h5>
                      </div>

                      <div className="proRowType">
                        <h5 className="subFlex alignCenter">
                          <span className="headline4 subTitles">
                            {t(translate.tickets.repairLocation)}
                          </span>
                          <span className="width50 body2 subdesc">
                            {TickObj?.repair_location || '-'}
                          </span>
                        </h5>
                      </div>

                      <div className="proRowType">
                        <h5 className="subFlex alignCenter">
                          <span className="headline4 subTitles">
                            {t(translate.tickets.travelRequired)}
                          </span>
                          <span className="width50 body2 subdesc">
                            {TickObj?.travel_required ? 'Yes' : 'No'}
                          </span>
                        </h5>
                      </div>

                      <div className="proRowType">
                        <h5 className="subFlex alignCenter">
                          <span className="headline4 subTitles">
                            {t(translate.tickets.warrantyRepair)}
                          </span>
                          <span className="width50 body2 subdesc">
                            {TickObj?.warranty_repair ? 'Yes' : 'No'}
                          </span>
                        </h5>
                      </div>
                    </>
                  )}
                  {TickObj?.ticket_description && (
                    <div className="proRowType rowGapBtm3">
                      <h5 className="headline4">
                        {t(translate.tickets.description)}
                      </h5>
                      <div className="txtLftGap w100 body2 mb0">
                        <Interweave content={TickObj?.ticket_description} />
                      </div>
                    </div>
                  )}
                  {TickObj?.tags && TickObj?.tags.length > 0 && (
                    <div className="rowGapBtm3 tagsSec subFlex alignBaseline mb20">
                      <h4 className="mr11 headline4 mb15">
                        {t(translate.fleetticketsHistory.tags)}
                      </h4>
                      <div className="tagsBlk">
                        <div className="tags-container">
                          {TickObj?.tags?.map((data: any) => {
                            return (
                              <div
                                className="tag-chip-selected body3 fMed"
                                key={data}
                              >
                                <span>{data}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                  {TickObj && (
                    <div className="rowGapBtm3">
                      <h4 className="headline5">
                        {t(translate.tickets.tractorOperational)}
                      </h4>
                      <div className="tagsBlk mt14 radioBtn">
                        {/* <Switch checked={toggleSwitch} disabled /> */}
                        <Radio.Group
                          defaultValue={TickObj?.tractor_operational}
                          value={toggleSwitch}
                          disabled
                        >
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                  )}
                  <div className="attachemtBlk editAttachementBlk rowGapBtm3 attachIcons">
                    <h4 className="headline4">
                      {t(translate.tickets.attachements)}({FleetData.length}){' '}
                    </h4>
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      fileList={FleetData}
                      onChange={onChange}
                      onPreview={onPreview}
                      beforeUpload={beforeUpload}
                      accept=".jpg,.png"
                      maxCount={5}
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    >
                      <Button
                        className="uploadBtn"
                        disabled={FleetData.length >= 5}
                      >
                        <PlusOutlined />
                      </Button>
                    </Upload>
                  </div>
                  {errCode && errCode.length > 0 && (
                    <div className="errorCodesBlk mb32 errorCodesList">
                      <h4 className="headline4">
                        {t(translate.tickets.errorCodes)}
                        <img
                          src={errorCodeIcon}
                          className="errorCodeIcon"
                          onClick={errorCodesLink}
                        />
                      </h4>

                      <table className="table proRoleValue">
                        <tbody>
                          {errCode &&
                            errCode.map((x: any, index: number) => {
                              return (
                                <tr key={index}>
                                  <td>-</td>
                                  <td>Error: {x?.indicator_error_code}</td>
                                  <td>
                                    <img
                                      className="mr8"
                                      src={getSeverityIcon(x?.level, 1)}
                                    />
                                    <img
                                      className="mr8"
                                      src={getSeverityIcon(x?.level, 2)}
                                    />
                                    <img src={getSeverityIcon(x?.level, 3)} />
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}
                  <div className="proRowType mb32">
                    <h5 className="headline4">
                      {t(translate.tickets.comments)}
                    </h5>
                    <h6 className="createTcktBtn mt16 headline5">
                      <div>
                        {commentflag && (
                          <>
                            <div className="tcktCommentBox newCommentStyle">
                              <CustomTextArea
                                label="Description"
                                dataTestid="commentsDescriptionInputField-TicketViewEdit"
                                value={commentData}
                                setValue={setCommentData}
                                description={commentData}
                                descriptionCount={descriptionCount}
                                required={true}
                              />
                              <CloseOutlined
                                onClick={() => {
                                  setComment(false);
                                  setCommentData('');
                                }}
                              />
                              <div className="commentsCounts">
                                {commentData && commentData.trim() && (
                                  <span
                                    id={applicationIds.ticketScreen.saveComment}
                                    onClick={SaveComment}
                                    className="commentSaveBtn"
                                    data-testid="saveButton-TicketViewEdit"
                                  >
                                    {t(translate.buttons.save)}
                                  </span>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      {!commentflag && (
                        <div
                          id={applicationIds.ticketScreen.addComment}
                          onClick={addcomment}
                          className="commentBtnBlk"
                        >
                          <span className="addCBtn addTcktCBtn">
                            <img
                              src={addCBtn}
                              data-testid="addCommentIcon-TicketViewEdit"
                            />
                          </span>
                          <span className="addCommentBtn">
                            {t(translate.tickets.addComment)}
                          </span>
                        </div>
                      )}
                      {!ticketObjectLoaing && (
                        <div>
                          {CommentsData &&
                            CommentsData.map((ele: any, i: number) => {
                              return (
                                <div className="commentsBlk" key={i}>
                                  {ele?.data_text && (
                                    <>
                                      <div className="commentsAvatar">
                                        <div className="commentsIcon">
                                          <Avatar
                                            style={
                                              ele?.is_service_ticket_raised ===
                                              true
                                                ? {
                                                    width: '42px',
                                                    height: '24px',
                                                    color: 'white',
                                                    fontFamily:
                                                      'Montserrat-Bold',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                  }
                                                : {
                                                    width: '43px',
                                                    height: '43px',
                                                    color: 'white',
                                                    fontFamily:
                                                      'Montserrat-Bold',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    backgroundColor: '#67afb7',
                                                    border: '1px solid #67afb7',
                                                  }
                                            }
                                            src={
                                              ele?.is_service_ticket_raised ===
                                              true
                                                ? Assigned_to
                                                : ele.created_by_user
                                                    ?.profile_url
                                            }
                                          >
                                            {ele.created_by_user?.first_name?.charAt(
                                              0,
                                            )}
                                            {ele.created_by_user?.last_name?.charAt(
                                              0,
                                            )}
                                          </Avatar>
                                        </div>
                                      </div>
                                      <div className="commentsContent">
                                        <h5
                                          data-testid={`${ele.data_text}-TicketViewEdit`}
                                        >
                                          {ele.data_text}
                                        </h5>
                                        <h6>
                                          {getDateTimes(ele?.created_date_time)}
                                        </h6>
                                      </div>
                                    </>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      )}
                      <AppLoader loader={ticketObjectLoaing} />
                    </h6>
                    <div className="create_ticket_btn_sec">
                      {!ticketObjectLoaing && (
                        <div className="fwdBtnsSec">
                          {TickObj &&
                            stauslable === 'Unresolved' &&
                            privilegeChecker(
                              privilagesConstants.Resolve_a_ticket_not_assigned_to_the_individual,
                            ) && (
                              <button
                                type="button"
                                className="ticket_resolve_btn"
                                onClick={StatusonChangeModal}
                                data-testid="resolveTicketSubmitButton-TicketViewEdit"
                              >
                                {t(translate.tickets.resolveTicket)}
                              </button>
                            )}
                          {privilegeChecker(
                            privilagesConstants.Forward_a_ticket_to_Monarch,
                          ) &&
                            stauslable === 'Unresolved' &&
                            TickObj &&
                            !TickObj.is_service_ticket_raised && (
                              <button
                                type="button"
                                className="ticket_fwd_btn addTicketBtn"
                                id={applicationIds.ticketScreen.forwardticket}
                                onClick={forwaredTicketModal}
                                data-testid="forwardButton-TicketViewEdit"
                              >
                                {t(translate.tickets.forwardToMonarch)}
                              </button>
                            )}
                        </div>
                      )}
                      {!ticketObjectLoaing && stauslable === 'Resolved' && (
                        <>
                          {TickObj &&
                            privilegeChecker(
                              privilagesConstants.Resolve_a_ticket_not_assigned_to_the_individual,
                            ) && (
                              <button
                                type="button"
                                className="ticket_unresolved_btn"
                                onClick={StatusonChangeModal}
                                data-testid="unResolveTicketButton-TicketViewEdit"
                              >
                                {t(translate.tickets.unresolveTicket)}
                              </button>
                            )}
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}

              <>
                {forwardTicketLoader && (
                  <div className="fwdTicketProgressSec">
                    <div className="fwdTicketMKV">
                      {t(translate.tickets.forwardingTicket)}
                    </div>
                    <div className="proRoleValue mt14 subdesc w100 frwdTcktProgrsssDesc">
                      {t(translate.tickets.supportMessage)}
                    </div>
                    <img src={ticketFwd} className="frwdTcktLoadingImg" />
                  </div>
                )}
                {ticketLoader && (
                  <div className="fwdTicketProgressSec">
                    <div
                      className="fwdTicketMKV"
                      data-testid="ticketForwardedSuccessMsg-TicketViewEdit"
                    >
                      {t(translate.tickets.forwardedSuccess)}
                    </div>
                    <div className="proRoleValue mt14 subdesc w100 frwdTcktProgrsssDesc">
                      {t(translate.tickets.supportMessage)}
                    </div>
                    <img src={ticketSucess} />
                  </div>
                )}
              </>
            </>
          )}

          {editflag && (
            <>
              <div className="proRowType rowGapBtm3">
                <h5 className="subFlex">
                  <span className="fSbd headline5 subTitles">
                    {t(translate.fleetticketsHistory.status)}
                  </span>
                  <span className="width50">
                    {TickObj?.status !== 'open' ? (
                      <span className="status_value status_value_resolved body3">
                        {t(translate.tickets.status.resolved)}
                      </span>
                    ) : (
                      <span className="status_value body3">
                        {t(translate.tickets.status.unResolved)}
                      </span>
                    )}
                  </span>
                </h5>
              </div>
              <div className="filters_card selectLabel">
                <div className="proRowType rowGapBtm3">
                  <CustomSeverityLevel
                    label="Severity Level"
                    cssClass="min_width"
                    value={stateVarible}
                    onSelect={setStateVarible}
                    required={true}
                  />
                </div>

                {result.length > 0 && (
                  <div className="rowGapBtm3">
                    <CustomSelect
                      label="Assigned To"
                      cssClass="min_width"
                      value={assignid}
                      setValue={onSelect}
                      options={result}
                      optionKey="id"
                      optionDisplay="fullname"
                      required={true}
                      validations={validations.assignid}
                    />
                  </div>
                )}

                <div className="rowGapBtm3">
                  <CustomSelect
                    label="Equipment"
                    cssClass="min_width"
                    value={selectedEquipment}
                    setValue={equipmentSelect}
                    options={equipmentsList}
                    jsonFormat={true}
                    optionDisplay="name"
                    required={true}
                    validations={validations.selectedEquipment}
                  />
                </div>

                <div className="rowGapBtm3">
                  <CustomDate
                    label="Incident Time"
                    cssClass="min_width"
                    value={incidentTime}
                    onChange={(dates: any) => {
                      setIncidentTime(dates);
                    }}
                    dateTime={true}
                    required={true}
                    validations={validations.incidentTime}
                    showTime={true}
                    allowClear={false}
                  ></CustomDate>
                </div>

                <div className="rowGapBtm3 desBorder">
                  <CustomTextArea
                    label="Description"
                    value={description}
                    setValue={setDescription}
                    required={true}
                    description={description}
                    descriptionCount={descriptionCount}
                    count={1500}
                  />
                </div>
              </div>

              <>
                <div className="rowGapBtm3 tagsSec">
                  <h4 className="headline5 mr11">
                    {t(translate.fleetticketsHistory.tags)}
                  </h4>
                  <div className="tagsBlk mt14">
                    {tagsList?.map((data: any) => {
                      return (
                        <CheckableTag
                          data-testid={`${data.name}-TicketViewEdit`}
                          className="checktag body3"
                          key={data.id}
                          checked={data.selected}
                          onChange={(checked: any) =>
                            handleChange(data, checked)
                          }
                        >
                          {data.name}
                        </CheckableTag>
                      );
                    })}
                  </div>
                </div>
                {TickObj && (
                  <div className="rowGapBtm3 tagsSec">
                    <h4 className="headline5 mr11">
                      {t(translate.tickets.tractorOperational)}
                    </h4>
                    <div className="tagsBlk mt14 radioBtn">
                      <Radio.Group
                        onChange={(e) => setToggleSwitch(e.target.value)}
                        // defaultValue={TickObj?.tractor_operational}
                        value={toggleSwitch}
                      >
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                )}
                <div className="attachemtBlk editAttachementBlk rowGapBtm3 attachIcons">
                  <h4 className="headline5">
                    {t(translate.tickets.attachements)}({FleetData.length}){' '}
                  </h4>
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    fileList={FleetData}
                    onChange={onChange}
                    onPreview={onPreview}
                    beforeUpload={beforeUpload}
                    accept=".jpg,.png"
                    maxCount={5}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  >
                    <Button
                      className="uploadBtn"
                      disabled={FleetData.length >= 5}
                    >
                      <PlusOutlined />
                    </Button>
                  </Upload>
                </div>
              </>

              <div className="edit-loader">
                {loader && (
                  <div className="loader">
                    <Spin size="large" />
                  </div>
                )}
              </div>
              <div className="create_ticket_btn_sec">
                <div className="fwdBtnsSec">
                  <button
                    className="ticket_resolve_btn"
                    data-testid="cancelButton-TicketViewEdit"
                    onClick={ticketCancel}
                  >
                    {' '}
                    {t(translate.buttons.cancel)}
                  </button>
                  <Button
                    className="ticket_fwd_btn"
                    data-testid="TicketUpdateSaveButton-TicketViewEdit"
                    id={applicationIds.ticketScreen.editSave}
                    onClick={saveUpdatedTicket}
                    disabled={isDisabled}
                  >
                    {t(translate.buttons.save)}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {forwardticketmodal && (
        <ForwardTicketModal
          setForwardTicketLoader={setForwardTicketLoader}
          forwardCancel={forwardCancel}
          TickObj={TickObj}
          getTicketById={getbyticketbyid}
          setEditFlagBody={seteditflagBody}
          setTicketLoader={setTicketLoader}
        />
      )}

      {statusmodal && (
        <ResolveTicketModal
          TickObj={TickObj}
          statusLabel={stauslable}
          resolveCancel={resolveCancel}
          ticketObj={ticketObj}
          setStatusModal={setstatusmodal}
          SetStatusLable={SetStatusLable}
          getTicketById={getbyticketbyid}
          Close={Close}
          editFlag={editflag}
        />
      )}

      {errorcodeFlag && (
        <Modal
          title="Error Codes"
          visible={true}
          onCancel={errorcodeCancel}
          width={1200}
          maskClosable={false}
          bodyStyle={{ height: 'auto' }}
          footer={false}
          className="opPopup errorCodesModal"
        >
          <div className="tblDft farmTabsTbl hoverable dsTbl errorCodesTbl">
            <Table
              sticky={true}
              dataSource={errCode}
              columns={columns}
              pagination={false}
            />
          </div>
        </Modal>
      )}

      <Modal
        visible={previewOpen}
        title={previewTitle}
        footer={null}
        maskClosable={false}
        onCancel={handleCancel}
        className="previewModal noTitle"
      >
        <div className="previewmodalBody">
          {FleetData.length - 1 !== currentIndex && (
            <ArrowLeftOutlined onClick={leftarrowClick} />
          )}
          {previewImage && (
            <img alt="example" className="img-responsive" src={previewImage} />
          )}
          {currentIndex !== 0 && (
            <ArrowRightOutlined onClick={rightarrowClick} />
          )}
        </div>
      </Modal>
    </Layout>
  );
};

export default TicketViewEdit;
