import { Tag } from 'antd';
import React from 'react';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';

interface Props {
  status: string;
  className?: string;
}

const statusClass: any = {
  open: 'openStyle',
  inprogress: 'inProgressStyle',
  completed: 'resolveStyle',
  Forwarded: 'forwardStyle',
};

const TicketStatusTag: React.FC<Props> = ({ status, className = '' }) => {
  const { t } = useTranslation();
  const statusText: any = {
    open: t(translate.tickets.status.open),
    inprogress: t(translate.tickets.status.inProgress),
    completed: t(translate.tickets.status.resolved),
    Forwarded: t(translate.tickets.status.forwarded),
  };
  return (
    <div className={`${statusClass[status]} ${className}`}>
      {statusText[status]}
    </div>
  );
};

export default TicketStatusTag;
