/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-empty-function */
import { notification } from 'antd';
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import base_station_icon from '../../assets/images/base_station_icon.svg';
import nworkOff from '../../assets/images/low.svg';
import tractorAlertIcon from '../../assets/images/tractorAlert.svg';
import tractorspic from '../../assets/images/tractor_health.svg';
import tOff from '../../assets/images/tractor_off.svg';
import wifiOff from '../../assets/images/wOff.svg';

import {
  addRemoteAVCommand,
  createSnapshot,
  executeRemoteAVCommand,
  getEmployeeDetails,
  getImplementAttached,
  validateOpsCode,
} from '../../constants/Api';
import {
  LS_RAV_COMMANDS_KEY,
  LS_ROUTINE,
  checkLoginUserAndTractorConnectUserSameTractorsList,
  checkTractorHaveMonitorStatueError,
  checkTractorInConnectedState,
  getConnectivityImg,
  getLocalStorageData,
  getNetworkTypeImg,
  isEmptyValue,
  setTitleAndDescription,
  tractorConstantsNew,
  wrapIndictorsData,
} from '../../constants/Common';
import constant, {
  AutoDriveTabs,
  MOS_CLOUD,
  PAUSE,
  REMOTE_AV,
  RESUME,
  clearCommandFrom,
  doNotCheckTheseCodesBeforeConnect,
  getDriveActionUuid,
  isInRemoteAvRunningState,
  removeBelowErrorIf7011,
} from '../../constants/constant';
import { MapFeature } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import { SET_TRACTOR_IMPLEMENT_STATE } from '../../context/actions';
import { track } from '../../util/logger';
import RemoteDriveReducer, { initialState } from './RemoteDriveReducer';
import {
  AUTO_DRIVE_MONITOR_ERROR_CODES,
  EXECUTE,
  RESET_STATE,
  SELECTED_ITEM_NAME,
  SELECTED_ROUTINE,
  SELECTED_TRACTORS,
  SET_ACTIVE_AUTODRIVE_TAB,
  SET_AUTO_DRIVE_INTERRUPT_MESSAGES,
  SET_AUTO_DRIVE_LOADER,
  SET_AUTO_DRIVE_MONITOR_POP,
  SET_AUTO_DRIVE_POP,
  SET_IMPLEMENT,
  SET_PINNED_TRACTORS,
  SET_SHOW_CONNECT_LOADER,
  SET_SHOW_CONNECT_TRY_AGAIN_POP,
  SET_SHOW_IMPLEMENT,
  SET_SHOW_LOSE_CONNECTION,
  SET_SHOW_PATH_MODAL,
  SET_SHOW_REMOTE_DRIVE_DETAILS,
  SET_SHOW_TERM_AND_COND,
  SET_TRIGGERED_GUARDRAIL,
  SHOW_TRACTOR_COMMAND_LOADER,
  SHOW_TRACTOR_START,
  SNAP_SHOT_INITIATED,
} from './actions';

const { TRACTOR_COMMUNICATION } = constant;
let globalAutoDriveInterruptMsgs: any = {};

interface RemoetDriveContextInterface {
  getImplement: (arg: number) => void;
  greenPolygons: MapFeature[];
  setGreenPolygons: (greenPolygons: MapFeature[]) => void;
  RDReducer: any[];
  commandsList: any;
  handlePauseResumeStopLiveCom: (
    command: string,
    tractor?: any,
    from?: 'Click' | 'Event',
  ) => void;
  getIndicatorIcons: (tractor: any) => void;
  guardRailsCheck: (guardrail: string, type: string) => void;
  iconsFlag: boolean;
  closeModal: () => void;
  tractorObj: any;
  mapPinnedTractors: (id: number) => void;
  unPinnedTractors: (id: number) => void;
  connectToTractor: (id: number) => void;
  saveCommandTOLocalDB: (tractorId: number, pointsData: any) => void;
  clearAllLocalDBCommands: (tractorId: number) => void;
  addRVRoutineCommand: () => void;
  clearCommandApiHandler: (tractorId: number, from: string) => void;
  startAutoDriveIteration: (tractorId: number, payload?: any) => void;
  selectedTractorsIds: any[];
  setSelectedTractorsIds: (ids: any) => void;
  connectTractorHandler: (tractorId?: number) => void;
  startAutoDrive: (e: any) => void;
  triggerSnapshot: () => void;
  addImplementSettings: (
    savedImplementDetails: any,
    isGotoBlock: boolean,
  ) => void;
}

export const RemoteDriveAppCtx = createContext<RemoetDriveContextInterface>({
  getImplement: (arg: number) => {},
  greenPolygons: [],
  setGreenPolygons: (greenPolygons: MapFeature[]) => [],
  RDReducer: [],
  // getCommands: () => {},
  commandsList: {},
  handlePauseResumeStopLiveCom: (
    command: string,
    tractor?: any,
    from?: string,
  ) => {},
  getIndicatorIcons: (tractor: any) => {},
  guardRailsCheck: (guardrail: string, type: string) => {},
  iconsFlag: false,
  closeModal: () => {},
  tractorObj: {},
  mapPinnedTractors: (id: number) => {},
  unPinnedTractors: (id: number) => {},
  connectToTractor: (id: number) => {},
  saveCommandTOLocalDB: (tractorId: number, pointsData: any) => {},
  clearAllLocalDBCommands: (tractorId: number) => {},
  addRVRoutineCommand: () => {},
  clearCommandApiHandler: (tractorId: number, from: string) => {},
  startAutoDriveIteration: (tractorId: number, payload?: any) => {},
  selectedTractorsIds: [],
  setSelectedTractorsIds: (ids: any) => {},
  connectTractorHandler: (tractorId?: number) => {},
  startAutoDrive: (e: any) => {},
  triggerSnapshot: () => {},
  addImplementSettings: (
    savedImplementDetails: any,
    isGotoBlock: boolean,
  ) => {},
});

// Provider in your app

const RemoteDriveContext: React.FC = ({ children }) => {
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const [appState] = APPReducer;
  const { tractorsList, tractorsMap } = appState;
  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const [autoDriveState, autoDriveDispatch] = AutoDriveReducer;
  const { remoteAvTractors, tractorImplementState } = autoDriveState;

  const [state, dispatch] = useReducer(RemoteDriveReducer, initialState);
  const [greenPolygons, setGreenPolygons] = useState<MapFeature[]>([]);
  const [commandsList, setCommandsList] = useState<any>({});
  const [iconsFlag, seticonsFlag] = useState<boolean>(false);
  const [tractorObj, setTractorObj] = useState<any>();
  const [opsCode, setOpsCode] = useState<string>('');
  const [selectedTractorsIds, setSelectedTractorsIds] = useState<any[]>([]);

  const {
    pinnedTractors,
    selectedImplementName,
    selectedTractor,
    autoDriveInterruptMsgs,
    showTractorStart,
    showTractorCommandLoader,
  } = state;
  useEffect(() => {
    globalAutoDriveInterruptMsgs = autoDriveInterruptMsgs;
  }, [autoDriveInterruptMsgs]);
  useEffect(() => {
    return () => {
      dispatch({ type: RESET_STATE });
    };
  }, []);
  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getCommands();
    }
  }, [userDetails]);

  const getImplement = async (tractor_id: number) => {
    try {
      const { organization } = userDetails;
      const { attached_implement } = await getImplementAttached(
        organization.api_url,
        tractor_id,
      );
      dispatch({ type: SET_IMPLEMENT, payload: attached_implement });
    } catch (error: any) {
      notification.error({
        message: error.message,
        duration: 1,
      });
    }
  };

  const mapPinnedTractors = (id: number) => {
    let tTractors: any[] = pinnedTractors;
    if (!pinnedTractors.length) tTractors = [id];
    else if (!pinnedTractors.includes(id)) {
      tTractors = [...pinnedTractors, id];
    }
    dispatch({
      type: SET_PINNED_TRACTORS,
      payload: tTractors,
    });
  };

  const getCommands = () => {
    const remoteAVCommandsObj: any = getLocalStorageData(
      LS_RAV_COMMANDS_KEY,
      true,
    );
    setCommandsList(remoteAVCommandsObj);
  };

  const handlePauseResumeStopLiveCom = async (
    command: string,
    heartbeat = selectedTractor?.heartbeat,
    from?: 'Click' | 'Event',
  ) => {
    try {
      const { organization } = userDetails;

      const payload: any = {
        organization_id: organization.id,
        operator_id: userDetails.id,
        action: command,
        planner: REMOTE_AV,
        tractor_id: heartbeat.tractor_id,
        drive_action_uuid:
          heartbeat?.drive_action_details?.current_drive_action,
      };
      // don't show lose connection pop when human manual pause
      command === PAUSE &&
        autoDriveDispatch({ type: SET_SHOW_LOSE_CONNECTION, payload: false });
      // if ((command === PAUSE || command === RESUME) && from === 'Click') {
      //   payload.details = 'button_press';
      //   payload.category = 'user_pause';
      //   payload.type = 'hard';
      // }
      // if ((command === PAUSE || command === RESUME) && from === 'Event') {
      //   payload.details = 'timeout';
      //   payload.category = 'user_pause';
      //   payload.type = 'hard';
      // }
      // if (command === PAUSE || command === RESUME) {
      if (command === PAUSE || command === RESUME) {
        payload.details = MOS_CLOUD;
        payload.category = MOS_CLOUD;
        payload.type = 'hard';
      }

      const { msg } = await executeRemoteAVCommand(
        organization.api_url,
        payload,
      );
      dispatch({
        type: SET_AUTO_DRIVE_POP,
        payload: false,
      });

      notification.success({
        message: msg,
        duration: 1,
      });
    } catch (err: any) {
      notification.error({
        message: err?.message,
        duration: 1,
      });
    }
  };

  const mapOpen = (tractor: number) => {
    track('Live Map', {
      event: `Fleet List Indicators Clicked`,
    });
    setTractorObj(tractor);
    seticonsFlag(true);
  };
  const closeModal = () => {
    seticonsFlag(false);
  };

  const getIndicatorIcons = (tractor: any) => {
    const { indicators: data } = tractor;
    const indicatorsData = wrapIndictorsData(data);
    const connectivity = getConnectivityImg(
      indicatorsData[TRACTOR_COMMUNICATION],
    );
    const isBaseStationIcon = !isEmptyValue(tractor?.heartbeat?.bs_serial_no);

    const isError =
      tractor.indicatorsData.filter(
        (indicator: { indicator_level: number }) =>
          indicator.indicator_level === 3,
      ).length > 0;
    if (tractor.isAvailable === tractorConstantsNew.off.key) {
      return (
        <>
          <div className="t_signals">
            <div className="t_signals_img">
              <img className="mr4" src={tOff} style={{ cursor: 'pointer' }} />
            </div>
            <div className="t_signals_img">
              {tractor.active_network === 'cellular' ? (
                <img
                  className="mr4"
                  style={{ marginLeft: '5px', cursor: 'pointer' }}
                  height="11"
                  src={nworkOff}
                />
              ) : (
                <img
                  className="mr4"
                  src={wifiOff}
                  style={{ cursor: 'pointer' }}
                />
              )}
            </div>
          </div>
        </>
      );
    }
    return (
      connectivity.trim() !== '' && (
        <div
          className={`t_signals ${isError ? 't_error' : ''}`}
          onClick={(e: any) => {
            e.stopPropagation();
            mapOpen(tractor);
          }}
        >
          <div className="t_signals_img">
            <img
              className="mr4"
              src={isError ? tractorAlertIcon : tractorspic}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div className="t_signals_img">
            {tractor.active_network === 'cellular' ? (
              <img
                className="mr4"
                width="16px"
                src={getNetworkTypeImg(tractor)}
                style={{ cursor: 'pointer' }}
              />
            ) : (
              <img
                className={isBaseStationIcon ? '' : 'mr4'}
                src={
                  isBaseStationIcon
                    ? base_station_icon
                    : getNetworkTypeImg(tractor)
                }
                style={{ cursor: 'pointer' }}
              />
            )}
          </div>
        </div>
      )
    );
  };

  const guardRailsCheck = async (guardRail: string, triggerState: string) => {
    try {
      dispatch({ type: SET_TRIGGERED_GUARDRAIL, payload: guardRail });
      if (triggerState === 'passive') {
        const titleAndDescription = setTitleAndDescription(guardRail);
        notification.warning({
          message: titleAndDescription.title,
          description: titleAndDescription.description,
        });
      } else if (triggerState === 'active') {
        if (
          guardRail === 'path protection' ||
          guardRail === 'human protection' ||
          guardRail === 'pto protection'
        ) {
          dispatch({ type: SET_SHOW_PATH_MODAL, payload: true });
        } else {
          const titleAndDescription = setTitleAndDescription(guardRail);
          notification.warning({
            message: titleAndDescription.title,
            description: titleAndDescription.description,
          });
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('error');
    }
  };
  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getProfile();
    }
  }, [userDetails]);

  const getProfile = async () => {
    const data = await getEmployeeDetails(
      userDetails.organization.api_url,
      userDetails.organization.id,
      userDetails.id,
    );
    setOpsCode(data.ops_code);
  };
  const getConnectedTractor = (id: number, fail: boolean) => {
    const [tractor] = tractorsList.filter((tractor: any) => tractor.id === id);
    if (fail) {
      dispatch({
        type: SET_SHOW_REMOTE_DRIVE_DETAILS,
        payload: true,
      });
      autoDriveDispatch({
        type: SET_SHOW_IMPLEMENT,
        payload: true,
      });
      dispatch({
        type: SET_AUTO_DRIVE_POP,
        payload: true,
      });
      dispatch({
        type: SELECTED_TRACTORS,
        payload: [tractor],
      });
    } else {
      autoDriveDispatch({
        type: SET_SHOW_IMPLEMENT,
        payload: false,
      });
      dispatch({
        type: SET_AUTO_DRIVE_POP,
        payload: false,
      });
      dispatch({
        type: SET_SHOW_REMOTE_DRIVE_DETAILS,
        payload: false,
      });
      dispatch({
        type: SELECTED_TRACTORS,
        payload: [],
      });
    }
  };

  const connectToTractor = async (tractorId: number) => {
    // setLoader(true);
    try {
      if (
        !checkTractorInConnectedState(
          selectedTractorsIds[0],
          userDetails?.id,
          remoteAvTractors,
        )
      ) {
        const { organization } = userDetails;
        const payload = {
          tractor_id: Number(tractorId),
          operator_id: userDetails.id,
          organization_id: userDetails.organization_id,
          ops_code: Number(opsCode),
        };
        await validateOpsCode(organization.api_url, payload);
        // display the connect again pop
        setTimeout(() => {
          dispatch({
            type: SET_SHOW_CONNECT_TRY_AGAIN_POP,
            payload: true,
          });
        }, 40000);
      }

      // setLoader(false);
      getConnectedTractor(tractorId, true);
      // auto drive pop stopped closing until tractor MQTT status
      dispatch({
        type: EXECUTE,
        payload: {
          connected: true,
        },
      });
    } catch (err: any) {
      getConnectedTractor(tractorId, false);
      notification.error({
        message: 'Failed to Connect',
        description: err?.message,
        duration: 1,
      });
      // hide the tractor connecting  gif
      dispatch({
        type: SET_SHOW_CONNECT_LOADER,
        payload: false,
      });
      // hide the tractor connecting try again pop
      dispatch({
        type: SET_SHOW_CONNECT_TRY_AGAIN_POP,
        payload: false,
      });
    }
  };
  const setLocalStorageData = (
    key: string,
    data: any,
    isJson: boolean,
  ): void => {
    if (data && isJson) {
      data = JSON.stringify(data);
    }
    if (data) {
      localStorage.setItem(key, data);
    }
    getCommands();
  };
  const clearAllLocalDBCommands = (tractorId: number) => {
    const commandsObj = getLocalStorageData(LS_RAV_COMMANDS_KEY, true);
    if (commandsObj && commandsObj[tractorId]) {
      delete commandsObj[tractorId];
      setLocalStorageData(LS_RAV_COMMANDS_KEY, commandsObj, true);
    }
  };
  const saveCommandTOLocalDB = (tractorId: number, pointsData: any): void => {
    let tractorCommands: any = [];
    let remoteAVCommandsObj: any = getLocalStorageData(
      LS_RAV_COMMANDS_KEY,
      true,
    );
    if (remoteAVCommandsObj && remoteAVCommandsObj[tractorId]) {
      tractorCommands = remoteAVCommandsObj[tractorId];
      pointsData.cmdIndex = tractorCommands.length + 1;
      tractorCommands.push(pointsData);
      if (tractorId) {
        remoteAVCommandsObj[tractorId] = tractorCommands;
      }
      setLocalStorageData(LS_RAV_COMMANDS_KEY, remoteAVCommandsObj, true);
    } else {
      tractorCommands = [];
      pointsData.cmdIndex = tractorCommands.length + 1;
      tractorCommands.push(pointsData);
      if (!remoteAVCommandsObj) {
        remoteAVCommandsObj = {};
      }
      if (tractorId) {
        remoteAVCommandsObj[tractorId] = tractorCommands;
      }
      setLocalStorageData(LS_RAV_COMMANDS_KEY, remoteAVCommandsObj, true);
    }
  };

  const addRVRoutineCommand = async () => {
    try {
      const { organization } = userDetails;
      if (organization && organization.api_url) {
        const routine: string | null = localStorage.getItem(LS_ROUTINE);
        const routineContent: any = routine && JSON.parse(routine);
        if (routineContent && routineContent.incomplete_operation_uuid) {
          const {
            name,
            param_type,
            fbk_msg_info_string,
            incomplete_operation_uuid,
            param_name,
          } = routineContent;
          const commandData: any = {
            command: 'incomplete_operation',
            command_uuid: incomplete_operation_uuid,
            operation: param_type,
            param_type,
            param_name,
            // routine: name,
            info_string: fbk_msg_info_string,
            implement: selectedImplementName ? selectedImplementName : null,
          };
          await addRemoteAVCommand(
            organization.api_url,
            [commandData],
            selectedTractor?.heartbeat?.drive_action_details
              ?.current_drive_action as string,
          );
          commandData.name = name;
          selectedTractor &&
            selectedTractor.id &&
            saveCommandTOLocalDB(selectedTractor.id, commandData);
        } else if (routineContent && routineContent.route_uuid) {
          const { name } = routineContent;
          const commandData: any = {
            command: 'routine',
            routine: name,
            implement: selectedImplementName ? selectedImplementName : null,
          };
          await addRemoteAVCommand(
            organization.api_url,
            [commandData],
            selectedTractor?.heartbeat?.drive_action_details
              ?.current_drive_action as string,
          );
          commandData.name = name;
          selectedTractor &&
            selectedTractor.id &&
            saveCommandTOLocalDB(selectedTractor.id, commandData);
        }
      }
    } catch (err: any) {
      dispatch({
        type: SELECTED_ITEM_NAME,
        payload: null,
      });
      dispatch({ type: SELECTED_ROUTINE, payload: null });

      notification.error({
        message: err?.message,
        duration: 1,
      });
    } finally {
      // setLoader(false);
    }
  };

  const clearCommandApiHandler = (id: number, from: string) => {
    console.log('from', from);
    const { id: userId } = userDetails;
    const [tractor] = tractorsList.filter((tractor: any) => tractor.id == id);
    if (tractor && tractor.id) {
      const { organization } = userDetails;
      if (organization && organization.api_url) {
        from !== clearCommandFrom && clearAllLocalDBCommands(id);
        const driveActionUuid = getDriveActionUuid(tractorsList, id);
        // if tractor is running don't clear the command
        isInRemoteAvRunningState(tractor) &&
          checkLoginUserAndTractorConnectUserSameTractorsList(
            tractor,
            id,
            userId,
          ) &&
          driveActionUuid &&
          driveActionUuid !== '' &&
          addRemoteAVCommand(
            organization.api_url,
            [
              {
                command: 'clear_command_queue',
              },
            ],
            driveActionUuid as string,
          );
      }
    }
  };

  const startAutoDriveIteration = async (id: number, actionPayload?: any) => {
    const [tractor] = tractorsList.filter((tractor: any) => tractor.id == id);
    if (tractor && tractor.id) {
      try {
        const { organization } = userDetails;
        const payload = {
          ...{
            organization_id: userDetails.organization_id,
            action: 'start',
            tractor_id: id,
            drive_action_uuid: tractor?.heartbeat?.drive_action_details
              ?.current_drive_action as string,
          },
          ...actionPayload,
        };
        const { msg } = await executeRemoteAVCommand(
          organization.api_url,
          payload,
        );
        notification.success({
          message: msg,
          duration: 1,
        });
        dispatch({
          type: SET_AUTO_DRIVE_POP,
          payload: false,
        });
        // clearing the interrupt messages on auto_drive start
        // !actionPayload &&
        //   globalAutoDriveInterruptMsgs &&
        //   Object.keys(globalAutoDriveInterruptMsgs).map(
        //     (autoDriveInterruptMsg: string) => {
        //       if (autoDriveInterruptMsg.includes(`${id}`)) {
        //         delete globalAutoDriveInterruptMsgs[autoDriveInterruptMsg];
        //       }
        //     },
        //   );
        if (!actionPayload) {
          globalAutoDriveInterruptMsgs[id] = {};
          dispatch({
            type: SET_AUTO_DRIVE_INTERRUPT_MESSAGES,
            payload: globalAutoDriveInterruptMsgs,
          });
        }
      } catch (err: any) {
        notification.error({
          message: err.message,
          duration: 1,
        });
      }
    }
  };
  const unPinnedTractors = (id: number) => {
    const tTractors = pinnedTractors.filter((item: any) => item !== id);
    dispatch({
      type: SET_PINNED_TRACTORS,
      payload: tTractors,
    });
  };

  const connectTractorHandler = (tractorId?: number) => {
    const message = checkTractorHaveMonitorStatueError(
      tractorId ? tractorId : selectedTractorsIds[0],
      remoteAvTractors,
    );
    // const message = '7001,7002, 76100';
    const msg = message && message.length > 0 ? message : '';
    let errors: number[] = [];
    if (message.length > 0) {
      errors = msg.split(',').map((element: string) => {
        return Number(element);
      });
      errors = errors.filter(
        (error) => !doNotCheckTheseCodesBeforeConnect.includes(error),
      );
      if (errors.includes(7011)) {
        errors = errors.filter(
          (error) => !removeBelowErrorIf7011.includes(Number(error)),
        );
      }
    }
    if (errors && errors.length > 0) {
      dispatch({
        type: AUTO_DRIVE_MONITOR_ERROR_CODES,
        payload: {
          message: message,
          errors: errors,
          tractorId: tractorId ? tractorId : selectedTractorsIds[0],
        },
      });
      dispatch({
        type: SET_AUTO_DRIVE_MONITOR_POP,
        payload: true,
      });
      // set selected tractor
    } else {
      dispatch({
        type: SET_SHOW_TERM_AND_COND,
        payload: true,
      });
      tractorId && setSelectedTractorsIds([selectedTractor.id]);
    }
  };
  // start auto drive
  const startAutoDrive = (e: any) => {
    dispatch({
      type: SET_SHOW_CONNECT_TRY_AGAIN_POP,
      payload: false,
    });
    dispatch({
      type: SET_ACTIVE_AUTODRIVE_TAB,
      payload: AutoDriveTabs.Parameter,
    });
    e.stopPropagation();
    // stop showing auto drive  start button
    showTractorStart[selectedTractor.id] = false;
    dispatch({
      type: SHOW_TRACTOR_START,
      payload: {
        ...showTractorStart,
      },
    });
    showTractorCommandLoader[selectedTractor.id] = false;
    dispatch({
      type: SHOW_TRACTOR_COMMAND_LOADER,
      payload: {
        ...showTractorCommandLoader,
      },
    });
    setSelectedTractorsIds([selectedTractor.id]);
    if (
      checkTractorInConnectedState(
        selectedTractor.id,
        userDetails?.id,
        remoteAvTractors,
      )
    ) {
      // if user already connected show the implement verify screen
      dispatch({
        type: SET_AUTO_DRIVE_POP,
        payload: true,
      });
      autoDriveDispatch({
        type: SET_SHOW_IMPLEMENT,
        payload: true,
      });
    } else {
      // if user not connected open term and cond pop
      connectTractorHandler(selectedTractor.id);
    }
    // added to show implement selection start here
    autoDriveDispatch({
      type: SET_SHOW_IMPLEMENT,
      payload: true,
    });
    // implement verification set false (default)
    tractorImplementState[selectedTractor.id] = false;
    autoDriveDispatch({
      type: SET_TRACTOR_IMPLEMENT_STATE,
      payload: tractorImplementState,
    });
    // added to show implement selection ends here
  };

  const triggerSnapshot = async () => {
    try {
      const { organization } = userDetails;
      const response = await createSnapshot(
        organization.api_url,
        organization.farm.id,
        selectedTractor.id,
      );
      if (!response?.id) throw new Error('SnapShot Failed');
      track('Live Map', {
        event: `Fleet List SnapShot successful`,
      });
      response['tractorName'] = tractorsMap.get(response.tractor_id)?.name;
      response['name'] = `${userDetails.first_name} ${userDetails.last_name}`;
      dispatch({
        type: SNAP_SHOT_INITIATED,
        payload: response,
      });
      notification.open({
        message: 'snapshot captured',
        duration: 1,
      });
      // notification.open({
      //   message: '',
      //   key: response.id,
      //   // duration: 3,
      //   description: (
      //     <>
      //       <p>Snapshot Captured</p>
      //       <Button
      //         onClick={() => {
      //           notification.close(response.id);
      //           dispatch({
      //             type: SET_SHOW_SNAPSHOT,
      //             payload: true,
      //           });
      //         }}
      //         className="cursor"
      //       >
      //         View
      //       </Button>
      //     </>
      //   ),
      // });
    } catch (error: any) {
      track('Live Map', {
        event: `Fleet List SnapShot Failed`,
      });
      notification.error({
        message: error.message,
        duration: 1,
      });
    }
  };

  const addImplementSettings = async (
    savedImplementDetails: any,
    isGotoBlock = true,
  ) => {
    dispatch({
      type: SET_AUTO_DRIVE_LOADER,
      payload: true,
    });
    try {
      const { organization } = userDetails;
      if (
        organization &&
        organization.api_url &&
        selectedTractor?.heartbeat?.drive_action_details?.current_drive_action
      ) {
        const commandData = {
          command: 'implement_ctrl_params',
          implement: selectedImplementName ? selectedImplementName : null,
          ...savedImplementDetails,
        };
        const { msg } = await addRemoteAVCommand(
          organization.api_url,
          [commandData],
          selectedTractor?.heartbeat?.drive_action_details
            ?.current_drive_action as string,
        );
        isGotoBlock &&
          dispatch({
            type: SET_ACTIVE_AUTODRIVE_TAB,
            payload: AutoDriveTabs.Block,
          });
        // msg &&
        //   notification.success({
        //     message: msg,
        //     duration: 1,
        //   });
      }
    } catch (err: any) {
      notification.error({
        message: err?.message,
        duration: 1,
      });
    } finally {
      dispatch({
        type: SET_AUTO_DRIVE_LOADER,
        payload: false,
      });
    }
  };

  return (
    <RemoteDriveAppCtx.Provider
      value={{
        getImplement,
        greenPolygons,
        setGreenPolygons,
        RDReducer: [state, dispatch],
        commandsList,
        handlePauseResumeStopLiveCom,
        getIndicatorIcons,
        guardRailsCheck,
        iconsFlag,
        closeModal,
        tractorObj,
        mapPinnedTractors,
        unPinnedTractors,
        connectToTractor,
        saveCommandTOLocalDB,
        clearAllLocalDBCommands,
        addRVRoutineCommand,
        clearCommandApiHandler,
        startAutoDriveIteration,
        selectedTractorsIds,
        setSelectedTractorsIds,
        connectTractorHandler,
        startAutoDrive,
        triggerSnapshot,
        addImplementSettings,
      }}
    >
      {children}
    </RemoteDriveAppCtx.Provider>
  );
};

export default RemoteDriveContext;
