/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/react-in-jsx-scope */
import { Modal, notification, Space, Tag } from 'antd';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FlagGrayIcon from '../../assets/images/flag_gray.svg';
import FlagOrangeIcon from '../../assets/images/flag_orange.svg';
import FlagRedIcon from '../../assets/images/flag_red.svg';
import { getFleetDiagnosticsHistory } from '../../constants/Api';
import { fleetObject } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import translate from '../../locale/en_translate.json';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';
import { mapDiagnosticData } from '../../lib/dataFormat';
import usePaginate from '../../hooks/usePaginate';
import { initScroller } from '../../constants/Common';

interface Props {
  iconsFlag: boolean;
}

export const getTags = (details: any) => {
  const { functionality_affected } = details;
  let tags = <></>;
  if (functionality_affected && functionality_affected.trim() !== '') {
    tags = functionality_affected
      .split(functionality_affected.includes(';') ? ';' : ',')
      .map((element: string, key: number) => (
        <Tag key={key} className="tagsName">
          {element}
        </Tag>
      ));
  }
  return tags;
};

export const getSeverity = (level: number) => {
  let images;
  switch (level) {
    case 1:
      images = (
        <>
          <img src={FlagOrangeIcon} className="mr7" />
          <img src={FlagGrayIcon} className="mr7" />
          <img src={FlagGrayIcon} className="mr7" />
        </>
      );
      break;
    case 2:
      images = (
        <>
          <img src={FlagOrangeIcon} className="mr7" />
          <img src={FlagOrangeIcon} className="mr7" />
          <img src={FlagGrayIcon} className="mr7" />
        </>
      );
      break;
    case 3:
      images = (
        <>
          <img src={FlagRedIcon} className="mr7" />
          <img src={FlagRedIcon} className="mr7" />
          <img src={FlagRedIcon} className="mr7" />
        </>
      );
      break;
    default:
      images = (
        <>
          <img src={FlagGrayIcon} className="mr7" />
          <img src={FlagGrayIcon} className="mr7" />
          <img src={FlagGrayIcon} className="mr7" />
        </>
      );
      break;
  }
  return <Space>{images}</Space>;
};

const DiagnosticsModal: React.FC<Props> = ({ iconsFlag }: Props) => {
  const { t } = useTranslation();
  const { closeModal, tractorObj } = useContext(RemoteDriveAppCtx);
  const { userDetails } = useContext(ApplicationContext);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPagesize] = useState<any>(25);
  const [total, setTotal] = useState(0);
  const { filterData, setData, hasMore, checkActivity, activity } =
    usePaginate();

  const columns = [
    {
      title: `${t(translate.headers.severity)}`,
      dataIndex: 'severity',
      key: 'indicator_level',
      width: '12%',
      render: (severity: number) => {
        return getSeverity(severity);
      },
    },
    {
      title: `${t(translate.headers.dateTime)}`,
      dataIndex: 'start_time',
      key: 'created_date_time',
      width: '18%',
    },
    {
      title: `${t(translate.tractorDetails.errorcode)}`,
      dataIndex: 'error_code',
      key: 'error_code',
      width: '12%',
      render: (error_code: any) => <>{error_code}</>,
    },
    {
      title: `${t(translate.headers.indicatorType)}`,
      dataIndex: 'indicator_type',
      key: 'indicator_type',
      // width: '12%',
    },
    {
      title: `${t(translate.headers.issue)}`,
      // width: '160px',
      dataIndex: 'subsystem',
      key: 'subsystem',
      render: (subsystem: string) => {
        return <span className="description">{subsystem}</span>;
      },
    },
    {
      title: `${t(translate.myTask.description)}`,
      dataIndex: 'description',
      key: 'description',
      width: '30%',
      render: (description: string) => {
        return (
          <div className="description">
            <div className="diagnosticsdesc">{description}</div>
          </div>
        );
      },
    },
  ];

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const loadData = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getFleetIndicatorHistoryList();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getFleetIndicatorHistoryList();
    }
  };

  useEffect(() => {
    if (tractorObj?.id) loadData();
  }, [userDetails, pageNumber, pageSize]);

  const getFleetIndicatorHistoryList = async () => {
    const { organization } = userDetails;
    if (checkActivity()) initScroller();

    try {
      setLoading(true);
      const sortInfo = {
        columnKey: 'created_datetime',
        order: 'descend',
      };
      const result = await getFleetDiagnosticsHistory(
        organization.api_url,
        tractorObj.id || 0,
        'unresolved',
        pageNumber,
        pageSize,
        sortInfo,
      );

      setTotal(result._metadata.total_records_count);
      const { records } = result;
      let data = Array.isArray(records) ? records : [];

      data = mapDiagnosticData(data);
      setData(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Modal
        className="modalContainer profileUpdatePopup termspopup diagnosticModal wVw talertPopup"
        title={tractorObj?.name}
        visible={iconsFlag}
        onCancel={closeModal}
        // width={1150}
        footer={false}
      >
        <div className="modalBox flexnone">
          <div className="formColW">
            <div className="userIcon">
              <div className="tblDft farmTabsTbl hoverable dsTbl">
                <InfiniteScrollTable
                  xScroll={1200}
                  columns={columns}
                  hasMore={hasMore}
                  loading={loading}
                  filterData={filterData}
                  handleLoadMore={handleLoadMore}
                  filename={'ALERTS'}
                  totalcount={total}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default DiagnosticsModal;
